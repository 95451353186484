import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import {
  Button,
  Row,
  Col,
  Modal,
  message,
  DatePicker,
  Select,
  Form,
  Spin,
  Progress,
} from 'antd';
import {
  AuthorizeRoleService,
  MasterdataService,
  ProjectService,
  TimesheetService,
} from '../../services/api';
import calendarBlackIcon from '../../assets/icons/calendarBlack.svg';
import timeIcon from '../../assets/icons/time.svg';
import editSquareIcon from '../../assets/icons/editsquare.svg';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { change0to24 } from '../../services/formatDate';

const Break = styled.div`
  width: 100%;
  border: 0.5px solid #eaeaea;
`;

const Cols = styled(Col)`
  display: flex;
  align-items: center;
  margin: 0px 10px;
`;

const Headdetail = styled.div`
  font-family: 'Kanit';
  font-size: 24px;
  font-weight: 500;
`;

const Horizontalbox = styled.div`
  width: auto;
  display: flex;
  justify-content: ${(props) => props.justify || 'start'};
  margin-bottom: 1rem;
  @media (max-width: 714px) {
    flex-direction: column;
  }
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  padding: 0px 15px;
  background-color: #ffffff;
  border-color: #eaeaea;
  justify-content: center;
  color: #232323;
  font-family: Kanit;
  font-style: Regular;
  font-size: 14px;
  line-height: 19.42px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
`;

const Selectdata = styled(Select)`
  width: 10rem;
  height: 2rem;
`;

class CreateOrEditOtModal extends Component {
  taskFormRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      projectOt: [],
      tasksList: [],
      isButtonLoading: false,
      tasksList: [],
      TIME_IN: '00:00:00',
      TIME_OUT: '00:00:00',
      LUNCH_IN: '00:00:00',
      LUNCH_OUT: '00:00:00',
    };
  }

  componentDidMount = () => {
    this.fetchTaskList();
    this.fetchTimeInTimeOutValue();
    this.fetchProjectOtListByUser(moment().format('YYYY-MM-DD'));
  };

  componentDidUpdate = (prevProps, prevState) => {
    const timeIn = this.getHourFromDate(this.state.TIME_IN);
    const timeOut = this.getHourFromDate(this.state.TIME_OUT);
    if (this.props.editOtModalVisible) {
      if (prevProps.selectedTask !== this.props.selectedTask) {
        this.taskFormRef.current.setFieldsValue({
          projectId: this.props.editOtModalVisible
            ? this.props.selectedTask.projectId
            : null,
          taskTypeId: this.props.editOtModalVisible
            ? this.props.selectedTask.taskTypeId
            : null,
          taskDate: this.props.editOtModalVisible
            ? moment(this.props.selectedTask.taskDate)
            : moment(),
          startTime: this.props.editOtModalVisible
            ? moment(this.props.selectedTask.startTime).format('HH:mm:ss')
            : moment({ hour: timeOut + 1 }).format('HH:mm:ss'),
          endTime: this.props.editOtModalVisible
            ? change0to24(
                moment(this.props.selectedTask.endTime).format('HH:mm:ss'),
                'HH:mm:ss',
              )
            : moment({ hour: timeOut + 2 }).format('HH:mm:ss'),
          description: this.props.editOtModalVisible
            ? this.props.selectedTask.taskDescription
            : null,
        });
      }
    }
    if (this.props.createOtModalVisible !== prevProps.createOtModalVisible) {
      this.taskFormRef.current.resetFields();
      this.taskFormRef.current.setFieldsValue({
        taskDate: moment(),
        startTime: moment({ hour: timeOut + 1 }).format('HH:mm:ss'),
        endTime: moment({ hour: timeOut + 2 }).format('HH:mm:ss'),
      });
    }
  };

  closeCreateOtModal = () => {
    this.props.closeCreateOtModal();
  };

  fetchProjectOtListByUser = (taskDate) => {
    const payload = { taskDate };
    ProjectService.fetchProjectOtListByUser(
      payload,
      ({ data }) => {
        this.setState({ projectOt: data });
        this.taskFormRef.current.setFieldsValue({
          projectId: null,
        });
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          message.error('Error: ' + response.data.message);
        }
      },
    );
  };

  fetchTaskList = () => {
    MasterdataService.fetchTaskList(
      undefined,
      ({ data }) => {
        this.setState({ tasksList: data });
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          message.error('Error: ' + response.data.message);
        }
      },
    );
  };

  fetchTimeInTimeOutValue = () => {
    AuthorizeRoleService.getTimeInTimeOutValue(
      ({ data }) => {
        const { TIME_IN, TIME_OUT, LUNCH_IN, LUNCH_OUT } = data;
        this.setState({
          TIME_IN: TIME_IN,
          TIME_OUT: TIME_OUT,
          LUNCH_IN: LUNCH_IN,
          LUNCH_OUT: LUNCH_OUT,
        });
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          message.error('Error: ' + response.data.message);
        }
      },
    );
  };

  getHourFromDate(dateStr) {
    return parseInt(dateStr.split(':')[0]);
  }

  getTimeOption = () => {
    let res = [];
    let start = moment('00:00:00', 'HH:mm:ss');
    while (start.hour() !== 23) {
      res.push(
        <Select.Option value={start.format('HH:mm:ss')}>
          {start.format('HH:mm')}
        </Select.Option>,
      );
      start.add(30, 'minutes');
    }
    for (let i = 0; i < 2; i++) {
      res.push(
        <Select.Option value={start.format('HH:mm:ss')}>
          {start.format('HH:mm')}
        </Select.Option>,
      );
      start.add(30, 'minutes');
    }
    res.push(<Select.Option value={'24:00:00'}>{'24:00'}</Select.Option>);

    return res;
  };

  handleCreateOt = async (values) => {
    const taskDate = moment(values.taskDate).format('YYYY-MM-DD');
    const payload = {
      projectId: values.projectId,
      taskTypeId: values.taskTypeId,
      taskDescription: values.description,
      taskDate: taskDate,
      startTime: `${taskDate} ${values.startTime}`,
      endTime: `${taskDate} ${values.endTime}`,
      endTaskDate: taskDate,
      isOt: true,
    };
    await TimesheetService.addTimesheet(
      payload,
      ({ data }) => {
        message.success('Successfully created');
      },
      (response) => {
        if (response && response.status === 400) {
          message.error(` ${response.data.message}`);
        }
      },
    );
  };

  handleEditOt = async (values) => {
    const taskDate = moment(values.taskDate).format('YYYY-MM-DD');
    const payload = {
      timesheetId: this.props.selectedTask.id,
      projectId: values.projectId,
      taskTypeId: values.taskTypeId,
      taskDescription: values.description,
      taskDate: taskDate,
      startTime: `${taskDate} ${values.startTime}`,
      endTime: `${taskDate} ${values.endTime}`,
      endTaskDate: taskDate,
      isOt: true,
    };
    await TimesheetService.updateTimesheet(
      payload,
      ({ data }) => {
        message.success('Successfully Edited');
      },
      (response) => {
        if (response && response.status === 400) {
          message.error(`Error: ${response.data.message}`);
        }
      },
    );
    this.setState({
      showTaskData: {},
      showTaskModalVisible: false,
    });
  };

  handleSubmit = async (values) => {
    this.setState({ isButtonLoading: true });
    if (this.props.editOtModalVisible) {
      await this.handleEditOt(values);
    } else {
      await this.handleCreateOt(values);
    }
    this.closeCreateOtModal();
    this.props.handlefetchTimesheetDetail();
    this.setState({ isButtonLoading: false });
  };

  onChangeTaskDate = async (date, dateStr) => {
    const taskDate = date.format('YYYY-MM-DD');
    this.fetchProjectOtListByUser(taskDate);
  };

  render() {
    return (
      <Modal
        width={600}
        open={this.props.createOtModalVisible || this.props.editOtModalVisible}
        onCancel={() => {
          this.closeCreateOtModal();
        }}
        footer={null}
      >
        <Form
          ref={this.taskFormRef}
          onFinish={this.handleSubmit}
          requiredMark={true}
        >
          <Headdetail>
            {this.props.editOtModalVisible ? 'Edit OT' : 'New OT'}
          </Headdetail>
          <Break style={{ marginTop: '1rem', marginBottom: '1.5rem' }} />

          <Horizontalbox>
            <Col
              style={{
                width: '40px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                src={calendarBlackIcon}
                style={{
                  width: '24px',
                  height: '24px',
                  filter:
                    'invert(0%) sepia(3%) saturate(14%) hue-rotate(90deg) brightness(93%) contrast(104%)', // to change color
                }}
              />
            </Col>
            <Form.Item
              style={{ width: '160px', margin: '0', height: 'auto' }}
              name="taskDate"
              rules={[
                {
                  required: true,
                  message: 'Please select Task Date.',
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                onChange={this.onChangeTaskDate}
              />
            </Form.Item>
          </Horizontalbox>
          <Horizontalbox>
            <Cols style={{ width: '40px', margin: '0' }}></Cols>
            <Form.Item
              style={{ width: '100%', margin: '0', maxWidth: '512px' }}
              name="projectId"
              rules={[
                {
                  required: true,
                  message: 'Please select project',
                },
              ]}
            >
              <Selectdata placeholder="Select Project">
                {this.state.projectOt.map((value) => (
                  <Select.Option value={value.id}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <div
                        style={{
                          width: '7px',
                          height: '7px',
                          backgroundColor: value.projectColorCode
                            ? value.projectColorCode
                            : '#000000',
                          borderRadius: '50%',
                          marginRight: '10px',
                        }}
                      ></div>
                      {value.projectNo} - {value.projectName}
                    </div>
                  </Select.Option>
                ))}
              </Selectdata>
            </Form.Item>
          </Horizontalbox>
          <Horizontalbox>
            <Col style={{ width: '40px' }}></Col>
            <Form.Item
              style={{ width: '100%', margin: '0', maxWidth: '512px' }}
              name="taskTypeId"
              rules={[
                {
                  required: true,
                  message: 'Please select task',
                },
              ]}
            >
              <Selectdata placeholder="Select Task">
                {this.state.tasksList
                  .filter((t) => {
                    return t.isActived;
                  })
                  .map((t) => (
                    <Select.Option value={t.id}>{t.taskTypeName}</Select.Option>
                  ))}
              </Selectdata>
            </Form.Item>
          </Horizontalbox>
          <Horizontalbox>
            <Col
              style={{
                width: '40px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                src={timeIcon}
                style={{
                  width: '24px',
                  height: '24px',
                }}
              />
            </Col>
            <Form.Item
              name="startTime"
              fieldKey="startTime"
              rules={[
                {
                  required: true,
                  message: 'Please select Start Time.',
                },
              ]}
            >
              <Selectdata style={{ width: '160px' }}>
                {this.getTimeOption()}
              </Selectdata>
            </Form.Item>

            <div
              style={{
                width: '20px',
                textAlign: 'center',
                verticalAlign: 'middle',
                lineHeight: '32px',
              }}
            >
              -
            </div>
            <Form.Item
              name="endTime"
              fieldKey="endTime"
              dependencies={['startTime', 'endTime']}
              rules={[
                {
                  required: true,
                  message: 'Please select End Time',
                },
                () => ({
                  validator: (_, value) => {
                    const startTimeMoment = moment(
                      this.taskFormRef.current.getFieldValue('startTime'),
                      'HH:mm',
                    );
                    const endTimeMoment = moment(value, 'HH:mm');
                    if (endTimeMoment.isBefore(startTimeMoment)) {
                      return Promise.reject(
                        'End Time must be greater than Start Time.',
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Selectdata style={{ width: '160px' }}>
                {this.getTimeOption()}
              </Selectdata>
            </Form.Item>
          </Horizontalbox>
          <Horizontalbox>
            <Col
              style={{
                width: '40px',
                height: '32px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src={editSquareIcon} />
            </Col>
            <Form.Item
              style={{ width: '100%', maxWidth: '512px', height: '5rem' }}
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Please enter description.',
                },
              ]}
            >
              <TextArea autoSize={{ minRows: 4, maxRows: 4 }}></TextArea>
            </Form.Item>
          </Horizontalbox>
          <Horizontalbox justify="end">
            <NormalButton onClick={this.closeCreateOtModal}>
              Cancel
            </NormalButton>
            <NormalButton
              loading={this.state.isButtonLoading}
              style={{
                marginLeft: '10px',
                backgroundColor: '#004368',
                color: '#FFFFFF',
              }}
              onClick={() => {
                this.taskFormRef.current.submit();
              }}
            >
              Submit
            </NormalButton>
          </Horizontalbox>
        </Form>
      </Modal>
    );
  }
}

export default CreateOrEditOtModal;
