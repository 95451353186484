import React, { Component } from 'react';
import styled from 'styled-components';
const Bar = styled.div`
  width: 100%;
  height: 7.38rem;
  box-sizing: border-box;
  padding-left: 10%;
  padding-right: 10%;
  background: linear-gradient(265.32deg, #2d95a8 0%, #000000 100%);
  font-size: 3.125em;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Kanit';
  color: white;
`;
class Topbar extends Component {
  render() {
    const { barname } = this.props;
    return <Bar>{barname}</Bar>;
  }
}
export default Topbar;
