import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Button,
  Input,
  Table,
  Row,
  Col,
  message,
  Select,
  Popover,
  Popconfirm,
  Spin,
} from 'antd';
import binIcon from '../../assets/icons/bin.svg';
import statusActiveIcon from '../../assets/icons/statusActive.svg';
import statusInactiveIcon from '../../assets/icons/statusInactive.svg';
import { PlusOutlined } from '@ant-design/icons';
import plusIcon from '../../assets/icons/plus.svg';
import { AnnounceService } from '../../services/api';
import { TextFormatter } from '../../components/TextFormatter';
import { redirectTo } from '../../services/redirect';
import moment from 'moment';
import Checkbox from 'antd/lib/checkbox/Checkbox';
const TitleBox = styled.div`
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  flex-direction: row;
  background: ${(props) => props.bg || '#ffffff'};
  height: 6em;
  justify-content: space-between;
  align-items: center;
`;
const FilterBox = styled.div`
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  height: 4em;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.subtitle ? '#20ABC5' : '#ff2132'};
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1.125em' : '1em'};
  font-weight: ${(props) =>
    props.title ? 'bold' : props.subtitle ? '600' : 'normal'};
  font-family: 'Kanit';
  text-transform: ${(props) => (props.subtitle ? 'uppercase' : 'none')};
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  padding: 0px 15px;
  background-color: #004368;
  border-color: #004368;
  justify-content: center;
  color: white;
  font-family: Kanit;
  font-style: Regular;
  font-size: 14px;
  line-height: 19.42px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;
const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#f6f6f6')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: auto;
  min-height: calc(100vh - 7.5em);
`;
const TableText = styled.div`
  color: ${(props) => (props.normal ? '#004368' : '#737373')};
  font-size: 1em;
  font-weight: ${(props) => (props.normal ? '500' : '300')};
  font-family: 'Kanit';
  font-size: 14px
  line-height: 19px;
  text-transform: ${(props) => props.capitalize && 'capitalize'};
  text-align: left;
`;
const AnnounceList = styled(Table)`
  .ant-table-thead .ant-table-cell {
    height: 4rem;
    background-color: #ffffff;
    font-family: 'Kanit';
    line-height: 22px;
    font-weight: 500;
    font-size: 1.25em;
  }
  .ant-table-tbody .ant-table-cell {
    background-color: #ffffff;
  }
  // .ant-pagination-item-active {
  //   background-color: #004368;
  // }

  // .ant-pagination-item-active a {
  //   font-family: 'Kanit';
  //   font-weight: 400;
  //   font-size: 14px;
  //   color: #ffffff !important;
  // }
  padding-left: 3%;
  padding-right: 3%;

  min-height: 330px;
  margin-bottom: 20px;
`;
const Cols = styled(Col)`
  display: flex;
  align-items: center;
  margin: 0px 10px;
`;
const Rows = styled(Row)`
  margin: 10px 0px;
  align-items: center;
`;

const StatusCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #004368;
  }
  fontsize: '14px';
  font-family: 'Kanit';
`;
const Box = styled.div`
  box-sizing: border-box;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 4.9375rem;
  padding-bottom: 5%;
  background: #f6f6f6;
`;

const StyledSpin = styled(Spin)`
  position: fixed;
  text-align: center;
  bottom: 50%;
  z-index: 10;
`;
const SpinBox = styled(Box)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 5;
`;

const { Search } = Input;
class Announce extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasData: false,
      incorrect: false,
      status: 'active',
      type: '',
      search: '',
      limit: 10,
      offset: 0,
      total: 1,
      current: 1,
      role: '',
    };
  }
  handlefetchAnnouncementList = () => {
    const params = {
      status: this.state.status,
      type: this.state.type,
      search: this.state.search,
    };
    AnnounceService.fetchAnnouncementList(
      params,
      ({ data }) => {
        this.setState({
          hasData: true,
          data: data,
          total: data.Total,
        });
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          if (response.data.message != 'empty array') {
            message.error('Cannot access data: ' + response.data.message);
          }
        }
      },
    );
  };

  handlefetchAnnouncementListWithStatus = (status) => {
    /* this.setState({ hasData: false }); */
    const params = {
      status: status,
      type: this.state.type,
      search: this.state.search,
    };
    AnnounceService.fetchAnnouncementList(
      params,
      ({ data }) => {
        this.setState({
          hasData: true,
          data: data,
          total: data.Total,
          status: status,
        });
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          if (response.data.message != 'empty array') {
            message.error('Cannot access data: ' + response.data.message);
          }
        }
      },
    );
  };

  handlefetchAnnouncementListWithSearch = (value) => {
    const params = {
      status: this.state.status,
      type: this.state.type,
      search: value,
    };
    AnnounceService.fetchAnnouncementList(
      params,
      ({ data }) => {
        this.setState({
          hasData: true,
          data: data,
          total: data.Total,
          search: value,
        });
        console.log('payload', params);
        console.log('resp serach onchange', data);
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          if (response.data.message != 'empty array') {
            message.error('Cannot access data: ' + response.data.message);
          }
        }
      },
    );
  };

  handlefetchAnnouncementListWithType = (value) => {
    const params = {
      status: this.state.status,
      type: value,
      search: this.state.search,
    };
    AnnounceService.fetchAnnouncementList(
      params,
      ({ data }) => {
        this.setState({
          hasData: true,
          data: data,
          total: data.Total,
          type: value,
        });
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          if (response.data.message != 'empty array') {
            message.error('Cannot access data: ' + response.data.message);
          }
        }
      },
    );
  };

  componentDidMount = () => {
    this.handlefetchAnnouncementList();
  };
  handleRedirect = (path) => {
    redirectTo(path);
  };
  handleDeleteAnnounce = (id) => {
    AnnounceService.deleteAnnouncement(
      id,
      ({ data }) => {
        let secondsToGo = 1;
        message.success('Delete announce successfully.');
        const timer = setInterval(() => {
          secondsToGo -= 1;
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          // redirectTo(`/customer`);
          this.handlefetchAnnouncementList();
        }, secondsToGo * 1000);
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Error: ' + response.data.message);
        }
      },
    );
  };
  showTotal(total) {
    return total > 1 ? `Total ${total} items` : `Total ${total} item`;
  }
  onSearch = (value) => {
    /* this.setState(
      {
        search: value,
        hasData: false,
      },
      () => this.handlefetchAnnouncementList()
    ); */
    this.handlefetchAnnouncementListWithSearch(value);
  };
  onActiveStatusFilterChange = (value) => {
    if (this.state.status === 'both') {
      this.handlefetchAnnouncementListWithStatus('inactive');
    } else if (this.state.status === 'inactive') {
      this.handlefetchAnnouncementListWithStatus('both');
    } else if (this.state.status === 'active') {
      this.handlefetchAnnouncementListWithStatus('not selected');
    } else {
      this.handlefetchAnnouncementListWithStatus('active');
    }
  };
  onInActiveStatusFilterChange = (value) => {
    if (this.state.status === 'both') {
      this.handlefetchAnnouncementListWithStatus('active');
    } else if (this.state.status === 'active') {
      this.handlefetchAnnouncementListWithStatus('both');
    } else if (this.state.status === 'inactive') {
      this.handlefetchAnnouncementListWithStatus('not selected');
    } else {
      this.handlefetchAnnouncementListWithStatus('inactive');
    }
  };
  onTypeFilterChange = (value) => {
    /* this.setState(
      {
        type: value,
        hasData: false,
      },
      () => this.handlefetchAnnouncementList()
    ); */
    this.handlefetchAnnouncementListWithType(value);
  };
  onChange = (page) => {
    console.log(page);
    const offset = (page - 1) * this.state.limit;
    this.setState(
      {
        current: page,
        offset: offset,
      },
      () => this.handlefetchAnnouncementList(),
    );
  };
  render() {
    let columns = [
      {
        title: 'ANNOUNCE TOPIC',
        dataIndex: 'announcementName',
        index: 'announcementName',
        width: '50%',
        fixed: 'left',
        align: 'center',
        sorter: {
          compare: (a, b) =>
            a.announcementName.localeCompare(b.announcementName),
        },
        render: (name, record) => (
          <a
            onClick={() =>
              this.handleRedirect('/announce/announceform/?id=' + record.id)
            }
          >
            {name.length > 75 ? (
              <TableText normal capitalize>
                {TextFormatter.normalText(name.substring(0, 74).concat('...'))}
              </TableText>
            ) : (
              <TableText normal capitalize>
                {TextFormatter.normalText(name)}
              </TableText>
            )}
          </a>
        ),
      },
      {
        title: 'TYPE',
        index: 'type',
        dataIndex: 'type',
        width: '10%',
        align: 'center',
        sorter: {
          compare: (a, b) => a.type.localeCompare(b.type),
        },
        render: (type) => (
          <TableText capitalize style={{ textAlign: 'center' }}>
            {type}
          </TableText>
        ),
      },
      {
        title: 'STATUS',
        index: 'isActived',
        dataIndex: 'isActived',
        align: 'center',
        width: '10%',
        render: (isActived) => (
          <Popover
            content={
              <span style={{ display: 'flex' }}>
                <img
                  src={!isActived ? statusInactiveIcon : statusActiveIcon}
                  style={{ marginRight: '5px' }}
                />
                <span>{isActived ? 'Active' : 'Inactive'}</span>
              </span>
            }
            arrowPointAtCenter
          >
            <img
              src={!isActived ? statusInactiveIcon : statusActiveIcon}
              style={{ marginRight: '5px' }}
            />
          </Popover>
        ),
      },
      {
        title: 'ACTION',
        index: 'action',
        // dataIndex: "index",
        align: 'center',
        width: '10%',
        render: (key, record) =>
          key && (
            <Popconfirm
              title="Are you sure to delete？"
              okText="OK"
              cancelText="Cancel"
              okButtonProps={{
                style: {
                  backgroundColor: '#004368',
                  borderColor: '##004368',
                },
              }}
              onConfirm={() => {
                this.handleDeleteAnnounce(record.id);
              }}
              onCancel={() => {
                console.log('Cancel');
              }}
            >
              <img src={binIcon} />
            </Popconfirm>
          ),
      },
    ];
    return this.state.hasData ? (
      <div style={{ width: 'auto', backgroundColor: '#FFFFFF' }}>
        <TitleBox style={{ paddingTop: '1%' }}>
          <Text title style={{ fontSize: '16px', fontWeight: '500' }}>
            All Announce
          </Text>
          <NormalButton
            href={`./announce/announceform/`}
            icon={<PlusOutlined />}
          >
            Create Announce
          </NormalButton>
        </TitleBox>
        <FilterBox style={{ height: 'auto' }}>
          <Rows>
            <Cols style={{ marginLeft: '0px' }}>
              <StatusCheckbox
                checked={
                  this.state.status === 'active' || this.state.status === 'both'
                }
                onChange={this.onActiveStatusFilterChange}
              >
                Active
              </StatusCheckbox>
            </Cols>
            <Cols>
              <StatusCheckbox
                checked={
                  this.state.status === 'inactive' ||
                  this.state.status === 'both'
                }
                onChange={this.onInActiveStatusFilterChange}
              >
                Inactive
              </StatusCheckbox>
            </Cols>
          </Rows>

          <Rows>
            <Cols>
              <Search
                placeholder="Search"
                //onSearch={(value) => this.onSearch(value)}
                onChange={(e) => this.onSearch(e.target.value)}
                style={{ width: 300 }}
                loading={!this.state.hasData && !this.state.incorrect}
              />
            </Cols>
            <Cols style={{ marginRight: '0px' }}>
              <div>
                <Select
                  style={{ width: 300 }}
                  onChange={this.onTypeFilterChange}
                  defaultValue={''}
                >
                  <Select.Option value="">All Type</Select.Option>
                  <Select.Option value="news">News</Select.Option>
                  <Select.Option value="social security">
                    Social security
                  </Select.Option>
                  <Select.Option value="other">Other</Select.Option>
                </Select>
              </div>
            </Cols>
          </Rows>
        </FilterBox>
        <ContentBox nc={true}>
          <AnnounceList
            columns={columns}
            dataSource={this.state.hasData ? this.state.data : null}
            style={{ width: '100%' }}
            scroll={{ x: 500 }}
            size="default"
            loading={!this.state.hasData && !this.state.incorrect}
            pagination={{
              position: ['bottomRight'],
              pageSizeOptions: [10, 20, 30, 40],
              showSizeChanger: true,
            }}
          />
        </ContentBox>
      </div>
    ) : (
      <SpinBox>
        <StyledSpin size="large" />
      </SpinBox>
    );
  }
}
export default Announce;
