import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import { Col, Modal } from 'antd';
import moment from 'moment';
import closeIcon from '../../assets/icons/close.svg';
import penIcon from '../../assets/icons/pen.svg';
import binBlackIcon from '../../assets/icons/binBlack.svg';
import taskDescriptionIcon from '../../assets/icons/taskDescription.svg';
import { change0to24 } from '../../services/formatDate';

const Cols = styled(Col)`
  display: flex;
  align-items: center;
  margin: 0px 10px;
`;

const Headdetail = styled.div`
  font-family: 'Kanit';
  font-size: 24px;
  font-weight: 500;
`;

const Horizontalbox = styled.div`
  width: auto;
  display: flex;
  justify-content: ${(props) => props.justify || 'start'};
  margin-bottom: 1rem;
  @media (max-width: 714px) {
    flex-direction: column;
  }
`;

const InfoText = styled.div`
  color: ${(props) => (props.color ? props.color : '#232323')};
  font-size: ${(props) =>
    props.title ? '1.125em' : props.subtitle ? '1.125em' : '0.9em'};
  font-weight: ${(props) => (props.title ? 'bold' : 'normal')};
  font-family: 'Kanit';
  text-transform: ${(props) => (props.subtitle ? 'uppercase' : 'none')};
  margin-bottom: 5px;
`;

class TaskModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'normal',
    };
  }

  changeMode = (data) => {
    if (data.startTime === null) {
      this.setState({
        mode: 'hour',
      });
    } else if (data.workMinutes > 0) {
      this.setState({
        mode: 'normal',
      });
    } else if (
      data.otWorkingMinutes > 0 ||
      data.otHolidayWorkingMinutes > 0 ||
      data.otHolidayNonWorkingMinutes > 0
    ) {
      this.setState({
        mode: 'ot',
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showTaskData !== prevProps.showTaskData) {
      this.changeMode(this.props.showTaskData);
    }
  };

  onClickOpenEditModal = () => {
    switch (this.state.mode) {
      case 'normal':
        this.props.openEditTaskModal();
        break;
      case 'ot':
        this.props.openEditOtModal();
        break;
      default:
        break;
    }
  };

  onClickOpenDeleteTaskModal = () => {
    this.props.openDeleteTaskModal();
  };

  render() {
    return (
      <Modal
        title={
          <div
            style={{
              justifyContent: 'end',
              display: 'flex',
            }}
          >
            {this.state.mode !== 'hour' && (
              <>
                <img
                  src={penIcon}
                  style={{
                    marginRight: '10px',
                  }}
                  onClick={this.onClickOpenEditModal}
                />
                <img
                  src={binBlackIcon}
                  style={{
                    marginRight: '10px',
                  }}
                  onClick={this.onClickOpenDeleteTaskModal}
                />
              </>
            )}
            <img src={closeIcon} onClick={this.props.closeTaskModal} />
          </div>
        }
        open={this.props.showTaskModalVisible}
        width={600}
        footer={null}
        onCancel={this.props.closeTaskModal}
        closable={false}
      >
        <Horizontalbox>
          <Cols
            style={{
              width: '40px',
            }}
          >
            <div
              style={{
                padding: '10px',
                borderRadius: '3px',
                backgroundColor: this.props.showTaskData?.projectColorCode
                  ? `${this.props.showTaskData?.projectColorCode}`
                  : '#000000',
              }}
            ></div>
          </Cols>
          <Headdetail>
            {this.props.showTaskData?.projectNo} -{' '}
            {this.props.showTaskData?.projectName}
          </Headdetail>
        </Horizontalbox>
        <Horizontalbox>
          <Cols style={{ width: '40px' }}></Cols>
          <InfoText>
            {moment(this.props.showTaskData?.taskDate).format(
              'dddd, MMM D, yyyy',
            )}
          </InfoText>
          <Cols width="30px"></Cols>
          {this.props.showTaskData?.startTime && (
            <InfoText>
              {moment(this.props.showTaskData?.startTime).format('HH.mm')}
              {' - '}
              {change0to24(
                moment(this.props.showTaskData?.endTime).format('HH.mm'),
                'HH.mm',
              )}
            </InfoText>
          )}
        </Horizontalbox>
        <Horizontalbox>
          <Cols style={{ width: '40px' }}>
            <img src={taskDescriptionIcon} />
          </Cols>
          <InfoText>{this.props.showTaskData?.taskDescription}</InfoText>
        </Horizontalbox>
      </Modal>
    );
  }
}

export default TaskModal;
