import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { redirectTo, subDomain } from '../services/redirect';
import { imageservice, profileservice as api } from '../services/api';

import { AppContext } from './context/AppContext';

import {
  message,
  Layout,
  Typography,
  Row,
  Col,
  Dropdown,
  Menu,
  Button,
  Avatar,
  Space,
  Badge,
  Divider,
} from 'antd';
import Icon, { CloudFilled } from '@ant-design/icons';
import { SyncOutlined, CheckOutlined } from '@ant-design/icons';
import defaultProfileImage from '../assets/icons/profileimage.svg';
import dragdownIcon from '../assets/icons/dragdown.svg';
import notificationIcon from '../assets/icons/notification.svg';
import { ReactComponent as smileIcon } from '../assets/icons/smile.svg';
import { ReactComponent as settingIcon } from '../assets/icons/setting.svg';
import { ReactComponent as logoutIcon } from '../assets/icons/logout.svg';

const { Text } = Typography;
const { Header } = Layout;

const Icon14 = ({ component }) => {
  return (
    <Icon
      component={component}
      style={{
        color: 'transparent',
        fontSize: '14px',
        margin: '0px 10px 0px 5px',
      }}
    />
  );
};

const NewHeader = ({ labelHeader }) => {
  const { userState, setUserState } = useContext(AppContext);
  const [fullname, setFullname] = useState('');
  const [profileImage, setprofileImage] = useState(null);
  const [notifs, setNotifs] = useState();

  const handleLogout = () => {
    sessionStorage.clear();
    let secondsToGo = 1;
    message.loading('Logout');
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      redirectTo(`/login`);
    }, secondsToGo * 1000);
  };

  const handleRedirect = (path) => {
    redirectTo(path);
  };

  var jwt = require('jsonwebtoken');
  var token = sessionStorage.getItem('access-token');
  var decode1 = jwt.decode(token);

  const notificationDropdownMenu = (
    <Menu style={{ fontFamily: 'Kanit' }}>
      <Menu.Item key="notificationLabelDropdown">
        <Space>notification</Space>
      </Menu.Item>
      <Menu.Item type="divider">{/* <Divider /> */}</Menu.Item>
    </Menu>
  );

  const switchRole = (role) => {
    setUserState({
      ...userState,
      currentRole: role,
    });
    sessionStorage.setItem('current-role', role);
  };

  const checked = (role) => {
    return userState.currentRole === role ? (
      <CheckOutlined />
    ) : (
      <CheckOutlined style={{ visibility: 'hidden' }} />
    );
  };

  const profileDropdownMenu = (
    <Menu style={{ fontFamily: 'Kanit' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          style={{ margin: '0 5%' }}
          size={23}
          src={profileImage ? profileImage : defaultProfileImage}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text>{fullname}</Text>
          <Text style={{ fontWeight: '300', fontSize: '0.85em' }}>
            {userState.currentRole}
          </Text>
        </div>
      </div>
      <Menu.Divider key="dividerDropdown" />
      <Menu.Item
        key="profileDropdown"
        icon={<Icon14 component={smileIcon} />}
        onClick={() => handleRedirect('/profile/?id=' + decode1.userId)}
        label="My Profile"
      >
        My Profile
      </Menu.Item>
      {userState.role !== 'USER' && (
        <Menu.SubMenu
          title="Switch Role"
          key="switchRoleDropdown"
          icon={
            <SyncOutlined
              style={{
                marginLeft: '7px',
              }}
            />
          }
        >
          {userState.role === 'ADMIN' && (
            <Menu.Item
              key="admin"
              icon={checked('ADMIN')}
              onClick={() => switchRole('ADMIN')}
            >
              Admin
            </Menu.Item>
          )}
          <Menu.Item
            key="user"
            icon={checked('USER')}
            onClick={() => switchRole('USER')}
          >
            User
          </Menu.Item>
        </Menu.SubMenu>
      )}
      {/* <Menu.Item
        key='settingDropdown'
        icon={<Icon14 component={settingIcon} />}
      >
        Setting
      </Menu.Item> */}
      <Menu.Item
        key="logoutDropdown"
        icon={<Icon14 component={logoutIcon} />}
        onClick={handleLogout}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const getUserData = async () => {
      if (decode1 && decode1.userId) {
        const emp_index = decode1.userId;
        await api.personalDetail.getpersonalDetail(
          emp_index,
          async ({ data }) => {
            const { firstnameEn, lastnameEn, picturePath } = data;
            setFullname(
              firstnameEn && lastnameEn ? `${firstnameEn} ${lastnameEn}` : '',
            );
            if (!!picturePath) {
              imageservice.image.getImage(picturePath, (res) => {
                if (res.status) return false;
                const blobUrl = URL.createObjectURL(res.data);
                console.log(blobUrl);
                setprofileImage(blobUrl);
              });
            }
          },
          (response) => {
            console.log('can not fetch fullname', response);
          },
        );
      }
    };
    getUserData();
  }, []);

  return (
    <Header
      style={{
        background: '#fff',
        borderBottom: '1px solid rgb(212, 212, 212)',
      }}
    >
      <Row align="middle">
        <Col>
          <Typography
            style={{
              fontWeight: '500',
              fontSize: '22px',
              fontFamily: 'Kanit',
            }}
          >
            {labelHeader}
          </Typography>
        </Col>
        <Col flex="auto"></Col>
        <Space size="large">
          <Col>
            {notifs ? (
              <Dropdown
                overlay={notificationDropdownMenu}
                placement="bottomRight"
                arrow={{ pointAtCenter: true }}
                overlayStyle={{ width: '300px' }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Badge count={0}>
                    <img src={notificationIcon} />
                  </Badge>
                </a>
              </Dropdown>
            ) : (
              <></>
            )}
          </Col>
          <Col>
            <Dropdown
              overlay={profileDropdownMenu}
              placement="bottomRight"
              arrow
              overlayStyle={{ width: '240px' }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Avatar
                    src={profileImage ? profileImage : defaultProfileImage}
                  />
                  <img src={dragdownIcon} />
                </Space>
              </a>
            </Dropdown>
          </Col>
        </Space>
      </Row>
    </Header>
  );
};

export default NewHeader;
