import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Thai from '../../assets/icons/thai.svg';
import UK from '../../assets/icons/uk.svg';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  Checkbox,
  InputNumber,
  TreeSelect,
  Switch,
  Row,
  Col,
  Upload,
  Badge,
} from 'antd';
import Uploader from '../components/uploader.js';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { MasterdataService } from '../../services/api';
import DetailBox from './detailBox';
import ChangePasswordModal from './changePasswordModal';
import { AppContext } from '../../components/context/AppContext';

const MainBox = styled.div`
  font-family: 'Kanit';
`;
const Checkboxdata = styled(Checkbox)`
  // width:100rem;
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ffffff;
    border-color: #e2e2e2;
  }
  & .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #004368 !important;
  }
`;
const Buttonedit = styled(Button)`
  width: 72px;
  height: 30px;
  background: #2d95a8;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-item: center;
  color: white;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
`;
const Horizontalbox = styled.div`
  display: flex;
  @media (max-width: 714px) {
    flex-direction: column;
  }
`;
const FlexBox = styled.div`
  width: 50%;
  display: flex;
  align-items: baseline;
  padding-right: 2rem;

  box-sizing: border-box;
  margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: column;
  }
  @media (max-width: 714px) {
    padding-right: 0;
    width: 100%;
  }
`;
const Formitem = styled(Form.Item)`
  // border: 2px solid red;
  &.ant-form-item {
    // background:blue;
  }
`;

const Inputdata = styled(Input)`
  // max-width:14rem;
  width: 100%;
  height: 2rem;
  // margin-left:2rem;
`;
const InputdataNumber = styled(InputNumber)`
  // max-width:14rem;
  width: 100%;
  height: 2rem;
  // margin-left:2rem;
`;

const Selectitem = styled(Select)`
  width: 100%;
  // max-width:14rem;
  // margin-left:2rem;
`;
const Selectoption = styled(Select.Option)``;
const Breakline = styled.br``;
const Formdata = styled(Form)`
  &.ant-form label {
    font-size: 16px;
    width: 10rem;
    padding-right: 60px;
  }
  layout: 'vertical';
`;
const Datepicker = styled(DatePicker)`
  width: 100%;
  // max-width:14rem;
  // margin-left:2rem;
`;
const Line = styled.div`
  // margin-top:2.0625rem;
  margin-bottom: 1.5rem;
  height: 0.0625rem;
  background: #e3e3e3;
  width: 100%;
`;
const Headdetail = styled.div`
  font-family: 'Kanit';
  font-size: 26px;
  font-weight: 500;
  color: #004368;
  margin-bottom: 1rem;
`;
const Label = styled.div`
  // font-family: 'Kanit';
  font-size: 16px;
  font-weight: 600;
  width: 11rem;
  min-width: 11rem;
  color: black;
  @media (max-width: 1424px) {
    width: 100%;
    min-width: none;
  }
`;
const Textdata = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  color: #737373;
`;
const Textemer = styled.div`
  color: black;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;
`;
const Image = styled.img`
  width: 25%;
`;

const nonRequiredFields = [
  'phoneNumber',
  'jlpt',
  'toeic',
  'carDrivingLicense',
  'signaturePath',
  'lineId',
];

const StatusSwitch = styled(Switch)`
  height: 32px;
  width: 85px;
  &.ant-switch {
    background: #ffab4f;
  }
  &.ant-switch .ant-switch-inner {
    margin: 0 -2px 0 25px;
  }

  &.ant-switch-checked {
    background: #004368;
  }

  & .ant-switch-handle {
    width: 27px;
    height: 27px;
    overflow: hidden;
    border-radius: 999999px;
  }

  &.ant-switch-checked .ant-switch-inner {
    margin: 0 25px 0 -3px;
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 30px);
  }
`;

class Personaldetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      osLicensePicturePath: this.props.osLicensePicturePath,
      microsoftOfficeLicensePicturePath:
        this.props.microsoftOfficeLicensePicturePath,
      signaturePath: this.props.signaturePath,
      isChangePasswordModalVisible: false,
      formValue: {},
      shouldRenderComponent: false,
    };
    this.formRef = React.createRef();
  }

  static contextType = AppContext;

  initialValues = () => {};

  onFinish = (values) => {
    console.log(values);
    values.osLicensePicturePath = this.state.osLicensePicturePath;
    values.microsoftOfficeLicensePicturePath =
      this.state.microsoftOfficeLicensePicturePath;
    values.signaturePath = this.state.signaturePath;
    this.props.onSubmit(values);
    this.props.changeEditMode();
  };
  handleOsLicensePicture = (fd, index) => {
    this.setState({ osLicensePicturePath: fd });
  };
  handleMicrosoftLicensePicture = (fd, index) => {
    this.setState({ microsoftOfficeLicensePicturePath: fd });
  };
  handleSignaturePicture = (fd, index) => {
    this.setState({ signaturePath: fd });
  };
  componentDidMount = async () => {
    MasterdataService.fetchRoleList({}, async ({ data }) => {
      this.setState({ roleList: data });
    });
    MasterdataService.fetchLevelList({}, async ({ data }) => {
      data = data.filter((value) => {
        return value.isActived;
      });
      this.setState({ levelList: data });
    });
    MasterdataService.fetchSupervisorList({}, async ({ data }) => {
      this.setState({ supervisorList: data });
    });
    MasterdataService.fetchDepartmentList({}, async ({ data }) => {
      data = data.filter((value) => {
        return value.isActived;
      });
      this.setState({ departmentList: data });
    });
    MasterdataService.fetchSectionList({}, async ({ data }) => {
      data = data.filter((value) => {
        return value.isActived;
      });
      this.setState({ sectionList: data });
    });
    MasterdataService.fetchPositionList({}, async ({ data }) => {
      data = data.filter((value) => {
        return value.isActived;
      });
      this.setState({ positionList: data });
    });
  };

  componentDidUpdate = () => {
    const { userState } = this.context;
    if (userState.currentRole !== this.state.userCurrentRole) {
      this.setState({
        userCurrentRole: userState.currentRole,
      });
    }
  };

  handleOpenModal = () => {
    this.setState({ isChangePasswordModalVisible: true });
  };

  handleCloseModal = () => {
    this.setState({ isChangePasswordModalVisible: false });
  };

  renderEditView = () => {
    const { forceEditMode, showRoleField, showPasswordField, currentPage } =
      this.props;
    // currentPage : user, profile, add
    var jwt = require('jsonwebtoken');
    var token = sessionStorage.getItem('access-token');
    var decode1 = jwt.decode(token);
    const enableAllFields = currentPage !== 'profile';
    return (
      <MainBox>
        <ChangePasswordModal
          visible={this.state.isChangePasswordModalVisible}
          closeModal={this.handleCloseModal}
        />
        <Box>
          <Form
            id="personalInformation"
            ref={this.formRef}
            onFinish={this.onFinish}
            onValuesChange={(changedValues, allValues) => {
              // Handle form values change here if needed
              // console.log('Changed Values:', changedValues);
              console.log('All Values:', allValues);

              // Optionally, update the component state with the form values
              this.setState({ formValue: allValues });
            }}
            initialValues={{
              firstnameEn: this.props.data.firstnameEn,
              lastnameEn: this.props.data.lastnameEn,
              firstnameTh: this.props.data.firstnameTh,
              lastnameTh: this.props.data.lastnameTh,
              position: this.props.data.position,
              positionId: this.props.data.positionId,
              department: this.props.data.department,
              departmentId: this.props.data.departmentId,
              sectionId: this.props.data.sectionId,
              gender: this.props.data.gender,
              email: this.props.data.email,
              nicknameTh: this.props.data.nicknameTh,
              nicknameEn: this.props.data.nicknameEn,
              phoneNumber: this.props.data.phoneNumber,
              mobileNumber: this.props.data.mobileNumber,
              empCode: this.props.data.empCode,
              citizenId: this.props.data.citizenId,
              jlpt: this.props.data.jlpt,
              toeic: this.props.data.toeic,
              maritalStatus: this.props.data.maritalStatus,
              carDrivingLicense: this.props.data.carDrivingLicense,
              bloodGroup: this.props.data.bloodGroup,
              religion: this.props.data.religion,
              nationality: this.props.data.nationality,
              notebookOwner: this.props.data.notebookOwner,
              notebookYear: this.props.data.notebookYear,
              notebookModel: this.props.data.notebookModel,
              notebookSerialNo: this.props.data.notebookSerialNo,
              lineId: this.props.data.lineId,
              role: this.props.data.role,
              roleId: this.props.data.roleId,
              contractType: this.props.data.contractType,
              level: this.props.data.level,
              notebookOs: this.props.data.notebookOs,
              // password: this.props.data.password,
              birthday: this.props.data.birthday
                ? moment(this.props.data.birthday, 'YYYY-MM-DD')
                : null,
              startJobDate: this.props.data.startJobDate
                ? moment(this.props.data.startJobDate, 'YYYY-MM-DD')
                : null,
              endProbationDate: this.props.data.endProbationDate
                ? moment(this.props.data.endProbationDate, 'YYYY-MM-DD')
                : null,
              emergencyName: this.props.data.emergencyName,
              emergencyRelationship: this.props.data.emergencyRelationship,
              emergencyMobileNumber: this.props.data.emergencyMobileNumber,
              emergencyAddress: this.props.data.emergencyAddress,

              militaryStatus: this.props.data.militaryStatus,
              osLicensePicturePath: this.props.data.osLicensePicturePath,
              picturePath: this.props.data.picturePath,
              updateDatetime: this.props.data.updateDatetime,
              index: this.props.data.index,
              userId: this.props.data.userId,
              microsoftOfficeLicensePicturePath:
                this.props.data.microsoftOfficeLicensePicturePath,
              signaturePath: this.props.data.signaturePath,
              supervisorId: this.props.data.supervisorId,
              resignaitonDate: this.props.data.resignaitonDate
                ? moment(this.props.data.resignaitonDate, 'YYYY-MM-DD')
                : null,
              isActived: this.props.data.isActived,
              isBoi: this.props.data.isBoi,
              bankAccountNo: this.props.data.bankAccountNo,
            }}
          >
            <Form.Item style={{ display: 'none' }} name="militaryStatus" />
            <Form.Item style={{ display: 'none' }} name="picturePath" />
            <Form.Item style={{ display: 'none' }} name="updateDatetime" />

            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  Role
                  {nonRequiredFields.find((nf) => {
                    return nf === 'roleId';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="roleId"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your Role',
                    },
                  ]}
                >
                  <Selectitem id="roleId" disabled={!enableAllFields}>
                    {this.state.roleList?.map((value) => {
                      return (
                        <Selectoption value={value.id}>
                          {value.dropDownName}
                        </Selectoption>
                      );
                    })}
                  </Selectitem>
                </Form.Item>
              </FlexBox>

              <FlexBox Right>
                <Label>
                  Position
                  {nonRequiredFields.find((nf) => {
                    return nf === 'positionId';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="positionId"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your Position',
                    },
                  ]}
                >
                  <Selectitem id="positionId" disabled={!enableAllFields}>
                    {this.state.positionList?.map((value) => {
                      return (
                        <Selectoption value={value.id}>
                          {`${value.positionName}`}
                        </Selectoption>
                      );
                    })}
                  </Selectitem>
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  Username
                  {nonRequiredFields.find((nf) => {
                    return nf === 'email';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Email',
                    },
                    {
                      type: 'email',
                      message: 'Please input valid Email',
                    },
                  ]}
                >
                  {enableAllFields ? (
                    <Inputdata id="email" />
                  ) : (
                    <Textdata>{this.props.data.email}</Textdata>
                  )}
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  Password
                  {nonRequiredFields.find((nf) => {
                    return nf === 'password';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                {currentPage !== 'profile' ? (
                  <Fragment>
                    {currentPage === 'add' ? (
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Password',
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (value.length < 8) {
                                return Promise.reject(
                                  'Your password must be at least 8 characters',
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Inputdata.Password id="password" />
                      </Form.Item>
                    ) : (
                      // <Textdata>{this.props.data.password}</Textdata>
                      <Textdata>*****</Textdata>
                    )}
                  </Fragment>
                ) : (
                  <Button
                    style={{
                      color: ' #2D95A8',
                      border: '1px solid #2D95A8',
                      height: '1.875rem',
                    }}
                    onClick={this.handleOpenModal}
                  >
                    Change Password
                  </Button>
                )}
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  Employee ID
                  {nonRequiredFields.find((nf) => {
                    return nf === 'empCode';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="empCode"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Employee ID',
                    },
                  ]}
                >
                  <Inputdata id="empCode" disabled={!enableAllFields} />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  Contract type
                  {nonRequiredFields.find((nf) => {
                    return nf === 'contractType';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="contractType"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your Contract type',
                    },
                  ]}
                >
                  <Selectitem id="contact" disabled={!enableAllFields}>
                    <Selectoption value="Permanent">Permanent</Selectoption>
                    <Selectoption value="Contract">Contract</Selectoption>
                    <Selectoption value="Part Time">Part Time</Selectoption>
                  </Selectitem>
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  Firstname&nbsp;<img src={UK}></img>
                  {nonRequiredFields.find((nf) => {
                    return nf === 'firstnameEn';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="firstnameEn"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Firstname',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        function regexEngChars(string) {
                          return /^[a-zA-Z']+$/.test(string);
                        }
                        if (!regexEngChars(value)) {
                          return Promise.reject('Please input only English.');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Inputdata id="firstnameEn" />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  Lastname&nbsp;<img src={UK}></img>
                  {nonRequiredFields.find((nf) => {
                    return nf === 'lastnameEn';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="lastnameEn"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Lastname',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        function regexEngChars(string) {
                          return /^[a-zA-Z']+$/.test(string);
                        }
                        if (!regexEngChars(value)) {
                          return Promise.reject('Please input only English.');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Inputdata id="lastnameEn" />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  Firstname&nbsp;<img src={Thai}></img>
                  {nonRequiredFields.find((nf) => {
                    return nf === 'firstnameTh';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="firstnameTh"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Firstname',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        function regexThaiChars(string) {
                          var regex = /^[\u0E00-\u0E7F']+$/;
                          return regex.test(string);
                        }
                        if (!regexThaiChars(value)) {
                          return Promise.reject('Please input only Thai.');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Inputdata id="firstnameTh" />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  Lastname&nbsp;<img src={Thai}></img>
                  {nonRequiredFields.find((nf) => {
                    return nf === 'lastnameTh';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="lastnameTh"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Lastname',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        function regexThaiChars(string) {
                          var regex = /^[\u0E00-\u0E7F']+$/;
                          return regex.test(string);
                        }
                        if (!regexThaiChars(value)) {
                          return Promise.reject('Please input only Thai.');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Inputdata id="lastnameTh" />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox>
                <Label>
                  Department
                  {nonRequiredFields.find((nf) => {
                    return nf === 'departmentId';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="departmentId"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your Department',
                    },
                  ]}
                >
                  <Selectitem disabled={!enableAllFields} id="departmentId">
                    {this.state.departmentList?.map((value) => {
                      return (
                        <Selectoption value={value.id}>
                          {`${value.name}`}
                        </Selectoption>
                      );
                    })}
                  </Selectitem>
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  Section
                  {nonRequiredFields.find((nf) => {
                    return nf === 'sectionId';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="sectionId"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your Section',
                    },
                  ]}
                >
                  <Selectitem disabled={!enableAllFields} id="sectionId">
                    {this.state.sectionList?.map((value) => {
                      return (
                        <Selectoption value={value.id}>
                          {`${value.sectionName}`}
                        </Selectoption>
                      );
                    })}
                  </Selectitem>
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  Gender
                  {nonRequiredFields.find((nf) => {
                    return nf === 'gender';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your Gender',
                    },
                  ]}
                >
                  <Selectitem
                    id="gender"
                    onSelect={(value) =>
                      this.setState({ selectedGender: value })
                    }
                  >
                    <Selectoption value="Male">Male</Selectoption>
                    <Selectoption value="Female">Female</Selectoption>
                    {/* <Selectoption value='Others'>Others</Selectoption> */}
                  </Selectitem>
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  Citizen ID
                  {nonRequiredFields.find((nf) => {
                    return nf === 'citizenId';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="citizenId"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please input your Citizen ID',
                  //   },
                  //   ({ getFieldValue }) => ({
                  //     validator(rule, value) {
                  //       console.log('val', value);
                  //       function containsOnlyNumbers(str) {
                  //         return /^\d+$/.test(str);
                  //       }
                  //       if (!containsOnlyNumbers(value)) {
                  //         return Promise.reject('Digits only.');
                  //       }
                  //       if (value.toString().length !== 13) {
                  //         return Promise.reject('Citizen ID is 13 digits.');
                  //       } else {
                  //         return Promise.resolve();
                  //       }
                  //     },
                  //   }),
                  // ]}
                >
                  <InputdataNumber id="citizenId" disabled={!enableAllFields} />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  Level
                  {nonRequiredFields.find((nf) => {
                    return nf === 'level';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="level"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your Level',
                    },
                  ]}
                >
                  <Selectitem id="level" disabled={!enableAllFields}>
                    {this.state.levelList?.map((value) => {
                      return (
                        <Selectoption value={value.levelNo}>
                          {value.levelName}
                        </Selectoption>
                      );
                    })}
                  </Selectitem>
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  Birthday
                  {nonRequiredFields.find((nf) => {
                    return nf === 'birthday';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="birthday"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your Birthday',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value.isAfter(moment())) {
                          return Promise.reject(
                            "Birthday can't be in the future",
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Datepicker id="birthday" format="DD/MM/YYYY" />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  Nickname&nbsp;<img src={Thai}></img>
                  {nonRequiredFields.find((nf) => {
                    return nf === 'nicknameTh';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="nicknameTh"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Nickname',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        function regexThaiChars(string) {
                          var regex = /^[\u0E00-\u0E7F']+$/;
                          return regex.test(string);
                        }
                        if (!regexThaiChars(value)) {
                          return Promise.reject('Please input only Thai.');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Inputdata id="nicknameTh" />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  Nickname&nbsp;<img src={UK}></img>
                  {nonRequiredFields.find((nf) => {
                    return nf === 'nicknameEn';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="nicknameEn"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Nickname',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        function regexEngChars(string) {
                          return /^[a-zA-Z']+$/.test(string);
                        }
                        if (!regexEngChars(value)) {
                          return Promise.reject('Please input only English.');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Inputdata id="nicknameEn" />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>Phone No.</Label>
                {nonRequiredFields.find((nf) => {
                  return nf === 'phoneNumber';
                }) ? (
                  <></>
                ) : (
                  <label style={{ color: 'red' }}>*</label>
                )}
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="phoneNumber"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        function containsOnlyNumbers(str) {
                          return /^\d+$/.test(str);
                        }
                        if (!value) {
                          return Promise.resolve();
                        } else if (!containsOnlyNumbers(value)) {
                          return Promise.reject('Digits only.');
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Inputdata id="phoneNumber" />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  Mobile Phone No.
                  {nonRequiredFields.find((nf) => {
                    return nf === 'mobileNumber';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="mobileNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Mobile Phone No.',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        function containsOnlyNumbers(str) {
                          return /^\d+$/.test(str);
                        }
                        if (!containsOnlyNumbers(value)) {
                          return Promise.reject('Digits only.');
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Inputdata id="mobileNumber" />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  JLPT Score
                  {nonRequiredFields.find((nf) => {
                    return nf === 'jlpt';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item style={{ width: '100%', margin: '0' }} name="jlpt">
                  <Inputdata id="jlpt" disabled={!enableAllFields} />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  TOEIC Score
                  {nonRequiredFields.find((nf) => {
                    return nf === 'toeic';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="toeic"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        function containsOnlyNumbers(str) {
                          return /^\d+$/.test(str);
                        }
                        if (!value) {
                          return Promise.resolve();
                        } else if (!containsOnlyNumbers(value)) {
                          return Promise.reject('Digits only.');
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <InputdataNumber id="toeic" disabled={!enableAllFields} />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox>
                <Label>
                  Start Job Date
                  {nonRequiredFields.find((nf) => {
                    return nf === 'startJobDate';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="startJobDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your Start Job Date',
                    },
                  ]}
                >
                  <Datepicker
                    id="startJobDate"
                    format="DD/MM/YYYY"
                    disabled={!enableAllFields}
                    onChange={(value) => {
                      const added120Days = value.clone().add(120, 'days');
                      this.formRef.current.setFieldsValue({
                        endProbationDate: added120Days,
                      });
                    }}
                  />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  End Probation Date
                  {nonRequiredFields.find((nf) => {
                    return nf === 'endProbationDate';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="endProbationDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your End Probation Date',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, endProbationDate) {
                        if (
                          !endProbationDate ||
                          getFieldValue('startJobDate') <= endProbationDate
                        ) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(
                            'End probation date must be after start job date',
                          ),
                        );
                      },
                    }),
                  ]}
                >
                  <Datepicker
                    id="endProbationDate"
                    format="DD/MM/YYYY"
                    disabledDate={(current) => {
                      return (
                        current <
                        this.formRef.current.getFieldValue('startJobDate')
                      );
                    }}
                    disabled={!enableAllFields}
                  />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  Signature
                  {nonRequiredFields.find((nf) => {
                    return nf === 'signaturePath';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="signaturePath"
                >
                  <Uploader
                    // initialFileName='picture_signature'
                    initialPath={this.props.data.signaturePath}
                    callbackUpload={this.handleSignaturePicture}
                  />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  Supervisor
                  {nonRequiredFields.find((nf) => {
                    return nf === 'supervisorId';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="supervisorId"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Supervisor',
                    },
                  ]}
                >
                  <Selectitem
                    showSearch
                    options={this.state.supervisorList?.map((value) => {
                      return {
                        value: value.userId,
                        label: `${value.firstnameEn} ${value.lastnameEn}`,
                      };
                    })}
                    filterOption={(input, option) => {
                      const regex = new RegExp('^' + input, 'i');
                      return regex.test(option?.label);
                    }}
                    disabled={this.state.userCurrentRole === 'USER'}
                  />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox>
                <Label>Effective Date</Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="resignationDate"
                >
                  <Datepicker
                    id="resignationDate"
                    format="DD/MM/YYYY"
                    disabled={!enableAllFields}
                  />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>Bank Account No</Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="bankAccountNo"
                >
                  <Inputdata id="bankAccountNo" disabled={!enableAllFields} />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>
            <Horizontalbox>
              <FlexBox left>
                <Label>
                  Status
                  {nonRequiredFields.find((nf) => {
                    return nf === 'isActived';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="isActived"
                  valuePropName="checked"
                >
                  <StatusSwitch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    id="isActived"
                    disabled={!enableAllFields}
                  />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  BOI <label style={{ color: 'red' }}>*</label>
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="isBoi"
                  valuePropName="checked"
                >
                  <StatusSwitch
                    checkedChildren="BOI"
                    unCheckedChildren="Non BOI"
                    id="isBoi"
                    disabled={!enableAllFields}
                  />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  Marital Status
                  {nonRequiredFields.find((nf) => {
                    return nf === 'maritalStatus';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="maritalStatus"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your Marital Status',
                    },
                  ]}
                >
                  <Selectitem id="maritalStatus">
                    <Selectoption value="Single">Single</Selectoption>
                    <Selectoption value="Married">Married</Selectoption>
                    <Selectoption value="Widowed">Widowed</Selectoption>
                    <Selectoption value="Divorced">Divorced</Selectoption>
                  </Selectitem>
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  Military Status
                  {this.state.formValue.gender === 'Male' &&
                    !nonRequiredFields.find((nf) => {
                      return nf === 'militaryStatus';
                    }) && <label style={{ color: 'red' }}>*</label>}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="militaryStatus"
                  dependencies={['gender']}
                  rules={[
                    () => ({
                      validator: (_, value) => {
                        if (
                          this.formRef?.current.getFieldValue('gender') ===
                            'Male' &&
                          !value
                        ) {
                          return Promise.reject(
                            'Please select Military Status',
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Selectitem id="militaryStatus" allowClear>
                    <Selectoption value="Serving">Serving</Selectoption>
                    <Selectoption value="Completed">Completed</Selectoption>
                    <Selectoption value="Exempted">Exempted</Selectoption>
                  </Selectitem>
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  Do you have parking rights?
                  {nonRequiredFields.find((nf) => {
                    return nf === 'carDrivingLicense';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="carDrivingLicense"
                  valuePropName="checked"
                >
                  <Checkboxdata
                    style={{ width: '100%' }}
                    id="carDrivingLicense"
                  >
                    Yes/No
                  </Checkboxdata>
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  Blood Group
                  {nonRequiredFields.find((nf) => {
                    return nf === 'bloodGroup';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="bloodGroup"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please select your Blood Group',
                  //   },
                  // ]}
                >
                  <Selectitem id="bloodGroup">
                    <Selectoption value="A">A</Selectoption>
                    <Selectoption value="B">B</Selectoption>
                    <Selectoption value="O">O</Selectoption>
                    <Selectoption value="AB">AB</Selectoption>
                  </Selectitem>
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  Religion
                  {nonRequiredFields.find((nf) => {
                    return nf === 'religion';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="religion"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please select your Religion',
                  //   },
                  // ]}
                >
                  <Selectitem id="religion">
                    <Selectoption value="Buddism">Buddism</Selectoption>
                    <Selectoption value="Christianity">
                      Christianity
                    </Selectoption>
                    <Selectoption value="Hinduism">Hinduism</Selectoption>
                    <Selectoption value="Islam">Islam</Selectoption>
                    <Selectoption value="Sikhism">Sikhism</Selectoption>
                    <Selectoption value="Others">Others</Selectoption>
                  </Selectitem>
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  Nationality
                  {nonRequiredFields.find((nf) => {
                    return nf === 'nationality';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="nationality"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please input your Nationality',
                  //   },
                  // ]}
                >
                  <Inputdata id="nationality" />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  Notebook Owner
                  {nonRequiredFields.find((nf) => {
                    return nf === 'notebookOwner';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="notebookOwner"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your Notebook Owner',
                    },
                  ]}
                >
                  <Selectitem id="notebookOwner">
                    <Selectoption value="Employee">Employee</Selectoption>
                    <Selectoption value="Company">Company</Selectoption>
                  </Selectitem>
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  Notebook OS
                  {nonRequiredFields.find((nf) => {
                    return nf === 'notebookOs';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="notebookOs"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your Notebook OS',
                    },
                  ]}
                >
                  <Selectitem id="notebookOs">
                    <Selectoption value="Windows">Windows</Selectoption>
                    <Selectoption value="Macos">MacOs</Selectoption>
                    <Selectoption value="Linux">Linux</Selectoption>
                  </Selectitem>
                </Form.Item>
              </FlexBox>
            </Horizontalbox>
            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  Notebook Year
                  {nonRequiredFields.find((nf) => {
                    return nf === 'notebookYear';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="notebookYear"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your Notebook Owner',
                    },
                  ]}
                >
                  <Inputdata id="notebookYear" />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  Notebook Model
                  {nonRequiredFields.find((nf) => {
                    return nf === 'notebookModel';
                  }) ? (
                    <></>
                  ) : (
                    <label style={{ color: 'red' }}>*</label>
                  )}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="notebookModel"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your Notebook OS',
                    },
                  ]}
                >
                  <Inputdata id="notebookModel" />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>
            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  Notebook Serial Number
                  {this.state.formValue.notebookOwner === 'Company' &&
                    !nonRequiredFields.find((nf) => {
                      return nf === 'notebookSerialNo';
                    }) && <label style={{ color: 'red' }}>*</label>}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="notebookSerialNo"
                  rules={[
                    () => ({
                      validator: (_, value) => {
                        if (
                          this.formRef.current.getFieldValue(
                            'notebookOwner',
                          ) === 'Company' &&
                          !value
                        ) {
                          return Promise.reject(
                            'Please select your Notebook SerialNo',
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Inputdata id="notebookSerialNo" />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>OS License</Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="osLicensePicturePath"
                >
                  <Uploader
                    initialPath={this.props.data.osLicensePicturePath}
                    callbackUpload={this.handleOsLicensePicture}
                  />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>Microsoft License</Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="microsoftOfficeLicensePicturePath"
                >
                  <Uploader
                    initialPath={
                      this.props.data.microsoftOfficeLicensePicturePath
                    }
                    callbackUpload={this.handleMicrosoftLicensePicture}
                  />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>
            <Horizontalbox>
              <FlexBox Left>
                <Label>
                  Line Id
                  {!nonRequiredFields.find((nf) => {
                    return nf === 'lineId';
                  }) && <label style={{ color: 'red' }}>*</label>}
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="lineId"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please input your Nationality',
                  //   },
                  // ]}
                >
                  <Inputdata id="lineId" />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>
            <Line style={{ marginTop: '1rem' }}></Line>
            <Headdetail>Emergency Contact</Headdetail>
            <Textemer>Emergency Contact 1</Textemer>
            <Horizontalbox>
              <FlexBox Left>
                <Label>Name</Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="emergencyName"
                >
                  <Inputdata />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>Relationship</Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="emergencyRelationship"
                >
                  <Inputdata />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>Mobile No.</Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="emergencyMobileNumber"
                >
                  <Inputdata />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox style={{ width: '100%' }}>
                <Label>Address</Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="emergencyAddress"
                >
                  <Inputdata />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>
          </Form>
        </Box>
      </MainBox>
    );
  };
  renderDefaultView = () => {
    const { data, currentPage } = this.props;
    return (
      <MainBox>
        <Box>
          <Horizontalbox>
            <FlexBox>
              <Label>Role</Label>
              <Textdata>{this.props.data.role}</Textdata>
            </FlexBox>
            <FlexBox Right>
              <Label>Position</Label>
              <Textdata>{this.props.data.position}</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>Username</Label>
              <Textdata>{this.props.data.email}</Textdata>
            </FlexBox>
            <FlexBox>
              <Label>Password</Label>
              {/* <Textdata>{this.props.data.password}</Textdata> */}
              <Textdata>*****</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>Employee ID</Label>
              <Textdata>{this.props.data.empCode}</Textdata>
            </FlexBox>
            <FlexBox>
              <Label>Contract type</Label>
              <Textdata>{this.props.data.contractType}</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>
                Firstname&nbsp;<img src={UK}></img>
              </Label>
              <Textdata>{this.props.data.firstnameEn}</Textdata>
            </FlexBox>
            <FlexBox>
              <Label>
                Lastname&nbsp;<img src={UK}></img>
              </Label>
              <Textdata>{this.props.data.lastnameEn}</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>
                Firstname&nbsp;<img src={Thai}></img>
              </Label>
              <Textdata>{this.props.data.firstnameTh}</Textdata>
            </FlexBox>
            <FlexBox>
              <Label>
                Lastname&nbsp;<img src={Thai}></img>
              </Label>
              <Textdata>{this.props.data.lastnameTh}</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>Department</Label>
              <Textdata>{this.props.data.department}</Textdata>
            </FlexBox>
            <FlexBox>
              <Label>Section</Label>
              {this.state.sectionList?.map((section) => {
                if (section.id === this.props.data.sectionId) {
                  return <Textdata>{`${section.sectionName}`}</Textdata>;
                }
                return false;
              })}
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>Gender</Label>
              <Textdata>{this.props.data.gender}</Textdata>
            </FlexBox>
            <FlexBox>
              <Label>Citizen ID</Label>
              <Textdata>{this.props.data.citizenId}</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>Level</Label>
              <Textdata>{this.props.data?.levelName}</Textdata>
            </FlexBox>
            <FlexBox>
              <Label>Birthday</Label>
              <Textdata>
                {moment(this.props.data.birthday, 'YYYY-MM-DD').format(
                  'DD MMM YYYY',
                )}
              </Textdata>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>
                Nickname&nbsp;<img src={Thai}></img>
              </Label>
              <Textdata>{this.props.data.nicknameTh}</Textdata>
            </FlexBox>
            <FlexBox>
              <Label>
                Nickname&nbsp;<img src={UK}></img>
              </Label>
              <Textdata>{this.props.data.nicknameEn}</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>Phone No.</Label>
              <Textdata>{this.props.data.phoneNumber}</Textdata>
            </FlexBox>
            <FlexBox>
              <Label>Mobile Phone No.</Label>
              <Textdata>{this.props.data.mobileNumber}</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>JLPT Score</Label>
              <Textdata>{this.props.data.jlpt}</Textdata>
            </FlexBox>
            <FlexBox>
              <Label>TOEIC Score</Label>
              <Textdata>{this.props.data.toeic}</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox>
              <Label>Start Job Date</Label>
              <Textdata>
                {moment(this.props.data.startJobDate, 'YYYY-MM-DD').format(
                  'DD MMM YYYY',
                )}
              </Textdata>
            </FlexBox>
            <FlexBox>
              <Label>End Probation Date</Label>
              <Textdata>
                {moment(this.props.data.endProbationDate, 'YYYY-MM-DD').format(
                  'DD MMM YYYY',
                )}
              </Textdata>
            </FlexBox>
          </Horizontalbox>
          <Horizontalbox>
            <FlexBox Left style={{ alignItems: 'flex-start' }}>
              <Label>Signature</Label>
              {/* <Uploader
                hideUploadButton
                initialFileName='picture_signature'
                initialPath={this.props.data.signaturePath}
                callbackUpload={this.handleSignaturePicture}
              /> */}
              {this.props.data.signatureImage && (
                <Image src={this.props.data.signatureImage} />
              )}
            </FlexBox>
            <FlexBox>
              <Label>Supervisor</Label>
              {this.state.supervisorList?.map((user) => {
                if (user.userId === this.props.data.supervisorId) {
                  return (
                    <Textdata>{`${user.firstnameEn} ${user.lastnameEn}`}</Textdata>
                  );
                }
                return false;
              })}
            </FlexBox>
          </Horizontalbox>
          <Horizontalbox>
            <FlexBox>
              <Label>Effective Date</Label>
              <Textdata>
                {this.props.data?.resignationDate
                  ? moment(
                      this.props.data?.resignationDate,
                      'YYYY-MM-DD',
                    ).format('DD MMM YYYY')
                  : ''}
              </Textdata>
            </FlexBox>
            <FlexBox>
              <Label>Bank Account No</Label>
              <Textdata>{this.props.data.bankAccountNo}</Textdata>
            </FlexBox>
          </Horizontalbox>
          <Horizontalbox>
            <FlexBox>
              <Label>Status</Label>
              <Textdata>
                <Badge
                  status={this.props.data.isActived ? 'success' : 'warning'}
                />
                {this.props.data.isActived ? 'Active' : 'Inactive'}
              </Textdata>
            </FlexBox>
            <FlexBox>
              <Label>BOI</Label>
              <Textdata>{this.props.data.isBoi ? 'BOI' : 'Non BOI'}</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>Marital Status</Label>
              <Textdata>{this.props.data.maritalStatus}</Textdata>
            </FlexBox>
            <FlexBox>
              <Label>Military Status</Label>
              <Textdata>{this.props.data.militaryStatus}</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>Do you have parking rights?</Label>
              <Checkboxdata
                style={{ width: '100%' }}
                checked={this.props.data.carDrivingLicense}
              >
                <Textdata>Yes/No</Textdata>
              </Checkboxdata>
            </FlexBox>
            <FlexBox>
              <Label>Blood Group</Label>
              <Textdata>{this.props.data.bloodGroup}</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>Religion</Label>
              <Textdata>{this.props.data.religion}</Textdata>
            </FlexBox>
            <FlexBox>
              <Label>Nationality</Label>
              <Textdata>{this.props.data.nationality}</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>Notebook Owner</Label>
              <Textdata>{this.props.data.notebookOwner}</Textdata>
            </FlexBox>
            <FlexBox>
              <Label>Notebook OS</Label>
              <Textdata>{this.props.data.notebookOs}</Textdata>
            </FlexBox>
          </Horizontalbox>
          <Horizontalbox>
            <FlexBox Left>
              <Label>Notebook Year</Label>
              <Textdata>{this.props.data.notebookYear}</Textdata>
            </FlexBox>
            <FlexBox>
              <Label>Notebook Model</Label>
              <Textdata>{this.props.data.notebookModel}</Textdata>
            </FlexBox>
          </Horizontalbox>
          {this.props.data.notebookOwner === 'Company' && (
            <Horizontalbox>
              <FlexBox Left>
                <Label>Serial No.</Label>
                <Textdata>{this.props.data.notebookSerialNo}</Textdata>
              </FlexBox>
            </Horizontalbox>
          )}

          <Horizontalbox>
            <FlexBox Left>
              <Label>OS License</Label>
              {this.props.data.osImage && (
                <Image src={this.props.data.osImage} />
              )}
            </FlexBox>
            <FlexBox>
              <Label>Microsoft License</Label>
              {this.props.data.msImage && (
                <Image src={this.props.data.msImage} />
              )}
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>Line ID</Label>
              <Textdata>{this.props.data.lineId}</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Line style={{ marginTop: '1rem' }}></Line>
          <Headdetail>Emergency Contact</Headdetail>
          <Textemer>Emergency Contact 1</Textemer>
          <Horizontalbox>
            <FlexBox Left>
              <Label>Name</Label>
              <Form.Item
                style={{ width: '100%', margin: '0' }}
                name="emergencyName"
              >
                <Textdata>{this.props.data.emergencyName}</Textdata>
              </Form.Item>
            </FlexBox>
            <FlexBox>
              <Label>Relationship</Label>
              <Form.Item
                style={{ width: '100%', margin: '0' }}
                name="emergencyRelationship"
              >
                <Textdata>{this.props.data.emergencyRelationship}</Textdata>
              </Form.Item>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>Mobile No.</Label>
              <Form.Item
                style={{ width: '100%', margin: '0' }}
                name="emergencyMobileNumber"
              >
                <Textdata>{this.props.data.emergencyMobileNumber}</Textdata>
              </Form.Item>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox style={{ width: '100%' }}>
              <Label>Address</Label>
              <Form.Item
                style={{ width: '100%', margin: '0' }}
                name="emergencyAddress"
              >
                <Textdata>{this.props.data.emergencyAddress}</Textdata>
              </Form.Item>
            </FlexBox>
          </Horizontalbox>
          <Line style={{ marginTop: '1rem' }}></Line>
          <Horizontalbox>
            <FlexBox Left style={{ marginBottom: 0 }}>
              <Label>Update Date</Label>
              <Form.Item
                style={{ width: '100%', margin: '0' }}
                name="updateDatetime"
              >
                <Textdata>
                  {this.props.data.updateDatetime
                    ? moment(
                        this.props.data.updateDatetime,
                        'YYYY-MM-DD',
                      ).format('DD MMM YYYY')
                    : '-'}
                </Textdata>
              </Form.Item>
            </FlexBox>
            <FlexBox style={{ marginBottom: 0 }}>
              <Label>Update By</Label>
              <Form.Item
                style={{ width: '100%', margin: '0' }}
                name="updateUserInfo"
              >
                <Textdata>
                  {this.props.data.updateUserInfo
                    ? `${this.props.data.updateUserInfo?.firstnameEn} ${this.props.data.updateUserInfo?.lastnameEn}`
                    : '-'}
                </Textdata>
              </Form.Item>
            </FlexBox>
          </Horizontalbox>
        </Box>
      </MainBox>
    );
  };
  render() {
    return (
      <DetailBox>
        {this.props.isInEditMode
          ? this.renderEditView()
          : this.renderDefaultView()}
      </DetailBox>
    );
  }
}
export default Personaldetail;
