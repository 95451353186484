import {
  Table,
  Row,
  Col,
  Space,
  Select,
  message,
  Button,
  Form,
  Input,
  DatePicker,
  InputNumber,
} from 'antd';
import styled from 'styled-components';
import React, { Component, createRef } from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { AuthorizeRoleService } from '../../services/api';
import { AppContext } from '../../components/context/AppContext';
import Uploader from '../components/uploader';
import { redirectTo } from '../../services/redirect';

const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ContentBox = styled.div`
  background: #f6f6f6;
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: auto;
  min-height: calc(100vh - 134px);
  justify-content: flex-start;
  padding-top: 0.5em;
`;

const FlexBox = styled.div`
  width: 50%;
  display: flex;
  padding-right: 2rem;

  box-sizing: border-box;
  margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: row;
  }
  @media (max-width: 714px) {
    padding-right: 0;
    width: 100%;
  }
`;

const Headdetail = styled.div`
  font-family: 'Kanit';
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 2rem;
`;

const Horizontalbox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || 'start'};
  @media (max-width: 714px) {
    flex-direction: column;
  }
`;

const Inputdata = styled(Input)`
  width: 100%;
  height: 2rem;
  border-radius: 5px;
`;

const Label = styled.div`
  font-family: 'Kanit';
  font-size: 16px;
  font-weight: 500;
  width: 11rem;
  min-width: 11rem;
  color: black;
  @media (max-width: 1424px) {
    min-width: none;
  }
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  border-radius: 5px;
  background-color: ${(props) =>
    props.white ? '#FFFFFF !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.white ? '#D9D9D9 !important' : '#004368 !important'};
  color: ${(props) => (props.white ? '#232323' : 'white')};
  font-family: Kanit;
  font-style: Regular;
  font-size: 14px;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
  &:focus {
    color: ${(props) => (props.whitefocus ? 'white !important' : '#232323')};
  }
`;

const RejectedButton = styled(Button)`
  width: ${(props) => (props.width ? props.width : '106px')};
  border-radius: 5px;
  display: flex;
  background-color: white;
  border: 1px solid #d9d9d9;
  justify-content: center;
  color: #232323 !important;
  font-family: Kanit;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding: 5px 15px;
  align-items: center;

  &:disabled {
    border: 1px solid white;
  }
`;

const Text = styled.div`
  color: ${(props) =>
    props.title ? '#004368' : props.subtitle ? '#20ABC5' : '#565656'};
  font-size: ${(props) =>
    props.title ? '26px' : props.subtitle ? '1.625em' : '1em'};
  font-weight: ${(props) =>
    props.title
      ? '500'
      : props.subtitle
      ? '500'
      : props.name
      ? '500'
      : 'normal'};
  font-family: 'Kanit';
`;

const TextCard = styled.div`
  height: auto;
  width: 100%;
  background-color: white;
  margin-top: 2em;
  border-radius: 5px;
`;

const TitleBox = styled.div`
  box-sizing: border-box;
  padding-left: 7%;
  padding-right: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  height: 70px;
  justify-content: space-between;
`;

const Verticalbox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2% 4%;
`;

class MedicalClaimRequestPage extends Component {
  formRef = createRef();
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      medicalClaimType: [],
      amountRemain: 0,
      fullName: '',
      medicalInvoicePicturePath: '',
      medicalCertificatePicturePath: '',
      amountUse: 0,
    };
  }

  componentDidMount = () => {
    this.fetchMedicalClaimAmountRemain();
    this.fetchMedicalClaimType();
  };

  fetchMedicalClaimAmountRemain = () => {
    AuthorizeRoleService.getMedicalClaimAmountRemain(
      ({ data }) => {
        this.setState({
          amountRemain: data.amountRemain,
        });
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  fetchMedicalClaimType = () => {
    AuthorizeRoleService.getMedicalClaimType(
      ({ data }) => {
        this.setState({
          medicalClaimType: data,
        });
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  handleMedicalInvoicePicturePath = (value, index) => {
    this.setState({
      medicalInvoicePicturePath: value,
    });
  };

  handleMedicalCertificatePicturePath = (value, index) => {
    this.setState({
      medicalCertificatePicturePath: value,
    });
  };

  onFinishMedicalClaimRequest = (value) => {
    value.medicalCertificatePicturePath =
      this.state.medicalCertificatePicturePath;
    value.medicalInvoicePicturePath = this.state.medicalInvoicePicturePath;

    AuthorizeRoleService.addMedicalClaimRequest(
      value,
      ({ data }) => {
        message.success('Success');
        setTimeout(() => {
          redirectTo('/medical-claim');
        }, 1000);
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  render() {
    const dot = <label style={{ color: 'red' }}>*</label>;
    return (
      <div style={{ width: 'auto' }}>
        <TitleBox>
          <Text title>Request Medical Claim</Text>
          <ButtonBlock width="170px">
            <NavLink to={'/medical-claim'}>
              <RejectedButton form="user-form" white width="76px">
                Back
              </RejectedButton>
            </NavLink>
            <NormalButton
              width="76px"
              onClick={() => this.formRef.current.submit()}
            >
              Submit
            </NormalButton>
          </ButtonBlock>
        </TitleBox>
        <ContentBox>
          <Form
            style={{ width: '100%' }}
            ref={this.formRef}
            onFinish={this.onFinishMedicalClaimRequest}
          >
            <TextCard style={{ marginBottom: '2.5em' }}>
              <Verticalbox>
                <Horizontalbox justify="space-between">
                  <Headdetail>General Information</Headdetail>
                </Horizontalbox>
                <Horizontalbox>
                  <FlexBox>
                    <Label>Treatment Date {dot}</Label>
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="effectiveDate"
                      rules={[
                        {
                          required: true,
                          message: 'Please input treatment date.',
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: '100%', borderRadius: '5px' }}
                      />
                    </Form.Item>
                  </FlexBox>
                </Horizontalbox>
                <Horizontalbox>
                  <FlexBox>
                    <Label>Reference Number {dot}</Label>
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="referenceNumber"
                      rules={[
                        {
                          required: true,
                          message: 'Please input reference number.',
                        },
                      ]}
                    >
                      <Inputdata placeholder="Reference Number" />
                    </Form.Item>
                  </FlexBox>
                </Horizontalbox>
                <Horizontalbox>
                  <FlexBox>
                    <Label>Type {dot}</Label>
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="medicalClaimTypeId"
                      rules={[
                        {
                          required: true,
                          message: 'Please select medical claim type.',
                        },
                      ]}
                    >
                      <Select placeholder="Medical Claim Type">
                        {this.state.medicalClaimType.map((record, index) => (
                          <Select.Option value={record.id}>
                            {record.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </FlexBox>
                </Horizontalbox>
                <Horizontalbox>
                  <FlexBox>
                    <Label>Amount {dot}</Label>
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="amount"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (
                              !value ||
                              `${value}`.split('.')[1]?.length > 2
                            ) {
                              return Promise.reject(
                                new Error('Please input number of 2 decimal'),
                              );
                            } else if (value > this.state.amountRemain) {
                              return Promise.reject(
                                new Error('Amount must be less than remain'),
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: '100%', borderRadius: '5px' }}
                        placeholder="Amount"
                        stringMode
                        precision={2}
                      />
                    </Form.Item>
                  </FlexBox>
                  <FlexBox style={{ alignItems: 'center' }}>
                    <Label style={{ color: '#20ABC5' }}>
                      {`Remain
                      ${
                        this.state.amountRemain
                          ? this.state.amountRemain.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : '0.00'
                      }
                      Baht`}
                    </Label>
                  </FlexBox>
                </Horizontalbox>
              </Verticalbox>
            </TextCard>
            <TextCard style={{ marginTop: 0 }}>
              <Verticalbox style={{ marginBottom: '40px' }}>
                <Horizontalbox>
                  <Headdetail>More Detail</Headdetail>
                </Horizontalbox>
                <Horizontalbox>
                  <FlexBox style={{ width: '100%' }}>
                    <Label>Hospital & Reason {dot}</Label>
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="reason"
                      rules={[
                        {
                          required: true,
                          message: 'Please input hospital and reason.',
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Reason (Max length is 255)"
                        maxLength={255}
                        rows={5}
                      />
                    </Form.Item>
                  </FlexBox>
                </Horizontalbox>
                <Horizontalbox>
                  <FlexBox>
                    <Label>Medical Invoice {dot}</Label>
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="medicalInvoicePicturePath"
                      validateTrigger="callbackUpload"
                      rules={[
                        {
                          required: !this.state.medicalInvoicePicturePath,
                          message: 'Please upload medical invoice picture.',
                        },
                      ]}
                    >
                      <Uploader
                        callbackUpload={this.handleMedicalInvoicePicturePath}
                      />
                    </Form.Item>
                  </FlexBox>
                  <FlexBox>
                    <Label>Medical Certificate {dot}</Label>
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="medicalCertificatePicturePath"
                      validateTrigger="callbackUpload"
                      rules={[
                        {
                          validator: (_, value) =>
                            this.state.medicalCertificatePicturePath
                              ? Promise.resolve()
                              : Promise.reject(
                                  'Please upload medical certificate picture.',
                                ),
                        },
                      ]}
                    >
                      <Uploader
                        callbackUpload={
                          this.handleMedicalCertificatePicturePath
                        }
                      />
                    </Form.Item>
                  </FlexBox>
                </Horizontalbox>
              </Verticalbox>
            </TextCard>
          </Form>
        </ContentBox>
      </div>
    );
  }
}

export default MedicalClaimRequestPage;
