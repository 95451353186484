import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import {
  Button,
  Row,
  Col,
  Modal,
  message,
  DatePicker,
  Select,
  Spin,
  Progress,
  Segmented as SegmentedDefault,
} from 'antd';
import { ProjectService, TimesheetService } from '../../services/api';
import {
  LeftOutlined,
  RightOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  InsertRowLeftOutlined,
} from '@ant-design/icons';
import { redirectTo } from '../../services/redirect';
import moment from 'moment';
import newTaskIcon from '../../assets/icons/newTask.svg';
import CreateOrEditTaskModal from '../../components/timesheet/createOrEditTaskModal';
import TaskModal from '../../components/timesheet/taskModal';
import LeaveModal from '../../components/timesheet/leaveModal';
import HolidayModal from '../../components/timesheet/holidayModal';
import TimesheetHourTable from '../../components/timesheet/timesheetHourTable';
import CreateOrEditOtModal from '../../components/timesheet/createOrEditOtModal';

const PageContent = styled.div`
  width: auto;
  font-family: Kanit;
`;
const FilterBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  justify-content: space-between;
`;
const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#ffffff')};
  box-sizing: border-box;
  // padding-left: 3%;
  // padding-right: 3%;
  display: flex;
  flex-direction: column;
  width: auto;
  height: calc(100vh - 64px);
`;
const Cols = styled(Col)`
  display: flex;
  align-items: center;
  margin: 0px 10px;
`;
const Rows = styled(Row)`
  margin: 10px 0px;
  align-items: center;
  position: right;
`;
const TableText = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.no ? '#20ABC5' : '#737373'};
  font-size: 1em;
  font-weight: ${(props) => (props.title ? '600' : 'normal')};
  font-family: 'Kanit';
  line-height: 14px;
  text-transform: ${(props) => props.capitalize && 'capitalize'};
`;
const Break = styled.div`
  width: 100%;
  border: 0.5px solid #eaeaea;
`;

const Schedule = styled.div`
  display: grid;
  grid-gap: 0px;
  grid-auto-flow: row;
  max-height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 7px;
    width: 7px;
    background-color: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #004368;
  }
  grid-template-rows:
    [tracks] auto
    [holiday] 40px //need to fix for week and day that have no holiday
    ${(props) =>
      props.isBefore9
        ? `[time-0000] minmax(30px, 1fr)
  [time-0015] minmax(30px, 1fr)
  [time-0030] minmax(30px, 1fr)
  [time-0045] minmax(30px, 1fr)
  [time-0100] minmax(30px, 1fr)
  [time-0115] minmax(30px, 1fr)
  [time-0130] minmax(30px, 1fr)
  [time-0145] minmax(30px, 1fr)
  [time-0200] minmax(30px, 1fr)
  [time-0215] minmax(30px, 1fr)
  [time-0230] minmax(30px, 1fr)
  [time-0245] minmax(30px, 1fr)
  [time-0300] minmax(30px, 1fr)
  [time-0315] minmax(30px, 1fr)
  [time-0330] minmax(30px, 1fr)
  [time-0345] minmax(30px, 1fr)
  [time-0400] minmax(30px, 1fr)
  [time-0415] minmax(30px, 1fr)
  [time-0430] minmax(30px, 1fr)
  [time-0445] minmax(30px, 1fr)
  [time-0500] minmax(30px, 1fr)
  [time-0515] minmax(30px, 1fr)
  [time-0530] minmax(30px, 1fr)
  [time-0545] minmax(30px, 1fr)
  [time-0600] minmax(30px, 1fr)
  [time-0615] minmax(30px, 1fr)
  [time-0630] minmax(30px, 1fr)
  [time-0645] minmax(30px, 1fr)
  [time-0700] minmax(30px, 1fr)
  [time-0715] minmax(30px, 1fr)
  [time-0730] minmax(30px, 1fr)
  [time-0745] minmax(30px, 1fr)
  [time-0800] minmax(30px, 1fr)
  [time-0815] minmax(30px, 1fr)
  [time-0830] minmax(30px, 1fr
  [time-0845] minmax(30px, 1fr)`
        : ''}
    [time-0900] minmax(30px, 1fr)
    [time-0915] minmax(30px, 1fr)
    [time-0930] minmax(30px, 1fr)
    [time-0945] minmax(30px, 1fr)
    [time-1000] minmax(30px, 1fr)
    [time-1015] minmax(30px, 1fr)
    [time-1030] minmax(30px, 1fr)
    [time-1045] minmax(30px, 1fr)
    [time-1100] minmax(30px, 1fr)
    [time-1115] minmax(30px, 1fr)
    [time-1130] minmax(30px, 1fr)
    [time-1145] minmax(30px, 1fr)
    [time-1200] minmax(30px, 1fr)
    [time-1215] minmax(30px, 1fr)
    [time-1230] minmax(30px, 1fr)
    [time-1245] minmax(30px, 1fr)
    [time-1300] minmax(30px, 1fr)
    [time-1315] minmax(30px, 1fr)
    [time-1330] minmax(30px, 1fr)
    [time-1345] minmax(30px, 1fr)
    [time-1400] minmax(30px, 1fr)
    [time-1415] minmax(30px, 1fr)
    [time-1430] minmax(30px, 1fr)
    [time-1445] minmax(30px, 1fr)
    [time-1500] minmax(30px, 1fr)
    [time-1515] minmax(30px, 1fr)
    [time-1530] minmax(30px, 1fr)
    [time-1545] minmax(30px, 1fr)
    [time-1600] minmax(30px, 1fr)
    [time-1615] minmax(30px, 1fr)
    [time-1630] minmax(30px, 1fr)
    [time-1645] minmax(30px, 1fr)
    [time-1700] minmax(30px, 1fr)
    [time-1715] minmax(30px, 1fr)
    [time-1730] minmax(30px, 1fr)
    [time-1745] minmax(30px, 1fr)
    [time-1800] minmax(30px, 1fr)
    ${(props) =>
      props.isAfter18
        ? `[time-1815] minmax(30px, 1fr)
      [time-1830] minmax(30px, 1fr)
      [time-1845] minmax(30px, 1fr)
      [time-1900] minmax(30px, 1fr)
      [time-1915] minmax(30px, 1fr)
      [time-1930] minmax(30px, 1fr)
      [time-1945] minmax(30px, 1fr)
      [time-2000] minmax(30px, 1fr)
      [time-2015] minmax(30px, 1fr)
      [time-2030] minmax(30px, 1fr)
      [time-2045] minmax(30px, 1fr)
      [time-2100] minmax(30px, 1fr)
      [time-2115] minmax(30px, 1fr)
      [time-2130] minmax(30px, 1fr)
      [time-2145] minmax(30px, 1fr)
      [time-2200] minmax(30px, 1fr)
      [time-2215] minmax(30px, 1fr)
      [time-2230] minmax(30px, 1fr)
      [time-2245] minmax(30px, 1fr)
      [time-2300] minmax(30px, 1fr)
      [time-2315] minmax(30px, 1fr)
      [time-2330] minmax(30px, 1fr)
      [time-2345] minmax(30px, 1fr)
      [time-2400] minmax(30px, 1fr)`
        : ''};
  grid-template-columns:
    [times] 4em
    ${(props) => (props.day ? `[day-1-start] 96%` : '')}
    ${(props) =>
      props.week
        ? `[day-1-start] 13.7%
  [day-1-end day-2-start] 13.7%
  [day-2-end day-3-start] 13.7%
  [day-3-end day-4-start] 13.7%
  [day-4-end day-5-start] 13.7%
  [day-5-end day-6-start] 13.7%
  [day-6-end day-7-start] 13.7%`
        : ''};
`;
const TimeSlot = styled.div`
  position: relative;
  grid-column: times;
  position: sticky;
  left: 0px;
  margin-top: -7px;
  font-family: 'Kanit';
  z-index: 2;
`;
const DaySlot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 5px 5px;
  position: sticky;
  top: 0;
  text-align: center;
  font-family: 'Kanit';
  z-index: 2;
`;
const TopLeftBg = styled.div`
  grid-column: times;
  grid-row: tracks / holiday;
  position: relative;
  grid-column: times;
  position: sticky;
  left: 0px;
  top: 0px;
  background-color: #ffffff;
  z-index: 3;
`;
const TimeBg = styled.div`
  position: relative;
  grid-column: times;
  position: sticky;
  left: 0px;
  background-color: #ffffff;
  z-index: 1;
`;
const DayBg = styled.div`
  position: relative;
  grid-row: tracks;
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 1;
`;
const TimelineBlock = styled.div`
  margin: 3px;
  padding: 10px;
  background-color: ${(props) => (props.leave ? '#FDEFF0' : '#F7FBFE')};
  border-radius: 5px;
  border-left: 3px solid ${(props) => (props.color ? props.color : '')};
  display: flex;
  flex-direction: column;
  font-family: 'Kanit';
`;
const HolidayBlock = styled.div`
  margin: 3px;
  padding: 5px;
  background-color: #ffab4f;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-family: 'Kanit';
`;
const TimelineVerticalBorder = styled.div`
  border-left: 1px solid #eaeaea;
  display: block;
  background-color: transparent;
`;
const TimelineHorizontalBorder = styled.div`
  border-top: 1px solid #eaeaea;
  display: block;
  background-color: transparent;
`;

const Calendar = styled.div`
  display: grid;
  grid-gap: 0px;
  grid-auto-flow: row;
  max-height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: 7px;
    background-color: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #004368;
  }
  grid-template-rows:
    [tracks] auto
    [week-1] minmax(150px, 1fr)
    [week-2] minmax(150px, 1fr)
    [week-3] minmax(150px, 1fr)
    [week-4] minmax(150px, 1fr)
    ${(props) => (props.weeksInMonth >= 4 ? `[week-5] minmax(150px,1fr)` : '')}
    ${(props) => (props.weeksInMonth >= 5 ? `[week-6] minmax(150px,1fr)` : '')}
    ${(props) => (props.weeksInMonth == 6 ? `[week-7] minmax(150px,1fr)` : '')};

  grid-template-columns:
    [sun-start] 14.2%
    [sun-end mon-start] 14.2%
    [mon-end tue-start] 14.2%
    [tue-end wed-start] 14.2%
    [wed-end thu-start] 14.2%
    [thu-end fri-start] 14.2%
    [fri-end sat-start] 14.8%;
`;

const CalendarDate = styled.div`
  border: '1px solid #eaeaea';
  border-bottom: transparent;
  padding-top: 10px;
  font-family: 'Kanit';
  color: ${(props) => (props.filler ? `#acacac` : '#000000')};

  display: flex;
  justify-content: center;
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  padding: 0px 15px;
  background-color: #ffffff;
  border-color: #eaeaea;
  justify-content: center;
  color: #232323;
  font-family: Kanit;
  font-style: Regular;
  font-size: 14px;
  line-height: 19.42px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
`;

const Selectdata = styled(Select)`
  // max-width:14rem;
  width: 10rem;
  height: 2rem;
  // margin-left:2rem;
`;

const TaskList = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
    width: 7px;
    background-color: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #e0e0e0;
  }
`;

const Segmented = styled(SegmentedDefault)`
  &.ant-segmented {
    background-color: #ffffff;
    border: 1px solid #eaeaea !important;
    border-radius: 5px;
  }

  &.ant-segmented:not(.ant-segmented-disabled):hover {
    background-color: #ffffff;
  }

  .ant-segmented-item-selected {
    background-color: #004368 !important;
    color: #ffffff;
  }

  .ant-segmented-item {
    transition: #004368 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-segmented-thumb {
    background-color: #004368;
  }
`;

class TimesheetDetailEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      hasData: false,
      incorrect: false,
      year: moment().year(),
      month: moment().month() + 1,
      type: '',
      search: '',
      limit: 10,
      offset: 0,
      total: 1,
      current: 1,
      mode: 'month',
      week: moment().week() - moment().startOf('month').week() + 1,
      day: moment().date(),
      createTaskModalVisible: false,
      createOtModalVisible: false,
      editTaskModalVisible: false,
      editOtModalVisible: false,
      showTaskModalVisible: false,
      showTaskData: {},
      showLeaveModalVisible: false,
      showLeaveData: {},
      showHolidayModalVisible: false,
      showHolidayData: {},
      deleteTaskModalVisible: false,
      userId: '',
      activeProject: [],
      selectedTask: {},
      isAllday: false,
      isButtonLoading: false,
      tasksList: [],
      TIME_IN: '00:00:00',
      TIME_OUT: '00:00:00',
      LUNCH_IN: '00:00:00',
      LUNCH_OUT: '00:00:00',
      timesheetMode: 'calendar',
    };
  }

  pad = (num, size) => {
    return num.toString().padStart(size, '0');
  };
  getSelectedYearMonth = () => {
    const yearMonth =
      this.state.year.toString() + '-' + this.pad(this.state.month, 2);
    return moment(yearMonth, 'YYYY-MM');
  };
  daysInMonth = () => {
    return moment(this.getSelectedYearMonth()).daysInMonth();
  };

  weeksInMonth = () => {
    //number of weeks in calendar ex. july 2022 has 6
    const startWeek = moment(this.getSelectedYearMonth())
      .startOf('month')
      .week();
    const endWeek = moment(this.getSelectedYearMonth()).endOf('month').week();
    //if enter new year give endWeek = 1
    if (endWeek < startWeek) {
      return (
        moment(this.getSelectedYearMonth()).weeksInYear() -
        startWeek +
        1 +
        endWeek
      );
    }
    return endWeek - startWeek + 1;
  };

  handleTodayButton = () => {
    this.setState(
      {
        year: moment().year(),
        month: moment().month() + 1,
        day: moment().date(),
        hasData: false,
        week: moment().week() - moment().startOf('month').week() + 1,
      },
      () => this.handlefetchTimesheetDetail(),
    );
  };

  handleDeleteTask = async () => {
    let success = true;
    await TimesheetService.deleteTimesheet(
      this.state.selectedTask.id,
      async ({ data }) => {},
      (response) => {
        if (response && response.status === 400) {
          message.error('Delete Error');
          success = false;
        }
      },
    );
    if (success) {
      message.success('Delete Success');
    }
    this.setState({
      deleteTaskModalVisible: false,
      showTaskData: {},
      showTaskModalVisible: false,
    });
    this.handlefetchTimesheetDetail();
  };

  closeCreateTaskModal = () => {
    this.setState({
      createTaskModalVisible: false,
      editTaskModalVisible: false,
    });
  };

  closeCreateOtModal = () => {
    this.setState({
      createOtModalVisible: false,
      editOtModalVisible: false,
    });
  };

  closeTaskModal = () => {
    this.setState({ showTaskModalVisible: false, showTaskData: {} });
  };

  closeLeaveModal = () => {
    this.setState({
      showLeaveModalVisible: false,
      showLeaveData: {},
    });
  };

  closeHolidayModal = () => {
    this.setState({
      showHolidayModalVisible: false,
      showHolidayData: {},
    });
  };

  fetchActiveProjectOfUser = async () => {
    await ProjectService.fetchActiveProjectListByUser(
      async ({ data }) => {
        this.setState({
          hasData: true,
          activeProject: data,
        });
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          if (response.data.message !== 'empty array') {
            message.error('Cannot access data: ' + response.data.message);
          }
        }
      },
    );
  };

  handleDayOffset = (value) => {
    if (this.state.day === 1 && value === -1) {
      this.handleMonthOffset(-1);
    } else if (
      this.state.day === moment(this.getSelectedYearMonth()).daysInMonth() &&
      value === 1
    ) {
      this.handleMonthOffset(1);
    } else {
      this.setState({
        day: this.state.day + value,
        //set week to contain that date
        week:
          moment({
            year: this.state.year,
            month: this.state.month - 1,
            day: this.state.day + value,
          }).week() -
          moment(this.getSelectedYearMonth()).startOf('month').week() +
          1,
      });
    }
  };
  handleWeekOffset = (value) => {
    if (this.state.week == 1 && value == -1) {
      this.handleMonthOffset(-1);
    } else if (this.state.week == this.weeksInMonth() && value == 1) {
      this.handleMonthOffset(1);
    } else {
      this.setState({
        week: this.state.week + value,
        //set day to start of that week
        day:
          this.state.week + value === 1
            ? 1
            : this.getSelectedYearMonth()
                .week(
                  this.getSelectedYearMonth().week() -
                    1 +
                    this.state.week +
                    value,
                )
                .startOf('week')
                .date(),
      });
    }
  };

  handleMonthOffset = (value) => {
    const newValue = moment(this.getSelectedYearMonth()).add(value, 'months');
    if (value == 1) {
      this.setState(
        {
          year: moment(newValue).year(),
          month: moment(newValue).month() + 1,
          hasData: false,
          week: 1,
          day: 1,
        },
        () => this.handlefetchTimesheetDetail(),
      );
    } else {
      const startWeek = moment(this.getSelectedYearMonth())
        .subtract(1, 'month')
        .startOf('month')
        .week();
      const endWeek = moment(this.getSelectedYearMonth())
        .subtract(1, 'month')
        .endOf('month')
        .week();

      //case cross year (endweek = 1)
      if (endWeek < startWeek) {
        this.setState({
          week:
            moment(this.getSelectedYearMonth())
              .subtract(1, 'month')
              .weeksInYear() -
            startWeek +
            1 +
            endWeek,
        });
      } else {
        this.setState({ week: endWeek - startWeek + 1 });
      }
      this.setState(
        {
          year: moment(newValue).year(),
          month: moment(newValue).month() + 1,
          hasData: false,
          day: moment(newValue).daysInMonth(),
        },
        () => this.handlefetchTimesheetDetail(),
      );
    }
  };

  daysInWeek = (weekNo) => {
    let dateList = [];
    const weekInYear = moment(this.getSelectedYearMonth()).week() + weekNo - 1;
    const startDate = moment(this.getSelectedYearMonth())
      .week(weekInYear)
      .day('Sunday');
    for (let i = 0; i < 7; i++) {
      dateList.push(startDate.format('llll'));
      startDate.add(1, 'day');
    }

    return dateList;
  };
  getWeekTimesheetTemplate = (day) => {
    const dayOfWeekIndex = moment(day).day();
    return (
      <React.Fragment>
        <DaySlot
          style={{
            gridRow: 'tracks',
            gridColumn: `day-${dayOfWeekIndex + 1}`,
            color: `${
              moment(day).month() + 1 === this.state.month
                ? '#000000'
                : '#ACACAC'
            }`,
          }}
        >
          {this.getDayName(day)}
        </DaySlot>
        <TimelineVerticalBorder
          style={{
            gridRow: `${
              this.state.data?.isBefore9 ? 'time-0000' : 'time-0900'
            } / ${this.state.data?.isAfter18 ? 'time-2400' : 'time-1800'}`,
            gridColumn: `day-${dayOfWeekIndex + 1}`,
          }}
        />
      </React.Fragment>
    );
  };

  getWeekTimelineBlock = (value, day) => {
    const timeRange = `time-${moment(value.startTime).format(
      'HHmm',
    )} / time-${moment(value.endTime).format('HHmm')}`;
    const dayOfWeekIndex = moment(day).day();

    if (moment(day).month() + 1 !== this.state.month) {
      return;
    }
    if (value.type === 'leave') {
      return (
        <TimelineBlock
          leave
          color="#D62923"
          style={{
            gridRow: timeRange,
            gridColumn: `day-${dayOfWeekIndex + 1}`,
          }}
          onClick={() => {
            this.setState({
              showLeaveModalVisible: true,
              showLeaveData: value,
            });
          }}
        >
          <div>
            <h4>{`${value.leaveName} LEAVE`}</h4>
          </div>

          <div
            style={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ color: '#737373', fontSize: '12px' }}>
              {value.purpose}
            </div>
            <div
              style={{
                color: '#737373',
                fontSize: '12px',
              }}
            >
              {moment(value.startTime).format('HH.mm')} -{' '}
              {moment(value.endTime).format('HH.mm')}
            </div>
          </div>
        </TimelineBlock>
      );
    } else if (value.type === 'task') {
      return (
        <TimelineBlock
          color={value.projectColorCode}
          style={{
            gridRow: timeRange,
            gridColumn: `day-${dayOfWeekIndex + 1}`,

            overflow: 'hidden',
          }}
          onClick={() => {
            this.setState({
              showTaskModalVisible: true,
              showTaskData: value,
            });
          }}
        >
          <div>
            <h4
              style={{
                fontSize: '12px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >{`${value.projectNo} - ${value.projectName}`}</h4>
          </div>
          <div
            style={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ color: '#737373', fontSize: '12px' }}>
              {value.taskDescription}
            </div>
            <div
              style={{
                color: '#737373',
                fontSize: '12px',
              }}
            >
              {moment(value.startTime).format('HH.mm')} -{' '}
              {moment(value.endTime).format('HH.mm')}
            </div>
          </div>
        </TimelineBlock>
      );
    } else if (value.type === 'holiday') {
      return (
        <HolidayBlock
          style={{
            gridRow: 'holiday',
            gridColumn: `day-${dayOfWeekIndex + 1}`,
          }}
          onClick={() => {
            this.setState({
              showHolidayModalVisible: true,
              showHolidayData: value,
            });
          }}
        >
          <TableText style={{ color: '#ffffff', fontSize: '12px' }}>
            {value.dateName}
          </TableText>
        </HolidayBlock>
      );
    }
  };

  generateCalendarHorizontalBorder = (weekNum) => {
    return [...Array(weekNum + 1)].map((x, i) => (
      <>
        <TimelineHorizontalBorder
          style={{
            gridRow: `week-${i + 1}`,
            gridColumn: `sun-start / sat-end`,
          }}
        />
      </>
    ));
  };

  getCalendarTemplate = (dateIndex) => {
    //to generate sun - sat at header and vertical border
    const dayOfWeek = moment().day(dateIndex).format('ddd').toLowerCase();
    const numWeek = this.weeksInMonth();
    return (
      <React.Fragment>
        <DaySlot
          style={{
            gridRow: 'tracks',
            gridColumn: `${dayOfWeek}-start`,
            color: '#acacac',
          }}
        >
          {dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)}
        </DaySlot>
        <TimelineVerticalBorder
          style={{
            gridRow: `week-1 /  week-${numWeek + 1}`, //to make the line full
            gridColumn: `${dayOfWeek}-start`,
          }}
        />
      </React.Fragment>
    );
  };

  getCalendarBlock = (dayNum) => {
    const day = moment({
      year: this.state.year,
      month: this.state.month - 1,
      day: dayNum,
    });

    const dayOfWeek = moment(day).format('ddd').toLowerCase();
    const startWeek = moment(day).startOf('month').week();
    const currWeek = moment(day).endOf('week').week();
    let weekNo;
    if (currWeek < startWeek) {
      weekNo =
        moment(this.getSelectedYearMonth()).weeksInYear() -
        startWeek +
        1 +
        currWeek;
    } else {
      weekNo = currWeek - startWeek + 1;
    }
    let data = this.state.data?.[`day${dayNum}`].filter(
      (value) => value.type === 'totalMinutes',
    );
    data = data ? data : [];
    const totalWorkHours =
      data.length === 0 ? 0 : data[0].totalWorkingMinutes / 60;
    const totalWorkMinutes =
      data.length === 0 ? 0 : data[0].totalWorkingMinutes % 60;
    const totalOtHours = data.length === 0 ? 0 : data[0].totalOtMinutes / 60;
    const totalOtMinutes = data.length === 0 ? 0 : data[0].totalOtMinutes % 60;

    return (
      <div
        style={{
          gridRow: `week-${weekNo}`,
          gridColumn: `${dayOfWeek}-start`,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CalendarDate>
          {day.format('L') === moment().format('L') && (
            <div
              style={{
                backgroundColor: '#177DDC',
                color: '#FFFFFF',
                borderRadius: '50%',
                width: '32px',
                height: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '20px',
              }}
            >
              {dayNum}
            </div>
          )}
          {day.format('L') !== moment().format('L') && (
            <div style={{ fontSize: '20px' }}>{dayNum}</div>
          )}
        </CalendarDate>
        <TaskList>
          {this.state.data?.[`day${dayNum}`]?.map((value) => {
            return this.getCalendarTaskBlock(value);
          })}
        </TaskList>
        {data.length !== 0 && (
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              padding: '8px',
              overflowX: 'clip',
              width: '100%',
            }}
          >
            <div
              style={{
                padding: '0px 8px',
                backgroundColor: '#E5ECF0',
                color: '#004368',
                borderRadius: '2px',
                fontSize: '12px',
                width: 'fit-content',
              }}
            >
              <ClockCircleOutlined
                style={{ marginRight: '4px', fontSize: '10px' }}
              />
              {totalWorkHours !== 0 ? `${totalWorkHours} hrs` : undefined}
              {totalWorkMinutes !== 0 ? ` ${totalWorkMinutes} mins` : undefined}
              <span style={{ color: '#D62923' }}>
                {data[0].totalWorkingMinutes && data[0].totalOtMinutes
                  ? ` + `
                  : undefined}
                {totalOtHours !== 0 ? `${totalOtHours} hrs` : undefined}
                {totalOtMinutes !== 0 ? ` ${totalOtMinutes} mins` : undefined}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  getCalendarTaskBlock = (value) => {
    if (value.type === 'leave') {
      return (
        <TimelineBlock
          style={{
            height: '25px',
            padding: '5px',
            overflow: 'hidden',
            backgroundColor: '#D62923',
            color: '#FFFFFF',
            margin: '0px',
            borderLeft: '0px',
          }}
          onClick={() => {
            this.setState({
              showLeaveModalVisible: true,
              showLeaveData: value,
            });
          }}
        >
          <div style={{ fontSize: '12px' }}>{`${value.leaveName} LEAVE`}</div>
        </TimelineBlock>
      );
    } else if (value.type === 'task') {
      return (
        <TimelineBlock
          color={value.projectColorCode}
          style={{
            height: '25px',
            padding: '5px',
            overflow: 'hidden',
            margin: '0px',
          }}
          onClick={() => {
            this.setState({
              showTaskModalVisible: true,
              showTaskData: value,
            });
          }}
        >
          <div>
            <h4
              style={{ fontSize: '12px' }}
            >{`${value.projectNo} - ${value.projectName}`}</h4>
          </div>
        </TimelineBlock>
      );
    } else if (value.type === 'holiday') {
      return (
        <HolidayBlock
          style={{
            height: '25px',
            overflow: 'hidden',
            margin: '0px',
          }}
          onClick={() => {
            this.setState({
              showHolidayModalVisible: true,
              showHolidayData: value,
            });
          }}
        >
          <TableText style={{ color: '#ffffff', fontSize: '12px' }}>
            {value.dateName}
          </TableText>
        </HolidayBlock>
      );
    }
  };

  fillCalendar = () => {
    //this method gen extra date to fill empty space in calendar
    let res = [];
    const startOfFirstWeek = moment(this.getSelectedYearMonth()).startOf(
      'week',
    ); // to get date in week before start of the month
    const startOfNextMonth = moment(this.getSelectedYearMonth())
      .add(1, 'month')
      .startOf('month');
    const EndOfLastWeek = moment(this.getSelectedYearMonth()).endOf('month'); // to get date after end of month but still in same week
    const endWeek = this.weeksInMonth();
    while (startOfFirstWeek.month() + 1 !== this.state.month) {
      res.push(
        <div
          style={{
            gridRow: `week-1`,
            gridColumn: `${startOfFirstWeek.format('ddd').toLowerCase()} `,
          }}
        >
          <CalendarDate filler>
            <div style={{ fontSize: '20px' }}>{startOfFirstWeek.date()}</div>
          </CalendarDate>
        </div>,
      );
      startOfFirstWeek.add(1, 'days');
    }
    while (EndOfLastWeek.week() === startOfNextMonth.week()) {
      res.push(
        <div
          style={{
            gridRow: `week-${endWeek}`,
            gridColumn: `${startOfNextMonth.format('ddd').toLowerCase()} `,
          }}
        >
          <CalendarDate filler>
            <div style={{ fontSize: '20px' }}>{startOfNextMonth.date()}</div>
          </CalendarDate>
        </div>,
      );
      startOfNextMonth.add(1, 'days');
    }
    return res;
  };

  getDayName = (day) => {
    return (
      <React.Fragment>
        <div style={{ color: '#ACACAC' }}>{moment(day).format('ddd')}</div>
        {moment(day).format('L') === moment().format('L') && (
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <div
              style={{
                backgroundColor: '#177DDC',
                color: '#FFFFFF',
                borderRadius: '50%',
                width: '32px',
                height: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '20px',
              }}
            >
              {moment(day).format('D')}
            </div>
          </div>
        )}
        {moment(day).format('L') !== moment().format('L') && (
          <div style={{ fontSize: '20px' }}>{moment(day).format('D')}</div>
        )}
      </React.Fragment>
    );
  };
  handleRedirect = (path) => {
    redirectTo(path);
  };
  handlefetchTimesheetDetail = async () => {
    const payload = {
      year: this.state.year || moment().year(),
      month: this.state.month || moment().month() + 1,
    };
    this.setState({ hasData: false });
    const data = await TimesheetService.fetchTimesheetDetail(
      payload,
      async ({ data }) => {
        return data;
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          if (response.data.message !== 'empty array') {
            message.error('Cannot access data: ' + response.data.message);
          }
        }
      },
    );
    Promise.resolve(data).then((values) => {
      this.setState({
        hasData: true,
        data: values,
      });
    });
  };

  componentDidMount = () => {
    this.fetchActiveProjectOfUser();
    this.getUser();
    this.handlefetchTimesheetDetail();
  };

  onSearchMonth = (value) => {
    this.setState(
      {
        year: moment(value).year(),
        month: moment(value).month() + 1,
        hasData: false,
        week: 1,
        day: 1,
      },
      () => this.handlefetchTimesheetDetail(),
    );
  };

  onChangeTimesheetMode = (value) => {
    this.setState({
      timesheetMode: value,
    });
  };

  openEditTaskModal = () => {
    this.setState({
      showTaskModalVisible: false,
      editTaskModalVisible: true,
      selectedTask: this.state.showTaskData,
    });
  };

  openEditOtModal = () => {
    this.setState({
      showTaskModalVisible: false,
      editOtModalVisible: true,
      selectedTask: this.state.showTaskData,
    });
  };

  openDeleteTaskModal = () => {
    this.setState({
      showTaskModalVisible: false,
      deleteTaskModalVisible: true,
      selectedTask: this.state.showTaskData,
    });
  };

  getTimelineBlock = (value) => {
    const timeRange = `time-${moment(value.startTime).format(
      'HHmm',
    )} / time-${moment(value.endTime).format('HHmm')}`;
    if (value.type === 'leave') {
      return (
        <TimelineBlock
          leave
          color="#D62923"
          style={{
            gridRow: timeRange,
            gridColumn: `day-1`,
          }}
          onClick={() => {
            this.setState({
              showLeaveModalVisible: true,
              showLeaveData: value,
            });
          }}
        >
          <div>
            <h4>{`${value.leaveName} LEAVE`}</h4>
          </div>

          <div
            style={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ color: '#737373', fontSize: '12px' }}>
              {value.purpose}
            </div>
            <div
              style={{
                color: '#737373',
                fontSize: '12px',
              }}
            >
              {moment(value.startTime).format('HH.mm')} -{' '}
              {moment(value.endTime).format('HH.mm')}
            </div>
          </div>
        </TimelineBlock>
      );
    } else if (value.type === 'task') {
      return (
        <TimelineBlock
          color={value.projectColorCode}
          style={{
            gridRow: timeRange,
            gridColumn: `day-1`,
            overflow: 'hidden',
          }}
          onClick={() => {
            this.setState({
              showTaskModalVisible: true,
              showTaskData: value,
            });
          }}
        >
          <div>
            <h4
              style={{ fontSize: '12px' }}
            >{`${value.projectNo} - ${value.projectName}`}</h4>
          </div>

          <div
            style={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ color: '#737373', fontSize: '12px' }}>
              {value.taskDescription}
            </div>
            <div
              style={{
                color: '#737373',
                fontSize: '12px',
              }}
            >
              {moment(value.startTime).format('HH.mm')} -{' '}
              {moment(value.endTime).format('HH.mm')}
            </div>
          </div>
        </TimelineBlock>
      );
    } else if (value.type === 'holiday') {
      return (
        <HolidayBlock
          style={{
            gridRow: 'holiday',
            gridColumn: `day-1`,
          }}
          onClick={() => {
            this.setState({
              showHolidayModalVisible: true,
              showHolidayData: value,
            });
          }}
        >
          <TableText style={{ color: '#ffffff', fontSize: '12px' }}>
            {value.dateName}
          </TableText>
        </HolidayBlock>
      );
    }
  };
  getTimesheetTemplate = (day) => {
    return (
      <React.Fragment>
        <DaySlot
          style={{
            gridRow: 'tracks',
            gridColumn: `day-1`,
          }}
        >
          {this.getDayName(day)}
        </DaySlot>
        <TimelineVerticalBorder
          style={{
            gridRow: `${
              this.state.data?.isBefore9 ? 'time-0000' : 'time-0900'
            } / ${this.state.data?.isAfter18 ? 'time-2400' : 'time-1800'}`,
            gridColumn: `day-1`,
          }}
        />
      </React.Fragment>
    );
  };

  generateTimeSlot = (start, end) => {
    const n = (end - start + 1) * 2 - 1;
    let res = [];
    //when generate slot 19 ,24 need to add 18.30 before
    if (start === 19) {
      res.push(
        <>
          <TimeSlot
            style={{
              gridRow: `time-1830`,
            }}
          >
            <TableText style={{ color: '#ACACAC', fontSize: '12px' }}>
              18:30
            </TableText>
          </TimeSlot>
        </>,
      );
    }

    [...Array(n)].map((x, i) =>
      res.push(
        <>
          <TimeSlot
            style={{
              gridRow: `time-${this.pad(Math.floor(i / 2) + start, 2)}${
                i % 2 === 0 ? '00' : '30'
              }`,
            }}
          >
            <TableText style={{ color: '#ACACAC', fontSize: '12px' }}>
              {this.pad(Math.floor(i / 2) + start, 2)}
              {i % 2 === 0 ? ':00' : ':30'}
            </TableText>
          </TimeSlot>
        </>,
      ),
    );

    //if generate 0,8 need to add 8.30
    if (end === 8) {
      res.push(
        <>
          <TimeSlot
            style={{
              gridRow: `time-0830`,
            }}
          >
            <TableText style={{ color: '#ACACAC', fontSize: '12px' }}>
              08:30
            </TableText>
          </TimeSlot>
        </>,
      );
    }

    return res;
  };
  generateHorizontalBorder = (start, end, days) => {
    const n = (end - start + 1) * 2 - 1;
    return [...Array(n)].map((x, i) => (
      <>
        <TimelineHorizontalBorder
          style={{
            gridRow:
              i % 2 === 0
                ? `time-${this.pad(start + Math.floor(i / 2), 2)}00`
                : ` time-${this.pad(start + Math.floor(i / 2), 2)}30`,
            gridColumn: `day-1-start / day-${days}-end`,
          }}
        />
      </>
    ));
  };

  getUser = () => {
    var jwt = require('jsonwebtoken');
    var token = sessionStorage.getItem('access-token');
    var decode1 = jwt.decode(token);
    this.setState({ userId: decode1.userId });
  };

  render() {
    const timesheetComponent = (
      <React.Fragment>
        {this.state.mode === 'week' &&
          this.daysInWeek(this.state.week).map((x, i) => {
            return this.getWeekTimesheetTemplate(x);
          })}

        {this.state.mode === 'week' &&
          this.daysInWeek(this.state.week).map((x, i) => {
            return this.state.data?.[`day${moment(x).format('D')}`]?.map(
              (value) => {
                return this.getWeekTimelineBlock(value, x);
              },
            );
          })}

        {this.state.mode === 'day' &&
          this.getTimesheetTemplate(
            moment({
              year: this.state.year,
              month: this.state.month - 1,
              day: this.state.day,
            }),
          )}

        {this.state.mode === 'day' &&
          this.state.data?.[`day${this.state.day}`]?.map((value) => {
            return this.getTimelineBlock(value);
          })}
      </React.Fragment>
    );

    return (
      <PageContent>
        <Spin spinning={!this.state.hasData}>
          <ContentBox>
            <FilterBox style={{ padding: '0 1%', marginTop: '1%' }}>
              <Rows
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Cols>
                  <Cols>
                    <Segmented
                      size="large"
                      options={[
                        {
                          value: 'calendar',
                          label: (
                            <div style={{ width: '50px' }}>
                              <CalendarOutlined />
                            </div>
                          ),
                        },
                        {
                          value: 'hour',
                          label: (
                            <div style={{ width: '50px' }}>
                              <InsertRowLeftOutlined />
                            </div>
                          ),
                        },
                      ]}
                      value={this.state.timesheetMode}
                      onChange={this.onChangeTimesheetMode}
                    />
                  </Cols>
                  <Cols>
                    <DatePicker
                      onChange={this.onSearchMonth}
                      picker="month"
                      allowClear={false}
                      format="MMM YYYY"
                      value={this.getSelectedYearMonth()}
                    ></DatePicker>
                  </Cols>
                  <Cols>
                    <Button
                      type="text"
                      icon={<LeftOutlined />}
                      shape="circle"
                      onClick={() => {
                        switch (this.state.mode) {
                          case 'day':
                            this.handleDayOffset(-1);
                            break;
                          case 'week':
                            this.handleWeekOffset(-1);
                            break;
                          case 'month':
                            this.handleMonthOffset(-1);
                            break;
                        }
                      }}
                    />
                    <Button
                      type="text"
                      icon={<RightOutlined />}
                      shape="circle"
                      onClick={() => {
                        switch (this.state.mode) {
                          case 'day':
                            this.handleDayOffset(1);
                            break;
                          case 'week':
                            this.handleWeekOffset(1);
                            break;
                          case 'month':
                            this.handleMonthOffset(1);
                            break;
                        }
                      }}
                    />
                  </Cols>
                </Cols>
                {this.state.timesheetMode === 'calendar' && (
                  <Col>
                    <Row>
                      <Cols>
                        <NormalButton onClick={this.handleTodayButton}>
                          Today
                        </NormalButton>
                      </Cols>
                      <Cols>
                        <Selectdata
                          onChange={(value) => this.setState({ mode: value })}
                          value={this.state.mode}
                        >
                          {/* <Select.Option key="day" value="day">
                            Day
                          </Select.Option>
                          <Select.Option key="week" value="week">
                            Week
                          </Select.Option> */}
                          <Select.Option key="month" value="month">
                            Month
                          </Select.Option>
                        </Selectdata>
                      </Cols>
                      <Cols>
                        <NormalButton
                          onClick={() => {
                            this.setState({
                              createOtModalVisible: true,
                              selectedTask: null,
                            });
                          }}
                          style={{
                            backgroundColor: '#004368',
                            color: '#FFFFFF',
                          }}
                        >
                          <img
                            src={newTaskIcon}
                            style={{ marginRight: '0.5rem' }}
                          />
                          OT
                        </NormalButton>
                      </Cols>
                      <Cols>
                        <NormalButton
                          onClick={() => {
                            this.setState({
                              createTaskModalVisible: true,
                              selectedTask: null,
                            });
                          }}
                          style={{
                            backgroundColor: '#004368',
                            color: '#FFFFFF',
                          }}
                        >
                          <img
                            src={newTaskIcon}
                            style={{ marginRight: '0.5rem' }}
                          />
                          New Task
                        </NormalButton>
                      </Cols>
                    </Row>
                  </Col>
                )}
              </Rows>
            </FilterBox>
            <Rows style={{ justifyContent: 'end' }}>
              <Col flex="400px">
                <Progress
                  style={{ margin: '0 10px' }}
                  percent={this.state.data?.percentTimesheetComplete * 100}
                  showInfo={false}
                />
              </Col>
              <Col>
                <span style={{ margin: '0 50px 0 20px' }}>
                  {Math.round(
                    this.state.data?.percentTimesheetComplete * 100,
                  ) || 0}
                  %
                </span>
              </Col>
            </Rows>
            <Break />
            {this.state.timesheetMode === 'hour' && (
              <TimesheetHourTable
                year={this.state.year}
                month={this.state.month}
                data={this.state.data}
                activeProject={this.state.activeProject}
                handlefetchTimesheetDetail={this.handlefetchTimesheetDetail}
              />
            )}
            {this.state.timesheetMode === 'calendar' && (
              <div style={{ padding: '0 3%' }}>
                {this.state.mode !== 'month' && (
                  <Schedule
                    daysInMonth={this.daysInMonth()}
                    isBefore9={this.state.data?.isBefore9}
                    isAfter18={this.state.data?.isAfter18}
                    day={this.state.mode === 'day'}
                    week={this.state.mode === 'week'}
                  >
                    <TopLeftBg />
                    <TimeBg
                      style={{
                        gridRow: `holiday  / ${
                          this.state.data?.isAfter18 ? 'time-2400' : 'time-1800'
                        }`,
                      }}
                    />
                    <DayBg
                      style={{
                        gridColumn:
                          this.state.mode === 'day'
                            ? 'day-1-start'
                            : `day-1-start / day-7-end`,
                      }}
                    />
                    {this.state.data?.isBefore9 && this.generateTimeSlot(0, 8)}
                    {this.generateTimeSlot(9, 18)}
                    {this.state.data?.isAfter18 &&
                      this.generateTimeSlot(19, 24)}
                    {this.generateHorizontalBorder(
                      this.state.data?.isBefore9 ? 0 : 9,
                      this.state.data?.isAfter18 ? 24 : 18,
                      this.state.mode === 'day' ? 1 : 7,
                    )}
                    {timesheetComponent}
                  </Schedule>
                )}
                {this.state.mode === 'month' && (
                  <Calendar weeksInMonth={this.weeksInMonth()}>
                    <TopLeftBg />
                    <DayBg
                      style={{
                        gridColumn: `sun-start / sat-end`,
                      }}
                    />
                    <TimeBg
                      style={{
                        gridRow: 'track',
                      }}
                    />

                    {this.generateCalendarHorizontalBorder(this.weeksInMonth())}
                    {this.state.mode === 'month' &&
                      [...Array(7)].map((x, i) => {
                        return this.getCalendarTemplate(i);
                      })}

                    {this.state.mode === 'month' &&
                      [...Array(this.daysInMonth())].map((x, i) => {
                        return this.getCalendarBlock(i + 1);
                      })}
                    {this.fillCalendar()}
                  </Calendar>
                )}
              </div>
            )}
            <CreateOrEditTaskModal
              createTaskModalVisible={this.state.createTaskModalVisible}
              editTaskModalVisible={this.state.editTaskModalVisible}
              closeCreateTaskModal={this.closeCreateTaskModal}
              handlefetchTimesheetDetail={this.handlefetchTimesheetDetail}
              selectedTask={this.state.selectedTask}
              activeProject={this.state.activeProject}
            />
            <CreateOrEditOtModal
              createOtModalVisible={this.state.createOtModalVisible}
              editOtModalVisible={this.state.editOtModalVisible}
              closeCreateOtModal={this.closeCreateOtModal}
              handlefetchTimesheetDetail={this.handlefetchTimesheetDetail}
              selectedTask={this.state.selectedTask}
              activeProject={this.state.activeProject}
            />
            <Modal
              open={this.state.deleteTaskModalVisible}
              onCancel={() => {
                this.setState({ deleteTaskModalVisible: false });
              }}
              onOk={this.handleDeleteTask}
            >
              Are you sure to delete?
            </Modal>
            <TaskModal
              closeTaskModal={this.closeTaskModal}
              openEditTaskModal={this.openEditTaskModal}
              openDeleteTaskModal={this.openDeleteTaskModal}
              showTaskModalVisible={this.state.showTaskModalVisible}
              showTaskData={this.state.showTaskData}
              openEditOtModal={this.openEditOtModal}
            />
            <LeaveModal
              closeLeaveModal={this.closeLeaveModal}
              showLeaveModalVisible={this.state.showLeaveModalVisible}
              showLeaveData={this.state.showLeaveData}
            />
            <HolidayModal
              closeHolidayModal={this.closeHolidayModal}
              showHolidayModalVisible={this.state.showHolidayModalVisible}
              showHolidayData={this.state.showHolidayData}
            />
          </ContentBox>
        </Spin>
      </PageContent>
    );
  }
}
export default TimesheetDetailEmployee;
