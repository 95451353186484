import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Col, Modal } from 'antd';
import closeIcon from '../../assets/icons/close.svg';
import taskDescriptionIcon from '../../assets/icons/taskDescription.svg';

const Cols = styled(Col)`
  display: flex;
  align-items: center;
  margin: 0px 10px;
`;

const Headdetail = styled.div`
  font-family: 'Kanit';
  font-size: 24px;
  font-weight: 500;
`;

const Horizontalbox = styled.div`
  width: auto;
  display: flex;
  justify-content: ${(props) => props.justify || 'start'};
  margin-bottom: 1rem;
  @media (max-width: 714px) {
    flex-direction: column;
  }
`;

const InfoText = styled.div`
  color: ${(props) => (props.color ? props.color : '#232323')};
  font-size: ${(props) =>
    props.title ? '1.125em' : props.subtitle ? '1.125em' : '0.9em'};
  font-weight: ${(props) => (props.title ? 'bold' : 'normal')};
  font-family: 'Kanit';
  text-transform: ${(props) => (props.subtitle ? 'uppercase' : 'none')};
  margin-bottom: 5px;
`;

class HolidayModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal
        title={
          <div
            style={{
              justifyContent: 'end',
              display: 'flex',
            }}
          >
            <img src={closeIcon} onClick={this.props.closeHolidayModal} />
          </div>
        }
        open={this.props.showHolidayModalVisible}
        width={600}
        footer={null}
        onCancel={this.props.closeHolidayModal}
        closable={false}
      >
        <Horizontalbox>
          <Cols
            style={{
              width: '40px',
            }}
          >
            <div
              style={{
                padding: '10px',
                borderRadius: '3px',
                backgroundColor: '#FFAB4F',
              }}
            ></div>
          </Cols>
          <Headdetail>{this.props.showHolidayData?.dateName}</Headdetail>
        </Horizontalbox>
        <Horizontalbox>
          <Cols style={{ width: '40px' }}></Cols>
          <InfoText>
            {moment(this.props.showHolidayData?.holidayDate).format(
              'dddd, MMM D, yyyy',
            )}
          </InfoText>
          <Cols width="30px"></Cols>
        </Horizontalbox>
        <Horizontalbox>
          <Cols style={{ width: '40px' }}>
            <img src={taskDescriptionIcon} />
          </Cols>
          <InfoText>Holidays in Thailand</InfoText>
        </Horizontalbox>
      </Modal>
    );
  }
}

export default HolidayModal;
