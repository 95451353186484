import React, { Component } from 'react';
import styled from 'styled-components';
import { Layout, Button, Row, Col, message, DatePicker } from 'antd';
import { imageservice, TimesheetService } from '../../services/api';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { redirectTo } from '../../services/redirect';
import moment from 'moment';
import profileImage from '../../assets/icons/profileimage.svg';
import { AppContext } from '../../components/context/AppContext';
import App from '../../App';
const PageContent = styled.div`
  width: auto;
  font-family: Kanit;
`;
const FilterBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  justify-content: space-between;
`;
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.subtitle ? '#20ABC5' : '#ff2132'};
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1.125em' : '1em'};
  font-weight: ${(props) =>
    props.title ? 'bold' : props.subtitle ? '600' : 'normal'};
  font-family: 'Kanit';
  text-transform: ${(props) => (props.subtitle ? 'uppercase' : 'none')};
`;
const InfoText = styled.div`
  color: ${(props) => (props.color ? props.color : '#232323')};
  font-size: ${(props) =>
    props.title ? '1.125em' : props.subtitle ? '1.125em' : '0.9em'};
  font-weight: ${(props) => (props.title ? 'bold' : 'normal')};
  font-family: 'Kanit';
  text-transform: ${(props) => (props.subtitle ? 'uppercase' : 'none')};
  margin-bottom: 5px;
`;
const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#ffffff')};
  box-sizing: border-box;
  // padding-left: 3%;
  // padding-right: 3%;
  display: flex;
  flex-direction: column;
  width: auto;
  height: calc(100vh - 64px);
`;
const Cols = styled(Col)`
  display: flex;
  align-items: center;
  margin: 0px 10px;
`;
const Rows = styled(Row)`
  margin: 10px 0px;
  align-items: center;
  position: right;
`;
const TableText = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.no ? '#20ABC5' : '#737373'};
  font-size: 1em;
  font-weight: ${(props) => (props.title ? '600' : 'normal')};
  font-family: 'Kanit';
  line-height: 14px;
  text-transform: ${(props) => props.capitalize && 'capitalize'};
`;
const Circle = styled.img`
  margin: 0;
  background: ${(props) =>
    props.small ? (props.src ? 'url(' + props.src + ')' : 'grey') : 'white'};
  background-repeat: no-repeat;
  background-size: 9.313rem;
  border-radius: 50%;
  height: ${(props) => (props.height ? props.height : '30px')};
  width: ${(props) => (props.width ? props.width : '30px')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
const Break = styled.div`
  // margin-top: 1rem;
  // margin-bottom: 1rem;
  width: 100%;
  border: 0.5px solid #eaeaea;
`;

const Schedule = styled.div`
  display: grid;
  grid-gap: 0px;
  grid-auto-flow: row;
  max-height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 7px;
    width: 7px;
    background-color: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #004368;
  }
  grid-template-rows:
    [tracks] auto
    [holiday] auto
    ${(props) =>
      props.isBefore9
        ? `[time-0000] minmax(30px, 1fr)
  [time-0015] minmax(30px, 1fr)
  [time-0030] minmax(30px, 1fr)
  [time-0045] minmax(30px, 1fr)
  [time-0100] minmax(30px, 1fr)
  [time-0115] minmax(30px, 1fr)
  [time-0130] minmax(30px, 1fr)
  [time-0145] minmax(30px, 1fr)
  [time-0200] minmax(30px, 1fr)
  [time-0215] minmax(30px, 1fr)
  [time-0230] minmax(30px, 1fr)
  [time-0245] minmax(30px, 1fr)
  [time-0300] minmax(30px, 1fr)
  [time-0315] minmax(30px, 1fr)
  [time-0330] minmax(30px, 1fr)
  [time-0345] minmax(30px, 1fr)
  [time-0400] minmax(30px, 1fr)
  [time-0415] minmax(30px, 1fr)
  [time-0430] minmax(30px, 1fr)
  [time-0445] minmax(30px, 1fr)
  [time-0500] minmax(30px, 1fr)
  [time-0515] minmax(30px, 1fr)
  [time-0530] minmax(30px, 1fr)
  [time-0545] minmax(30px, 1fr)
  [time-0600] minmax(30px, 1fr)
  [time-0615] minmax(30px, 1fr)
  [time-0630] minmax(30px, 1fr)
  [time-0645] minmax(30px, 1fr)
  [time-0700] minmax(30px, 1fr)
  [time-0715] minmax(30px, 1fr)
  [time-0730] minmax(30px, 1fr)
  [time-0745] minmax(30px, 1fr)
  [time-0800] minmax(30px, 1fr)
  [time-0815] minmax(30px, 1fr)
  [time-0830] minmax(30px, 1fr)
  [time-0845] minmax(30px, 1fr)`
        : ''}
    [time-0900] minmax(30px, 1fr)
    [time-0915] minmax(30px, 1fr)
    [time-0930] minmax(30px, 1fr)
    [time-0945] minmax(30px, 1fr)
    [time-1000] minmax(30px, 1fr)
    [time-1015] minmax(30px, 1fr)
    [time-1030] minmax(30px, 1fr)
    [time-1045] minmax(30px, 1fr)
    [time-1100] minmax(30px, 1fr)
    [time-1115] minmax(30px, 1fr)
    [time-1130] minmax(30px, 1fr)
    [time-1145] minmax(30px, 1fr)
    [time-1200] minmax(30px, 1fr)
    [time-1215] minmax(30px, 1fr)
    [time-1230] minmax(30px, 1fr)
    [time-1245] minmax(30px, 1fr)
    [time-1300] minmax(30px, 1fr)
    [time-1315] minmax(30px, 1fr)
    [time-1330] minmax(30px, 1fr)
    [time-1345] minmax(30px, 1fr)
    [time-1400] minmax(30px, 1fr)
    [time-1415] minmax(30px, 1fr)
    [time-1430] minmax(30px, 1fr)
    [time-1445] minmax(30px, 1fr)
    [time-1500] minmax(30px, 1fr)
    [time-1515] minmax(30px, 1fr)
    [time-1530] minmax(30px, 1fr)
    [time-1545] minmax(30px, 1fr)
    [time-1600] minmax(30px, 1fr)
    [time-1615] minmax(30px, 1fr)
    [time-1630] minmax(30px, 1fr)
    [time-1645] minmax(30px, 1fr)
    [time-1700] minmax(30px, 1fr)
    [time-1715] minmax(30px, 1fr)
    [time-1730] minmax(30px, 1fr)
    [time-1745] minmax(30px, 1fr)
    [time-1800] minmax(30px, 1fr)
    ${(props) =>
      props.isAfter18
        ? `[time-1815] minmax(30px, 1fr)
      [time-1830] minmax(30px, 1fr)
      [time-1845] minmax(30px, 1fr)
      [time-1900] minmax(30px, 1fr)
      [time-1915] minmax(30px, 1fr)
      [time-1930] minmax(30px, 1fr)
      [time-1945] minmax(30px, 1fr)
      [time-2000] minmax(30px, 1fr)
      [time-2015] minmax(30px, 1fr)
      [time-2030] minmax(30px, 1fr)
      [time-2045] minmax(30px, 1fr)
      [time-2100] minmax(30px, 1fr)
      [time-2115] minmax(30px, 1fr)
      [time-2130] minmax(30px, 1fr)
      [time-2145] minmax(30px, 1fr)
      [time-2200] minmax(30px, 1fr)
      [time-2215] minmax(30px, 1fr)
      [time-2230] minmax(30px, 1fr)
      [time-2245] minmax(30px, 1fr)
      [time-2300] minmax(30px, 1fr)
      [time-2315] minmax(30px, 1fr)
      [time-2330] minmax(30px, 1fr)
      [time-2345] minmax(30px, 1fr)
      [time-2400] minmax(30px, 1fr)`
        : ''};
  grid-template-columns:
    [times] 4em
    [day-1-start] 170px
    [day-1-end day-2-start] 170px
    [day-2-end day-3-start] 170px
    [day-3-end day-4-start] 170px
    [day-4-end day-5-start] 170px
    [day-5-end day-6-start] 170px
    [day-6-end day-7-start] 170px
    [day-7-end day-8-start] 170px
    [day-8-end day-9-start] 170px
    [day-9-end day-10-start] 170px
    [day-10-end day-11-start] 170px
    [day-11-end day-12-start] 170px
    [day-12-end day-13-start] 170px
    [day-13-end day-14-start] 170px
    [day-14-end day-15-start] 170px
    [day-15-end day-16-start] 170px
    [day-16-end day-17-start] 170px
    [day-17-end day-18-start] 170px
    [day-18-end day-19-start] 170px
    [day-19-end day-20-start] 170px
    [day-20-end day-21-start] 170px
    [day-21-end day-22-start] 170px
    [day-22-end day-23-start] 170px
    [day-23-end day-24-start] 170px
    [day-24-end day-25-start] 170px
    [day-25-end day-26-start] 170px
    [day-26-end day-27-start] 170px
    [day-27-end day-28-start] 170px
    ${(props) =>
      props.daysInMonth >= 29 ? '[day-28-end day-29-start] 170px' : ''}
    ${(props) =>
      props.daysInMonth >= 30 ? '[day-29-end day-30-start] 170px' : ''}
    ${(props) =>
      props.daysInMonth >= 31 ? '[day-30-end day-31-start] 170px' : ''};
`;
const TimeSlot = styled.div`
  position: relative;
  grid-column: times;
  position: sticky;
  left: 0px;
  margin-top: -7px;
  font-family: 'Kanit';
  z-index: 2;
`;
const DaySlot = styled.div`
  display: flex;
  flex-direction: column;
  justifycontent: center;
  padding: 10px 5px 5px;
  position: sticky;
  top: 0;
  text-align: center;
  font-family: 'Kanit';
  z-index: 2;
`;
const TopLeftBg = styled.div`
  grid-column: times;
  grid-row: tracks / holiday;
  position: relative;
  grid-column: times;
  position: sticky;
  left: 0px;
  top: 0px;
  background-color: #ffffff;
  z-index: 3;
`;
const TimeBg = styled.div`
  position: relative;
  grid-column: times;
  position: sticky;
  left: 0px;
  background-color: #ffffff;
  // margin-top: -70px;
  // margin-bottom: -20px;
  z-index: 1;
`;
const DayBg = styled.div`
  position: relative;
  grid-row: tracks;
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 1;
`;
const TimelineBlock = styled.div`
  margin: 3px;
  padding: 10px;
  background-color: ${(props) => (props.leave ? '#FDEFF0' : '#F7FBFE')};
  border-radius: 5px;
  border-left: 3px solid ${(props) => (props.color ? props.color : '')};
  display: flex;
  flex-direction: column;
  font-family: 'Kanit';
`;
const HolidayBlock = styled.div`
  margin: 3px;
  padding: 5px;
  background-color: #ffab4f;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-family: 'Kanit';
`;
const TimelineVerticalBorder = styled.div`
  border-left: 1px solid #eaeaea;
  display: block;
  background-color: transparent;
`;
const TimelineHorizontalBorder = styled.div`
  border-top: 1px solid #eaeaea;
  display: block;
  background-color: transparent;
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  background-color: ${(props) =>
    props.grey
      ? '#C6C6C6 !important'
      : props.white
      ? '#FFFFFF !important'
      : '#004386 !important'};
  border-color: ${(props) =>
    props.grey
      ? '#C6C6C6 !important'
      : props.white
      ? '#D9D9D9 !important'
      : '#004386 !important'};
  justify-content: center;
  color: ${(props) =>
    props.grey ? '#737373' : props.white ? '#232323 !important' : 'white'};
  font-family: Kanit;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 5px 15px;
  align-items: center;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;

class TimesheetDetail extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    /* sessionStorage.getItem("selected-mmyyyy")? 
        moment(sessionStorage.getItem("selected-mmmyyyy"), "MM-YYYY").year():   */

    this.state = {
      hasData: false,
      incorrect: false,
      year: sessionStorage.getItem('selected-mmyyyy')
        ? moment(sessionStorage.getItem('selected-mmyyyy'), 'MM YYYY').year()
        : moment().year(),
      month: sessionStorage.getItem('selected-mmyyyy')
        ? moment(sessionStorage.getItem('selected-mmyyyy'), 'MM YYYY').month() +
          1
        : moment().month() + 1,
      type: '',
      search: '',
      limit: 10,
      offset: 0,
      total: 1,
      current: 1,
    };
    this.id = new URLSearchParams(this.props.location.search).get('id');
  }
  taskFormRef = React.createRef();

  pad = (num, size) => {
    num = num.toString();
    while (num.length < size) num = '0' + num;
    return num;
  };
  getSelectedYearMonth = () => {
    const yearMonth =
      this.state.year.toString() + '-' + this.pad(this.state.month, 2);
    //console.log("getSelectedYearMonth", moment(yearMonth, 'YYYY-MM'));
    return moment(yearMonth, 'YYYY-MM');
  };
  daysInMonth = () => {
    return moment(this.getSelectedYearMonth()).daysInMonth();
  };

  getDayName = (dayNum) => {
    const date =
      this.state.year.toString() +
      '-' +
      this.pad(this.state.month, 2) +
      '-' +
      this.pad(dayNum, 2);

    return (
      <React.Fragment>
        <div style={{ color: '#ACACAC' }}>
          {moment(date, 'YYYY-MM-DD').format('ddd')}
        </div>

        <div style={{ fontSize: '20px' }}>{dayNum}</div>
      </React.Fragment>
    );
  };
  handleRedirect = () => {
    window.history.back();
  };
  handlefetchTimesheetDetail = async () => {
    const params = {
      year: this.state.year || moment().year(),
      month: this.state.month || moment().month() + 1,
      userId: this.id,
    };
    console.log('payload', params);
    const data = await TimesheetService.fetchTimesheetDetail(
      params,
      async ({ data }) => {
        if (data.picturePath) {
          const valueWithImage = await imageservice.image.getImage(
            data.picturePath,
            (res) => {
              if (res.status) return false;
              const url = URL.createObjectURL(res.data);
              return { ...data, image: url };
            },
          );
          if (valueWithImage) {
            return valueWithImage;
          } else return data;
        } else {
          return data;
        }
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          if (response.data.message != 'empty array') {
            message.error('Cannot access data: ' + response.data.message);
          }
        }
      },
    );
    Promise.resolve(data).then((values) => {
      this.setState({
        hasData: true,
        data: values,
      });
    });
  };
  componentDidMount = () => {
    this.handlefetchTimesheetDetail();
    console.log('didmount', this.state);
    console.log(moment(sessionStorage.getItem('selected-mmyyyy'), 'MM-YYYY'));
    console.log(
      moment(sessionStorage.getItem('selected-mmyyyy'), 'MM-YYYY').year(),
    );
    console.log(
      moment(sessionStorage.getItem('selected-mmyyyy'), 'MM-YYYY').month() + 1,
    );
  };

  componentDidUpdate = () => {
    const { userState } = this.context;
    if (userState.currentRole !== 'ADMIN') {
      redirectTo('/timesheet');
    }
  };
  onSearchMonth = (value) => {
    this.setState(
      {
        year: moment(value).year(),
        month: moment(value).month() + 1,
        hasData: false,
      },
      () => this.handlefetchTimesheetDetail(),
    );
  };
  handleMonthOffset = (value) => {
    const newValue = moment(this.getSelectedYearMonth()).add(value, 'months');
    this.setState(
      {
        year: moment(newValue).year(),
        month: moment(newValue).month() + 1,
        hasData: false,
      },
      () => this.handlefetchTimesheetDetail(),
    );
  };

  getTimelineBlock = (value, dayNum) => {
    const timeRange = `time-${moment(value.startTime).format(
      'HHmm',
    )} / time-${moment(value.endTime).format('HHmm')}`;
    if (value.type === 'leave') {
      return (
        <TimelineBlock
          leave
          color="#D62923"
          style={{
            gridRow: timeRange,
            gridColumn: `day-${dayNum}`,
          }}
        >
          <div>
            <h4>{`${value.leaveName} LEAVE`}</h4>
          </div>

          <div
            style={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ color: '#737373', fontSize: '12px' }}>
              {value.purpose}
            </div>
            <div
              style={{
                color: '#737373',
                fontSize: '12px',
              }}
            >
              {moment(value.startTime).format('HH.mm')} -{' '}
              {moment(value.endTime).format('HH.mm')}
            </div>
          </div>
        </TimelineBlock>
      );
    } else if (value.type === 'task') {
      return (
        <TimelineBlock
          color={value.projectColorCode}
          style={{
            gridRow: timeRange,
            gridColumn: `day-${dayNum}`,
            overflow: 'hidden',
          }}
        >
          <div>
            <h4
              style={{ fontSize: '12px' }}
            >{`${value.projectNo} - ${value.projectName}`}</h4>
          </div>

          <div
            style={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ color: '#737373', fontSize: '12px' }}>
              {value.taskDescription}
            </div>
            <div
              style={{
                color: '#737373',
                fontSize: '12px',
              }}
            >
              {moment(value.startTime).format('HH.mm')} -{' '}
              {moment(value.endTime).format('HH.mm')}
            </div>
          </div>
        </TimelineBlock>
      );
    } else if (value.type === 'holiday') {
      return (
        <HolidayBlock
          style={{
            gridRow: 'holiday',
            gridColumn: `day-${dayNum}`,
          }}
        >
          <TableText style={{ color: '#ffffff', fontSize: '12px' }}>
            {value.dateName}
          </TableText>
        </HolidayBlock>
      );
    }
  };

  generateTimeSlot = (start, end) => {
    const n = (end - start + 1) * 2 - 1;
    let res = [];
    if (start === 19) {
      res.push(
        <>
          <TimeSlot
            style={{
              gridRow: `time-1830`,
            }}
          >
            <TableText style={{ color: '#ACACAC', fontSize: '12px' }}>
              18:30
            </TableText>
          </TimeSlot>
        </>,
      );
    }

    [...Array(n)].map((x, i) =>
      res.push(
        <>
          <TimeSlot
            style={{
              gridRow: `time-${this.pad(Math.floor(i / 2) + start, 2)}${
                i % 2 === 0 ? '00' : '30'
              }`,
            }}
          >
            <TableText style={{ color: '#ACACAC', fontSize: '12px' }}>
              {this.pad(Math.floor(i / 2) + start, 2)}
              {i % 2 === 0 ? ':00' : ':30'}
            </TableText>
          </TimeSlot>
        </>,
      ),
    );
    if (end === 8) {
      res.push(
        <>
          <TimeSlot
            style={{
              gridRow: `time-0830`,
            }}
          >
            <TableText style={{ color: '#ACACAC', fontSize: '12px' }}>
              08:30
            </TableText>
          </TimeSlot>
        </>,
      );
    }

    return res;
  };

  generateHorizontalBorder = (start, end, days) => {
    const n = (end - start + 1) * 2 - 1;
    return [...Array(n)].map((x, i) => (
      <>
        <TimelineHorizontalBorder
          style={{
            gridRow:
              i % 2 === 0
                ? `time-${this.pad(start + Math.floor(i / 2), 2)}00`
                : ` time-${this.pad(start + Math.floor(i / 2), 2)}30`,
            gridColumn: `day-1-start / day-${days}-end`,
          }}
        />
      </>
    ));
  };
  getTimesheetTemplate = (dayNum) => {
    return (
      <React.Fragment>
        <DaySlot
          style={{
            gridRow: 'tracks',
            gridColumn: `day-${dayNum}`,
          }}
        >
          {this.getDayName(dayNum)}
        </DaySlot>
        <TimelineVerticalBorder
          style={{
            gridRow: `${
              this.state.data?.isBefore9 ? 'time-0000' : 'time-0900'
            } / ${this.state.data?.isAfter18 ? 'time-2400' : 'time-1800'}`,
            gridColumn: `day-${dayNum}`,
          }}
        />
      </React.Fragment>
    );
  };

  render() {
    const timesheetComponent = (
      <React.Fragment>
        {[...Array(this.daysInMonth())].map((x, i) => {
          return this.getTimesheetTemplate(i + 1);
        })}
        {[...Array(this.daysInMonth())].map((x, i) => {
          return this.state.data?.[`day${i + 1}`]?.map((value) => {
            return this.getTimelineBlock(value, i + 1);
          });
        })}
      </React.Fragment>
    );

    return (
      <PageContent>
        <ContentBox>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '2%',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '15px' }}>
                <Circle
                  style={{ margin: 'auto' }}
                  width={80}
                  height={80}
                  src={
                    this.state.data?.image
                      ? this.state.data.image
                      : profileImage
                  }
                />
              </div>
              <div>
                <InfoText>{this.state.data?.empCode}</InfoText>
                <InfoText title>
                  {`${this.state.data?.firstnameEn} ${this.state.data?.lastnameEn}`}
                </InfoText>
                <span style={{ display: 'flex' }}>
                  <InfoText style={{ marginRight: '5px' }} color="#A3A3A3">
                    Department
                  </InfoText>
                  <InfoText>{this.state.data?.department}</InfoText>
                  <InfoText
                    style={{ marginRight: '5px', marginLeft: '20px' }}
                    color="#A3A3A3"
                  >
                    Position
                  </InfoText>
                  <InfoText>{this.state.data?.position}</InfoText>
                </span>
              </div>
            </div>
            {/* <a
              style={{ alignSelf: 'flex-end' }}
              onClick={() => this.handleRedirect()}
            >
              <TableText no capitalize>
                {`< Back`}
              </TableText>
            </a> */}
            <NormalButton
              white
              form="user-form"
              width="96px"
              onClick={() => {
                this.handleRedirect();
              }}
              //style={{ height: '40px' }}
            >
              <LeftOutlined />
              Back
            </NormalButton>
          </div>
          <Break />
          <FilterBox style={{ padding: '0 1%' }}>
            <Rows>
              <Cols>
                <DatePicker
                  onChange={this.onSearchMonth}
                  picker="month"
                  defaultValue={moment()}
                  allowClear={false}
                  format="MMM YYYY"
                  value={this.getSelectedYearMonth()}
                ></DatePicker>
              </Cols>
              <Cols>
                <Button
                  type="text"
                  icon={<LeftOutlined />}
                  shape="circle"
                  onClick={() => {
                    this.handleMonthOffset(-1);
                  }}
                />
                <Button
                  type="text"
                  icon={<RightOutlined />}
                  shape="circle"
                  onClick={() => {
                    this.handleMonthOffset(1);
                  }}
                />
              </Cols>
            </Rows>
          </FilterBox>
          <Break />
          <div style={{ padding: '0 3%' }}>
            <Schedule
              daysInMonth={this.daysInMonth()}
              isBefore9={this.state.data?.isBefore9}
              isAfter18={this.state.data?.isAfter18}
            >
              <TopLeftBg />
              <TimeBg
                style={{
                  gridRow: `holiday  / ${
                    this.state.data?.isAfter18 ? 'time-2400' : 'time-1800'
                  }`,
                }}
              />
              <DayBg
                style={{
                  gridColumn: `day-1-start / day-${this.daysInMonth()}-end`,
                }}
              />
              {this.state.data?.isBefore9 && this.generateTimeSlot(0, 8)}
              {this.generateTimeSlot(9, 18)}
              {this.state.data?.isAfter18 && this.generateTimeSlot(19, 24)}
              {this.generateHorizontalBorder(
                this.state.data?.isBefore9 ? 0 : 9,
                this.state.data?.isAfter18 ? 24 : 18,
                this.daysInMonth(),
              )}
              {timesheetComponent}
            </Schedule>
          </div>
        </ContentBox>
      </PageContent>
    );
  }
}
export default TimesheetDetail;
