import React, { Component, createRef } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Button, Form, InputNumber, Row, Table, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { TimesheetService } from '../../services/api';

const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#FFFFFF')};
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  width: auto;
`;

const Inputdata = styled(InputNumber)`
  width: 100%;
  height: 2rem;
  border-radius: 5px;
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  border-radius: 5px;
  background-color: ${(props) =>
    props.white ? '#FFFFFF  !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.white ? '#004368  !important' : '#004368 !important'};
  color: ${(props) => (props.white ? '#004368' : 'white')};
  font-family: Inter;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
  &:disabled {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }
`;

const TableText = styled.div`
  color: ${(props) => (props.title ? '#232323' : '#737373')};
  font-size: 1em;
  font-weight: ${(props) => (props.title ? '600' : 'normal')};
  font-family: 'Kanit';
  line-height: 22px;
  text-transform: ${(props) => props.capitalize && 'capitalize'};
  text-align: left;
`;

const TimesheetList = styled(Table)`
  &.table-striped-rows tr > th {
    background-color: #ffffff;
  }
  min-height: 330px;
  &.ant-table-tbody > tr > td {
    border-bottom: 1px solid #eaeaea;
  }
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 6px 8px;
  }
`;

class TimesheetHourTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'view',
      totalHoursPerDay: [],
      totalOtPerDay: [],
      totalHoursPerProject: [],
      buttonLoading: false,
    };
  }
  formRef = createRef();

  componentDidMount = () => {
    this.getTotalHoursPerDay();
    this.getTotalHoursPerProject();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.data !== this.props.data) {
      this.getTotalHoursPerDay();
      this.getTotalHoursPerProject();
      this.setState({
        mode: 'view',
      });
    }
  };

  dataSource = () => {
    const days = {};
    for (let i = 1; i <= 31; i++) {
      const key = `day${i}`;
      days[key] = 0;
    }
    const data = [];
    this.props.activeProject.map((p) => {
      data.push({
        projectId: p.id,
        projectNo: p.projectNo,
        projectName: p.projectName,
        ...days,
      });
    });
    return data;
  };

  daysInMonth = () => {
    const yearMonth =
      this.props.year.toString() + '-' + this.pad(this.props.month, 2);
    return moment(yearMonth, 'YYYY-MM').daysInMonth();
  };

  //Display cell in table
  displayTimesheetHour = (minute, record, day) => {
    const key = `day${day}`;
    const isWeekend = this.getIsWeekend(this.props.year, this.props.month, day);
    let data = this.props.data[key];
    const isHoliday = this.getIsHoliday(day);
    const disabled = this.getDisableInput(data);
    data = data.filter(
      (d) => d.type === 'task' && d.projectNo === record.projectNo,
    );
    data.push({
      workMinutes: data.reduce((total, d) => {
        return (total += d.workMinutes);
      }, 0),
    });
    const value = data.length > 0 ? data[data.length - 1]?.workMinutes / 60 : 0;
    const timesheetId = data.map((d) => d.id)[0]
      ? data.map((d) => d.id)[0]
      : '';
    if (isWeekend || isHoliday) {
      return {
        props: {
          style: { background: isWeekend ? '#F2F2F2' : '#FFF6EE' },
        },
      };
    }
    switch (this.state.mode) {
      case 'view':
        return (
          <TableText style={{ textAlign: 'center' }}>{`${value}`}</TableText>
        );
      case 'edit':
        //If disabled, it should not send value via API
        return (
          <Form.Item
            name={`${disabled}::${timesheetId}::${record.projectId}::${key}`}
            initialValue={value}
            style={{ marginBottom: 0 }}
          >
            <Inputdata
              stringMode
              min={0}
              max={8}
              placeholder="hr"
              disabled={disabled}
              defaultValue={value}
            />
          </Form.Item>
        );
      default:
        return <></>;
    }
  };

  displayTotalHoursPerProject = (_, record) => {
    const value = this.state.totalHoursPerProject[record.projectId];
    return <TableText>{value ? value : 0} hrs</TableText>;
  };

  getDateColumn = (dayNum) => {
    const date =
      this.props.year.toString() +
      '-' +
      this.pad(this.props.month, 2) +
      '-' +
      this.pad(dayNum, 2);
    return (
      <React.Fragment>
        <div style={{ fontWeight: 'bold' }}>
          {moment(date, 'YYYY-MM-DD').format('ddd').toUpperCase()}
        </div>
        <div style={{ color: '#737373' }}>{`${dayNum}`}</div>
      </React.Fragment>
    );
  };

  //Disable input button when it is edit mode
  //Diasble when it is "leave", "holiday", and "that day had been added by calendar"
  getDisableInput = (data) => {
    if (data.length === 0) return false;
    const dataAddByCalendar = data.filter(
      (d) =>
        (d.type === 'task' && d.startTime) ||
        d.type === 'leave' ||
        d.type === 'holiday',
    );
    if (dataAddByCalendar.length === 0) return false;
    return true;
  };

  getIsHoliday = (dayNum) => {
    const key = `day${dayNum}`;
    const data = this.props.data[key];
    const isHoliday = data.filter((d) => d.type === 'holiday').length !== 0;
    return isHoliday;
  };

  getIsWeekend = (year, month, day) => {
    const date =
      year.toString() + '-' + this.pad(month, 2) + '-' + this.pad(day, 2);
    const isWeekend =
      moment(date, 'YYYY-MM-DD').day() === 0 ||
      moment(date, 'YYYY-MM-DD').day() === 6;
    return isWeekend;
  };

  //Init value of total hours sum by day.
  getTotalHoursPerDay = () => {
    let totalHours = new Array(32);
    let totalOt = new Array(32);
    const data = this.props.data;
    for (let i = 0; i < 31; i++) {
      const key = `day${i + 1}`;
      const value = data[key].filter(
        (value) => value.type === 'totalMinutes',
      )[0];
      totalHours[i] = value ? value.totalMinutes / 60 : 0;
      totalOt[i] = value ? value.totalOtMinutes / 60 : 0;
    }
    totalHours = totalHours.filter(
      (value, index) => index < this.daysInMonth(),
    );
    this.setState({
      totalHoursPerDay: totalHours,
      totalOtPerDay: totalOt,
    });
  };

  //Init value of total hours sum by project.
  getTotalHoursPerProject = () => {
    let project = this.props.activeProject;
    const data = this.props.data;
    const totalHoursPerProject = {};
    project.map((p) => {
      const res = {};
      const key = p.id;
      totalHoursPerProject[key] = 0;
    });
    for (let i = 0; i < 31; i++) {
      const key = `day${i + 1}`;
      const timesheet = data[key];
      timesheet.map((t) => {
        if (t.type === 'task') {
          totalHoursPerProject[t.projectId] += t.workMinutes / 60;
        }
      });
    }
    this.setState({
      totalHoursPerProject,
    });
  };

  getPayloadForSubmitTimesheetHour = (values) => {
    const { year, month } = this.props;
    let payload = {
      month: month,
      year: year,
      timesheet: [],
    };
    for (const [key, value] of Object.entries(values)) {
      const [diasble, timesheetId, projectId, day] = key.split('::');
      if ((timesheetId || parseFloat(value)) && diasble === 'false') {
        const workMinutes = parseFloat(value) * 60;
        const taskDate = moment(
          `${year}-${month}-${day.split('day')[1]}`,
          'YYYY-M-D',
        ).format('YYYY-MM-DD');
        payload.timesheet.push({
          timesheetId,
          projectId,
          taskDate,
          diasble,
          workMinutes,
        });
      }
    }
    return payload;
  };

  //Add the total timesheet hours per day to the last row of the table
  getSummaryTimesheetHourPerDay = (pageData) => {
    const totalHours = this.state.totalHoursPerDay;
    const totalOt = this.state.totalOtPerDay;
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
            <TableText>Total</TableText>
          </Table.Summary.Cell>
          {totalHours.map((value, index) => {
            const key = `day${index + 1}`;
            const isWeekend = this.getIsWeekend(
              this.props.year,
              this.props.month,
              index + 1,
            );
            const isHoliday = this.getIsHoliday(index + 1);
            if (isWeekend || isHoliday) {
              return <Table.Summary.Cell index={key}></Table.Summary.Cell>;
            }
            return (
              <Table.Summary.Cell index={key}>
                <TableText style={{ textAlign: 'center' }}>
                  {`${totalHours[index] - totalOt[index]}`}
                  <span style={{ color: '#D62923' }}>
                    {totalOt[index] ? ` + ${totalOt[index]}` : ''}
                  </span>
                  {` hrs`}
                </TableText>
              </Table.Summary.Cell>
            );
          })}
          <Table.Summary.Cell
            index={totalHours.length + 1}
          ></Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  onClickCancelButton = () => {
    this.formRef.current.resetFields();
    this.setState({
      mode: 'view',
    });
  };

  onClickEditButton = () => {
    this.formRef.current.resetFields();
    this.setState({
      mode: 'edit',
    });
  };

  onSubmitTimesheetHour = (values) => {
    const payload = this.getPayloadForSubmitTimesheetHour(values);
    this.setState({
      buttonLoading: true,
    });
    TimesheetService.addTimesheetHour(
      payload,
      ({ data }) => {
        this.onClickCancelButton();
        this.props.handlefetchTimesheetDetail();
        this.setState({
          buttonLoading: false,
        });
      },
      (response) => {
        if (response) {
          this.setState({
            buttonLoading: false,
          });
          message.error('Error: ' + response.data.message);
        }
      },
    );
  };

  //Update total value when change value
  onValuesChange = (changedValues, allValues) => {
    const totalHoursPerDay = this.state.totalHoursPerDay.map((t) => 0);
    const totalHoursPerProject = this.state.totalHoursPerProject;
    for (const [key, value] of Object.entries(totalHoursPerProject)) {
      totalHoursPerProject[key] = 0;
    }
    //Calculate
    for (const [key, value] of Object.entries(allValues)) {
      if (value) {
        const [diasble, timesheetId, projectId, day] = key.split('::');
        const index = day.split('day')[1] - 1;
        totalHoursPerDay[index] += parseFloat(value);
        totalHoursPerProject[projectId] += parseFloat(value);
      }
    }
    this.setState({
      totalHoursPerDay,
      totalHoursPerProject,
    });
  };

  pad = (num, size) => {
    return num.toString().padStart(size, '0');
  };

  render() {
    const columns = [
      {
        title: 'Project',
        index: 'project',
        dataIndex: 'project',
        fixed: 'left',
        width: '200px',
        align: 'center',
        day: 0,
        render: (_, record) => (
          <TableText>{`${record.projectNo}-${record.projectName}`}</TableText>
        ),
      },
      {
        title: this.getDateColumn(1),
        index: 'day1',
        dataIndex: 'day1',
        align: 'center',
        width: '10%',
        day: 1,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 1);
        },
      },
      {
        title: this.getDateColumn(2),
        index: 'day2',
        dataIndex: 'day2',
        align: 'center',
        width: '10%',
        day: 2,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 2);
        },
      },
      {
        title: this.getDateColumn(3),
        index: 'day3',
        dataIndex: 'day3',
        align: 'center',
        width: '10%',
        day: 3,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 3);
        },
      },
      {
        title: this.getDateColumn(4),
        index: 'day4',
        dataIndex: 'day4',
        align: 'center',
        width: '10%',
        day: 4,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 4);
        },
      },
      {
        title: this.getDateColumn(5),
        index: 'day5',
        dataIndex: 'day5',
        align: 'center',
        width: '10%',
        day: 5,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 5);
        },
      },
      {
        title: this.getDateColumn(6),
        index: 'day6',
        dataIndex: 'day6',
        align: 'center',
        width: '10%',
        day: 6,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 6);
        },
      },
      {
        title: this.getDateColumn(7),
        index: 'day7',
        dataIndex: 'day7',
        align: 'center',
        width: '10%',
        day: 7,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 7);
        },
      },
      {
        title: this.getDateColumn(8),
        index: 'day8',
        dataIndex: 'day8',
        align: 'center',
        width: '10%',
        day: 8,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 8);
        },
      },
      {
        title: this.getDateColumn(9),
        index: 'day9',
        dataIndex: 'day9',
        align: 'center',
        width: '10%',
        day: 9,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 9);
        },
      },
      {
        title: this.getDateColumn(10),
        index: 'day10',
        dataIndex: 'day10',
        align: 'center',
        width: '10%',
        day: 10,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 10);
        },
      },
      {
        title: this.getDateColumn(11),
        index: 'day11',
        dataIndex: 'day11',
        align: 'center',
        width: '10%',
        day: 11,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 11);
        },
      },
      {
        title: this.getDateColumn(12),
        index: 'day12',
        dataIndex: 'day12',
        align: 'center',
        width: '10%',
        day: 12,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 12);
        },
      },
      {
        title: this.getDateColumn(13),
        index: 'day13',
        dataIndex: 'day13',
        align: 'center',
        width: '10%',
        day: 13,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 13);
        },
      },
      {
        title: this.getDateColumn(14),
        index: 'day14',
        dataIndex: 'day14',
        align: 'center',
        width: '10%',
        day: 14,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 14);
        },
      },
      {
        title: this.getDateColumn(15),
        index: 'day15',
        dataIndex: 'day15',
        align: 'center',
        width: '10%',
        day: 15,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 15);
        },
      },
      {
        title: this.getDateColumn(16),
        index: 'day16',
        dataIndex: 'day16',
        align: 'center',
        width: '10%',
        day: 16,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 16);
        },
      },
      {
        title: this.getDateColumn(17),
        index: 'day17',
        dataIndex: 'day17',
        align: 'center',
        width: '10%',
        day: 17,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 17);
        },
      },
      {
        title: this.getDateColumn(18),
        index: 'day18',
        dataIndex: 'day18',
        align: 'center',
        width: '10%',
        day: 18,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 18);
        },
      },
      {
        title: this.getDateColumn(19),
        index: 'day19',
        dataIndex: 'day19',
        align: 'center',
        width: '10%',
        day: 19,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 19);
        },
      },
      {
        title: this.getDateColumn(20),
        index: 'day20',
        dataIndex: 'day20',
        align: 'center',
        width: '10%',
        day: 20,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 20);
        },
      },
      {
        title: this.getDateColumn(21),
        index: 'day21',
        dataIndex: 'day21',
        align: 'center',
        width: '10%',
        day: 21,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 21);
        },
      },
      {
        title: this.getDateColumn(22),
        index: 'day22',
        dataIndex: 'day22',
        align: 'center',
        width: '10%',
        day: 22,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 22);
        },
      },
      {
        title: this.getDateColumn(23),
        index: 'day23',
        dataIndex: 'day23',
        align: 'center',
        width: '10%',
        day: 23,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 23);
        },
      },
      {
        title: this.getDateColumn(24),
        index: 'day24',
        dataIndex: 'day24',
        align: 'center',
        width: '10%',
        day: 24,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 24);
        },
      },
      {
        title: this.getDateColumn(25),
        index: 'day25',
        dataIndex: 'day25',
        align: 'center',
        width: '10%',
        day: 25,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 25);
        },
      },
      {
        title: this.getDateColumn(26),
        index: 'day26',
        dataIndex: 'day26',
        align: 'center',
        width: '10%',
        day: 26,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 26);
        },
      },
      {
        title: this.getDateColumn(27),
        index: 'day27',
        dataIndex: 'day27',
        align: 'center',
        width: '10%',
        day: 27,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 27);
        },
      },
      {
        title: this.getDateColumn(28),
        index: 'day28',
        dataIndex: 'day28',
        align: 'center',
        width: '10%',
        day: 28,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 28);
        },
      },
      {
        title: this.getDateColumn(29),
        index: 'day29',
        dataIndex: 'day29',
        align: 'center',
        width: '10%',
        day: 29,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 29);
        },
      },
      {
        title: this.getDateColumn(30),
        index: 'day30',
        dataIndex: 'day30',
        align: 'center',
        width: '10%',
        day: 30,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 30);
        },
      },
      {
        title: this.getDateColumn(31),
        index: 'day31',
        dataIndex: 'day31',
        align: 'center',
        width: '10%',
        day: 31,
        render: (minute, record) => {
          return this.displayTimesheetHour(minute, record, 31);
        },
      },
      {
        title: 'HOURS',
        index: 'totalWorkingMinutes',
        dataIndex: 'totalWorkingMinutes',
        align: 'center',
        width: '10%',
        fixed: 'right',
        day: 0,
        render: this.displayTotalHoursPerProject,
      },
    ].filter((value) => value.day <= this.daysInMonth());

    return (
      <div style={{ width: 'auto' }}>
        <Row justify="end" style={{ margin: '10px 50px' }}>
          {this.state.mode === 'view' && (
            <NormalButton onClick={this.onClickEditButton}>
              <EditOutlined />
              Edit
            </NormalButton>
          )}
          {this.state.mode === 'edit' && (
            <>
              <NormalButton
                white
                onClick={this.onClickCancelButton}
                style={{ marginRight: '10px' }}
              >
                Cancel
              </NormalButton>
              <NormalButton
                onClick={() => this.formRef.current.submit()}
                loading={this.state.buttonLoading}
              >
                Submit
              </NormalButton>
            </>
          )}
        </Row>
        <ContentBox>
          <Form
            ref={this.formRef}
            onFinish={this.onSubmitTimesheetHour}
            onValuesChange={this.onValuesChange}
          >
            <TimesheetList
              className="table-striped-rows"
              columns={columns}
              dataSource={this.dataSource()}
              style={{ width: '100%' }}
              size="small"
              scroll={{ x: 3000, y: 500 }}
              summary={this.getSummaryTimesheetHourPerDay}
              pagination={false}
            />
          </Form>
        </ContentBox>
      </div>
    );
  }
}

export default TimesheetHourTable;
