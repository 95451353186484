import { Upload, message, Button } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { imageservice } from '../../services/api';
import binIcon from '../../assets/icons/bin.svg';
import fileImage from '../../assets/images/fileimg.png';
import styled from 'styled-components';

const CloseContainer = styled.div`
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
`;

const Container = styled.div`
  &:hover ${CloseContainer} {
    opacity: 1;
  }
`;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'application/pdf';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG/PDF file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 100);
};

class Uploader extends React.Component {
  constructor(props) {
    super();
  }
  state = {
    loading: false,
    fileList: [],
  };

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      var formData = new FormData();
      formData.append('img', info.file.originFileObj);
      imageservice.image.uploadImage(formData, ({ data }) => {
        imageservice.image.getImage(data, (res) => {
          const blobUrl = URL.createObjectURL(res.data);
          this.setState({
            formData: data,
            image: data,
            loading: false,
            imageUrl: blobUrl,
            isPdf: data.search('.pdf') >= 0,
          });
        });
      });
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.image != this.state.image) {
      this.props.callbackUpload(this.state.image, this.props.index);
    }
  }
  componentDidMount() {
    if (this.props.initialPath) {
      imageservice.image.getImage(this.props.initialPath, (res) => {
        const blobUrl = URL.createObjectURL(res.data);
        this.setState({
          loading: false,
          imageUrl: blobUrl,
          fileList: [
            {
              uid: '1',
              name: this.props.initialFileName,
              status: 'done',
              url: blobUrl,
            },
          ],
        });
      });
    }
  }
  removeImage = (img) => {
    this.setState({
      formData: '',
      image: '',
      loading: false,
      imageUrl: '',
    });
  };
  render() {
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { imageUrl } = this.state;
    return (
      <Container hasImage={imageUrl ? true : false}>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          onChange={this.handleChange}
          // fileList={this.state.fileList}
          disabled={!!imageUrl}
        >
          {imageUrl ? (
            <div style={{ position: 'relative' }}>
              <img
                src={this.state.isPdf ? fileImage : imageUrl}
                alt="avatar"
                /* style={{ width: '100%' }} */ height={100}
                width={100}
              />
              <CloseContainer>
                <Button onClick={this.removeImage}>
                  <img src={binIcon} />
                </Button>
              </CloseContainer>
            </div>
          ) : (
            uploadButton
          )}
          {/* {!this.props.hideUploadButton && <Button>Upload</Button>} */}
        </Upload>
      </Container>
    );
  }
}

export default Uploader;
