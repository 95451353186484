import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { DatePicker, Form, Input, Modal, Col, Button } from 'antd';
import calendarBlackIcon from '../../assets/icons/calendarBlack.svg';

const Headdetail = styled.div`
  font-family: 'Kanit';
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 2rem;
`;

const Horizontalbox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || 'start'};
  margin-bottom: 1rem;
  @media (max-width: 714px) {
    flex-direction: column;
  }
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
  width: 11rem;
  min-width: 11rem;
  color: black;
  @media (max-width: 1424px) {
    min-width: none;
  }
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  border-radius: 5px;
  background-color: ${(props) =>
    props.white ? '#FFFFFF !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.white ? '#D9D9D9 !important' : '#004368 !important'};
  color: ${(props) => (props.white ? '#232323' : 'white')};
  font-family: Kanit;
  font-style: Regular;
  font-size: 14px;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
  &:focus {
    color: ${(props) => (props.whitefocus ? 'white !important' : '#232323')};
  }
`;

const RejectedButton = styled(Button)`
  width: ${(props) => (props.width ? props.width : '106px')};
  border-radius: 5px;
  display: flex;
  background-color: white;
  border: 1px solid #d9d9d9;
  justify-content: center;
  color: #232323 !important;
  font-family: Kanit;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding: 5px 15px;
  align-items: center;
  &:disabled {
    border: 1px solid white;
  }
`;

class CreateOtModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        width={600}
        open={this.props.otModalVisible}
        onCancel={this.props.closeOtModal}
        footer={null}
      >
        <Form
          ref={this.props.otFormRef}
          onFinish={this.props.submitAddOt}
          requiredMark={true}
        >
          <Headdetail>Add OT Date</Headdetail>

          <Horizontalbox>
            <Label>Start Date</Label>
            <Form.Item
              style={{ width: '100%', margin: '0', height: 'auto' }}
              name="startDate"
              rules={[
                {
                  required: true,
                  message: 'Please select Start Date.',
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                onChange={(value) => {
                  this.props.otFormRef.current.setFieldsValue({
                    endDate: value,
                  });
                }}
              />
            </Form.Item>
          </Horizontalbox>
          <Horizontalbox>
            <Label>End Date</Label>
            <Form.Item
              style={{ width: '100%', margin: '0', height: 'auto' }}
              name="endDate"
              dependencies={['startDate', 'endDate']}
              rules={[
                {
                  required: true,
                  message: 'Please select End Date',
                },
                () => ({
                  validator: (_, value) => {
                    if (
                      moment(
                        this.props.otFormRef.current.getFieldValue('startDate'),
                      ) > moment(value)
                    ) {
                      return Promise.reject(
                        'End Date must be greater than Start Date.',
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePicker
                allowClear={false}
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </Horizontalbox>
          <Horizontalbox justify="end">
            <RejectedButton
              form="user-form"
              grey
              width="96px"
              onClick={this.props.closeOtModal}
            >
              Cancel
            </RejectedButton>
            <NormalButton
              style={{ marginLeft: '1rem', minWidth: '106px' }}
              width="96px"
              onClick={() => this.props.otFormRef.current.submit()}
            >
              Add
            </NormalButton>
          </Horizontalbox>
        </Form>
      </Modal>
    );
  }
}

export default CreateOtModal;
