import React, { Component } from 'react';
import styled from 'styled-components';
import playicon from '../assets/icons/playIconTextBlue.svg';
import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import mailIcon from '../assets/icons/mail.svg';
import loginBg from '../assets/images/new_login_bg.png';
import { authentication } from '../services/api';
import { message, Modal } from 'antd';
import { subDomain } from '../services/redirect';
import { GoogleLogin } from '@react-oauth/google';
const Box = styled.div`
  // height: 33.9375rem;
  // width: 61.5rem;
  width: 61.5rem;
  margin: 32px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // display: flex;
  font-family: 'Kanit';
`;
const Mainbox = styled.div`
  height: 100vh;
  // box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // positon: relative;
`;
const Flexbox = styled.div`
  width: 50%;
  background: ${(props) =>
    props.left
      ? 'linear-gradient(8.65deg, #004368 -0.2%, #000000 117.76%)'
      : ' white;'};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 4.875rem;
`;
const Text = styled.div`
  font-size: 20px;
`;
const Text2 = styled.div`
  font-size: 14px;
`;
const Text3 = styled.div`
  font-size: ${(props) => (props.size ? props.size + 'rem' : '1rem')};
  // position: absolute;

  font-weight: 500;
  color: rgba(255, 255, 255);
  // display: flex;
  // align-items: center;
  text-align: center;
`;
const Formall = styled(Form)`
  width: 100%;
  margin-top: 1.375rem;
  &.ant-form label {
    font-weight: 500;
  }
`;
const Formitem = styled(Form.Item)``;
const Buttonedit = styled(Button)`
  height: 2.5rem;
  width: 100%;
  background: ${(props) => (props.google ? 'white' : '#004368')};
  color: ${(props) => (props.google ? 'black' : 'white')};
  box-shadow: ${(props) =>
    props.google ? '0px 0px 15px rgba(0, 0, 0, 0.15)' : 'none'};
  border-radius: 5px;
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      incorrect: false,
      email: '',
    };
    this.handleLoginWithEmail = this.handleLoginWithEmail.bind(this);
  }
  state = {
    visible: false,
    visible2: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  showModal2 = () => {
    this.setState({
      visible2: true,
    });
  };

  handleCancel2 = (e) => {
    console.log(e);
    this.setState({
      visible2: false,
    });
  };

  handleLoginWithEmail() {
    const payload = {
      username: this.state.username,
      password: this.state.password,
    };

    authentication.signInWithEmail(
      payload,
      ({ data }) => {
        sessionStorage.setItem('access-token', data.accessToken);
        sessionStorage.setItem('refresh-token', data.refreshToken);
        this.confirmLogin();
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          const key = 'login';
          message.error({
            content: 'Login failed' + ' - ' + response.data.message,
            key,
          });
        }
      },
    );
  }

  handleLoginWithGoogle = (response) => {
    const payload = {
      token: response.credential,
      device: 'web',
    };
    authentication.signInWithGoogle(
      payload,
      ({ data }) => {
        sessionStorage.setItem('access-token', data.accessToken);
        sessionStorage.setItem('refresh-token', data.refreshToken);
        this.confirmLogin();
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          message.error('Login failed' + ' - ' + response.data.message);
        }
      },
    );
    // sessionStorage.setItem("access-token", response.accessToken);
    // sessionStorage.setItem("refresh-token", response.accessToken);
    // this.confirmLogin();
  };

  confirmLogin() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    let nextUrl = `${subDomain}/announce`;
    switch (params.get('type')) {
      case 'announcement':
        const id = params.get('redirect').split('-')[1];
        if (!id) break;
        nextUrl = `${subDomain}/announce/announceform/?id=${id}`;
        break;
      default:
        break;
    }
    message.success('Login successful');
    let secondsToGo = 1;
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      window.location = nextUrl;
    }, secondsToGo * 1000);
  }
  onFinish = (values) => {
    this.setState(values);
    // console.log(this.state)
    this.handleLoginWithEmail();
  };
  onFinishForgot = (values) => {
    this.setState({
      email: values.username,
    });
    console.log(values);
    const payload = values;
    authentication.forgotPassword(
      payload,
      ({ data }) => {
        console.log(data);
        this.handleCancel();
        this.showModal2();
      },
      (response) => {
        message.error(response.data.message);
      },
    );
  };
  responseGoogle = (response) => {
    if (response && response.status === 400) {
      this.setState({
        incorrect: true,
      });
      message.error('Login failed' + ' - ' + response.data.message);
    }
  };
  render() {
    return (
      <Mainbox>
        <Modal
          // title="Basic Modal"
          footer={null}
          visible={this.state.visible2}
          onCancel={this.handleCancel2}
        >
          <Text
            style={{ color: 'black', fontSize: '1.75em', fontWeight: '500' }}
          >
            <img src={mailIcon}></img>&nbsp;Email already sent!
          </Text>
          <Text2 style={{ display: 'flex' }}>
            Go to your email
            <Text2 style={{ color: '#004368', display: 'contents' }}>
              &nbsp;{this.state.email}&nbsp;
            </Text2>
            and click for setting new password.
          </Text2>
        </Modal>
        <Modal
          // title="Basic Modal"
          footer={null}
          visible={this.state.visible}
          onCancel={this.handleCancel}
        >
          <Formall layout="vertical" onFinish={this.onFinishForgot}>
            <Text
              style={{ color: 'black', fontSize: '1.75em', fontWeight: '500' }}
            >
              Forgot Password?
            </Text>
            <Text2 style={{ marginBottom: '1rem' }}>
              Please insert your email to send new password.
            </Text2>
            <Formitem
              label="Email"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your Email',
                },
                { type: 'email', message: 'Please input valid Email' },
              ]}
            >
              <Input />
            </Formitem>
            <Formitem>
              <Buttonedit type="primary" htmlType="submit">
                Send Email
              </Buttonedit>
            </Formitem>
          </Formall>
        </Modal>
        <Box>
          <Row style={{ height: '100%' }}>
            <Col
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              style={{
                width: '100%',
                padding: '32px',
                background:
                  'linear-gradient(8.65deg, #004368 -0.2%, #000000 117.76%)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text3
                size="2"
                style={{
                  zIndex: 1,
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  // top: '50%',
                  // left: '25%',
                  // transform: 'translate(-50%, -50%)',
                }}
              >
                SPEED X QUALITY
              </Text3>
              <Text3
                size="0.75"
                style={{
                  zIndex: 1,
                  marginTop: '16px',
                  // top: '90%',
                  // left: '25%',
                  // transform: 'translate(-50%, -50%)',
                }}
              >
                Let’s make our company better
              </Text3>
              <img
                src={loginBg}
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  zIndex: 0,
                }}
              />
            </Col>
            <Col
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              style={{
                width: '100%',
                padding: '96px 32px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text>
                <img src={playicon}></img>
              </Text>
              <Formall
                layout="vertical"
                onFinish={this.onFinish}
                style={{ maxWidth: '360px' }}
              >
                <Formitem
                  name="username"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Email',
                    },
                    { type: 'email', message: 'Please input valid Email' },
                  ]}
                >
                  <Input placeholder="Email" />
                </Formitem>
                <Formitem
                  name="password"
                  label="Password"
                  style={{ marginBottom: '0' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Password',
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Formitem>
                <Formitem>
                  <a
                    onClick={this.showModal}
                    style={{
                      position: 'absolute',
                      right: '0',
                      color: '#737373',
                    }}
                  >
                    Forgot Password?
                  </a>
                </Formitem>
                <Formitem>
                  <Buttonedit type="primary" htmlType="submit">
                    Login
                  </Buttonedit>
                </Formitem>
                {/* <GoogleLoginButton /> */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <GoogleLogin
                    onSuccess={this.handleLoginWithGoogle}
                    onError={this.responseGoogle}
                    locale="en_US"
                    width="360px"
                  />
                </div>
              </Formall>
            </Col>
          </Row>
        </Box>
      </Mainbox>
    );
  }
}
export default Login;
