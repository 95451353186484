import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Button,
  Row,
  Col,
  Divider,
  Modal,
  Table,
  message,
  Spin,
  Input as AntInput,
  Form as AntForm,
} from 'antd';
import editIcon from '../../assets/icons/edit.svg';
import binIcon from '../../assets/icons/bin.svg';
import { CustomerService } from '../../services/api';
import { TextFormatter } from '../../components/TextFormatter.js';
import { redirectTo } from '../../services/redirect';
const TitleBox = styled.div`
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  height: 6.875em;
  justify-content: space-between;
`;
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.subtitle ? '#20ABC5' : '#565656'};
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1.625em' : '1em'};
  font-weight: ${(props) =>
    props.title
      ? 'bold'
      : props.subtitle
      ? '600'
      : props.name
      ? '500'
      : 'normal'};
  font-family: 'Kanit';
  text-transform: ${(props) => props.capitalize && 'capitalize'};
`;
const { Search } = AntInput;
const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#2d95a8 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#2d95a8 !important'};
  justify-content: center;
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Inter;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 5px 15px;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;
const OutlineButton = styled(Button).attrs((props) => ({
  type: 'default',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#2d95a8 !important'};
  justify-content: center;
  color: #2d95a8 !important;
  font-family: Inter;
  font-style: Bold;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  align-items: center;
  padding: 5px 15px;
  text-transform: capitalize;
  transition: 0.5s;
  &:hover {
    border-color: #d77501 !important;
    color: #d77501 !important;
  }
  &:active {
    border-color: #e77d00 !important;
    color: #e77d00 !important;
  }
`;
const Icons = styled.path`
  fill: ${(props) => props.fill};
  transition: 0.5s;
  ${OutlineButton}:hover & {
    fill: #d77501 !important;
  }
  ${OutlineButton}:active {
    fill: #e77d00 !important;
  }
`;
const ContentBox = styled.div`
  background: #f6f6f6;
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: auto;
  min-height: calc(100vh - 7.5em);
  justify-content: center;
`;
const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;
const TextCard = styled.div`
  height: auto;
  width: 100%;
  background-color: white;
  margin-top: 2em;
  box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 40px 50px;
`;
const FlexContent = styled.div`
  display: flex;
  align-items: stretch;
  padding: 10px 5px;
  align-items: center;
`;
const FlexItem = styled.div`
  flex-grow: ${(props) => props.flexGrow || 1};
  padding: 5px 5px;
  justify-content: left;
  /* border: 2px solid black; */
`;
const Input = styled(AntInput)`
  height: 35px;
  width: ${(props) => (props.full ? '100%' : '100%')};
  /* max-width: 300px; */
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0px 11px;
  font-family: Inter;
  font-style: normal;
  font-size: 1em;
  color: #737373;
  outline: none;
  &:focus {
    border-color: #20abc5;
  }
`;
const Form = styled.form`
  padding: 5px 5px;
  height: auto;
`;
const Cols = styled(Col)`
  display: flex;
  align-items: center;
`;
const Rows = styled(Row)`
  margin: ${(props) => (props.start ? '0px 0px' : '36px 0px')};
  display: flex;
  align-items: ${(props) => (props.start ? 'baseline' : 'center')};
`;
const Required = styled.span`
  padding-left: 5px;
  color: #eb5757;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;
const TableText = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.no ? '#20ABC5' : '#737373'};
  font-size: 1em;
  font-weight: ${(props) =>
    props.title ? '600' : props.no ? 'bold' : 'normal'};
  font-family: 'Kanit';
  line-height: 22px;
  text-transform: ${(props) => props.capitalize && 'capitalize'};
`;
const ActionBox = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;
const ActionItem = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
`;
const ContactList = styled(Table)`
  &.table-striped-rows tr > td {
    background-color: #ffffff;
  }
  &.table-striped-rows tr > th {
    background-color: #ffffff;
  }
  &.table-striped-rows thead {
    background-color: #ffffff;
  }
  &.ant-table-thead > tr > th,
  &.ant-table-tbody > tr > td {
    padding: 16px 16px;
    word-break: break-word;
    -ms-word-break: break-all;
  }
  /* media query to override the default ant design style that is specifically for desktop devices */
  @media (min-width: 768px) {
    &.ant-table-thead > tr > th,
    &.ant-table-tbody > tr > td {
      padding: 16px 16px;
    }
  }
`;
const { confirm } = Modal;
const validateMessages = {
  required: '${name} is required!',
  types: {
    text: '${name} is not validate!',
    email: '${name} is not validate email!',
    number: '${name} is not a validate number!',
  },
  number: {
    range: '${name} must be between ${min} and ${max}',
  },
};
const typeTemplate = "'${name}' is not a valid ${type}";

export const defaultValidateMessages = {
  default: "Validation error on field '${name}'",
  required: "'${name}' is required",
  enum: "'${name}' must be one of [${enum}]",
  whitespace: "'${name}' cannot be empty",
  date: {
    format: "'${name}' is invalid for format date",
    parse: "'${name}' could not be parsed as date",
    invalid: "'${name}' is invalid date",
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate,
  },
  string: {
    len: "'${name}' must be exactly ${len} characters",
    min: "'${name}' must be at least ${min} characters",
    max: "'${name}' cannot be longer than ${max} characters",
    range: "'${name}' must be between ${min} and ${max} characters",
  },
  number: {
    len: "'${name}' must equal ${len}",
    min: "'${name}' cannot be less than ${min}",
    max: "'${name}' cannot be greater than ${max}",
    range: "'${name}' must be between ${min} and ${max}",
  },
  array: {
    len: "'${name}' must be exactly ${len} in length",
    min: "'${name}' cannot be less than ${min} in length",
    max: "'${name}' cannot be greater than ${max} in length",
    range: "'${name}' must be between ${min} and ${max} in length",
  },
  pattern: {
    mismatch: "'${name}' does not match pattern ${pattern}",
  },
};
class ViewCustomer extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      hasData: false,
      incorrect: false,
      searchName: '',
      limit: 500,
      offset: 0,
      edit: false,
    };
  }
  // addData = () => {
  //   this.setState((state) => {
  //     const data = [...state.data, state.newValue];
  //     return {
  //       data,
  //       newValue: "",
  //     };
  //   });
  //   this.onReset();
  // };
  shouldComponentUpdate() {
    return true;
  }

  handlefetchCustomer = () => {
    let payload = this.state.index;
    console.log(payload);
    CustomerService.fetchCustomer(
      payload,
      ({ data }) => {
        console.log(data);
        this.setState({
          hasData: true,
          dataCustomer: data.customer,
        });
      },
      (response) => {
        // console.log(response.data.message);
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          message.error('Cannot access data: ' + response.data.message);
        }
      },
    );
  };
  handleEditCustomer = (payload) => {
    // alert(payload);
    CustomerService.editCustomer(
      this.state.index,
      payload,
      ({ data }) => {
        let secondsToGo = 1;
        message.success(data.message);
        const timer = setInterval(() => {
          secondsToGo -= 1;
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          window.location.reload();
        }, secondsToGo * 1000);
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Cannot edit data: ' + response.data.message);
        }
      },
    );
  };
  handleEdit = () => {
    this.setState({ edit: true });
  };
  handleCancel = () => {
    this.setState({ edit: false });
    this.onReset();
  };

  onReset = () => {
    this.formRef.current.resetFields();
  };
  async componentDidMount() {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var no = await url.searchParams.get('no');
    this.setState({ index: no });
    this.handlefetchCustomer();
  }

  render() {
    return (
      <div style={{ width: 'auto' }}>
        <TitleBox>
          <Text title capitalize>
            {TextFormatter.normalText(
              this.state.hasData && this.state.dataCustomer.name,
            )}
          </Text>
          {this.state.edit ? (
            <ButtonBlock width="200px">
              <NormalButton
                form="my-form1"
                grey
                width="96px"
                onClick={this.handleCancel}
              >
                Cancel
              </NormalButton>
              <NormalButton width="96px" form="my-form1" htmlType="submit">
                Save
              </NormalButton>
            </ButtonBlock>
          ) : (
            <ButtonBlock width="96px">
              <NormalButton width="96px" onClick={this.handleEdit}>
                Edit
              </NormalButton>
            </ButtonBlock>
          )}
        </TitleBox>
        <ContentBox>
          {this.state.edit ? (
            <TextCard style={{ marginBottom: '2.5em', paddingBottom: '1em' }}>
              <Text subtitle>Customer Information</Text>
              <Form>
                <AntForm
                  id="my-form1"
                  ref={this.formRef}
                  name="new contact"
                  onFinish={this.handleEditCustomer}
                  validateMessages={defaultValidateMessages}
                  autoComplete={false}
                >
                  <Rows start style={{ marginTop: '2.1em' }}>
                    <Cols span={4}>
                      <Text name>
                        Company Name<Required>*</Required>
                      </Text>
                    </Cols>
                    <Cols span={7}>
                      <AntForm.Item
                        style={{ width: '100%' }}
                        name="name"
                        initialValue={this.state.dataCustomer.name}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input type="text" />
                      </AntForm.Item>
                    </Cols>
                    <Cols span={1}></Cols>
                    <Cols span={4}>
                      <Text name>
                        Company Tel<Required>*</Required>
                      </Text>
                    </Cols>
                    <Cols span={8}>
                      <AntForm.Item
                        style={{ width: '100%' }}
                        name="phone_number"
                        initialValue={this.state.dataCustomer.phone_number}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input type="text" />
                      </AntForm.Item>
                    </Cols>
                  </Rows>
                  <Rows start style={{ marginBottom: '0px' }}>
                    <Cols span={4}>
                      <Text name>
                        Tax No<Required>*</Required>
                      </Text>
                    </Cols>
                    <Cols span={7}>
                      <AntForm.Item
                        style={{ width: '100%' }}
                        name="tax_no"
                        initialValue={this.state.dataCustomer.tax_no}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input type="text" />
                      </AntForm.Item>
                    </Cols>
                    <Cols span={1}></Cols>
                    <Cols span={4}>
                      <Text name>
                        Fax<Required>*</Required>
                      </Text>
                    </Cols>
                    <Cols span={8}>
                      <AntForm.Item
                        style={{ width: '100%' }}
                        name="fax"
                        initialValue={this.state.dataCustomer.fax}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input type="text" />
                      </AntForm.Item>
                    </Cols>
                  </Rows>
                  <Divider
                    style={{
                      margin: '40px 0px',
                      marginTop: '0px',
                      borderColor: '#737373',
                    }}
                    dashed
                  />
                  <Rows start>
                    <Cols span={4}>
                      <Text name>
                        Address Line1<Required>*</Required>
                      </Text>
                    </Cols>
                    <Cols span={20}>
                      <AntForm.Item
                        style={{ width: '100%' }}
                        name="address_1"
                        initialValue={this.state.dataCustomer.address_1}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input type="text" full />
                      </AntForm.Item>
                    </Cols>
                  </Rows>
                  <Rows start>
                    <Cols span={4}>
                      <Text name>Address Line2</Text>
                    </Cols>
                    <Cols span={20}>
                      <AntForm.Item
                        style={{ width: '100%' }}
                        name="address_2"
                        initialValue={this.state.dataCustomer.address_2}
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input type="text" full />
                      </AntForm.Item>
                    </Cols>
                  </Rows>
                  <Rows start>
                    <Cols span={4}>
                      <Text name>
                        Country<Required>*</Required>
                      </Text>
                    </Cols>
                    <Cols span={7}>
                      <AntForm.Item
                        style={{ width: '100%' }}
                        name="country"
                        initialValue={this.state.dataCustomer.country}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input type="text" />
                      </AntForm.Item>
                    </Cols>
                    <Cols span={1}></Cols>
                    <Cols span={4}>
                      <Text name>
                        Province<Required>*</Required>
                      </Text>
                    </Cols>
                    <Cols span={8}>
                      <AntForm.Item
                        style={{ width: '100%' }}
                        name="province"
                        initialValue={this.state.dataCustomer.province}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input type="text" />
                      </AntForm.Item>
                    </Cols>
                  </Rows>
                  <Rows start>
                    <Cols span={4}>
                      <Text name>
                        District<Required>*</Required>
                      </Text>
                    </Cols>
                    <Cols span={7}>
                      <AntForm.Item
                        style={{ width: '100%' }}
                        name="district"
                        initialValue={this.state.dataCustomer.district}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input type="text" />
                      </AntForm.Item>
                    </Cols>
                    <Cols span={1}></Cols>
                    <Cols span={4}>
                      <Text name>
                        Postcode<Required>*</Required>
                      </Text>
                    </Cols>
                    <Cols span={8}>
                      <AntForm.Item
                        style={{ width: '100%' }}
                        name="zip_code"
                        initialValue={this.state.dataCustomer.zip_code}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input type="text" />
                      </AntForm.Item>
                    </Cols>
                  </Rows>
                </AntForm>
              </Form>
            </TextCard>
          ) : (
            <TextCard>
              <Text subtitle>Customer Information</Text>
              <Spin tip="Loading..." spinning={!this.state.hasData}>
                <Form>
                  <Rows>
                    <Cols span={4}>
                      <Text name>Company Name</Text>
                    </Cols>
                    <Cols span={7}>
                      <Text capitalize>
                        {TextFormatter.normalText(
                          this.state.hasData && this.state.dataCustomer.name,
                        )}
                      </Text>
                    </Cols>
                    <Cols span={1}></Cols>
                    <Cols span={4}>
                      <Text name>Company Tel</Text>
                    </Cols>
                    <Cols span={8}>
                      <Text>
                        {TextFormatter.normalText(
                          this.state.hasData &&
                            this.state.dataCustomer.phone_number,
                        )}
                      </Text>
                    </Cols>
                  </Rows>
                  <Rows>
                    <Cols span={4}>
                      <Text name>Tax No</Text>
                    </Cols>
                    <Cols span={7}>
                      <Text>
                        {TextFormatter.normalText(
                          this.state.hasData && this.state.dataCustomer.tax_no,
                        )}
                      </Text>
                    </Cols>
                    <Cols span={1}></Cols>
                    <Cols span={4}>
                      <Text name>Fax</Text>
                    </Cols>
                    <Cols span={8}>
                      <Text>
                        {TextFormatter.normalText(
                          this.state.hasData && this.state.dataCustomer.fax,
                        )}
                      </Text>
                    </Cols>
                  </Rows>
                  <Divider
                    style={{
                      margin: '40px 0px',
                      borderColor: '#737373',
                    }}
                    dashed
                  />
                  <Rows>
                    <Cols span={4}>
                      <Text name>Address Line1</Text>
                    </Cols>
                    <Cols span={20}>
                      <Text capitalize>
                        {TextFormatter.normalText(
                          this.state.hasData &&
                            this.state.dataCustomer.address_1,
                        )}
                      </Text>
                    </Cols>
                  </Rows>
                  <Rows>
                    <Cols span={4}>
                      <Text name>Address Line2</Text>
                    </Cols>
                    <Cols span={20}>
                      <Text capitalize>
                        {this.state.hasData &&
                          TextFormatter.normalText(
                            this.state.dataCustomer.address_2,
                          )}
                      </Text>
                    </Cols>
                  </Rows>
                  <Rows>
                    <Cols span={4}>
                      <Text name>Country</Text>
                    </Cols>
                    <Cols span={7}>
                      <Text capitalize>
                        {TextFormatter.normalText(
                          this.state.hasData && this.state.dataCustomer.country,
                        )}
                      </Text>
                    </Cols>
                    <Cols span={1}></Cols>
                    <Cols span={4}>
                      <Text name>Province</Text>
                    </Cols>
                    <Cols span={8}>
                      <Text capitalize>
                        {TextFormatter.normalText(
                          this.state.hasData &&
                            this.state.dataCustomer.province,
                        )}
                      </Text>
                    </Cols>
                  </Rows>
                  <Rows style={{ marginBottom: '0px' }}>
                    <Cols span={4}>
                      <Text name>District</Text>
                    </Cols>
                    <Cols span={7}>
                      <Text capitalize>
                        {TextFormatter.normalText(
                          this.state.hasData &&
                            this.state.dataCustomer.district,
                        )}
                      </Text>
                    </Cols>
                    <Cols span={1}></Cols>
                    <Cols span={4}>
                      <Text name>Postcode</Text>
                    </Cols>
                    <Cols span={8}>
                      <Text>
                        {TextFormatter.normalText(
                          this.state.hasData &&
                            this.state.dataCustomer.zip_code,
                        )}
                      </Text>
                    </Cols>
                  </Rows>
                </Form>
              </Spin>
            </TextCard>
          )}
          {this.state.hasData && (
            <ContactPerson company_index={this.state.index} />
          )}
        </ContentBox>
      </div>
    );
  }
}
class ContactPerson extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      hasData: false,
      incorrect: false,
      limit: 500,
      dataContactPerson: {},
      offset: 0,
      updateContactPerson: false,
      newData: {},
      editing: false,
      editingData: {},
      editedData: {},
      editingIndex: null,
      searchString: '',
      company_index: parseInt(props.company_index),
      limit: 500,
      offset: 0,
    };
    this.onEdit = this.onEdit.bind(this);
  }
  formRef = React.createRef();
  onReset = () => {
    this.formRef.current.resetFields();
  };
  handleCancel = async () => {
    await this.setState({
      editingData: {},
      editing: false,
      editedData: {},
      editingIndex: null,
      visible: false,
    });
    console.log(this.state.editingData);
    console.log(this.state.editing);
    this.onReset();
  };
  showModal = async () => {
    await this.setState({
      visible: true,
    });
  };
  componentDidMount() {
    this.handlefetchContactPersonList();
  }
  onSearch = (value) => {
    console.log(value);
    this.setState(
      {
        searchString: value,
        hasData: false,
      },
      () => this.handlefetchContactPersonList(),
    );
  };
  showDeleteConfirmation = async (payload) => {
    confirm({
      title: 'Do you want to delete this contact?',
      content: `Contact No: ${payload}`,
      onOk: () => {
        this.handleDelete(payload);
      },
      onCancel: () => {
        console.log('Cancel');
      },
    });
  };
  handleDelete = async (payload) => {
    console.log('delete');
    await this.setState({ deleteData: payload });
    await this.handleDeleteContactPerson();
    return;
  };
  handleDeleteContactPerson = async () => {
    let payload = this.state.deleteData;
    await CustomerService.deleteContactPerson(
      payload,
      ({ data }) => {
        console.log(data);
        message.success(data.message);
        this.handlefetchContactPersonList();
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Cannot delete the contact: ' + response.data.message);
        }
      },
    );
  };
  onEdit = async (payload, index) => {
    // console.log(payload);
    await this.setState({ editingData: { ...payload }, editingIndex: index });
    await this.setState({ editing: true });
    await this.showModal();
    this.state.editing &&
      this.formRef.current.setFieldsValue({
        name: this.state.editingData.name,
        email: this.state.editingData.email,
        mobile_number: this.state.editingData.mobile_number,
        position: this.state.editingData.position,
        department: this.state.editingData.department,
      });
  };
  handleOk = async (payload) => {
    if (this.state.editing) {
      console.log('edit');
      this.setState({ editedData: payload, visible: false });
      console.log(this.state.editedData);
      await this.handleEditContactPerson();
    } else {
      console.log('add');
      let data = { ...payload, company_index: this.props.company_index };
      this.setState({ newData: data, visible: false });
      await this.handleAddContactPerson();
    }
    this.setState({
      editing: false,
      editingData: {},
      editedData: {},
      editingIndex: null,
    });
    this.onReset();
  };
  handleEditContactPerson = async () => {
    let payload = this.state.editedData;
    let index = this.state.editingIndex;
    CustomerService.editContactPerson(
      index,
      payload,
      ({ data }) => {
        console.log(data);
        message.success(data.message);
        this.handlefetchContactPersonList();
      },
      (response) => {
        message.error('Cannot edit contact: ' + response.data.message);
      },
    );
  };
  handleAddContactPerson = async () => {
    let payload = this.state.newData;
    CustomerService.addContactPerson(
      payload,
      ({ data }) => {
        console.log(data);
        message.success(data.message);
        this.handlefetchContactPersonList();
      },
      (response) => {
        message.error('Cannot add new contact: ' + response.data.message);
      },
    );
  };
  handlefetchContactPersonList = () => {
    const payload = {
      searchString: this.state.searchString,
      company_index: this.state.company_index,
      limit: this.state.limit,
      offset: this.state.offset,
    };
    CustomerService.fetchContactPersonList(
      payload,
      ({ data }) => {
        console.log(data);
        this.setState({
          hasData: true,
          dataContactPerson: data.contactPerson,
        });
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          if (response.data.message != 'empty array')
            message.error('Cannot access data: ' + response.data.message);
        }
      },
    );
    console.log(this.state.dataContactPerson);
  };
  render() {
    const columns = [
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        render: (name) => <TableText>{name}</TableText>,
      },
      {
        title: 'EMAIL',
        dataIndex: 'email',
        key: 'email',
        render: (email) => <TableText>{email}</TableText>,
      },
      {
        title: 'TEL',
        dataIndex: 'mobile_number',
        key: 'tel',
        render: (tel) => <TableText>{tel}</TableText>,
      },
      {
        title: 'POSITION',
        key: 'position',
        dataIndex: 'position',
        render: (position) => <TableText>{position}</TableText>,
      },
      {
        title: 'DEPARTMENT',
        key: 'department',
        dataIndex: 'department',
        render: (department) => <TableText>{department}</TableText>,
      },
      {
        title: 'ACTION',
        key: 'action',
        dataIndex: 'index',
        render: (index, record, indexTable) => (
          <ActionBox>
            <ActionItem>
              <a onClick={() => this.onEdit(record, index)}>
                <img src={editIcon} width="20" height="20" />
              </a>
            </ActionItem>
            <ActionItem>
              {/* <a onClick={() => this.handleDelete(index)}> */}
              <a onClick={() => this.showDeleteConfirmation(index)}>
                <img src={binIcon} />
              </a>
            </ActionItem>
          </ActionBox>
        ),
      },
    ];
    const validateMessages = {
      required: '${name} is required!',
      types: {
        text: '${name} is not validate!',
        email: '${name} is not validate email!',
        number: '${name} is not a validate number!',
      },
      number: {
        range: '${name} must be between ${min} and ${max}',
      },
    };
    return (
      <TextCard style={{ marginBottom: '2em' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Text subtitle>Attn Contact</Text>
          <Search
            placeholder="Search Contact"
            onSearch={(value) => this.onSearch(value)}
            style={{ width: 200 }}
          />
        </div>
        <ContactList
          className="table-striped-rows"
          columns={columns}
          dataSource={this.state.hasData ? this.state.dataContactPerson : null}
          style={{ width: '100%', margin: '28px 0px' }}
          loading={!this.state.hasData && !this.state.incorrect}
          pagination={false}
        />
        <OutlineButton style={{ margin: '0px 15px' }} onClick={this.showModal}>
          <svg
            style={{ marginRight: '5px' }}
            width="18"
            height="18"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <Icons
              d="M16.0312 1.1875H2.96875C2.49633 1.1875 2.04327 1.37517 1.70922 1.70922C1.37517 2.04327 1.1875 2.49633 1.1875 2.96875V16.0312C1.1875 16.5037 1.37517 16.9567 1.70922 17.2908C2.04327 17.6248 2.49633 17.8125 2.96875 17.8125H16.0312C16.5037 17.8125 16.9567 17.6248 17.2908 17.2908C17.6248 16.9567 17.8125 16.5037 17.8125 16.0312V2.96875C17.8125 2.49633 17.6248 2.04327 17.2908 1.70922C16.9567 1.37517 16.5037 1.1875 16.0312 1.1875ZM13.0625 10.0938H10.0938V13.0625C10.0938 13.22 10.0312 13.371 9.91985 13.4823C9.8085 13.5937 9.65747 13.6562 9.5 13.6562C9.34253 13.6562 9.19151 13.5937 9.08016 13.4823C8.96881 13.371 8.90625 13.22 8.90625 13.0625V10.0938H5.9375C5.78003 10.0938 5.629 10.0312 5.51766 9.91985C5.40631 9.8085 5.34375 9.65747 5.34375 9.5C5.34375 9.34253 5.40631 9.19151 5.51766 9.08016C5.629 8.96881 5.78003 8.90625 5.9375 8.90625H8.90625V5.9375C8.90625 5.78003 8.96881 5.629 9.08016 5.51766C9.19151 5.40631 9.34253 5.34375 9.5 5.34375C9.65747 5.34375 9.8085 5.40631 9.91985 5.51766C10.0312 5.629 10.0938 5.78003 10.0938 5.9375V8.90625H13.0625C13.22 8.90625 13.371 8.96881 13.4823 9.08016C13.5937 9.19151 13.6562 9.34253 13.6562 9.5C13.6562 9.65747 13.5937 9.8085 13.4823 9.91985C13.371 10.0312 13.22 10.0938 13.0625 10.0938Z"
              fill="#2D95A8"
            />
          </svg>
          Add New Contact
        </OutlineButton>
        <Modal
          bodyStyle={{
            maxWidth: '565px',
            maxHeight: '450px',
            backgroundColor: 'white',
            boxShadow: '0px 0px 21px rgba(0, 0, 0, 0.11)',
            borderRadius: '5px',
            padding: '28px 29px',
            paddingBottom: '10px',
          }}
          title={false}
          visible={this.state.visible}
          // onOk={this.handleOk}
          // onCancel={this.handleCancel}
          closable={false}
          footer={false}
        >
          <Text subtitle>
            {this.state.editing ? 'Attn Contact' : 'Add Attn Contact'}
          </Text>
          <Form style={{ margin: '10px 0px' }}>
            <AntForm
              ref={this.formRef}
              name="new contact"
              onFinish={this.handleOk}
              validateMessages={defaultValidateMessages}
            >
              <Rows start>
                <Cols span={9}>
                  <Text name>
                    Name<Required>*</Required>
                  </Text>
                </Cols>
                <Cols span={15}>
                  <AntForm.Item
                    style={{ width: '100%' }}
                    name="name"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </AntForm.Item>
                </Cols>
              </Rows>
              <Rows start>
                <Cols span={9}>
                  <Text name>
                    Email<Required>*</Required>
                  </Text>
                </Cols>
                <Cols span={15}>
                  <AntForm.Item
                    style={{ width: '100%' }}
                    name="email"
                    rules={[
                      {
                        required: true,

                        type: 'email',
                      },
                    ]}
                  >
                    <Input />
                  </AntForm.Item>
                </Cols>
              </Rows>
              <Rows start>
                <Cols span={9}>
                  <Text name>
                    Tel<Required>*</Required>
                  </Text>
                </Cols>
                <Cols span={15}>
                  <AntForm.Item
                    style={{ width: '100%' }}
                    name="mobile_number"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </AntForm.Item>
                </Cols>
              </Rows>
              <Rows start>
                <Cols span={9}>
                  <Text name>
                    Position<Required>*</Required>
                  </Text>
                </Cols>
                <Cols span={15}>
                  <AntForm.Item
                    style={{ width: '100%' }}
                    name="position"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </AntForm.Item>
                </Cols>
              </Rows>
              <Rows start>
                <Cols span={9}>
                  <Text name>
                    Department<Required>*</Required>
                  </Text>
                </Cols>
                <Cols span={15}>
                  <AntForm.Item
                    style={{ width: '100%' }}
                    name="department"
                    rules={[
                      {
                        required: 'Department is required',
                      },
                    ]}
                  >
                    <Input />
                  </AntForm.Item>
                </Cols>
              </Rows>
              <Rows style={{ marginTop: '20px' }}>
                <Cols span={24} style={{ justifyContent: 'space-between' }}>
                  <NormalButton grey onClick={this.handleCancel}>
                    Cancel
                  </NormalButton>
                  <NormalButton htmlType="submit">Submit</NormalButton>
                </Cols>
              </Rows>
            </AntForm>
          </Form>
        </Modal>
      </TextCard>
    );
  }
}
class DemoS extends React.Component {
  formRef = React.createRef();

  componentDidMount() {
    this.formRef.current.setFieldsValue({
      username: 'Bamboo',
    });
  }

  render() {
    return (
      <AntForm ref={this.formRef}>
        <AntForm.Item name="username" rules={[{ required: true }]}>
          <Input />
        </AntForm.Item>{' '}
        <AntForm.Item name="kk" rules={[{ required: true }]}>
          <Input />
        </AntForm.Item>
      </AntForm>
    );
  }
}
export default ViewCustomer;
