import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Layout,
  Button,
  Input,
  Table,
  Space,
  Row,
  Col,
  Modal,
  message,
  Pagination,
  DatePicker,
  Select,
  Timeline,
  Divider,
} from 'antd';
import binIcon from '../../assets/icons/bin.svg';
import statusActiveIcon from '../../assets/icons/statusActive.svg';
import statusInactiveIcon from '../../assets/icons/statusInactive.svg';
import deleteIcon from '../../assets/icons/delete.svg';
// import "./customer.css";
import {
  AnnounceService,
  CustomerService,
  imageservice,
  TimesheetService,
} from '../../services/api';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { TextFormatter } from '../../components/TextFormatter';
import { redirectTo } from '../../services/redirect';
import moment from 'moment';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import profileImage from '../../assets/icons/profileimage.svg';

const { Header, Content, Footer } = Layout;
const { RangePicker } = DatePicker;
const PageContent = styled.div`
  width: auto;
  font-family: Kanit;
`;
const TitleBox = styled.div`
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  height: 6.875em;
  justify-content: space-between;
  align-items: center;
`;
const FilterBox = styled.div`
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  height: 6em;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.subtitle ? '#20ABC5' : '#ff2132'};
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1.125em' : '1em'};
  font-weight: ${(props) =>
    props.title ? 'bold' : props.subtitle ? '600' : 'normal'};
  font-family: 'Kanit';
  text-transform: ${(props) => (props.subtitle ? 'uppercase' : 'none')};
`;
const ModalText = styled.div`
  color: ${(props) => (props.color ? props.color : '#232323')};
  font-size: ${(props) =>
    props.title ? '1.125em' : props.subtitle ? '1.125em' : '0.9em'};
  font-weight: ${(props) => (props.title ? '500' : 'normal')};
  font-family: 'Kanit';
  text-transform: ${(props) => (props.subtitle ? 'uppercase' : 'none')};
`;
const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  padding: 5px 15px;
  background-color: #2d95a8 !important;
  border-color: #2d95a8 !important;
  justify-content: center;
  color: white;
  font-family: Inter;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;
const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#FFFFFF')};
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  width: auto;
  min-height: calc(100vh - 6em - 64px);
`;
const TableText = styled.div`
  color: ${(props) => (props.title ? '#232323' : '#737373')};
  font-size: 1em;
  font-weight: ${(props) => (props.title ? '600' : 'normal')};
  font-family: 'Kanit';
  line-height: 16px;
  text-transform: ${(props) => props.capitalize && 'capitalize'};
`;
const HourText = styled.div`
  color: ${(props) => (props.color ? props.color : '#737373')};
  font-size: 1em;
  font-weight: ${(props) =>
    props.title ? '600' : props.no ? 'bold' : 'normal'};
  line-height: 22px;
  text-transform: ${(props) => props.capitalize && 'capitalize'};
  cursor: pointer;
`;
const TimesheetList = styled(Table)`
  &.table-striped-rows tr > th {
    background-color: #ffffff;
  }
  min-height: 330px;
  &.ant-table-tbody > tr > td {
    border-bottom: 1px solid #eaeaea;
  }
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 6px 8px;
  }
`;
const PaginationBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 5px;
  margin-bottom: 15px;
`;
const Cols = styled(Col)`
  display: flex;
  align-items: center;
  margin: 0px 10px;
`;
const Rows = styled(Row)`
  margin: 10px 0px;
  align-items: center;
  position: right;
`;
const Circle = styled.img`
  margin: 0;
  background: ${(props) =>
    props.small ? (props.src ? 'url(' + props.src + ')' : 'grey') : 'white'};
  background-repeat: no-repeat;
  background-size: 9.313rem;
  border-radius: 50%;
  height: ${(props) => (props.height ? props.height : '30px')};
  width: ${(props) => (props.width ? props.width : '30px')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
const Break = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  border: 0.5px solid #eaeaea;
`;
//for timesheet detail
const Description = styled.span`
  color: #737373;
  font-size: 12px;
`;
const Schedule = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-rows:
    [tracks] auto
    // [holiday] 1fr
    ${(props) =>
      props.isBefore9
        ? `[time-0000] 1fr
      [time-0030] 1fr
      [time-0100] 1fr
      [time-0130] 1fr
      [time-0200] 1fr
      [time-0230] 1fr
      [time-0300] 1fr
      [time-0330] 1fr
      [time-0400] 1fr
      [time-0430] 1fr
      [time-0500] 1fr
      [time-0530] 1fr
      [time-0600] 1fr
      [time-0630] 1fr
      [time-0700] 1fr
      [time-0730] 1fr
      [time-0800] 1fr
      [time-0830] 1fr`
        : ''}
    [time-0900] 1fr
    [time-0930] 1fr
    [time-1000] 1fr
    [time-1030] 1fr
    [time-1100] 1fr
    [time-1130] 1fr
    [time-1200] 1fr
    [time-1230] 1fr
    [time-1300] 1fr
    [time-1330] 1fr
    [time-1400] 1fr
    [time-1430] 1fr
    [time-1500] 1fr
    [time-1530] 1fr
    [time-1600] 1fr
    [time-1630] 1fr
    [time-1700] 1fr
    [time-1730] 1fr
    [time-1800] 1fr
    ${(props) =>
      props.isAfter18
        ? `[time-1830] 1fr
        [time-1900] 1fr
        [time-1930] 1fr
        [time-2000] 1fr
        [time-2030] 1fr
        [time-2100] 1fr
        [time-2130] 1fr
        [time-2200] 1fr
        [time-2230] 1fr
        [time-2300] 1fr
        [time-2330] 1fr
        [time-2400] 1fr`
        : ''};
  grid-template-columns:
    [times] 6em
    [timesheet] auto;
`;
const TimeSlot = styled.div`
  grid-column: times;
  font-family: 'Kanit';
  font-weight: 300;
  text-align: right;
  padding-right: 5px;
  color: #acacac;
`;
const TimelineBlock = styled.div`
  font-family: 'Kanit';
  margin-top: 10px;
  margin-bottom: -10px;
  padding: 10px;
  background-color: ${(props) => (props.color ? `${props.color}33` : '')};
  border-radius: 5px;
  border-left: 3px solid ${(props) => (props.color ? props.color : '')};
  display: block;
  grid-column: timesheet;
`;
// const HolidayBlock = styled.div`
//   margin-top: 10px;
//   margin-bottom: -10px;
//   padding: 5px;
//   background-color: #ffab4f;
//   border-radius: 5px;
//   display: block;
//   font-family: 'Kanit';
//   grid-column: timesheet;
// `;
const { Search } = Input;
const { confirm } = Modal;
class Timesheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasData: false,
      incorrect: false,
      year: moment().year(),
      month: moment().month() + 1,
      type: '',
      search: '',
      modalVisible: false,
      limit: 10,
      offset: 0,
      total: 1,
      current: 1,
      isBefore9: false,
      isAfter18: false,
    };
  }
  pad = (num, size) => {
    return num.toString().padStart(size, '0');
  };
  daysInMonth = () => {
    const yearMonth =
      this.state.year.toString() + '-' + this.pad(this.state.month, 2);
    return moment(yearMonth, 'YYYY-MM').daysInMonth();
  };
  getDayName = (dayNum) => {
    const date =
      this.state.year.toString() +
      '-' +
      this.pad(this.state.month, 2) +
      '-' +
      this.pad(dayNum, 2);
    return `${moment(date, 'YYYY-MM-DD').format('ddd')} ${dayNum}`;
  };
  getDateColumn = (dayNum) => {
    const date =
      this.state.year.toString() +
      '-' +
      this.pad(this.state.month, 2) +
      '-' +
      this.pad(dayNum, 2);
    return (
      <React.Fragment>
        <div style={{ fontWeight: 'bold' }}>
          {moment(date, 'YYYY-MM-DD').format('ddd').toUpperCase()}
        </div>
        <div style={{ color: '#737373' }}>{`${dayNum}`}</div>
      </React.Fragment>
    );
  };
  getModalDayName = (dayNum) => {
    const date =
      this.state.year.toString() +
      '-' +
      this.pad(this.state.month, 2) +
      '-' +
      this.pad(dayNum, 2);
    return `${moment(date, 'YYYY-MM-DD').format('ddd')}`;
  };
  getModalDate = (dayNum) => {
    const date =
      this.state.year.toString() +
      '-' +
      this.pad(this.state.month, 2) +
      '-' +
      this.pad(dayNum, 2);
    return `${this.pad(dayNum, 2)} ${moment(date, 'YYYY-MM-DD').format(
      'MMMM YYYY',
    )} `;
  };
  handlefetchTimesheetList = async (search) => {
    const status = this.state.status;
    const params = {
      year: this.state.year || moment().year(),
      month: this.state.month || moment().month() + 1,
      search: typeof search === 'string' ? search : this.state.search,
    };
    await TimesheetService.fetchTimesheetList(
      params,
      async ({ data }) => {
        const tableData = await data.timesheets
          .filter(function (value) {
            if (status === 'Incomplete') {
              if (value.isComplete) {
                return false;
              }
            }
            if (status === 'Complete') {
              if (!value.isComplete) {
                return false;
              }
            }
            return true;
          })
          .map(async (value) => {
            if (status === 'Incomplete') {
              if (value.isComplete) {
                return false;
              }
            }
            if (status === 'Complete') {
              if (!value.isComplete) {
                return false;
              }
            }
            if (value.picturePath) {
              const valueWithImage = await imageservice.image.getImage(
                value.picturePath,
                (res) => {
                  if (res.status) return false;
                  const url = URL.createObjectURL(res.data);
                  return { ...value, image: url };
                },
              );
              if (valueWithImage) {
                return valueWithImage;
              } else return value;
            } else {
              return value;
            }
          });
        Promise.all(tableData).then((values) => {
          this.setState({
            hasData: true,
            data: values,
            holidays: data.holidays,
            workingDay: data.workingDay,
            search: typeof search === 'string' ? search : this.state.search,
          });
        });
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          if (response.data.message != 'empty array') {
            message.error('Cannot access data: ' + response.data.message);
          }
        }
      },
    );
  };
  componentDidMount = () => {
    this.handlefetchTimesheetList();
  };
  showTotal(total) {
    return total > 1 ? `Total ${total} items` : `Total ${total} item`;
  }
  onSearch = (value) => {
    /* this.setState(
      {
        search: value,
        hasData: false,
      },
      () => this.handlefetchTimesheetList()
    ); */
    this.handlefetchTimesheetList(value);
  };
  onStatusFilterChange = (value) => {
    this.setState(
      {
        status: value,
        hasData: false,
      },
      () => this.handlefetchTimesheetList(),
    );
  };
  onSearchMonth = (value) => {
    this.setState(
      {
        year: moment(value).year(),
        month: moment(value).month() + 1,
        hasData: false,
      },
      () => this.handlefetchTimesheetList(),
    );
  };
  handleRedirect = (path) => {
    redirectTo(path);
  };
  onShowTimesheetModal = (record, day) => {
    const params = {
      userId: record.userId,
      year: this.state.year,
      month: this.state.month,
      day: day,
    };
    TimesheetService.getTimesheetByDate(
      params,
      ({ data }) => {
        data.map((value) => {
          if (moment(value.startTime).format('HH') < '09') {
            this.setState({ isBefore9: true });
          }
          if (moment(value.endTime).format('HH') > '18') {
            this.setState({ isAfter18: true });
          }
        });
        this.setState({
          timesheetByDate: data,
          modalVisible: true,
          selectedUserData: { ...record, day: day },
        });
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          if (response.data.message != 'empty array') {
            message.error('Cannot access data: ' + response.data.message);
          }
        }
      },
    );
  };
  onCloseModal = () => {
    this.setState({
      modalVisible: false,
      isBefore9: false,
      isAfter18: false,
    });
  };
  onChange = (page) => {
    const offset = (page - 1) * this.state.limit;
    this.setState(
      {
        current: page,
        offset: offset,
      },
      () => this.handlefetchTimesheetList(),
    );
  };
  getTimesheetHour = (minute, record, day) => {
    const isHoliday = this.state?.holidays.find((value) => value.day === day);
    const isWeekend = ['Sat', 'Sun'].includes(
      this.getDayName(day).substring(0, 3),
    );
    const workingMinutes = minute?.working;
    const leaveMinutes = minute?.leave;
    const timesheetColor =
      workingMinutes + leaveMinutes < 480
        ? '#FFAB4F'
        : workingMinutes + leaveMinutes > 480 && workingMinutes > 0
        ? '#D62923'
        : '';
    var hours = Math.floor(workingMinutes / 60);
    var minutes = moment.utc(workingMinutes * 60 * 1000).format('mm');
    if (isWeekend || !!isHoliday) {
      return {
        props: {
          style: { background: isWeekend ? '#F2F2F2' : '#FFF6EE' },
        },
        children: (
          <HourText
            color="#D62923"
            onClick={() => this.onShowTimesheetModal(record, day)}
          >
            {workingMinutes == 0 ? '' : `${hours}:${minutes}`}
          </HourText>
        ),
      };
    }
    return {
      children: (
        <HourText
          color={timesheetColor}
          onClick={() => this.onShowTimesheetModal(record, day)}
        >
          {leaveMinutes === 480 ? 'Leave' : `${hours}:${minutes}`}
        </HourText>
      ),
    };
  };

  render() {
    const columns = [
      {
        title: '',
        index: 'image',
        dataIndex: 'image',
        align: 'center',
        fixed: 'left',
        width: '6%',
        day: 0,
        render: (image) => {
          return <Circle src={image ? image : profileImage} />;
        },
      },
      {
        title: 'EMP NO.',
        index: 'empCode',
        dataIndex: 'empCode',
        fixed: 'left',
        width: '15%',
        align: 'center',
        day: 0,
        defaultSortOrder: 'descend',
        sorter: {
          compare: (a, b) => a.empCode.localeCompare(b.empCode),
        },
        render: (empCode) => <TableText>{empCode}</TableText>,
      },
      {
        title: 'Name'.toUpperCase(),
        dataIndex: 'firstnameEn',
        index: 'firstnameEn',
        fixed: 'left',
        width: '24%',
        align: 'center',
        day: 0,
        render: (name, record) => (
          <a
            onClick={() => {
              sessionStorage.setItem(
                'selected-mmyyyy',
                `${this.state.month} ${this.state.year}`,
              );
              this.handleRedirect('/timesheetDetail/?id=' + record.userId);
            }}
          >
            <TableText capitalize>
              {`${record.firstnameEn} ${record.lastnameEn}`}
            </TableText>
          </a>
        ),
      },
      {
        title: 'Position'.toUpperCase(),
        index: 'position',
        dataIndex: 'position',
        fixed: 'left',
        width: '20%',
        align: 'center',
        day: 0,
        render: (position) => <TableText>{position}</TableText>,
      },
      {
        title: this.getDateColumn(1),
        index: 'day1',
        dataIndex: 'day1',
        align: 'center',
        width: '10%',
        day: 1,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 1);
        },
      },
      {
        title: this.getDateColumn(2),
        index: 'day2',
        dataIndex: 'day2',
        align: 'center',
        width: '10%',
        day: 2,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 2);
        },
      },
      {
        title: this.getDateColumn(3),
        index: 'day3',
        dataIndex: 'day3',
        align: 'center',
        width: '10%',
        day: 3,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 3);
        },
      },
      {
        title: this.getDateColumn(4),
        index: 'day4',
        dataIndex: 'day4',
        align: 'center',
        width: '10%',
        day: 4,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 4);
        },
      },
      {
        title: this.getDateColumn(5),
        index: 'day5',
        dataIndex: 'day5',
        align: 'center',
        width: '10%',
        day: 5,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 5);
        },
      },
      {
        title: this.getDateColumn(6),
        index: 'day6',
        dataIndex: 'day6',
        align: 'center',
        width: '10%',
        day: 6,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 6);
        },
      },
      {
        title: this.getDateColumn(7),
        index: 'day7',
        dataIndex: 'day7',
        align: 'center',
        width: '10%',
        day: 7,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 7);
        },
      },
      {
        title: this.getDateColumn(8),
        index: 'day8',
        dataIndex: 'day8',
        align: 'center',
        width: '10%',
        day: 8,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 8);
        },
      },
      {
        title: this.getDateColumn(9),
        index: 'day9',
        dataIndex: 'day9',
        align: 'center',
        width: '10%',
        day: 9,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 9);
        },
      },
      {
        title: this.getDateColumn(10),
        index: 'day10',
        dataIndex: 'day10',
        align: 'center',
        width: '10%',
        day: 10,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 10);
        },
      },
      {
        title: this.getDateColumn(11),
        index: 'day11',
        dataIndex: 'day11',
        align: 'center',
        width: '10%',
        day: 11,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 11);
        },
      },
      {
        title: this.getDateColumn(12),
        index: 'day12',
        dataIndex: 'day12',
        align: 'center',
        width: '10%',
        day: 12,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 12);
        },
      },
      {
        title: this.getDateColumn(13),
        index: 'day13',
        dataIndex: 'day13',
        align: 'center',
        width: '10%',
        day: 13,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 13);
        },
      },
      {
        title: this.getDateColumn(14),
        index: 'day14',
        dataIndex: 'day14',
        align: 'center',
        width: '10%',
        day: 14,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 14);
        },
      },
      {
        title: this.getDateColumn(15),
        index: 'day15',
        dataIndex: 'day15',
        align: 'center',
        width: '10%',
        day: 15,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 15);
        },
      },
      {
        title: this.getDateColumn(16),
        index: 'day16',
        dataIndex: 'day16',
        align: 'center',
        width: '10%',
        day: 16,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 16);
        },
      },
      {
        title: this.getDateColumn(17),
        index: 'day17',
        dataIndex: 'day17',
        align: 'center',
        width: '10%',
        day: 17,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 17);
        },
      },
      {
        title: this.getDateColumn(18),
        index: 'day18',
        dataIndex: 'day18',
        align: 'center',
        width: '10%',
        day: 18,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 18);
        },
      },
      {
        title: this.getDateColumn(19),
        index: 'day19',
        dataIndex: 'day19',
        align: 'center',
        width: '10%',
        day: 19,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 19);
        },
      },
      {
        title: this.getDateColumn(20),
        index: 'day20',
        dataIndex: 'day20',
        align: 'center',
        width: '10%',
        day: 20,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 20);
        },
      },
      {
        title: this.getDateColumn(21),
        index: 'day21',
        dataIndex: 'day21',
        align: 'center',
        width: '10%',
        day: 21,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 21);
        },
      },
      {
        title: this.getDateColumn(22),
        index: 'day22',
        dataIndex: 'day22',
        align: 'center',
        width: '10%',
        day: 22,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 22);
        },
      },
      {
        title: this.getDateColumn(23),
        index: 'day23',
        dataIndex: 'day23',
        align: 'center',
        width: '10%',
        day: 23,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 23);
        },
      },
      {
        title: this.getDateColumn(24),
        index: 'day24',
        dataIndex: 'day24',
        align: 'center',
        width: '10%',
        day: 24,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 24);
        },
      },
      {
        title: this.getDateColumn(25),
        index: 'day25',
        dataIndex: 'day25',
        align: 'center',
        width: '10%',
        day: 25,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 25);
        },
      },
      {
        title: this.getDateColumn(26),
        index: 'day26',
        dataIndex: 'day26',
        align: 'center',
        width: '10%',
        day: 26,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 26);
        },
      },
      {
        title: this.getDateColumn(27),
        index: 'day27',
        dataIndex: 'day27',
        align: 'center',
        width: '10%',
        day: 27,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 27);
        },
      },
      {
        title: this.getDateColumn(28),
        index: 'day28',
        dataIndex: 'day28',
        align: 'center',
        width: '10%',
        day: 28,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 28);
        },
      },
      {
        title: this.getDateColumn(29),
        index: 'day29',
        dataIndex: 'day29',
        align: 'center',
        width: '10%',
        day: 29,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 29);
        },
      },
      {
        title: this.getDateColumn(30),
        index: 'day30',
        dataIndex: 'day30',
        align: 'center',
        width: '10%',
        day: 30,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 30);
        },
      },
      {
        title: this.getDateColumn(31),
        index: 'day31',
        dataIndex: 'day31',
        align: 'center',
        width: '10%',
        day: 31,
        render: (minute, record) => {
          return this.getTimesheetHour(minute, record, 31);
        },
      },
      {
        title: 'Hours'.toUpperCase(),
        index: 'totalWorkingMinutes',
        dataIndex: 'totalWorkingMinutes',
        align: 'center',
        width: '10%',
        fixed: 'right',
        day: 0,
        render: (minute, record) => {
          var hours = Math.floor(minute / 60);
          var minutes = moment.utc(minute * 60 * 1000).format('mm');
          var workingMinute = this.state.workingDay?.workingDay * 8 * 60;
          var expectedMinute = workingMinute - record.totalLeaveMinutes;
          var color =
            minute === expectedMinute
              ? '#004368'
              : minute > expectedMinute
              ? '#D62923'
              : '#FFAB4F';
          return (
            <TableText
              style={{ color: color }}
            >{`${hours}:${minutes}`}</TableText>
          );
        },
      },
      {
        title: 'Expected Hours'.toUpperCase(),
        index: 'workingDay',
        dataIndex: 'workingDay',
        align: 'center',
        width: '12.5%',
        fixed: 'right',
        day: 0,
        render: (workingDay, record) => {
          var workingMinute = this.state.workingDay?.workingDay * 8 * 60;
          var diffMinute = workingMinute - record.totalLeaveMinutes;
          var hours = Math.floor(diffMinute / 60);
          var minutes = moment.utc(diffMinute * 60 * 1000).format('mm');
          return <TableText>{`${hours}:${minutes}`}</TableText>;
        },
      },
    ].filter((value) => value.day <= this.daysInMonth());
    return (
      <PageContent>
        <FilterBox>
          <Rows>
            <Cols>
              <DatePicker
                onChange={this.onSearchMonth}
                picker="month"
                defaultValue={moment()}
                allowClear={false}
                format="MMM YYYY"
              ></DatePicker>
            </Cols>
          </Rows>
          <Rows>
            <Cols>
              <Search
                placeholder="Search"
                //onSearch={(value) => this.onSearch(value)}
                onChange={(e) => this.onSearch(e.target.value)}
                style={{ width: 230 }}
                loading={!this.state.hasData && !this.state.incorrect}
              />
            </Cols>
            <Cols>
              <div>
                <Select
                  style={{ width: 230 }}
                  onChange={(value) => this.onStatusFilterChange(value)}
                  defaultValue={'All'}
                >
                  <Select.Option value="All">All</Select.Option>
                  <Select.Option value="Complete">Complete</Select.Option>
                  <Select.Option value="Incomplete">Incomplete</Select.Option>
                </Select>
              </div>
            </Cols>
          </Rows>
        </FilterBox>
        <ContentBox>
          <TimesheetList
            className="table-striped-rows"
            columns={columns}
            dataSource={this.state.hasData ? this.state.data : null}
            style={{ width: '100%' }}
            size="small"
            loading={!this.state.hasData && !this.state.incorrect}
            scroll={{ x: 3000 }}
            pagination={{
              position: ['bottomRight'],
              pageSizeOptions: [10, 20, 30, 40],
              showSizeChanger: true,
            }}
          />
        </ContentBox>
        <Modal
          visible={this.state.modalVisible}
          onCancel={() => this.onCloseModal()}
          footer={null}
          width={448}
        >
          <Row>
            <Col span={6}>
              <Space
                direction="vertical"
                align="center"
                size={6}
                style={{ width: '100%', paddingRight: 24 }}
              >
                <Circle
                  width={66}
                  height={66}
                  src={
                    this.state.selectedUserData?.image
                      ? this.state.selectedUserData.image
                      : profileImage
                  }
                />
                <ModalText>{this.state.selectedUserData?.empCode}</ModalText>
              </Space>
            </Col>
            <Col span={18}>
              <ModalText>Timesheet</ModalText>
              <ModalText title style={{ marginBottom: '3px' }}>
                {`${this.state.selectedUserData?.firstnameEn} ${this.state.selectedUserData?.lastnameEn}`}
              </ModalText>
              <Space size="large">
                <div>
                  <ModalText color="#A3A3A3">Department</ModalText>
                  <ModalText>
                    {this.state.selectedUserData?.department}
                  </ModalText>
                </div>
                <div>
                  <ModalText color="#A3A3A3">Position</ModalText>
                  <ModalText>{this.state.selectedUserData?.position}</ModalText>
                </div>
              </Space>
            </Col>
          </Row>
          <Divider style={{ margin: '15px 0px 5px 0px' }} />
          <div style={{ display: 'flex' }}>
            <ModalText title style={{ marginRight: '10px' }}>
              {this.state.selectedUserData?.day
                ? this.getModalDate(this.state.selectedUserData?.day)
                : ''}
            </ModalText>
            <ModalText subtitle color="#706F72">
              {this.state.selectedUserData?.day
                ? this.getModalDayName(this.state.selectedUserData?.day)
                : ''}
            </ModalText>
          </div>
          {this.state.timesheetByDate?.map((value) => {
            if (value.type === 'holiday') {
              return (
                <div
                  style={{
                    backgroundColor: '#ffab4f',
                    padding: '5px',
                    borderRadius: '3px',
                  }}
                >
                  <ModalText style={{ color: '#FFFFFF' }}>
                    {value.dateName}
                  </ModalText>
                </div>
              );
            }
          })}
          <Divider style={{ margin: '5px 0px' }} />
          <div>
            <Schedule
              isBefore9={this.state.isBefore9}
              isAfter18={this.state.isAfter18}
            >
              {this.state.isBefore9 && (
                <React.Fragment>
                  <TimeSlot style={{ gridRow: 'time-0000' }}>
                    0:00 AM -
                  </TimeSlot>
                  <TimeSlot style={{ gridRow: 'time-0100' }}>
                    1:00 AM -
                  </TimeSlot>
                  <TimeSlot style={{ gridRow: 'time-0200' }}>
                    2:00 AM -
                  </TimeSlot>
                  <TimeSlot style={{ gridRow: 'time-0300' }}>
                    3:00 AM -
                  </TimeSlot>
                  <TimeSlot style={{ gridRow: 'time-0400' }}>
                    4:00 AM -
                  </TimeSlot>
                  <TimeSlot style={{ gridRow: 'time-0500' }}>
                    5:00 AM -
                  </TimeSlot>
                  <TimeSlot style={{ gridRow: 'time-0600' }}>
                    6:00 AM -
                  </TimeSlot>
                  <TimeSlot style={{ gridRow: 'time-0700' }}>
                    7:00 AM -
                  </TimeSlot>
                  <TimeSlot style={{ gridRow: 'time-0800' }}>
                    8:00 AM -
                  </TimeSlot>
                </React.Fragment>
              )}
              <TimeSlot style={{ gridRow: 'time-0900' }}>9:00 AM -</TimeSlot>
              <TimeSlot style={{ gridRow: 'time-1000' }}>10:00 AM -</TimeSlot>
              <TimeSlot style={{ gridRow: 'time-1100' }}>11:00 AM -</TimeSlot>
              <TimeSlot style={{ gridRow: 'time-1200' }}>12:00 PM -</TimeSlot>
              <TimeSlot style={{ gridRow: 'time-1300' }}>1:00 PM -</TimeSlot>
              <TimeSlot style={{ gridRow: 'time-1400' }}>2:00 PM -</TimeSlot>
              <TimeSlot style={{ gridRow: 'time-1500' }}>3:00 PM -</TimeSlot>
              <TimeSlot style={{ gridRow: 'time-1600' }}>4:00 PM -</TimeSlot>
              <TimeSlot style={{ gridRow: 'time-1700' }}>5:00 PM -</TimeSlot>
              <TimeSlot style={{ gridRow: 'time-1800' }}>6:00 PM -</TimeSlot>
              {this.state.isAfter18 && (
                <React.Fragment>
                  {/* <TimeSlot style={{ gridRow: 'time-1800' }}>
                    18:00 PM -
                  </TimeSlot> */}
                  <TimeSlot style={{ gridRow: 'time-1900' }}>
                    7:00 PM -
                  </TimeSlot>
                  <TimeSlot style={{ gridRow: 'time-2000' }}>
                    8:00 PM -
                  </TimeSlot>
                  <TimeSlot style={{ gridRow: 'time-2100' }}>
                    9:00 PM -
                  </TimeSlot>
                  <TimeSlot style={{ gridRow: 'time-2200' }}>
                    10:00 PM -
                  </TimeSlot>
                  <TimeSlot style={{ gridRow: 'time-2300' }}>
                    11:00 PM -
                  </TimeSlot>
                  <TimeSlot style={{ gridRow: 'time-2400' }}>
                    12:00 PM -
                  </TimeSlot>
                </React.Fragment>
              )}
              {this.state.timesheetByDate?.map((value) => {
                const timeRange = `time-${moment(value.startTime).format(
                  'HHmm',
                )} / time-${moment(value.endTime).format('HHmm')}`;
                if (value.type === 'leave') {
                  return (
                    <TimelineBlock
                      color="#D62923"
                      style={{ gridRow: timeRange }}
                    >
                      <ModalText style={{ color: '#D62923' }}>
                        {`${value.leaveName} LEAVE`}
                      </ModalText>
                      <ModalText>{value.purpose}</ModalText>
                    </TimelineBlock>
                  );
                } else if (value.type === 'task') {
                  return (
                    <TimelineBlock
                      color={value.projectColorCode}
                      style={{ gridRow: timeRange }}
                    >
                      <ModalText>{`${value.projectNo} - ${value.projectName}`}</ModalText>
                      <ModalText>{value.taskDescription}</ModalText>
                    </TimelineBlock>
                  );
                }
                // else if (value.type === 'holiday') {
                //   return (
                //     <HolidayBlock
                //       style={{
                //         gridRow: 'holiday',
                //       }}
                //     >
                //       <ModalText style={{ color: '#FFFFFF' }}>
                //         {value.dateName}
                //       </ModalText>
                //     </HolidayBlock>
                //   );
                // }
              })}
            </Schedule>
          </div>
        </Modal>
      </PageContent>
    );
  }
}
export default Timesheet;
