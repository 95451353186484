import { Modal, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { imageservice } from '../../services/api';
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 100);
};

export default class UploadModal extends React.Component {
  constructor(props) {
    super(props);
  }
  state = { visible: true, loading: false, formData: null, imageUrl: null };

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      var formData = new FormData();
      formData.append('img', info.file.originFileObj);
      imageservice.image.uploadImage(formData, ({ data }) => {
        imageservice.image.getImage(data, (res) => {
          if (res.status) return false;
          const blobUrl = URL.createObjectURL(res.data);
          this.setState({
            formData: data,
            image: data,
            loading: false,
            imageUrl: blobUrl,
          });
        });
      });
    }
  };
  componentDidUpdate = async (prevProps, prevState) => {
    if (this.props.visible !== prevProps.visible) {
      await this.setState({ visible: this.props.visible });
    }
  };
  onCancel = () => {
    this.props.handleCancel();
    this.setState({
      formData: null,
      imageUrl: null,
    });
  };
  onOK = () => {
    this.props.handleOk(this.state.image);
    this.setState({
      formData: null,
      imageUrl: null,
    });
  };
  render() {
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { imageUrl } = this.state;
    return (
      <>
        {this.props.button}
        <Modal
          title="Upload"
          visible={this.props.visible}
          onOk={this.onOK}
          onCancel={this.onCancel}
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            customRequest={dummyRequest}
            beforeUpload={beforeUpload}
            onChange={this.handleChange}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Modal>
      </>
    );
  }
}
