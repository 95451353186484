import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Divider, message, Button, Modal, Col, Space } from 'antd';
import { AuthorizeRoleService, imageservice } from '../../services/api';
import { AppContext } from '../context/AppContext';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';

const Break = styled(Divider)`
  margin: 12px 0;
`;

const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: end;
  align-items: center;
`;

const BigHeader = styled.div`
  font-family: Kanit;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

const BigHeader2 = styled.div`
  font-family: Kanit;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #004368;
`;

const Circle = styled.img`
  margin: 0;
  background: ${(props) =>
    props.small ? (props.src ? 'url(' + props.src + ')' : 'grey') : 'white'};
  background-repeat: no-repeat;
  background-size: 9.313rem;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Label = styled.div`
  font-family: 'Kanit';
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin: 0px 1rem;
  color: ${(props) => (props.grey ? '#737373' : '#232323')};
`;

const LilHeader = styled.div`
  max-width: 400px;
  font-family: Kanit;
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
`;

const LilHeader2 = styled.div`
  font-family: Kanit;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #acacac;
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  border-radius: 5px;
  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Kanit;
  font-style: Regular;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid white !important;
    background-color: #f5f5f5 !important;
  }
`;

const RejectedButton = styled(Button)`
  min-width: 106px;
  border-radius: 5px;
  display: flex;
  background-color: white;
  border: 1px solid #f2f2f2;
  justify-content: center;
  color: #232323;
  font-family: Kanit;
  font-style: Regular;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 5px 15px;
  align-items: center;
  &:disabled {
    border: 1px solid white !important;
  }
`;

const TableText = styled.div`
  font-size: '14px';
  font-weight: '400';
  font-family: 'Kanit';
  line-height: 18px;
`;

class EditMedicalClaimModal extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      payslipType: [],
      isLoading: false,
      isAdmin: false,
      remarkText: null,
    };
  }

  componentDidMount = () => {
    if (this.context.userState.currentRole === 'ADMIN') {
      this.setState({
        isAdmin: true,
      });
    }
  };

  onCancelMedicalClaimEdit = () => {
    this.setState({
      remarkText: null,
    });
    this.props.onCancel();
  };

  onChangeRemarkText = ({ target: { value } }) => {
    this.setState({
      remarkText: value,
    });
  };

  onFinishMedicalClaimByUser = () => {
    const payload = {
      medicalClaimRequestId: this.props.record.medicalClaimRequestId,
    };
    AuthorizeRoleService.updateMedicalClaimByUser(
      payload,
      ({ data }) => {
        message.success('Success');
        setTimeout(() => {
          this.props.onFetch();
        }, 1000);
        this.onCancelMedicalClaimEdit();
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  onFinishMedicalClaimByAdmin = (requestStatusId) => {
    const payload = {
      medicalClaimRequestId: this.props.record.medicalClaimRequestId,
      requestStatusId: requestStatusId,
      remark: this.state.remarkText,
    };
    AuthorizeRoleService.updateMedicalClaimByAdmin(
      payload,
      ({ data }) => {
        message.success('Success');
        setTimeout(() => {
          this.props.onFetch();
        }, 1000);
        this.onCancelMedicalClaimEdit();
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  render() {
    return (
      <Modal
        width="450px"
        bodyStyle={{
          maxHeight: '800px',
        }}
        open={this.props.open}
        centered
        footer={null}
        onCancel={this.onCancelMedicalClaimEdit}
        maskClosable
      >
        <Row>
          <Col span={6}>
            <Space
              direction="vertical"
              align="center"
              size={6}
              style={{ width: '100%', paddingRight: 24 }}
            >
              <Circle
                width={66}
                height={66}
                src={this.props.record?.userImage}
              ></Circle>
              <Label style={{ textAlign: 'center' }}>
                {this.props.record?.employeeId}
              </Label>
            </Space>
          </Col>

          <Col span={18}>
            <LilHeader>Medical Claim</LilHeader>
            <BigHeader>
              {this.props.record?.firstnameEn} {this.props.record?.lastnameEn}
            </BigHeader>
            <Row
              style={{
                width: '100%',
                paddingTop: '10px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Col style={{ alignItems: 'flex-start', width: '50%' }}>
                <LilHeader2>Department</LilHeader2>
                <LilHeader>{this.props.record?.userInfo.department}</LilHeader>
              </Col>
              <Col style={{ alignItems: 'flex-start', width: '50%' }}>
                <LilHeader2>Position</LilHeader2>
                <LilHeader>{this.props.record?.userInfo.position}</LilHeader>
              </Col>
            </Row>
          </Col>
        </Row>
        <Break />
        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <BigHeader style={{ color: '#004368' }}>
            {this.props.record?.medicalClaimName}
          </BigHeader>
          <TableText>
            {this.props.record?.requestStatusName === 'Pending' ? (
              <label style={{ color: '#FFAB4F' }}> Pending</label>
            ) : this.props.record?.requestStatusName === 'Approved' ? (
              <label style={{ color: '#3C8618' }}>Approved</label>
            ) : this.props.record?.requestStatusName === 'Rejected' ? (
              <label style={{ color: '#668EA4' }}>Rejected</label>
            ) : this.props.record?.requestStatusName ===
              'Canceled by Approver' ? (
              <label style={{ color: '#D62923' }}>Canceled by Approver</label>
            ) : (
              <label style={{ color: '#F37370' }}>Canceled by User</label>
            )}
          </TableText>
        </Row>
        <Row>
          <BigHeader2 style={{ color: '#668EA4' }}>
            Amount = {this.props.record?.amountUse} Baht
          </BigHeader2>
        </Row>
        <Row>
          <Col style={{ alignItems: 'flex-start', width: '50%' }}>
            <LilHeader2>Medical Invoice</LilHeader2>
            <a
              style={{ position: 'relative' }}
              href={this.props.record?.medicalInvoiceURL}
              target="_blank"
            >
              <img
                src={this.props.record?.medicalInvoiceURL}
                alt="avatar"
                height={100}
                width={100}
              />
            </a>
          </Col>
          <Col style={{ alignItems: 'flex-start', width: '50%' }}>
            <LilHeader2>Medical Certificate</LilHeader2>
            <a
              style={{ position: 'relative' }}
              href={this.props.record?.medicalCertificateURL}
              target="_blank"
            >
              <img
                src={this.props.record?.medicalCertificateURL}
                alt="avatar"
                height={100}
                width={100}
              />
            </a>
          </Col>
        </Row>
        <Break />
        {this.props.record?.requestStatusName === 'Pending' ? (
          this.state.isAdmin ? (
            <>
              <Row
                style={{
                  padding: '10px 10px 0 10px',
                }}
              >
                <TextArea
                  value={this.state.remarkText}
                  onChange={this.onChangeRemarkText}
                  placeholder="Description"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Row>
              <Row
                style={{ justifyContent: 'space-between', margin: '24px 0' }}
              >
                <ButtonBlock width="100px">
                  <RejectedButton
                    width="100px"
                    onClick={() => this.onFinishMedicalClaimByAdmin(300)}
                    disabled={!this.state.remarkText}
                  >
                    Reject
                  </RejectedButton>
                </ButtonBlock>
                <ButtonBlock width="100px">
                  <NormalButton
                    width="100px"
                    onClick={() => this.onFinishMedicalClaimByAdmin(200)}
                  >
                    Approve
                  </NormalButton>
                </ButtonBlock>
              </Row>
            </>
          ) : (
            <Row style={{ justifyContent: 'start' }}>
              <ButtonBlock width="100px">
                <RejectedButton
                  width="100px"
                  onClick={this.onFinishMedicalClaimByUser}
                >
                  Cancel Reqeust
                </RejectedButton>
              </ButtonBlock>
            </Row>
          )
        ) : this.props.record?.requestStatusName !== 'Canceled by User' ? (
          <>
            <Row>
              <Col>
                <Circle
                  style={{
                    height: '45px',
                    width: '45px',
                  }}
                  src={this.props.record?.updateUserImage}
                ></Circle>
              </Col>
              <Col
                style={{
                  paddingLeft: '15px',
                  alignItems: 'flex-start',
                  width: '60%',
                }}
              >
                <LilHeader>
                  {this.props.record
                    ? this.props.record.requestStatusName === 'Approved'
                      ? 'Approved by'
                      : this.props.record.requestStatusName ===
                        'Canceled by Approver'
                      ? 'Canceled by'
                      : 'Rejected by'
                    : ''}
                </LilHeader>
                <BigHeader2>
                  {this.props.record?.updateUserInfo.firstnameEn}{' '}
                  {this.props.record?.updateUserInfo.lastnameEn}
                </BigHeader2>
                <Row style={{ width: '100%' }}>
                  <LilHeader>
                    {moment(this.props.record?.updateDatetime).format(
                      'DD MMMM YYYY, HH:mm',
                    )}
                  </LilHeader>
                </Row>
              </Col>
            </Row>
            <Row style={{ paddingTop: '10px' }}>
              <LilHeader2>Description</LilHeader2>
            </Row>
            <Row>
              <LilHeader>{this.props.record?.remark}</LilHeader>
            </Row>
          </>
        ) : (
          <></>
        )}
      </Modal>
    );
  }
}

export default EditMedicalClaimModal;
