import {
  InboxOutlined,
  SettingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Col, Form, Row, Select, Spin, Upload, message } from 'antd';
import moment from 'moment';
import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import { AuthorizeRoleService } from '../../services/api';
import { redirectTo } from '../../services/redirect';

const ContentBox = styled.div`
  background: #f6f6f6;
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: auto;
  min-height: calc(100vh - 7em);
  justify-content: flex-start;
  padding-top: 0.5em;
`;

const Label = styled.div`
  font-family: 'Kanit';
  font-size: 16px;
  font-weight: 500;
  width: 11rem;
  min-width: 11rem;
  color: black;
  @media (max-width: 1424px) {
    width: 100%;
    min-width: none;
  }
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  background-color: ${(props) =>
    props.white ? '#FFFFFF  !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.white ? '#004368  !important' : '#004368 !important'};
  justify-content: center;
  color: ${(props) => (props.white ? '#004368' : 'white')};
  font-family: Inter;
  font-style: Regular;
  font-size: 14px;
  line-height: 19.42px;
  text-align: center;
  vertical-align: middle;
  padding: 5px 15px;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;

const TextCard = styled.div`
  height: auto;
  width: 100%;
  background-color: white;
  margin-top: 2em;
  box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 40px 50px;
`;

class SalaryUploadPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      isLoading: false,
      isUpload: false,
      options: ['All'],
      selectedOption: 'All',
      file: null,
    };
  }
  formRef = createRef();

  beforeUpload = (file) => {
    this.setState({
      fileList: [file],
      options: ['All'],
      selectedOption: 'All',
    });
  };

  customRequest = ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
    this.setState({
      isUpload: true,
      file: file,
    });
    AuthorizeRoleService.uploadSalaryExcel(
      formData,
      ({ data }) => {
        this.setState({
          isUpload: false,
          options: ['All', ...data],
          selectedOption: 'All',
        });
        onSuccess('ok');
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
        this.setState({
          isUpload: false,
          options: ['All'],
          selectedOption: 'All',
          file: null,
        });
        onError(response);
      },
    );
  };

  handleChange = ({ file }) => {
    this.setState({
      fileList: file.status === 'removed' ? [] : [file],
    });
  };

  handleRedirect = (path) => {
    redirectTo(path);
  };

  onRemoveFile = () => {
    this.setState({
      options: ['All'],
      selectedOption: 'All',
      file: null,
    });
  };

  onFinishSalaryUpload = (value) => {
    const formData = new FormData();
    formData.append('file', this.state.file);
    formData.append('month', value.month + 1);
    formData.append('year', value.year);
    formData.append('empCode', this.state.selectedOption.split(':')[0]);
    this.setState({
      isLoading: true,
    });
    AuthorizeRoleService.addExcelRecord(
      formData,
      ({ data }) => {
        this.setState({
          fileList: [],
          isLoading: false,
          options: ['All'],
          selectedOption: 'All',
        });
        message.success('Upload success');
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
        this.setState({
          isLoading: false,
        });
      },
    );
  };

  render() {
    const monthList = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const defaultMonth = moment().month();
    const defaultYear = moment().year();
    return (
      <div style={{ width: 'auto' }}>
        <Spin spinning={this.state.isLoading}>
          <ContentBox>
            <TextCard>
              <Form ref={this.formRef} onFinish={this.onFinishSalaryUpload}>
                <Row justify="end" gutter={[16, 16]}>
                  <Col flex="none">
                    <NormalButton
                      white
                      style={{
                        width: '128px',
                        alignItems: 'center',
                        marginBottom: '20px',
                      }}
                      onClick={() => this.handleRedirect('/payslip/setting')}
                    >
                      <SettingOutlined style={{ fontSize: '16px' }} />
                      Settings
                    </NormalButton>
                  </Col>
                </Row>
                <Row gutter={[64, 16]} style={{ marginBottom: '24px' }}>
                  <Col
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    style={{ width: '100%' }}
                  >
                    <Row gutter={16}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                        <Label>EmpCode-Name</Label>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 16 }}
                      >
                        <Select
                          style={{
                            width: '100%',
                          }}
                          disabled={this.state.options.length === 1}
                          value={this.state.selectedOption}
                          onChange={(value) => {
                            this.setState({
                              selectedOption: value,
                            });
                          }}
                        >
                          {this.state.options.map((option) => (
                            <Select.Option value={option}>
                              {option}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={[64, 16]}>
                  <Col
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    style={{ width: '100%' }}
                  >
                    <Row gutter={16}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                        <Label>Year</Label>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 16 }}
                      >
                        <Form.Item
                          name="year"
                          style={{ width: '100%', height: '2rem' }}
                          initialValue={defaultYear}
                        >
                          <Select
                            style={{
                              width: '100%',
                            }}
                            defaultValue={defaultYear}
                          >
                            <Select.Option value={defaultYear - 1}>
                              {defaultYear - 1}
                            </Select.Option>
                            <Select.Option value={defaultYear}>
                              {defaultYear}
                            </Select.Option>
                            <Select.Option value={defaultYear + 1}>
                              {defaultYear + 1}
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    style={{ width: '100%' }}
                  >
                    <Row gutter={16}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                        <Label>Month</Label>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 16 }}
                      >
                        <Form.Item
                          style={{ width: '100%', height: '2rem' }}
                          name="month"
                          initialValue={defaultMonth}
                        >
                          <Select
                            style={{
                              width: '100%',
                            }}
                            defaultValue={defaultMonth}
                          >
                            {monthList.map((value, index) => (
                              <Select.Option value={index}>
                                {value}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Form.Item>
                  <Upload.Dragger
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    maxCount={1}
                    fileList={this.state.fileList}
                    onChange={this.handleChange}
                    beforeUpload={this.beforeUpload}
                    onRemove={this.onRemoveFile}
                    customRequest={this.customRequest}
                    disabled={this.state.isUpload}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                  </Upload.Dragger>
                </Form.Item>
                <Row justify="end" gutter={[16, 16]}>
                  <Col flex="none">
                    <NormalButton
                      style={{
                        width: '128px',
                        alignItems: 'center',
                      }}
                      onClick={() => this.formRef.current.submit()}
                    >
                      <UploadOutlined style={{ fontSize: '16px' }} />
                      Upload
                    </NormalButton>
                  </Col>
                </Row>
              </Form>
            </TextCard>
          </ContentBox>
        </Spin>
      </div>
    );
  }
}

export default SalaryUploadPage;
