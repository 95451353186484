import React, { Component, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import {
  Button,
  Input,
  Table,
  Row,
  message,
  Form,
  Badge,
  Popover,
  Popconfirm,
  Modal,
  Select,
} from 'antd';
import binIcon from '../../assets/icons/bin.svg';
import { PlusOutlined } from '@ant-design/icons';
import { ProjectService } from '../../services/api';
import { redirectTo } from '../../services/redirect';
import { SearchOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { AppContext } from '../../components/context/AppContext';

import { NavLink } from 'react-router-dom';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import statusActiveIcon from '../../assets/icons/statusActive.svg';
import statusInactiveIcon from '../../assets/icons/statusInactive.svg';

//#region
const TitleBox = styled.div`
  box-sizing: border-box;
  padding: 20px 3% 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  justify-content: space-between;
  align-items: center;
`;
const FilterBox = styled.div`
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  // height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.div`
  color: ${(props) => (props.title ? '#232323' : '#232323')};
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1.125em' : '16px'};
  font-weight: ${(props) =>
    props.title ? 'bold' : props.subtitle ? '600' : '500'};
  font-family: 'Kanit';
  text-transform: ${(props) => (props.subtitle ? 'uppercase' : 'none')};
`;
const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};

  border-radius: 5px;

  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#2d95a8 !important'};
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Kanit;
  font-style: Regular;
  font-size: 14px;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;

const SearchInput = styled(Input.Search)``;

const StatusSelect = styled(Select)`
  width: 10rem;
  border-radius: 5px;
`;

const StatusOption = styled(Select.Option)`
  .ant-select-item {
    color: red;
  }

  .ant-select-item
    .ant-select-item-option
    .custom-option
    .ant-select-item-option-active {
    background-color: white !important;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: white !important;
  }
  .ant-select-item-option-content:hover {
    font-weight: bold;
  }
`;

const StatusCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #004368;
  }
  fontsize: '14px';
  font-family: 'Kanit';
`;

const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: end;
  align-items: center;
`;
const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#ffffff')};
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  /* flex-direction: row; */
  /* align-items: center; */
  width: auto;
  min-height: calc(100vh - 199px);
`;

const TableText = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.isKey ? '#004368' : '#737373'};
  font-size: 1em;
  font-weight: ${(props) =>
    props.title ? '600' : props.isKey ? '500' : '300'};
  font-family: 'Kanit';
  line-height: 22px;
  text-align: left;
`;

const ProjectTable = styled(Table)`
  //Header
  & .ant-table.ant-table-small .ant-table-thead > tr > th {
    font-size: 16px;
    font-weight: bold;
    background-color: white;
    padding-bottom: 20px;
  }

  &.table-striped-rows tr > th {
    background-color: #ffffff;
    font-weight: bold;
  }

  &.ant-table
    > ul.ant-pagination.ant-pagination-mini
    > li.ant-pagination-total-text {
    color: red;
  }
`;

const Rows = styled(Row)`
  margin: 3px 0px;
`;

const { Search } = Input;
const { confirm } = Modal;
//#endregion

const Project = () => {
  const [data, setData] = useState([]);
  const [datamain, setDataMain] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [type, setType] = useState('2');
  const [searchWord, setSearchWord] = useState();
  const [progress, setProgress] = useState(['1']);

  const [filterFormRef] = Form.useForm();

  const { userState } = useContext(AppContext);
  const [userCurrentRole, setUserCurrentRole] = useState(userState.currentRole);
  const [status, setStatus] = useState('active');

  var jwt = require('jsonwebtoken');
  var token = sessionStorage.getItem('access-token');
  var decode1 = jwt.decode(token);
  const userId = decode1?.userId;

  const defaultStringCompare = (property) => (a, b) =>
    String(a[property]).localeCompare(String(b[property]));

  const columns = [
    {
      key: 'projectNo',
      title: 'PROJECT NO.',
      dataIndex: 'projectNo',
      width: '12%',
      fixed: 'left',
      align: 'center',
      sorter: defaultStringCompare('projectNo'),
      render: (value) => <TableText>{value}</TableText>,
    },
    {
      key: 'projectName',
      title: 'PROJECT NAME',
      dataIndex: 'projectName',
      width: '45%',
      align: 'center',
      sorter: defaultStringCompare('projectName'),
      render: (name, record) => (
        <NavLink to={'/project/projectform/?id=' + record.id}>
          <TableText isKey>{name}</TableText>
        </NavLink>
      ),
    },
    {
      key: 'customer',
      title: 'CUSTOMER',
      dataIndex: 'customerName',
      width: '15%',
      align: 'center',
      sorter: defaultStringCompare('customerName'),
      render: (value) => <TableText>{value}</TableText>,
    },
    {
      key: 'progress',
      title: 'PROGRESS',
      dataIndex: 'projectProgressId',
      align: 'center',
      width: '12%',
      sorter: (a, b) => a.isActive - b.isActive,
      render: (value) => (
        /* <TableText
          style={{
            color:
              value === 1 ? '#004368' : value === 2 ? '#49AA19' : '#B2E58B',
          }}
        >
          {value === 1 ? 'New' : value === 2 ? 'In Progress' : 'Done'}
        </TableText> */
        <Popover
          content={
            <span style={{ display: 'flex' }}>
              <img
                src={value === 3 ? statusInactiveIcon : statusActiveIcon} // 1 new
                style={{ marginRight: '5px' }}
              />
              <span>{value === 3 ? 'Done' : 'In Progress'}</span>
            </span>
          }
          arrowPointAtCenter
        >
          <img
            src={value === 3 ? statusInactiveIcon : statusActiveIcon}
            style={{ marginRight: '5px' }}
          />
        </Popover>
      ),
    },
    {
      title: 'ACTION',
      align: 'center',
      width: '7%',
      render: (key, record) =>
        userCurrentRole === 'ADMIN' && record.isDeletable ? (
          <>
            <Popconfirm
              placement="topRight"
              title="Are you sure to delete?"
              okButtonProps={{
                style: {
                  backgroundColor: '#004368',
                  borderColor: '#2d95a8',
                },
              }}
              onConfirm={() => {
                handleDeleteProject(record.id);
              }}
              onCancel={() => {}}
            >
              <img style={{ cursor: 'pointer' }} src={binIcon} />
            </Popconfirm>
          </>
        ) : (
          ''
        ),
    },
  ];

  const fetchProjects = (filter) => {
    setIsLoading(true);
    if (userCurrentRole === 'ADMIN') {
      ProjectService.fetchProjectList(
        {},
        ({ data }) => {
          setIsLoading(false);
          setDataMain(data);
          const filteredData = data.filter((data) => {
            if (type != 'All') {
              return data.projectProgressId == type;
            } else {
              return true;
            }
          });
          setData(filteredData);
        },
        (response) => {
          setIsLoading(false);
          setData(null);

          if (response && response.status === 400) {
            message.error(`Cannot access data - ${response.data.message}`);
          } else {
            message.error('Error');
          }
        },
      );
    } else {
      ProjectService.fetchProjectListByUser(
        ({ data }) => {
          setIsLoading(false);
          setDataMain(data);
          const filteredData = data.filter((data) => {
            if (type != 'All') {
              return data.projectProgressId == type;
            } else {
              return true;
            }
          });
          setData(filteredData);
        },
        (response) => {
          setIsLoading(false);
          setData(null);

          if (response && response.status === 400) {
            message.error(`Cannot access data - ${response.data.message}`);
          } else {
            message.error('Error');
          }
        },
      );
    }
    getProjectProgess();
  };

  useEffect(() => {
    fetchProjects();
  }, [userCurrentRole]);

  useEffect(() => {
    setUserCurrentRole(userState.currentRole);
  }, [userState]);

  const showDeleteConfirmation = (payload, record) => {
    confirm({
      title: 'Do you want to delete this project?',
      content: `Project: ${record.projectName}`,
      onOk: () => {
        handleDeleteProject(record.id);
      },
      onCancel: () => {},
    });
  };

  const handleDeleteProject = (id) => {
    ProjectService.deleteProject(
      id,
      ({ data }) => {
        let secondsToGo = 1;
        message.success('Delete project successfully.');
        const timer = setInterval(() => {
          secondsToGo -= 1;
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          // redirectTo(`/customer`);
          fetchProjects();
        }, secondsToGo * 1000);
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Error: ' + response.data.message);
        }
      },
    );
  };

  const getProjectProgess = () => {
    ProjectService.progessProject(
      (data) => {
        // setProgress(data.data);
        setProgress(
          data.data.filter((item) => {
            return item.progressName !== 'New';
          }),
        );
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Error: ' + response.data.message);
        }
      },
    );
  };

  const onTypeFilterChange = (value) => {
    setType(value);
    switch (value) {
      case 'All':
        setStatus('both');
        break;
      case '2':
      case 2:
        setStatus('active');
        break;
      case '3':
      case 3:
        setStatus('inactive');
        break;
      default:
        setStatus('not selected');
        break;
    }
    filterChange(searchWord, value);
  };

  const onSearch = (value) => {
    setSearchWord(value);
    filterChange(value, type);
  };

  const onActiveStatusFilterChange = (value) => {
    if (status === 'both') {
      onTypeFilterChange('3');
    } else if (status === 'inactive') {
      onTypeFilterChange('All');
    } else if (status === 'active') {
      onTypeFilterChange('');
    } else {
      // not selected
      onTypeFilterChange('2');
    }
  };

  const onInActiveStatusFilterChange = (value) => {
    if (status === 'both') {
      onTypeFilterChange('2');
    } else if (status === 'active') {
      onTypeFilterChange('All');
    } else if (status === 'inactive') {
      onTypeFilterChange('');
    } else {
      // not selected
      onTypeFilterChange('3');
    }
  };

  const filterChange = (search, type) => {
    setData(
      datamain
        .filter((data) => {
          if (!search) {
            return true;
          } else {
            return (
              String(data.projectNo)
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              String(data.projectName)
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              String(data.customerName)
                .toUpperCase()
                .includes(search.toUpperCase())
            );
          }
        })
        .filter((data) => {
          if (type != 'All' && type != '') {
            return data.projectProgressId == type;
          } else if (type == '') {
            return true;
          } else {
            return true;
          }
        }),
    );
  };

  return (
    <div style={{ width: 'auto' }}>
      <TitleBox>
        <Text>All Project</Text>
        <ButtonBlock width="200px">
          {userCurrentRole === 'ADMIN' && (
            <NavLink
              to={`./project/projectform/`}
              onClick={() => {
                sessionStorage.removeItem('add-projectform-state');
              }}
            >
              {/*<NormalButton width='150px' size='large' icon={<PlusOutlined />}>*/}
              <NormalButton width="150px" icon={<PlusOutlined />}>
                Create Project
              </NormalButton>
            </NavLink>
          )}
        </ButtonBlock>
      </TitleBox>
      <FilterBox>
        <Form
          component={false}
          id="searchform"
          width="auto"
          form={filterFormRef}
          onFinish={fetchProjects}
        >
          <Rows>
            <StatusCheckbox
              checked={status === 'active' || status === 'both'}
              onChange={onActiveStatusFilterChange}
            >
              Active
            </StatusCheckbox>
            <StatusCheckbox
              checked={status === 'inactive' || status === 'both'}
              onChange={onInActiveStatusFilterChange}
            >
              Inactive
            </StatusCheckbox>
          </Rows>
          <Rows align="middle" justify="right">
            <SearchInput
              //size='large'
              placeholder="Search"
              /* onSearch={(value) => onSearch(value)} */
              onChange={(e) => onSearch(e.target.value)}
              style={{ width: 230 }}
            />
            <StatusSelect
              defaultValue={'All'}
              //size='large'
              style={{ margin: '1rem 0 1rem 1rem', width: 230 }}
              onChange={(value) => {
                onTypeFilterChange(value);
              }}
            >
              <StatusOption value="All" key="All">
                All Progress
              </StatusOption>
              {progress.map((item) => {
                return (
                  <>
                    <StatusOption value={item.id}>
                      {String(item.progressName)}
                    </StatusOption>
                  </>
                );
              })}
            </StatusSelect>
          </Rows>
        </Form>
      </FilterBox>
      <ContentBox>
        <ProjectTable
          className="table-striped-rows"
          columns={columns}
          scroll={{ x: 1300 }}
          dataSource={data}
          rowKey="id"
          style={{ width: '100%' }}
          size="small"
          indentSize={1}
          loading={isLoading}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40'],
            locale: { items_per_page: '/ page' },
            position: ['bottomRight'],
          }}
        />
      </ContentBox>
    </div>
  );
};

export default Project;
