import React, { Component } from 'react';
import styled from 'styled-components';
import { message, Button, Spin, Row, Col } from 'antd';
import { AnnounceService } from '../../services/api';
import htmlParser from 'html-react-parser';
import moment from 'moment';
import { redirectTo } from '../../services/redirect';

import { LeftOutlined } from '@ant-design/icons';

// #region Component Styles
const TitleBox = styled.div`
  box-sizing: border-box;
  padding-left: 6%;
  padding-right: 6%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  height: 6.875em;
  justify-content: space-between;
`;
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#004368' : props.subtitle ? '#20ABC5' : '#565656'};
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1.625em' : '1em'};
  font-weight: ${(props) =>
    props.title
      ? 'bold'
      : props.subtitle
      ? '600'
      : props.name
      ? '500'
      : 'normal'};
  font-family: 'Kanit';
`;
const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  background-color: ${(props) =>
    props.grey
      ? '#C6C6C6 !important'
      : props.white
      ? '#FFFFFF !important'
      : '#004386 !important'};
  border-color: ${(props) =>
    props.grey
      ? '#C6C6C6 !important'
      : props.white
      ? '#D9D9D9 !important'
      : '#004386 !important'};
  justify-content: center;
  color: ${(props) =>
    props.grey ? '#737373' : props.white ? '#232323 !important' : 'white'};
  font-family: Inter;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 5px 15px;
  align-items: center;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;
const ContentBox = styled.div`
  background: #f6f6f6;
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: auto;
  min-height: calc(100vh - 7em);
  justify-content: flex-start;
  padding-top: 0.5em;
`;
const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;
const TextCard = styled.div`
  height: auto;
  width: 100%;
  background-color: white;
  margin-top: 2em;
  box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 40px 50px;
`;
const Horizontalbox = styled.div`
  display: flex;
  @media (max-width: 714px) {
    flex-direction: column;
  }
`;
const FlexBox = styled.div`
  width: 50%;
  display: flex;
  padding-right: 2rem;

  box-sizing: border-box;
  margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: row;
  }
  @media (max-width: 714px) {
    padding-right: 0;
    width: 100%;
  }
`;
const Label = styled.div`
  font-family: 'Kanit';
  font-size: 16px;
  font-weight: 500;
  width: 11rem;
  min-width: 11rem;
  color: black;
  @media (max-width: 1424px) {
    width: 100%;
    min-width: none;
  }
  &.required::after {
    content: '*';
    color: red;
  }
`;

const ShowAnnounceContent = styled.div`
  color: #232323;
  padding: 1rem;
  height: 2.5em;
  align-items: center;
  display: flex;
  font-family: 'Kanit';
  font-size: 16px;
  font-weight: 500;
  min-width: 11rem;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  margin-bottom: 5px;
  height: auto;
`;

const ViewData = styled.div`
  width: 100%;
  height: 2rem;
  font-family: 'Kanit';
  font-size: 16px;
`;

const Box = styled.div`
  box-sizing: border-box;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 4.9375rem;
  padding-bottom: 5%;
  background: #f6f6f6;
`;

const StyledSpin = styled(Spin)`
  position: fixed;
  text-align: center;
  bottom: 50%;
  z-index: 10;
`;
const SpinBox = styled(Box)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 5;
`;

export default class AnnounceFormEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {},
      isLoading: true,
    };
  }
  id = new URLSearchParams(this.props.location.search).get('id');
  checkId = () => {
    if (!this.id) {
      redirectTo('/announce');
    }
  };
  getAnnounceDetail = () => {
    const announcementId = this.id;
    if (!announcementId) return false;
    AnnounceService.getAnnouncement(
      announcementId,
      ({ data }) => {
        if (data[0].endDate !== undefined) {
          //reset endDate api 9999 to undefined
          const endDate = data[0].endDate;
          if (moment(endDate).year() === 9999) {
            data[0].endDate = undefined;
          }
        }
        this.setState({
          initialValues: {
            ...data[0],
            startDate: data[0]?.startDate
              ? moment(data[0].startDate).add(7, 'hours')
              : null,
            endDate: data[0]?.endDate
              ? moment(data[0]?.endDate).add(7, 'hours')
              : null,
            type: data[0]?.type,
          },
          isLoading: false,
        });
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          if (response.data.message != 'empty array') {
            message.error('Cannot access data: ' + response.data.message);
          }
        }
      },
    );
  };

  handleCancel = () => {
    redirectTo('/announce');
  };

  componentDidMount() {
    this.checkId(); //for case admin change to user when enter create announce
    this.getAnnounceDetail();
  }

  render() {
    return this.state.isLoading ? (
      <SpinBox>
        <StyledSpin size="large" />
      </SpinBox>
    ) : (
      <div style={{ width: 'auto' }}>
        <TitleBox>
          <Text
            title
            style={{
              color: '#232323',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            View Announce News
          </Text>
          <ButtonBlock width="100px">
            <NormalButton
              white
              form="user-form"
              width="96px"
              onClick={() => {
                this.handleCancel();
              }}
              //style={{ height: '40px' }}
            >
              <LeftOutlined /> Back
            </NormalButton>
          </ButtonBlock>
        </TitleBox>
        <ContentBox>
          <TextCard style={{ marginBottom: '2.5em', paddingBottom: '1em' }}>
            <Row gutter={[32, 16]}>
              <Col
                sm={{ span: 24 }}
                md={{ span: 12 }}
                style={{ width: '100%' }}
              >
                <Row gutter={16}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    <Label>Announce Topic</Label>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 16 }}>
                    <ViewData
                      style={{
                        width: '100%',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {/*this.state.initialValues?.announcementName.length > 25
                    ? this.state.initialValues?.announcementName
                      .substring(0, 24)
                      .concat('...')
            : this.state.initialValues?.announcementName*/}
                      {this.state.initialValues?.announcementName}
                    </ViewData>
                  </Col>
                </Row>
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 12 }}
                style={{ width: '100%' }}
              >
                <Row gutter={16}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    <Label>Start Date</Label>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 16 }}>
                    <ViewData>
                      {this.state.initialValues
                        ? moment(this.state.initialValues?.startDate).format(
                            'YYYY-MM-DD',
                          )
                        : null}
                    </ViewData>
                  </Col>
                </Row>
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 12 }}
                style={{ width: '100%' }}
              >
                <Row gutter={16}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    <Label>Type</Label>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 16 }}>
                    <ViewData>{this.state.initialValues?.type}</ViewData>
                  </Col>
                </Row>
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 12 }}
                style={{ width: '100%' }}
              >
                <Row gutter={16}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    <Label>End Date</Label>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 16 }}>
                    <ViewData>
                      {this.state.initialValues?.endDate
                        ? moment(this.state.initialValues?.endDate).format(
                            'YYYY-MM-DD',
                          )
                        : null}
                    </ViewData>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <ShowAnnounceContent>
                  <span style={{ overflowWrap: 'break-word' }}>
                    {this.state.initialValues?.announcementDetail
                      ? htmlParser(this.state.initialValues.announcementDetail)
                      : ''}
                  </span>
                </ShowAnnounceContent>
              </Col>
            </Row>
          </TextCard>
        </ContentBox>
      </div>
    );
  }
}
