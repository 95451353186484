import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import { Row, Form, Divider, message, Select, Button, Modal } from 'antd';
import { AuthorizeRoleService } from '../../services/api';
import { DatePicker, Input } from 'antd';

const Break = styled(Divider)`
  margin: 12px 0;
`;

const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: end;
  align-items: center;
`;

const FormLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const HeadDetail = styled.div`
  font-family: 'Kanit';
  font-size: 18px;
  font-weight: 500;
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  border-radius: 5px;
  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Kanit;
  font-style: Regular;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid white !important;
    background-color: #f5f5f5 !important;
  }
`;

const RejectedButton = styled(Button)`
  min-width: 106px;
  border-radius: 5px;
  display: flex;
  background-color: white;
  border: 1px solid #f2f2f2;
  justify-content: center;
  color: #232323;
  font-family: Kanit;
  font-style: Regular;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 5px 15px;
  align-items: center;
  &:disabled {
    border: 1px solid white !important;
  }
`;

class RequestPayslipModal extends Component {
  payslipFormRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      payslipType: [],
      isLoading: false,
      requestSlipModalVisible: false,
      selectedPayslipId: null,
      remark: null,
    };
  }

  componentDidMount = () => {
    this.fetchPayslipType();
  };

  fetchPayslipType = () => {
    AuthorizeRoleService.getPayslipType(
      ({ data }) => {
        this.setState({ payslipType: data });
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  onCancelPayslipRequest = () => {
    this.payslipFormRef.current.setFieldsValue({
      index: null,
    });
    this.props.onCancel();
  };

  onChangePayslipType = (index) => {
    const record = this.state.payslipType[index];
    this.setState({
      selectedPayslipId: record.id,
      remark: record.remark,
    });
  };

  onFinishPayslipRequest = (value) => {
    value.payslipTypeId = this.state.selectedPayslipId;
    if (value.dateRange) {
      value.startDate = value.dateRange[0];
      value.endDate = value.dateRange[1];
    }
    AuthorizeRoleService.addPayslipRequest(
      value,
      ({ data }) => {
        message.success('Success');
        setTimeout(() => {
          this.props.onFetch();
        }, 1000);
        this.onCancelPayslipRequest();
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  render() {
    return (
      <Modal
        width="450px"
        bodyStyle={{
          maxHeight: '800px',
        }}
        open={this.props.open}
        centered
        footer={null}
        onCancel={this.onCancelPayslipRequest}
        maskClosable
      >
        <Form ref={this.payslipFormRef} onFinish={this.onFinishPayslipRequest}>
          <Row>
            <HeadDetail>Create Request</HeadDetail>
          </Row>
          <Break />
          <FormLabel>Salary Certificate Type</FormLabel>
          <Form.Item
            name="index"
            rules={[
              {
                required: true,
                message: 'Please select payslip type',
              },
            ]}
          >
            <Select
              placeholder="Select payslip type"
              onChange={this.onChangePayslipType}
            >
              {this.state.payslipType.map((type, index) => (
                <Select.Option value={index}>{type.payslipName}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Break />
          {this.state.remark === 'VISA' && (
            <>
              <FormLabel>Country</FormLabel>
              <Form.Item
                name="country"
                rules={[
                  {
                    required: true,
                    message: 'Please input your country',
                  },
                ]}
              >
                <Input placeholder="Country" />
              </Form.Item>
              <FormLabel>Pick Date</FormLabel>
              <Form.Item
                name="dateRange"
                rules={[
                  {
                    required: true,
                    message: 'Please input leave date.',
                  },
                ]}
              >
                <DatePicker.RangePicker format="DD/MM/YYYY" />
              </Form.Item>
              <Break />
            </>
          )}
          <Row style={{ justifyContent: 'space-between' }}>
            <ButtonBlock width="100px">
              <RejectedButton
                width="100px"
                onClick={this.onCancelPayslipRequest}
              >
                Cancel
              </RejectedButton>
            </ButtonBlock>
            <ButtonBlock width="100px">
              <NormalButton
                width="100px"
                onClick={() => this.payslipFormRef.current.submit()}
              >
                Submit
              </NormalButton>
            </ButtonBlock>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default RequestPayslipModal;
