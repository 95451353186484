import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Layout,
  Button,
  Table,
  Space,
  Row,
  Col,
  message,
  Select,
  Form,
  Input as AntInput,
} from 'antd';
import binIcon from '../../assets/icons/bin.svg';

import { ItemService } from '../../services/api';
import { redirectTo } from '../../services/redirect';

//#region
const { Header, Content, Footer } = Layout;
const TitleBox = styled.div`
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  height: 6.875em;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.subtitle ? '#20ABC5' : '#565656'};
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1.625em' : '1em'};
  font-weight: ${(props) =>
    props.title ? 'bold' : props.subtitle ? '600' : '500'};
  font-family: 'Kanit';
`;
const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#2d95a8 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#2d95a8 !important'};
  justify-content: center;
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Inter;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 5px 15px;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;
const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;
const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#f6f6f6')};
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  /* flex-direction: row; */
  /* align-items: center; */
  width: auto;
  min-height: calc(100vh - 7.5em);
`;
const Input = styled.input`
  height: 35px;
  width: 100%; /* max-width: 300px; */
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0px 11px;
  font-family: Inter;
  font-style: normal;
  font-size: 1em;
  color: #737373;
  outline: none;
  &:focus {
    border-color: #20abc5;
  }
`;
const MyForm = styled.form`
  padding: 20px 5px;
  height: auto;
`;
const Cols = styled(Col)`
  display: flex;
  align-items: center;
`;
const Rows = styled(Row)`
  margin: 10px 0px;
  align-items: baseline;
`;
const TextCard = styled.div`
height: auto;
width: 100%;
background - color: white;
margin - top: 2em;
box - shadow: 0px 0px 21px rgba(0, 0, 0, 0.11);
border - radius: 5px;
padding: 40px 50px;
`;
const { TextArea } = AntInput;
const { Option } = Select;
const Required = styled.span`
padding - left: 5px;
color: #eb5757;
font - family: Inter;
font - style: normal;
font - weight: 500;
font - size: 16px;
`;
//#endregion

class AddItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }
  //#region
  handleCancel = (e) => {
    redirectTo('/./item');
  };
  handleOk = (values) => {
    const payload = {
      item_code: values.code,
      item_type: values.type,
      item_name: values.name,
      item_descriptions: values.description == null ? '' : values.description,
      price_per_unit: values.price,
      unit: 'manday',
      sow: values.sow == null ? '' : values.sow,
      deliverable: values.deliverable == null ? '' : values.deliverable,
    };
    console.log(payload);
    ItemService.addItems(
      payload,
      ({ data }) => {
        console.log(data);
        if (data.message == 'Add Items is success') {
          // this.setState({
          //   visible: true,
          // });
          message.success('Add Items is success', 0.5, this.handleCancel);
        }
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Error' + ' - ' + response.data.message);
        }
      },
    );
  };
  formRef = React.createRef();
  onTypeChange = (value) => {
    this.formRef.current.setFieldsValue({
      code: value + '-',
    });
  };
  //#endregion
  render() {
    return (
      <div style={{ width: 'auto' }}>
        <TitleBox>
          <Text title>Add Item</Text>
          <ButtonBlock width="200px">
            <NormalButton grey width="96px" href="../item">
              Cancel
            </NormalButton>
            <NormalButton width="96px" form="item-form" htmlType="submit">
              Submit
            </NormalButton>
          </ButtonBlock>
        </TitleBox>
        <ContentBox>
          <TextCard>
            <Text subtitle>Item Information</Text>
            <MyForm>
              <Form id="item-form" ref={this.formRef} onFinish={this.handleOk}>
                <Rows>
                  <Cols span={5}>
                    <Text>
                      Item Type<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={7}>
                    <Form.Item
                      name={['type']}
                      initialValue="OS"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      style={{ width: '100%', height: '35px' }}
                    >
                      <Select defaultValue="OS" onChange={this.onTypeChange}>
                        <Option value="OS">OS</Option>
                        <Option value="TK">TK</Option>
                      </Select>
                    </Form.Item>
                  </Cols>
                </Rows>
                <Rows>
                  <Cols span={5}>
                    <Text>
                      Item Code<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={7}>
                    <Form.Item
                      name={['code']}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      style={{ width: '100%' }}
                    >
                      <Input defaultValue="OS-"></Input>
                    </Form.Item>
                  </Cols>
                </Rows>
                <Rows>
                  <Cols span={5}>
                    <Text>
                      Item Name<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={7}>
                    <Form.Item
                      name={['name']}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      style={{ width: '100%' }}
                    >
                      <Input></Input>
                    </Form.Item>
                  </Cols>
                </Rows>
                <Rows>
                  <Cols span={5}>
                    <Text>
                      Price Per Manday<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={7}>
                    <Form.Item
                      name={['price']}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      style={{ width: '100%' }}
                    >
                      <Input type="number" min="0"></Input>
                    </Form.Item>
                  </Cols>
                </Rows>
                <Rows>
                  <Cols span={5}>
                    <Text>Item Description</Text>
                  </Cols>
                  <Cols span={7}>
                    <Form.Item name={['description']} style={{ width: '100%' }}>
                      <TextArea
                        rows={2}
                        placeholder="Description Only"
                      ></TextArea>
                    </Form.Item>
                  </Cols>
                </Rows>
                <Rows>
                  <Cols span={5}>
                    <Text>Scope Of Work</Text>
                  </Cols>
                  <Cols span={7}>
                    <Form.Item name={['sow']} style={{ width: '100%' }}>
                      <TextArea rows={4}></TextArea>
                    </Form.Item>
                  </Cols>
                </Rows>
                <Rows>
                  <Cols span={5}>
                    <Text>Deliverable</Text>
                  </Cols>
                  <Cols span={7}>
                    <Form.Item name={['deliverable']} style={{ width: '100%' }}>
                      <TextArea rows={2}></TextArea>
                    </Form.Item>
                  </Cols>
                </Rows>
              </Form>
            </MyForm>
          </TextCard>
        </ContentBox>
      </div>
    );
  }
}
export default AddItem;
