import React, { Component } from 'react';
import styled from 'styled-components';
import { Layout, Button, Input, Table, Space, Row, Col, message } from 'antd';
import binIcon from '../../assets/icons/bin.svg';
import pdfIcon from '../../assets/icons/pdf.svg';
import revisedIcon from '../../assets/icons/revised.svg';
import { QuotaionService } from '../../services/api';
// import "./customer.css";
//#region
const { Header, Content, Footer } = Layout;
const TitleBox = styled.div`
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  height: 6.875em;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.subtitle ? '#20ABC5' : '#ff2132'};
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1.125em' : '1em'};
  font-weight: ${(props) =>
    props.title ? 'bold' : props.subtitle ? '600' : 'normal'};
  font-family: 'Kanit';
  text-transform: ${(props) => (props.subtitle ? 'uppercase' : 'none')};
`;
const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  padding: 5px 15px;
  background-color: #2d95a8 !important;
  border-color: #2d95a8 !important;
  justify-content: center;
  color: white;
  font-family: Inter;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;
const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#f6f6f6')};
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  /* flex-direction: row; */
  /* align-items: center; */
  width: auto;
  min-height: calc(100vh - 7.5em);
`;
const TableText = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.no ? '#20ABC5' : '#737373'};
  font-size: 1em;
  font-weight: ${(props) =>
    props.title ? '600' : props.no ? 'bold' : 'normal'};
  font-family: 'Kanit';
  line-height: 22px;
`;
const QuotationList = styled(Table)`
  &.table-striped-rows tr > th {
    background-color: #f6f6f6;
  }
  &.table-striped-rows tr:nth-child(2n) td {
    background-color: #f2f2f2;
  }
  &.table-striped-rows tr:nth-child(2n + 1) td {
    background-color: #f6f6f6;
  }
  &.table-striped-rows thead {
    background-color: #f1f1f1;
  }
`;
const { Search } = Input;
//#endregion

const columns = [
  {
    title: 'QUOTATION NO.',
    dataIndex: 'quotation_no',
    index: 'quotation_no',
    sorter: {
      compare: (a, b) => a.quotation_no.localeCompare(b.quotation_no),
      // multiple: 2,
    },
    sorter: {
      compare: (a, b) => a.quotation_no.localeCompare(b.quotation_no),
      // multiple: 2,
    },
    render: (quotation_no, record) => {
      if (record.revised_no == 0) {
        return <TableText>{quotation_no}</TableText>;
      }
      return (
        <TableText>
          {quotation_no}-{record.revised_no}
        </TableText>
      );
    },
  },
  {
    title: 'PROJECT NAME',
    dataIndex: 'project_name',
    index: 'name',
    sorter: {
      compare: (a, b) => a.name.localeCompare(b.name),
      // multiple: 2,
    },
    render: (name) => <TableText no>{name}</TableText>,
  },
  {
    title: 'PROJECT TYPE',
    index: 'type',
    dataIndex: 'project_type',
    sorter: {
      compare: (a, b) => a.type.localeCompare(b.type),
      // multiple: 2,
    },
    render: (type) => <TableText>{type}</TableText>,
  },
  {
    title: 'CUSTOMER',
    index: 'customer',
    dataIndex: 'customer_name',
    sorter: {
      compare: (a, b) => a.customer_name.localeCompare(b.customer_name),
      // multiple: 2,
    },
    render: (customer) => <TableText>{customer}</TableText>,
  },
  {
    title: 'PDF',
    index: 'pdf',
    dataIndex: 'index',
    render: (key) =>
      key && (
        <Row>
          <Col span={24}>
            <a>
              <img src={pdfIcon} />
            </a>
          </Col>
        </Row>
      ),
  },
  {
    title: 'ACTION',
    index: 'action',
    dataIndex: 'index',
    render: (key) =>
      key && (
        <Row>
          <Col span={12}>
            <a>
              <img src={binIcon} />
            </a>
          </Col>
          <Col span={12}>
            <a>
              <img src={revisedIcon} />
            </a>
          </Col>
        </Row>
      ),
  },
];
export default class Quotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasData: false,
      incorrect: false,
      limit: 500,
      offset: 0,
    };
  }
  handlefetchQuotationList = (value) => {
    this.setState({
      hasData: false,
    });
    const payload = {
      searchString: value,
      limit: this.state.limit,
      offset: this.state.offset,
    };
    console.log(payload);
    QuotaionService.fetchQuatationList(
      payload,
      ({ data }) => {
        console.log(data);
        this.setState({
          hasData: true,
          data: data,
        });
      },
      (response) => {
        // console.log(response.data.message);
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          if (response.data.message != 'Quotation not found') {
            message.error('Cannot access data' + ' - ' + response.data.message);
          }
        }
      },
    );
  };
  componentDidMount = () => {
    this.handlefetchQuotationList('');
  };

  render() {
    return (
      <div style={{ width: 'auto' }}>
        <TitleBox>
          <Text title>Quotaion</Text>
          <NormalButton href="./quotation/createquotation">
            +Create Quotation
          </NormalButton>
        </TitleBox>
        <TitleBox bg="#f6f6f6">
          <Text subtitle>All Quotation</Text>
          <Search
            placeholder="Search Quotation"
            onSearch={(value) => {
              this.handlefetchQuotationList(value);
            }}
            style={{ width: 200 }}
          />
        </TitleBox>
        <ContentBox>
          <QuotationList
            className="table-striped-rows"
            columns={columns}
            dataSource={
              this.state.hasData ? this.state.data.Quotation_List : null
            }
            style={{ width: '100%' }}
            indentSize={1}
            loading={!this.state.hasData && !this.state.incorrect}
            pagination={{ position: ['bottomRight'] }}
          />
        </ContentBox>
      </div>
    );
  }
}
