import axios from 'axios';
import { message } from 'antd';
import { createAuthCycle } from './authCycle';
import Certificate from '../pages/components/certificate';
import { redirectTo } from './redirect';

require('dotenv').config();

const authorizeURL = process.env.REACT_APP_API_URL;
const customerURL = process.env.REACT_APP_API_URL;
const announceURL = process.env.REACT_APP_API_URL;
const profileURL = process.env.REACT_APP_API_URL;
const quotationURL = process.env.REACT_APP_API_URL;
const timesheetURL = process.env.REACT_APP_API_URL;
const masterdataURL = process.env.REACT_APP_API_URL;
const itemURL = process.env.REACT_APP_API_URL;
const projectURL = process.env.REACT_APP_API_URL;
const authorizeRoleURL = process.env.REACT_APP_API_URL;

const authorizeRoleApi = axios.create({
  baseURL: authorizeRoleURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('access-token')}`,
  },
});
createAuthCycle(authorizeRoleApi);

const authorizeApi = axios.create({
  baseURL: authorizeURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const commonApi = axios.create({
  // baseURL: 'https://testing-env.playtorium.co.th/plays-ess-author/api',
  baseURL: authorizeURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const imageApi = axios.create({
  baseURL: profileURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('access-token')}`,
  },
});

const profileApi = axios.create({
  baseURL: profileURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('access-token')}`,
  },
});
const customerApi = axios.create({
  baseURL: customerURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('access-token')}`,
  },
});
const announceApi = axios.create({
  baseURL: announceURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('access-token')}`,
  },
});
const userApi = axios.create({
  baseURL: authorizeURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('access-token')}`,
  },
});
const masterdataApi = axios.create({
  baseURL: masterdataURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('access-token')}`,
  },
});
const quotationApi = axios.create({
  baseURL: quotationURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('access-token')}`,
  },
});
const projectApi = axios.create({
  baseURL: projectURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('access-token')}`,
  },
});
const timesheetApi = axios.create({
  baseURL: timesheetURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('access-token')}`,
  },
});

const itemApi = axios.create({
  baseURL: itemURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('access-token')}`,
  },
});

const HolidayApi = axios.create({
  baseURL: projectURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('access-token')}`,
  },
});

const LeaveApi = axios.create({
  baseURL: projectURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('access-token')}`,
  },
});

//Region: Interceptors (Request)
const setTokenToHeaders = (config) => {
  const token = sessionStorage.getItem('access-token');

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

authorizeApi.interceptors.request.use(setTokenToHeaders, (err) => {
  return Promise.reject(err);
});

profileApi.interceptors.request.use(setTokenToHeaders, (err) => {
  return Promise.reject(err);
});

customerApi.interceptors.request.use(setTokenToHeaders, (err) => {
  return Promise.reject(err);
});

announceApi.interceptors.request.use(setTokenToHeaders, (err) => {
  return Promise.reject(err);
});

userApi.interceptors.request.use(setTokenToHeaders, (err) => {
  return Promise.reject(err);
});

masterdataApi.interceptors.request.use(setTokenToHeaders, (err) => {
  return Promise.reject(err);
});

quotationApi.interceptors.request.use(setTokenToHeaders, (err) => {
  return Promise.reject(err);
});

projectApi.interceptors.request.use(setTokenToHeaders, (err) => {
  return Promise.reject(err);
});

timesheetApi.interceptors.request.use(setTokenToHeaders, (err) => {
  return Promise.reject(err);
});

itemApi.interceptors.request.use(setTokenToHeaders, (err) => {
  return Promise.reject(err);
});

HolidayApi.interceptors.request.use(setTokenToHeaders, (err) => {
  return Promise.reject(err);
});

LeaveApi.interceptors.request.use(setTokenToHeaders, (err) => {
  return Promise.reject(err);
});
//End region

//Region: Interceptors (Response)
// TODO token expire must validate from backend front should handle 401 and refresh token only
const onRejected = (error) => {
  if (error.response.status === 401) {
    sessionStorage.clear();
    let secondsToGo = 1;
    message.error('Session Expired');
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      redirectTo(`/login`);
    }, secondsToGo * 1000);
  }
  return Promise.reject(error);
};

authorizeApi.interceptors.response.use(null, onRejected);

profileApi.interceptors.response.use(null, onRejected);

customerApi.interceptors.response.use(null, onRejected);

announceApi.interceptors.response.use(null, onRejected);

userApi.interceptors.response.use(null, onRejected);

masterdataApi.interceptors.response.use(null, onRejected);

quotationApi.interceptors.response.use(null, onRejected);

projectApi.interceptors.response.use(null, onRejected);

timesheetApi.interceptors.response.use(null, onRejected);

itemApi.interceptors.response.use(null, onRejected);

HolidayApi.interceptors.response.use(null, onRejected);

LeaveApi.interceptors.response.use(null, onRejected);
//End region

// createAuthCycle(authorizedApi);

export const leaveService = {
  getleave: (payload, callback, onRejected) =>
    LeaveApi.post('/leave/getLeaveByMonth', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  getleaveByYear: (payload, callback, onRejected) =>
    LeaveApi.post('/leave/getLeaveByYear', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  getType: (callback, onRejected) =>
    LeaveApi.get('/leave/getLeaveType')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  getLeaveUser: (callback, onRejected) =>
    LeaveApi.get('/leave/fetch')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  getLeaveByUser: (callback, onRejected) =>
    LeaveApi.get('/leave/user/fetch')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  getRemainAll: (callback, onRejected) =>
    LeaveApi.get('leave/remain/fetch')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  getRemainByUserId: (payload, callback, onRejected) =>
    LeaveApi.get(`leave/remain/fetch/${payload}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  addLeave: (payload, callback, onRejected) =>
    LeaveApi.post('/leave/create', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  editLeave: (payload, callback, onRejected) => {
    LeaveApi.patch(`/leave/update`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

export const holidayService = {
  getAll: (callback, onRejected) =>
    HolidayApi.get('/holiday/getHoliday')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  getYear: (payload, callback, onRejected) =>
    HolidayApi.get(`/holiday/fetch/${payload}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addHoliday: (payload, callback, onRejected) =>
    HolidayApi.post('/holiday/create', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  editHoliday: (payload, callback, onRejected) => {
    HolidayApi.patch(`/holiday/update`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },

  deleteHoliday: (payload, callback, onRejected) => {
    HolidayApi.patch(`/holiday/delete`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

export const authentication = {
  signInWithEmail: (payload, callback, onRejected) =>
    authorizeApi
      .post('/auth/login', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  signInWithGoogle: (payload, callback, onRejected) =>
    authorizeApi
      .post('/auth/login-with-google', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  forgotPassword: (payload, callback, onRejected) =>
    authorizeApi
      .post('/auth/forgot-password', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  getNewToken: (refreshToken) =>
    authorizeApi
      .get('/auth/refreshAccessToken', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: refreshToken,
        },
      })
      .then(({ data }) => data),
  resetPassword: (payload, callback, onRejected) =>
    authorizeApi
      .post('/auth/reset-password/', payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('reset-token')}`,
        },
      })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
};

export const CustomerService = {
  fetchCustomerList: (payload, callback, onRejected) => {
    customerApi
      .post('/customers/fetchCustomerList/', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchCustomer: (payload, callback, onRejected) => {
    customerApi
      .get(`/customers/fetchCustomer/${payload}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  addCustomer: (payload, callback, onRejected) => {
    customerApi
      .post('/customers/addCustomer', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  deleteCustomer: (payload, callback, onRejected) => {
    customerApi
      .delete(`/customers/deleteCustomer/${payload}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  editCustomer: (index, payload, callback, onRejected) => {
    customerApi
      .patch(`/customers/editCustomer/${index}`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchContactPerson: (payload, callback, onRejected) => {
    customerApi
      .get(`/customers/fetchContactPerson/${payload}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchContactPersonList: (payload, callback, onRejected) => {
    customerApi
      .post('/customers/fetchContactPersonList', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  addContactPerson: (payload, callback, onRejected) => {
    customerApi
      .post('/customers/addContactPerson/', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  deleteContactPerson: (payload, callback, onRejected) => {
    customerApi
      .delete(`/customers/deleteContactPerson/${payload}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  editContactPerson: (index, payload, callback, onRejected) => {
    customerApi
      .patch(`/customers/editContactPerson/${index}`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  signInWithEmail: (payload, callback, onRejected) =>
    authorizeApi
      .post('/auth/login', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  signInWithGoogle: () =>
    (window.location = `http://localhost:3001/api/auth/login/google`),
  forgotPassword: (payload, callback, onRejected) =>
    authorizeApi
      .post('/auth/forgetPassword', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  getNewToken: (refreshToken) =>
    authorizeApi
      .get('/auth/refreshAccessToken', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: refreshToken,
        },
      })
      .then(({ data }) => data),
  resetPassword: (payload, callback, onRejected) =>
    authorizeApi
      .post('/resetPassword/', payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('reset-token')}`,
        },
      })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
};

export const AnnounceService = {
  fetchAnnouncementList: (params, callback, onRejected) => {
    announceApi
      .post('/announcement/getAnnouncements', params)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getAnnouncement: (id, callback, onRejected) => {
    announceApi
      .get('/announcement/fetch/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  addAnnouncement: (payload, callback, onRejected) => {
    announceApi
      .post('/announcement/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updateAnnouncement: (payload, callback, onRejected) => {
    announceApi
      .patch('/announcement/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  deleteAnnouncement: (id, callback, onRejected) => {
    announceApi
      .delete('/announcement/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

export const imageservice = {
  image: {
    getImage: (image, callback, onRejected) =>
      profileApi
        .get(`/image/getImage/` + image, {
          responseType: 'blob',
        })
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => callback(response)),
    uploadImage: (image, callback) =>
      profileApi
        .post(`/image/upload`, image)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => console.log('RESPONSE', response)),
  },
};

export const profileservice = {
  profilePicture: {
    getImage: (image, callback) =>
      profileApi
        .get(`/image/get-image/` + image, {
          responseType: 'blob',
        })
        .then(({ data }) => callback({ data }))
        .catch((response) => console.log('RESPONSE', response)),
    editprofileImage: (payload, callback) =>
      profileApi
        .post(`/profiles/profilePicture/update`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => console.log('RESPONSE', response)),
  },
  personalDetail: {
    createpersonalDetail: (payload, callback, onRejected) => {
      profileApi
        .post(`/profiles/generalInfo/add/`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => console.log('RESPONSE', response));
    },
    getpersonalDetail: (emp_index, callback, onRejected) =>
      profileApi
        .get(`/user/get-user/${emp_index}`, emp_index)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    editpersonalDetail: (payload, callback, onRejected) =>
      profileApi
        .patch(`/user/update-user/`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    changePassword: (payload, callback, onRejected) =>
      profileApi
        .patch(`/user/change-password`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
  },
  workExperience: {
    getWorkExperience: (emp_index, callback, onRejected) =>
      profileApi
        .get(`/profiles/workExperiences/fetch/${emp_index}`, emp_index)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    editWorkExperience: (payload, callback) =>
      profileApi
        .patch(`/profiles/workExperiences/update`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => console.log('RESPONSE', response)),
  },
  education: {
    getEducation: (emp_index, callback, onRejected) =>
      profileApi
        .get(`/profiles/education/fetch/${emp_index}`, emp_index)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    editEducation: (payload, callback) =>
      profileApi
        .patch(`/profiles/education/update`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => console.log('RESPONSE', response)),
  },
  trainingCourses: {
    getTrainingCourses: (emp_index, callback, onRejected) =>
      profileApi
        .get(`/profiles/trainingCourses/fetch/${emp_index}`, emp_index)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    editTrainingCourses: (payload, callback) =>
      profileApi
        .patch(`/profiles/trainingCourses/update`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => console.log('RESPONSE', response)),
  },
  address: {
    getAddress: (emp_index, callback, onRejected) =>
      profileApi
        .get(`/profiles/address/fetch/${emp_index}`, emp_index)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    editAddress: (payload, callback) =>
      profileApi
        .patch(`/profiles/address/update`, payload)
        .then(({ data }) => callback({ data })),
  },
  certificate: {
    getCertificate: (emp_index, callback, onRejected) =>
      profileApi
        .get(`/profiles/certificates/fetch/${emp_index}`, emp_index)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    editCertificate: async (payload, callback) =>
      // console.log(payload);
      await profileApi
        .patch(`/profiles/certificates/update`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => {
          message.error('error');
          console.log('RESPONSE', response);
        }),
    getCertificatePictures: (file_name, callback, onRejected) =>
      profileApi
        .get(`images/` + file_name, { responseType: 'blob' })
        .then((res) => callback(res))
        .catch((response) => console.log('RESPONSE', response)),
  },
  computerSkills: {
    getComputerSkills: (emp_index, callback, onRejected) =>
      profileApi
        .get(`/profiles/computerSkills/fetch/${emp_index}`, emp_index)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => onRejected(response)),
    editComputerSkills: (payload, callback) =>
      profileApi
        .patch(`/profiles/computerSkills/update`, payload)
        .then(({ data }) => callback({ data }))
        .catch(({ response }) => console.log('RESPONSE', response)),
  },
};

export const UserService = {
  fetchUserAll: (payload, callback, onRejected) =>
    userApi
      // .get('/user/get-all-user', { params })
      .post('/user/get-all-user', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchAllUserInfo: (callback, onRejected) =>
    userApi
      .get('/user/get-all-user-info')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  getUserOptions: (payload, callback, onRejected) =>
    userApi
      .post('/user/get-user-options', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addUser: (payload, callback, onRejected) => {
    userApi
      .post('/user/add-user', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getActiveUserCount: (payload, callback, onRejected) => {
    userApi
      .get('/user/getActivedUserCount')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

export const MasterdataService = {
  fetchInstitutionList: async (params, callback, onRejected) => {
    await masterdataApi
      .get('/master/institution/getAllInstitution', {})
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchEducationMajorList: async (params, callback, onRejected) => {
    await masterdataApi
      .get('/master/educationMajor/getAllEducationMajor', {})
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchDepartmentList: async (params, callback, onRejected) => {
    await masterdataApi
      .get('/master/department/getDepartmentList', {})
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchPositionList: async (params, callback, onRejected) => {
    await masterdataApi
      .get('/master/position/getPositionList', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchLevelList: async (params, callback, onRejected) => {
    await masterdataApi
      .get('/master/user/getAllLevel')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchSkillList: async (params, callback, onRejected) => {
    await masterdataApi
      .get('/master/skill/getComputerSkillType')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchRoleList: async (params, callback, onRejected) => {
    await masterdataApi
      .get('/master/user/getAllRole')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchComputerSkillList: async (params, callback, onRejected) => {
    await masterdataApi
      .get('/master/skill/getComputerSkillType')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchTaskList: async (params, callback, onRejected) => {
    await masterdataApi
      .get('/master/task/getTaskList')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchSupervisorList: async (params, callback, onRejected) => {
    await masterdataApi
      .get('/master/user/getAllSupervisor')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchSectionList: async (params, callback, onRejected) => {
    await masterdataApi
      .get('/master/section/getAllSection')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchProjectTypeList: async (params, callback, onRejected) => {
    await masterdataApi
      .get('/master/project-type/get-all-project-type')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updateInstitution: (payload, callback, onRejected) =>
    masterdataApi
      .patch('/master/institution/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateEducationMajor: (payload, callback, onRejected) =>
    masterdataApi
      .patch('/master/educationMajor/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateDepartment: (payload, callback, onRejected) =>
    masterdataApi
      .patch('/master/department/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateSection: (payload, callback, onRejected) =>
    masterdataApi
      .patch('/master/section/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updatePosition: (payload, callback, onRejected) =>
    masterdataApi
      .patch('/master/position/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateLevel: (payload, callback, onRejected) =>
    masterdataApi
      .patch('/master/level/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateSkill: (payload, callback, onRejected) =>
    masterdataApi
      .patch('/master/skill/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateTask: (payload, callback, onRejected) =>
    masterdataApi
      .patch('/master/task/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateProjectType: (payload, callback, onRejected) =>
    masterdataApi
      .patch('/master/project-type/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addInstitution: (payload, callback, onRejected) =>
    masterdataApi
      .post('/master/institution/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addEducationMajor: (payload, callback, onRejected) =>
    masterdataApi
      .post('/master/educationMajor/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addDepartment: (payload, callback, onRejected) =>
    masterdataApi
      .post('/master/department/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addSection: (payload, callback, onRejected) =>
    masterdataApi
      .post('/master/section/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addPosition: (payload, callback, onRejected) =>
    masterdataApi
      .post('/master/position/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addLevel: (payload, callback, onRejected) =>
    masterdataApi
      .post('/master/level/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addSkill: (payload, callback, onRejected) =>
    masterdataApi
      .post('/master/skill/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addTask: (payload, callback, onRejected) =>
    masterdataApi
      .post('/master/task/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addProjectType: (payload, callback, onRejected) =>
    masterdataApi
      .post('/master/project-type/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteInstitution: (id, callback, onRejected) =>
    masterdataApi
      .delete('/master/institution/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteEducationMajor: (id, callback, onRejected) =>
    masterdataApi
      .delete('/master/educationMajor/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteDepartment: (id, callback, onRejected) =>
    masterdataApi
      .delete('/master/department/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteSection: (id, callback, onRejected) =>
    masterdataApi
      .delete('/master/section/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deletePosition: (id, callback, onRejected) =>
    masterdataApi
      .delete('/master/position/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteLevel: (id, callback, onRejected) =>
    masterdataApi
      .delete('/master/level/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteSkill: (id, callback, onRejected) =>
    masterdataApi
      .delete('/master/skill/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteTask: (id, callback, onRejected) =>
    masterdataApi
      .delete('/master/task/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteProjectType: (id, callback, onRejected) =>
    masterdataApi
      .delete('/master/project-type/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
};

export const TimesheetService = {
  fetchTimesheetList: (payload, callback, onRejected) =>
    timesheetApi
      .post('/timesheet/getAllTimesheet', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  getTimesheetByDate: (payload, callback, onRejected) =>
    timesheetApi
      .post('/timesheet/getTimesheetByDate', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchTimesheetDetail: (payload, callback, onRejected) =>
    timesheetApi
      .post('/timesheet/getTimesheetDetailByMonth', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addTimesheet: (payload, callback, onRejected) =>
    timesheetApi
      .post('/timesheet/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateTimesheet: (payload, callback, onRejected) =>
    timesheetApi
      .patch('/timesheet/update', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  deleteTimesheet: (id, callback, onRejected) =>
    timesheetApi
      .delete('/timesheet/delete/' + id)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addTimesheetHour: (payload, callback, onRejected) =>
    timesheetApi
      .post('/timesheet/add-hour', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
};

export const TimesheetReportService = {
  fetchTimesheetReportByMonth: (payload, callback, onRejected) =>
    timesheetApi
      .post('/timesheetReport/getTimesheetReportDetailByMonth', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchSummaryTimesheetReportByYear: (payload, callback, onRejected) =>
    timesheetApi
      .post('/timesheetReport/getSummaryTimesheetReportByYear', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchSummaryTimesheetReportByProject: (payload, callback, onRejected) =>
    timesheetApi
      .post('/timesheetReport/getSummaryTimesheetReportByProject', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchUserProjectByMonth: (payload, callback, onRejected) =>
    timesheetApi
      .post('/timesheetReport/getReportFilterOption', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
};

export const QuotaionService = {
  fetchQuatationList: (payload, callback, onRejected) =>
    quotationApi
      .post('/quotation/fetchQuotationList', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
};

export const ProjectService = {
  fetchProject: (id, callback, onRejected) =>
    projectApi
      .get(`/project/get-project/${id}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  fetchProjectList: (params, callback, onRejected) =>
    projectApi
      .get('/project/get-all-project', { params })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchProjectListByUser: (callback, onRejected) =>
    projectApi
      .get(`/project/get-all-project-of-user`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchActiveProjectListByUser: (callback, onRejected) =>
    projectApi
      .get(`/project/get-all-active-project-of-user`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  fetchProjectOtListByUser: (payload, callback, onRejected) =>
    projectApi
      .post(`/project/get-all-project-ot-of-user`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  addProject: (payload, callback, onRejected) =>
    projectApi
      .post('/project/add-project', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  editProject: (payload, callback, onRejected) =>
    projectApi
      .patch(`/project/update-project`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  updateProjectColor: (queries, payload, callback, onRejected) =>
    projectApi
      .patch(
        `/project/update-user-project-color-code?projectId=${queries.projectId}&memberId=${queries.memberId}`,
        payload,
      )
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),

  deleteProject: (id, callback, onRejected) =>
    projectApi
      .delete(`/project/delete-project/${id}`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
  progessProject: (callback, onRejected) =>
    projectApi
      .get(`master/project/getProjectProgressList`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response)),
};

export const ItemService = {
  addItems: (payload, callback, onRejected) => {
    itemApi
      .post('/items/addItems/', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchItemsList: (payload, callback, onRejected) => {
    itemApi
      .post('/items/fetchItemsList/', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  deleteItems: (payload, callback, onRejected) => {
    itemApi
      .delete(`/items/deleteItems/${payload}/`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  fetchItem: (payload, callback, onRejected) => {
    itemApi
      .get(`/items/fetchItem/${payload}/`)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  editItems: (index, payload, callback, onRejected) => {
    itemApi
      .patch(`/items/editItems/${index}/`, payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
};

export const AuthorizeRoleService = {
  //Region: Payslip
  addPayslipRequest: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/payslip/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updatePayslipByAdmin: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/payslip/update-request-status-by-admin', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updatePayslipByUser: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/payslip/update-request-status-by-user', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getAllPayslipHistory: (callback, onRejected) => {
    authorizeRoleApi
      .get('/payslip/get-all-payslip-history')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getPayslipType: (callback, onRejected) => {
    authorizeRoleApi
      .get('/payslip/get-payslip-type')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getPayslipHistory: (callback, onRejected) => {
    authorizeRoleApi
      .get('/payslip/get-payslip-history-by-userid')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  //End region

  //Region: Medical claim
  getAllMedicalClaimHistory: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/medical-claim/get-all-medical-claim-history', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getMedicalClaimHistroryByUserId: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/medical-claim/get-medical-claim-history-by-userid', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getMedicalClaimType: (callback, onRejected) => {
    authorizeRoleApi
      .get('/medical-claim/get-medical-type')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getMedicalClaimAmountRemain: (callback, onRejected) => {
    authorizeRoleApi
      .get('/medical-claim/get-medical-claim-amount-remain')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getMedicalClaimReport: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/medical-claim/export-medical-treatment-report', payload, {
        responseType: 'blob',
      })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getMedicalClaimHistroryByYear: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/medical-claim/get-medical-claim-history-by-year', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  addMedicalClaimRequest: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/medical-claim/add', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updateMedicalClaimByAdmin: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/medical-claim/update-request-status-by-admin', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updateMedicalClaimByUser: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/medical-claim/update-request-status-by-user', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  //End region

  //Region : Salary
  getSalaryItem: (callback, onRejected) => {
    authorizeRoleApi
      .get('/salary/salary-item')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getSalaryFilterOption: (callback, onRejected) => {
    authorizeRoleApi
      .get('/salary/salary-filter-option')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  getSalaryPDFFile: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/salary/salary-pdf', payload, { responseType: 'blob' })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  uploadSalaryExcel: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/salary/upload-excel', payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  addExcelRecordOld: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/salary/add-excel-record', payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  addExcelRecord: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/salary/upload-salary-record', payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updateSalaryItem: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/salary/update-salary-item', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  updateSalaryItemClass: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/salary/update-salary-item-classification', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  //End region

  //Region Announcement
  notifyAnnouncementToLineGroup: (payload, callback, onRejected) => {
    authorizeRoleApi
      .post('/announcement/noti', payload)
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  //End region

  //Region Configuration
  getTimeInTimeOutValue: (callback, onRejected) => {
    authorizeRoleApi
      .get('/master/configs/get-time-in-time-out')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },
  //End region

  //Region project type
  getAllProjectType: (callback, onRejected) => {
    authorizeRoleApi
      .get('/master/project-type/get-all-project-type')
      .then(({ data }) => callback({ data }))
      .catch(({ response }) => onRejected(response));
  },

  //End region
};
