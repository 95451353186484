import React, { Component } from 'react';
import styled from 'styled-components';
import { Form, Input, Button, Checkbox } from 'antd';
import googleicon from '../assets/icons/google.svg';
import { authentication } from '../services/api';
import { message, Modal } from 'antd';
import { relative } from 'path';
import { redirectTo } from '../services/redirect';
import loginbg from '../assets/images/new_login_bg.png';
const Box = styled.div`
  height: 33.9375rem;
  width: 61.5rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  font-family: 'Kanit';
`;
const Mainbox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  positon: relative;
`;
const Flexbox = styled.div`
  width: 50%;
  background: ${(props) =>
    props.left
      ? 'linear-gradient(8.65deg, #004368 -0.2%, #000000 117.76%)'
      : ' white;'};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 4.875rem;
`;
const Text = styled.div`
  font-size: 20px;
`;
const Text2 = styled.div`
  font-size: 14px;
`;
const Text3 = styled.div`
  font-size: ${(props) => (props.size ? props.size + 'rem' : '1rem')};
  position: absolute;

  font-weight: 500;
  color: rgba(255, 255, 255);
  display: flex;
  align-items: center;
`;

const Formall = styled(Form)`
  width: 100%;
  margin-top: 1.375rem;
  &.ant-form label {
    font-weight: 500;
  }
`;
const Formitem = styled(Form.Item)``;
const Buttonedit = styled(Button)`
  height: 2.5rem;
  width: 100%;
  background: ${(props) => (props.google ? 'white' : '#004368')};
  color: ${(props) => (props.google ? 'black' : 'white')};
  box-shadow: ${(props) =>
    props.google ? '0px 0px 15px rgba(0, 0, 0, 0.15)' : 'none'};
  border-radius: 5px;
`;

class Resetpassword extends Component {
  constructor(props) {
    super(props);
    // this.handleLoginWithEmail = this.handleLoginWithEmail.bind(this);
  }

  componentDidMount() {
    const { token } = this.props.match.params;
    console.log(token);
    sessionStorage.setItem('reset-token', token);
  }

  onFinish = (values) => {
    const payload = { password: values.newPassword };
    authentication.resetPassword(
      payload,
      ({ data }) => {
        console.log(data);
        message.success('Reset Password Successful');
        sessionStorage.removeItem('reset-token');
        let secondsToGo = 1;
        const timer = setInterval(() => {
          secondsToGo -= 1;
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          redirectTo(`/login`);
        }, secondsToGo * 1000);
      },
      (response) => {
        message.error('Reset Password Failed');
      },
    );
  };

  render() {
    return (
      <Mainbox>
        <Box>
          <Flexbox left>
            <Text3
              size="2"
              style={{
                top: '50%',
                left: '25%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              SPEED X QUALITY
            </Text3>
            <Text3
              size="0.75"
              style={{
                top: '90%',
                left: '25%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              Let’s make our company better
            </Text3>
            <img src={loginbg} />
          </Flexbox>
          <Flexbox>
            <Text style={{ alignSelf: 'flex-start', color: '#AAAAAA' }}>
              Create a new password
            </Text>
            <Text2 style={{ color: '#AAAAAA' }}>
              Your password must be at least 8 characters.
            </Text2>
            <Formall layout="vertical" onFinish={this.onFinish}>
              <Formitem
                name="newPassword"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: 'Please Input your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value.length < 8) {
                        return Promise.reject(
                          'Your password must be at least 8 characters',
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="New Password" />
              </Formitem>
              <Formitem
                name="confirmPassword"
                label="Re-New Password"
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        'The two passwords that you entered do not match!',
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Re-New Password" />
              </Formitem>
              <Formitem>
                <Buttonedit type="primary" htmlType="submit">
                  Submit
                </Buttonedit>
              </Formitem>
            </Formall>
          </Flexbox>
        </Box>
      </Mainbox>
    );
  }
}
export default Resetpassword;
