import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Button,
  Row,
  Col,
  Input,
  Select,
  AutoComplete,
  Typography,
  Spin,
  message,
  Form,
} from 'antd';

import downloadIcon from '../../assets/icons/download.svg';
import {
  profileservice,
  UserService,
  TimesheetReportService,
} from '../../services/api';
import moment from 'moment';
import {
  generateExcelNormal,
  generateExcelSpecial,
  generateExcelSummary,
  generateExcelSummaryByProject,
  generateExcelSummaryOt,
} from '../../services/reportGenerator';

const Excel = require('exceljs');

const Selectdata = styled(Select)`
  width: 10rem;
  height: 2rem;
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  justify-content: center;
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Inter;
  font-style: Regular;
  font-size: 14px;
  line-height: 19.42px;
  text-align: center;
  vertical-align: middle;
  padding: 5px 15px;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;

const ContentBox = styled.div`
  background: #f6f6f6;
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: auto;
  min-height: calc(100vh - 7em);
  justify-content: flex-start;
  padding-top: 0.5em;
`;
const TextCard = styled.div`
  height: auto;
  width: 100%;
  background-color: white;
  margin-top: 2em;
  box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 40px 50px;
`;
const Horizontalbox = styled.div`
  display: flex;
  @media (max-width: 714px) {
    flex-direction: column;
  }
`;
const FlexBox = styled.div`
  width: 50%;
  display: flex;
  padding-right: 2rem;

  box-sizing: border-box;
  margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: row;
  }
  @media (max-width: 714px) {
    padding-right: 0;
    width: 100%;
  }
`;

const Label = styled.div`
  font-family: 'Kanit';
  font-size: 16px;
  font-weight: 500;
  width: 11rem;
  min-width: 11rem;
  color: black;
  @media (max-width: 1424px) {
    width: 100%;
    min-width: none;
  }
`;

const Box = styled.div`
  box-sizing: border-box;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 4.9375rem;
  padding-bottom: 5%;
  background: #f6f6f6;
`;

const StyledSpin = styled(Spin)`
  position: fixed;
  text-align: center;
  bottom: 50%;
  z-index: 10;
`;
const SpinBox = styled(Box)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 5;
`;

export default class ReportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      userId: '',
      timesheetType: 'Normal',
      year: moment().year(),
      month: moment().month() + 1,
      projectOptions: [],
      selectedProject: '',
      userDetail: {},
      selectedUser: {},
      selectedUserDetail: {},
      options: null,
      monthList: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    };
  }
  formRef = React.createRef();

  getPersonalDetail = async () => {
    var jwt = require('jsonwebtoken');
    var token = sessionStorage.getItem('access-token');
    var decode1 = jwt.decode(token);
    const userId = decode1.userId;

    await profileservice.personalDetail.getpersonalDetail(
      userId,
      async ({ data }) => {
        this.setState({ userDetail: data });
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );

    this.setState({
      userId: userId,
      isLoading: false,
    });
  };

  getOptions = (search) => {
    this.setState({
      selectedUser: null,
      selectedUserDetail: null,
      year: moment().year(),
      month: moment().month() + 1,
      projectOptions: [],
    });

    this.formRef.current.setFieldsValue({
      month: moment().month() + 1,
      year: moment().year(),
      projectId: null,
    });

    UserService.getUserOptions(
      {
        search: search,
      },
      async (data) => {
        const options = await data?.data.map((res) => {
          const getHighlightedText = (text) => {
            let isSearchThai = search && /[\u0E00-\u0E7F]/g.test(search);

            let searchCriteria = text;
            let index = searchCriteria
              .toLowerCase()
              .indexOf(search.toLowerCase());

            if (search.length != 0 && index !== -1) {
              let prefix = searchCriteria.substr(0, index);
              let subfix = searchCriteria.substr(index + search.length);
              let matched = searchCriteria.substr(index, search.length);
              return (
                <>
                  {prefix}
                  <span style={{ fontWeight: 'bold', fontColor: '#004368' }}>
                    {matched}
                  </span>
                  {subfix}
                </>
              );
            } else if (
              !isSearchThai &&
              (res.firstnameEn
                .toLowerCase()
                .indexOf(search.toLowerCase().trim()) !== -1 ||
                res.lastnameEn
                  .toLowerCase()
                  .indexOf(search.toLowerCase().trim()) !== -1 ||
                res.email.toLowerCase().indexOf(search.toLowerCase().trim()) !==
                  -1 ||
                res.nicknameEn
                  .toLowerCase()
                  .indexOf(search.toLowerCase().trim()) !== -1)
            ) {
              return (
                [
                  res.firstnameEn,
                  res.lastnameEn,
                  res.nicknameEn,
                  res.email,
                ].includes(text) && text
              );
            } else {
              return (
                [
                  res.firstnameTh,
                  res.lastnameTh,
                  res.nicknameTh,
                  res.email,
                ].includes(text) && text
              );
            }
          };

          return {
            email: res.email,
            value: `${res.firstnameEn} ${res.lastnameEn} (${res.email})`,
            label: (
              <Row>
                <Col span={15}>
                  <Typography.Title
                    style={{
                      fontWeight: 'normal',
                      fontSize: '15px',
                    }}
                    level={5}
                    ellipsis={{
                      rows: 1,
                    }}
                  >
                    {getHighlightedText(res.firstnameEn)}
                    {getHighlightedText(res.firstnameTh)}{' '}
                    {getHighlightedText(res.lastnameEn)}
                    {getHighlightedText(res.lastnameTh)}
                  </Typography.Title>

                  <Typography.Text
                    style={{
                      fontWeight: 'normal',
                      fontSize: '12px',
                    }}
                    ellipsis={{
                      rows: 1,
                    }}
                  >
                    {getHighlightedText(res.email)}
                  </Typography.Text>
                </Col>
                <Col span={5} offset={3}>
                  <Typography.Title
                    style={{
                      fontWeight: 'normal',
                      fontSize: '15px',
                      textAlign: 'left',
                    }}
                    level={5}
                    ellipsis={{
                      rows: 1,
                    }}
                  >
                    {getHighlightedText(res.nicknameEn)}
                    {getHighlightedText(res.nicknameTh)}
                  </Typography.Title>
                </Col>
              </Row>
            ),
          };
        });

        this.setState({
          options: options,
        });
      },
    );
  };

  selectOptions = async (value, option) => {
    this.formRef.current.setFieldsValue({
      month: moment().month() + 1,
      year: moment().year(),
      projectId: null,
    });
    let selectedUser;
    await UserService.getUserOptions(
      {
        search: option.email,
      },
      async (data) => {
        selectedUser = data.data[0];
        this.setState({ selectedUser: selectedUser });
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );

    await profileservice.personalDetail.getpersonalDetail(
      selectedUser.userId,
      async ({ data }) => {
        const dataValue = JSON.stringify(data);
        const setDataValue = JSON.parse(dataValue);
        this.setState({
          selectedUserDetail: setDataValue,
        });
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
    await TimesheetReportService.fetchUserProjectByMonth(
      {
        userId: selectedUser.userId,
        year: this.state.year,
        month: this.state.month,
      },
      (data) => {
        if (data) {
          this.setState({
            projectOptions: data.data.projectOptions,
          });
        }
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );

    this.handleFetchProject();
  };

  getYearlist = () => {
    let startDate;

    if (this.state.selectedUserDetail) {
      startDate = this.state.selectedUserDetail.startJobDate;
    } else {
      return;
    }

    let startYear = moment(startDate).year();
    let currYear = moment().year();

    let res = [];
    for (let i = currYear; i >= startYear; i--) {
      res.push(<Select.Option value={i}>{i}</Select.Option>);
    }

    return res;
  };

  getYearlistSummary = () => {
    let res = [];
    for (let i = moment().year(); i >= 2017; i--) {
      res.push(<Select.Option value={i}>{i}</Select.Option>);
    }

    return res;
  };

  getMonthlist = () => {
    let startDate;

    if (this.state.selectedUserDetail) {
      if (
        this.state.timesheetType != 'Normal' &&
        this.state.year != moment().year()
      ) {
        return [
          <Select.Option value={12}>{this.state.monthList[11]}</Select.Option>,
        ];
      } else {
        startDate = this.state.selectedUserDetail.startJobDate;
      }
    } else {
      return [
        <Select.Option value={this.state.month}>
          {this.state.monthList[this.state.month - 1]}
        </Select.Option>,
      ];
    }

    let startYear = moment(startDate).year();
    let startMonth = moment(startDate).month() + 1;

    let currYear = moment().year();
    let currMonth = moment().month() + 1;

    let res = [];

    if (startYear == currYear && startMonth == currMonth) {
      res.push(
        <Select.Option value={currMonth}>
          {this.state.monthList[currMonth - 1]}
        </Select.Option>,
      );
    } else if (this.state.year == currYear) {
      if (currYear == startYear) {
        for (let i = currMonth; i >= startMonth; i--) {
          res.push(
            <Select.Option value={i}>
              {this.state.monthList[i - 1]}
            </Select.Option>,
          );
        }
      } else {
        for (let i = currMonth; i > 0; i--) {
          res.push(
            <Select.Option value={i}>
              {this.state.monthList[i - 1]}
            </Select.Option>,
          );
        }
      }
    } else if (this.state.year === startYear) {
      for (let i = 12; i >= startMonth; i--) {
        res.push(
          <Select.Option value={i}>
            {this.state.monthList[i - 1]}
          </Select.Option>,
        );
      }
    } else {
      for (let i = 12; i > 0; i--) {
        res.push(
          <Select.Option value={i}>
            {this.state.monthList[i - 1]}
          </Select.Option>,
        );
      }
    }
    return res;
  };

  handleFetchProject = async () => {
    await TimesheetReportService.fetchUserProjectByMonth(
      {
        userId: this.state.selectedUserDetail.userId,
        year: this.state.year,
        month: this.state.month,
      },
      (data) => {
        if (data) {
          this.setState({
            projectOptions: data.data.projectOptions,
          });
        }
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  getProject = () => {
    let res = [];
    if (this.state.projectOptions) {
      this.state.projectOptions.forEach((value) => {
        res.push(
          <Select.Option value={value.projectId}>
            {value.projectNo + ' - ' + value.projectName}
          </Select.Option>,
        );
      });
    }
    return res;
  };

  handleSubmitAdmin = (values) => {
    if (values.timesheetType === 'Normal') {
      TimesheetReportService.fetchTimesheetReportByMonth(
        {
          userId: this.state.selectedUserDetail.userId,
          year: values.year,
          month: values.month,
          projectId: values.projectId,
        },
        async ({ data }) => {
          generateExcelNormal(data, this.state);
        },
        (response) => {
          if (response) message.error(`Error: ${response.data.message}`);
        },
      );
    } else if (values.timesheetType === 'Special') {
      TimesheetReportService.fetchTimesheetReportByMonth(
        {
          userId: this.state.selectedUserDetail.userId,
          year: values.year,
          month: values.month,
          projectId: values.projectId,
        },
        async ({ data }) => {
          generateExcelSpecial(data, this.state);
        },
        (response) => {
          if (response) message.error(`Error: ${response.data.message}`);
        },
      );
    } else if (values.timesheetType === 'Summary') {
      TimesheetReportService.fetchSummaryTimesheetReportByYear(
        {
          year: values.year,
        },
        async ({ data }) => {
          if (data) {
            generateExcelSummary(data, this.state);
          }
        },
        (response) => {
          if (response) message.error(`Error: ${response.data.message}`);
        },
      );
    } else if (values.timesheetType === 'SummaryOt') {
      TimesheetReportService.fetchSummaryTimesheetReportByYear(
        {
          year: values.year,
        },
        async ({ data }) => {
          if (data) {
            generateExcelSummaryOt(data, this.state);
          }
        },
        (response) => {
          if (response) message.error(`Error: ${response.data.message}`);
        },
      );
    } else if (values.timesheetType === 'SummaryByProject') {
      TimesheetReportService.fetchSummaryTimesheetReportByProject(
        {
          year: values.year,
        },
        async ({ data }) => {
          if (data) {
            generateExcelSummaryByProject(data, this.state);
          }
        },
        (response) => {
          if (response) message.error(`Error: ${response.data.message}`);
        },
      );
    }
  };

  componentDidMount = () => {
    this.getPersonalDetail();
  };

  render() {
    if (this.state.isLoading) {
      return (
        <SpinBox>
          <StyledSpin size="large" />
        </SpinBox>
      );
    } else {
      return (
        <div style={{ width: 'auto' }}>
          <ContentBox>
            <TextCard>
              <Form
                ref={this.formRef}
                onFinish={this.handleSubmitAdmin}
                requiredMark={true}
                initialValues={{
                  timesheetType: 'Normal',
                  month: moment().month() + 1,
                  year: moment().year(),
                }}
              >
                <Row gutter={[64, 16]}>
                  <Col
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    style={{ width: '100%' }}
                  >
                    <Row gutter={16}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                        <Label>Report type</Label>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 16 }}
                      >
                        <Form.Item
                          style={{ width: '100%', height: '2rem' }}
                          name="timesheetType"
                        >
                          <Selectdata
                            onChange={(value) => {
                              this.setState({
                                timesheetType: value,
                                year: moment().year(),
                              });
                              this.formRef.current.resetFields();
                              this.formRef.current.setFieldsValue({
                                timesheetType: value,
                              });
                            }}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Select.Option value="Normal">
                              Timesheet (Normal)
                            </Select.Option>
                            <Select.Option value="Special">
                              Timesheet (Special)
                            </Select.Option>
                            <Select.Option value="Summary">
                              Summary Timesheet
                            </Select.Option>
                            <Select.Option value="SummaryOt">
                              Summary Timesheet (OT)
                            </Select.Option>
                            <Select.Option value="SummaryByProject">
                              Summary Timesheet By Project
                            </Select.Option>
                          </Selectdata>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    style={{ width: '100%' }}
                  >
                    <Row gutter={16}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                        <Label>User</Label>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 16 }}
                      >
                        <Form.Item
                          style={{ width: '100%', height: '2rem' }}
                          name="userId"
                          rules={[
                            {
                              required:
                                !this.state.timesheetType.includes('Summary'),
                              message: 'Please select user',
                            },
                          ]}
                        >
                          <AutoComplete
                            placeholder="Search User"
                            onChange={this.getOptions}
                            options={this.state.options}
                            onSelect={this.selectOptions}
                            style={{ width: '100%' }}
                            disabled={this.state.timesheetType.includes(
                              'Summary',
                            )}
                          ></AutoComplete>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    style={{ width: '100%' }}
                  >
                    <Row gutter={16}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                        <Label>Year</Label>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 16 }}
                      >
                        <Form.Item
                          name="year"
                          style={{ width: '100%', height: '2rem' }}
                        >
                          <Selectdata
                            style={{
                              width: '100%',
                            }}
                            onChange={(value) => {
                              if (value != moment().year()) {
                                this.setState({ month: 12 });
                                this.formRef.current.setFieldsValue({
                                  month: 12,
                                  projectId: null,
                                });
                              } else {
                                this.setState({
                                  month: moment().month() + 1,
                                });
                                this.formRef.current.setFieldsValue({
                                  month: moment().month() + 1,
                                  projectId: null,
                                });
                              }
                              this.setState({ year: value }, () => {
                                this.handleFetchProject();
                              });
                            }}
                          >
                            {!this.state.timesheetType.includes('Summary')
                              ? this.getYearlist()
                              : this.getYearlistSummary()}
                          </Selectdata>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    style={{ width: '100%' }}
                  >
                    <Row gutter={16}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                        <Label>Month</Label>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 16 }}
                      >
                        <Form.Item
                          style={{ width: '100%', height: '2rem' }}
                          name="month"
                        >
                          <Selectdata
                            onChange={(value) => {
                              this.setState(
                                {
                                  month: value,
                                },
                                () => {
                                  this.handleFetchProject();
                                },
                              );

                              this.formRef.current.setFieldsValue({
                                projectId: null,
                              });
                            }}
                            style={{
                              width: '100%',
                            }}
                            disabled={this.state.timesheetType.includes(
                              'Summary',
                            )}
                          >
                            {this.getMonthlist()}
                          </Selectdata>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    style={{ width: '100%' }}
                  >
                    <Row gutter={16}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                        <Label>Project</Label>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 16 }}
                      >
                        <Form.Item
                          style={{ width: '100%', height: '2rem' }}
                          name="projectId"
                          rules={[
                            {
                              required:
                                !this.state.timesheetType.includes('Summary'),
                              message: 'Please select project',
                            },
                          ]}
                        >
                          <Selectdata
                            style={{
                              width: '100%',
                            }}
                            disabled={this.state.timesheetType.includes(
                              'Summary',
                            )}
                            onChange={(value) => {
                              this.setState({ selectedProject: value });
                            }}
                          >
                            {this.getProject()}
                          </Selectdata>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                >
                  <NormalButton
                    onClick={() => {
                      this.formRef.current.submit();
                    }}
                    style={{
                      width: '128px',
                      alignItems: 'center',
                      margin: '0px',
                    }}
                  >
                    <img src={downloadIcon} style={{ marginRight: '0.5rem' }} />
                    Download
                  </NormalButton>
                </div>
              </Form>
            </TextCard>
          </ContentBox>
        </div>
      );
    }
  }
}
