import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Input,
  Table,
  Row,
  message,
  Form,
  Modal,
  DatePicker,
  Select,
  Col,
  Popconfirm,
} from 'antd';
import moment from 'moment';
import binIcon from '../../assets/icons/bin.svg';
import { redirectTo } from '../../services/redirect';
import { holidayService } from '../../services/api';
import { PlusOutlined } from '@ant-design/icons';

import { AppContext } from '../../components/context/AppContext';
//#region
const TitleBox = styled.div`
  box-sizing: border-box;
  padding: 20px 3% 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  justify-content: space-between;
  align-items: center;
`;

const ActionBox = styled.div`
  box-sizing: border-box;
  padding: 20px 3%;
  background: ${(props) => props.bg || '#ffffff'};
`;

const Text = styled.div`
  color: '#232323';
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1.125em' : '16px'};
  font-weight: ${(props) =>
    props.title ? 'bold' : props.subtitle ? '600' : '500'};
  font-family: 'Kanit';
  text-transform: ${(props) => (props.subtitle ? 'uppercase' : 'none')};
`;

const RejectedButton = styled(Button)`
  min-width: 106px;
  border-radius: 5px;
  display: flex;
  background-color: white;
  border: 1px solid #f2f2f2;
  justify-content: center;
  color: #232323;
  font-family: Kanit;
  font-style: Regular;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 5px 15px;
  align-items: center;
  &:disabled {
    border: 1px solid white !important;
  }
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  border-radius: 5px;
  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  // justify-content: center;
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Kanit;
  font-style: Regular;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid white !important;
    background-color: #f5f5f5 !important;
  }
`;
const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: end;
  align-items: center;
`;
const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#ffffff')};
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  /* flex-direction: row; */
  /* align-items: center; */
  width: auto;
  min-height: calc(100vh - 7.5em);
`;
const TableText = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.isKey ? '#004368' : '#737373'};
  font-size: 1em;
  font-weight: ${(props) =>
    props.title ? '600' : props.isKey ? '500' : '300'};
  font-family: Kanit;
  line-height: 22px;
  text-align: left;
`;
const ProjectTable = styled(Table)`
  & .ant-table.ant-table-small .ant-table-thead > tr > th {
    font-size: 16px;
    font-weight: bold;
    background-color: white;
    padding-bottom: 20px;
  }
  &.table-striped-rows tr > th {
    background-color: #ffffff;
  }
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;

  box-sizing: border-box;
  margin-bottom: 1rem;
`;

const Label = styled.div`
  font-family: 'Kanit';
  font-size: 16px;
  font-weight: 400;
  min-width: 40%;
  color: black;
`;

const Inputdata = styled(Input)`
  height: 2rem;
`;

const Picker = styled(DatePicker)`
  width: 100%;
  height: 2rem;
`;

const Selectdata = styled(Select)`
  width: 10rem;
  height: 2rem;
`;

const { confirm } = Modal;
//#endregion

class Holiday extends Component {
  constructor(props) {
    super(props);
    this.filterFormRef = React.createRef();
    this.state = {
      data: null,
      holidayForm: {
        holidayName: '',
        holidayDate: '',
      },
      isLoading: true,
      year: new Date().getFullYear(),
      yearall: [new Date().getFullYear()],
      addHolidayModalVisible: false,
      userCurrentRole: null,
    };

    const defaultStringCompare = (property) => (a, b) =>
      a[property].localeCompare(b[property]);
    this.columns = [
      {
        title: 'DATE',
        dataIndex: 'holidayDate',
        width: '15%',
        align: 'center',
        sorter: defaultStringCompare('holidayDate'),
        render: (value) => (
          <TableText style={{ textAlign: 'center' }}>
            {moment(value).format('DD/MM/YYYY')}
          </TableText>
        ),
      },
      {
        title: 'NAME',
        dataIndex: 'dateName',
        fixed: 'left',
        align: 'center',
        sorter: defaultStringCompare('dateName'),
        render: (name, record) => <TableText isKey>{name}</TableText>,
      },
      {
        title: 'ACTION',
        align: 'center',
        width: '10%',
        render: (key, record) => (
          <>
            {(this.state.userCurrentRole === 'ADMIN' ||
              this.state.userCurrentRole === 'ADMIN_HR') && (
              /* moment() <= moment(record.holidayDate) && */ <Popconfirm
                title="Are you sure to delete？"
                okText="OK"
                cancelText="Cancel"
                okButtonProps={{
                  style: {
                    backgroundColor: '#004368',
                    borderColor: '##004368',
                  },
                }}
                onConfirm={() => {
                  this.showDeleteConfirmation(key, record);
                }}
                onCancel={() => {
                  console.log('Cancel');
                }}
              >
                <img src={binIcon} />
              </Popconfirm>
            )}
          </>
        ),
      },
    ];
  }

  static contextType = AppContext;
  formRef = React.createRef();

  onYearFilterChange = (value) => {
    this.setState(
      {
        year: value,
      },
      () => this.fetchHoliday(),
    );
  };

  fetchYear = () => {
    holidayService.getAll(({ data }) => {
      this.setState({
        yearall: [...new Set(data.map((r) => r.holidayYear))],
      });
    });
  };

  fetchHoliday = () => {
    this.setState({
      isLoading: true,
    });

    holidayService.getYear(
      this.state.year,
      ({ data }) => {
        this.setState({
          isLoading: false,
          data: data,
        });
      },
      (response) => {
        this.setState({
          isLoading: false,
          data: null,
        });
        if (response && response.status === 400) {
          message.error(`Cannot access data - ${response.data.message}`);
        } else {
          message.error('Error');
        }
      },
    );
  };

  componentDidMount = () => {
    this.fetchHoliday();
    this.fetchYear();
  };

  componentDidUpdate = () => {
    const { userState } = this.context;
    if (userState.currentRole !== this.state.userCurrentRole) {
      this.setState({
        userCurrentRole: userState.currentRole,
      });
    }
  };

  handleRedirect = (path) => {
    redirectTo(path);
  };

  showDeleteConfirmation = (payload, record) => {
    this.handleDeleteHoliday(record.id);
  };
  handleDeleteHoliday = (id) => {
    holidayService.deleteHoliday(
      { holidayId: id },
      ({ data }) => {
        let secondsToGo = 1;
        message.success('Delete Holiday successfully.');
        const timer = setInterval(() => {
          secondsToGo -= 1;
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          // redirectTo(`/customer`);
          this.fetchHoliday();
        }, secondsToGo * 1000);
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Error: ' + response.data.message);
        }
      },
    );
  };

  showAddHolidayModal = () => {
    this.setState({
      addHolidayModalVisible: true,
    });
  };

  closeAddHolidayModal = () => {
    this.setState({
      addHolidayModalVisible: false,
      holidayForm: {
        holidayName: '',
        holidayDate: '',
      },
    });
  };

  showAddHoliday = () => {
    confirm({
      title: 'Add Holiday',
      content: (
        <div>
          <Form>
            <FlexBox>
              <Label>
                Holiday Name<label style={{ color: 'red' }}>*</label>
              </Label>
              <Form.Item
                style={{ width: '100%', margin: '0' }}
                rules={[
                  {
                    required: true,
                    message: 'Please add holiday name.',
                  },
                ]}
              >
                <Inputdata
                  id="holidayName"
                  placeholder="Name"
                  value={this.state.holidayForm.holidayName}
                  onChange={(event) => {
                    this.setState({
                      holidayForm: {
                        ...this.state.holidayForm,
                        holidayName: event.target.value,
                      },
                    });
                    console.log(this.state);
                  }}
                />
              </Form.Item>
            </FlexBox>
            <FlexBox>
              <Label>
                Date<label style={{ color: 'red' }}>*</label>
              </Label>
              <Form.Item
                style={{ width: '100%', margin: '0' }}
                // name="holidayDate"
                rules={[
                  {
                    required: true,
                    message: 'Please add holiday date.',
                  },
                ]}
              >
                <Picker
                  defaultValue={''}
                  format="DD/MM/YYYY"
                  onChange={(value) =>
                    this.setState({
                      holidayForm: {
                        ...this.state.holidayForm,
                        holidayDate: moment(value, 'YYYY-MM-DD'),
                      },
                    })
                  }
                />
              </Form.Item>
            </FlexBox>
          </Form>
        </div>
      ),
      onOk: () => {
        this.handleAddHoliday();
      },
      onCancel: () => {},
    });
    // console.log('add function');
  };
  handleAddHoliday = () => {
    // console.log(document.getElementById('holidayDate').value);
    console.log(this.state.holidayForm.holidayDate);

    if (moment() > this.state.holidayForm.holidayDate) {
      message.warning('warning: Date is in the past.');
    }
    holidayService.addHoliday(
      {
        holidayDate: this.state.holidayForm.holidayDate.format('YYYY-MM-DD'),
        startDatetime:
          this.state.holidayForm.holidayDate.format('YYYY-MM-DD') + ' 09:00:00',
        endDatetime:
          this.state.holidayForm.holidayDate.format('YYYY-MM-DD') + ' 18:00:00',
        dateName: this.state.holidayForm.holidayName,
        description: 'วันหยุด',
      },
      ({ data }) => {
        let secondsToGo = 1;
        message.success('Successfully created.');
        const timer = setInterval(() => {
          secondsToGo -= 1;
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          this.fetchHoliday();
        }, secondsToGo * 1000);
        this.closeAddHolidayModal();
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Error: ' + response.data.message);
        }
      },
    );
  };

  render() {
    var dataSource;

    if (this.state.isLoading === false) {
      // force rerender Table // binIcon
      dataSource = this.state.data.map((item) => ({
        ...item,
        key: item.id + '_frerender',
      }));
    }

    return (
      <div style={{ width: 'auto' }}>
        <TitleBox>
          <Text>All Holidays</Text>
        </TitleBox>
        <ActionBox>
          <Row align="middle">
            <Col>
              <Selectdata
                defaultValue={this.state.year}
                onChange={(value) => {
                  this.onYearFilterChange(value);
                }}
              >
                {this.state.yearall.map((item) => (
                  <Select.Option value={item}>{item}</Select.Option>
                ))}
              </Selectdata>
            </Col>
            <Col flex="auto" />
            <Col>
              {(this.state.userCurrentRole === 'ADMIN' ||
                this.state.userCurrentRole === 'ADMIN_HR') && (
                <NormalButton
                  icon={<PlusOutlined />}
                  onClick={this.showAddHolidayModal}
                  style={{ width: 135 }}
                >
                  Add Holiday
                </NormalButton>
              )}
            </Col>
          </Row>
        </ActionBox>

        <ContentBox>
          <ProjectTable
            className="table-striped-rows"
            columns={this.columns}
            scroll={{ x: 300 }}
            dataSource={dataSource}
            rowKey="id"
            style={{ width: '100%' }}
            size="small"
            indentSize={1}
            loading={this.state.isLoading}
            pagination={{
              position: ['bottomRight'],
              pageSizeOptions: [10, 20, 30, 40],
              showSizeChanger: true,
            }}
          />
        </ContentBox>
        <Modal
          title="Add Holiday"
          visible={this.state.addHolidayModalVisible}
          closable={false}
          width={565}
          footer={null}
          onCancel={this.closeAddHolidayModal}
        >
          <div>
            <Form>
              <FlexBox>
                <Label>
                  Holiday Name<label style={{ color: 'red' }}> *</label>
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please add holiday name.',
                    },
                  ]}
                >
                  <Inputdata
                    id="holidayName"
                    placeholder="Name"
                    value={this.state.holidayForm.holidayName}
                    onChange={(event) => {
                      this.setState({
                        holidayForm: {
                          ...this.state.holidayForm,
                          holidayName: event.target.value,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </FlexBox>
              <FlexBox>
                <Label>
                  Date<label style={{ color: 'red' }}> *</label>
                </Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  // name="holidayDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please add holiday date.',
                    },
                  ]}
                >
                  <Picker
                    placeholder="Date"
                    defaultValue={''}
                    format="DD/MM/YYYY"
                    value={this.state.holidayForm.holidayDate}
                    onChange={(value) =>
                      this.setState({
                        holidayForm: {
                          ...this.state.holidayForm,
                          holidayDate: moment(value, 'YYYY-MM-DD'),
                        },
                      })
                    }
                  />
                </Form.Item>
              </FlexBox>
            </Form>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '1.5rem',
              }}
            >
              <RejectedButton
                grey
                style={{ width: 106 }}
                onClick={this.closeAddHolidayModal}
              >
                Cancel
              </RejectedButton>
              <NormalButton
                style={{ width: 106 }}
                onClick={this.handleAddHoliday}
                disabled={
                  this.state.holidayForm.holidayName === '' ||
                  this.state.holidayForm.holidayDate === ''
                }
              >
                Submit
              </NormalButton>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Holiday;
