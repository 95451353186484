import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Layout,
  Button,
  Input,
  Table,
  Space,
  Row,
  Col,
  Modal,
  message,
  Pagination,
} from 'antd';
import binIcon from '../../assets/icons/bin.svg';
// import "./customer.css";
import { CustomerService } from '../../services/api';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { TextFormatter } from '../../components/TextFormatter';
import { redirectTo } from '../../services/redirect';
const { Header, Content, Footer } = Layout;
const TitleBox = styled.div`
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  height: 6.875em;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.subtitle ? '#20ABC5' : '#ff2132'};
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1.125em' : '1em'};
  font-weight: ${(props) =>
    props.title ? 'bold' : props.subtitle ? '600' : 'normal'};
  font-family: 'Kanit';
  text-transform: ${(props) => (props.subtitle ? 'uppercase' : 'none')};
`;
const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  padding: 5px 15px;
  background-color: #2d95a8 !important;
  border-color: #2d95a8 !important;
  justify-content: center;
  color: white;
  font-family: Inter;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;
const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#f6f6f6')};
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  width: auto;
  min-height: calc(100vh - 7.5em);
`;
const TableText = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.no ? '#20ABC5' : '#737373'};
  font-size: 1em;
  font-weight: ${(props) =>
    props.title ? '600' : props.no ? 'bold' : 'normal'};
  font-family: 'Kanit';
  line-height: 22px;
  text-transform: ${(props) => props.capitalize && 'capitalize'};
`;
const CustomerList = styled(Table)`
  &.table-striped-rows tr > th {
    background-color: #f6f6f6;
  }
  &.table-striped-rows tr:nth-child(2n) td {
    background-color: #f2f2f2;
  }
  &.table-striped-rows tr:nth-child(2n + 1) td {
    background-color: #f6f6f6;
  }
  &.table-striped-rows thead {
    background-color: #f1f1f1;
  }
  min-height: 330px;
  margin-bottom: 20px;
`;
const PaginationBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 5px;
  margin-bottom: 15px;
`;
const { Search } = Input;
const { confirm } = Modal;
class Customer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasData: false,
      incorrect: false,
      searchName: '',
      limit: 10,
      offset: 0,
      total: 1,
      current: 1,
    };
  }
  handlefetchCustomerList = () => {
    console.log(
      this.state.limit + ' ' + this.state.offset + ' ' + this.state.total,
    );
    const payload = {
      searchString: this.state.searchName,
      limit: this.state.limit,
      offset: this.state.offset,
    };
    console.log(payload);
    CustomerService.fetchCustomerList(
      payload,
      ({ data }) => {
        console.log(data);
        this.setState({
          hasData: true,
          data: data.customer,
          total: data.Total,
        });
      },
      (response) => {
        // console.log(response.data.message);
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          if (response.data.message != 'empty array') {
            message.error('Cannot access data: ' + response.data.message);
          }
        }
      },
    );
  };
  componentDidMount = () => {
    this.handlefetchCustomerList();
  };
  handleRedirect = (path) => {
    redirectTo(path);
  };
  showDeleteConfirmation = (payload, record) => {
    confirm({
      title: 'Do you want to delete this customer?',
      content: `Customer: ${record.name}`,
      onOk: () => {
        this.handleDeleteCustomer(payload);
      },
      onCancel: () => {
        console.log('Cancel');
      },
    });
  };
  handleDeleteCustomer = (payload) => {
    CustomerService.deleteCustomer(
      payload,
      ({ data }) => {
        console.log(data);
        let secondsToGo = 1;
        message.success(data.message);
        const timer = setInterval(() => {
          secondsToGo -= 1;
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          // redirectTo(`/customer`);
          this.handlefetchCustomerList();
        }, secondsToGo * 1000);
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Error: ' + response.data.message);
        }
      },
    );
  };
  showTotal(total) {
    return total > 1 ? `Total ${total} items` : `Total ${total} item`;
  }
  onSearch = (value) => {
    console.log(value);
    this.setState(
      {
        searchName: value,
        hasData: false,
      },
      () => this.handlefetchCustomerList(),
    );
  };
  onChange = (page) => {
    console.log(page);
    const offset = (page - 1) * this.state.limit;
    this.setState(
      {
        current: page,
        offset: offset,
      },
      () => this.handlefetchCustomerList(),
    );
  };
  render() {
    const name = 'Customer';
    const columns = [
      {
        title: 'NO.',
        dataIndex: 'index',
        index: 'index',
        sorter: {
          compare: (a, b) => a.index - b.index,
          // multiple: 2,
        },
        render: (text, record) => (
          <TableText>
            {TextFormatter.normalText(
              this.state.data.indexOf(record) + 1 + this.state.offset,
            )}
          </TableText>
        ),
      },
      {
        title: 'Company Name',
        dataIndex: 'name',
        index: 'name',
        sorter: {
          compare: (a, b) => a.name.localeCompare(b.name),
          // multiple: 1,
        },
        render: (name, record) => (
          <a
            onClick={() =>
              this.handleRedirect('/customer/view/?no=' + record.index)
            }
          >
            <TableText no capitalize>
              {TextFormatter.normalText(name)}
            </TableText>
          </a>
        ),
      },
      {
        title: 'TAX NO.',
        index: 'tax',
        dataIndex: 'tax_no',
        render: (tax) => <TableText>{TextFormatter.normalText(tax)}</TableText>,
      },
      {
        title: 'COMPANY TEL',
        index: 'tel',
        dataIndex: 'phone_number',
        render: (tel) => <TableText>{TextFormatter.normalText(tel)}</TableText>,
      },
      {
        title: 'FAX',
        index: 'fax',
        dataIndex: 'fax',
        render: (fax) => <TableText>{TextFormatter.normalText(fax)}</TableText>,
      },
      {
        title: 'ACTION',
        index: 'action',
        dataIndex: 'index',
        render: (key, record) =>
          key && (
            <Row>
              <Col span={24}>
                {/* <a onClick={() => this.handleDeleteCustomer(key)}>*/}
                <a onClick={() => this.showDeleteConfirmation(key, record)}>
                  <img src={binIcon} />
                </a>
              </Col>
            </Row>
          ),
      },
    ];
    return (
      <div style={{ width: 'auto' }}>
        <TitleBox>
          <Text title>Customer</Text>
          <NormalButton href={`./customer/addcustomer/`}>
            +Add Customer
          </NormalButton>
        </TitleBox>
        <TitleBox bg="#f6f6f6">
          <Text subtitle>All Customer</Text>
          <Search
            placeholder="Search Customer"
            onSearch={(value) => this.onSearch(value)}
            style={{ width: 200 }}
            loading={!this.state.hasData && !this.state.incorrect}
          />
        </TitleBox>
        <ContentBox>
          <CustomerList
            className="table-striped-rows"
            columns={columns}
            dataSource={this.state.hasData ? this.state.data : null}
            style={{ width: '100%' }}
            size="small"
            loading={!this.state.hasData && !this.state.incorrect}
            pagination={{ position: ['bottomRight'] }}
          />
          <PaginationBlock>
            <Pagination
              defaultCurrent={1}
              current={this.state.current}
              onChange={this.onChange}
              total={this.state.hasData ? this.state.total : 0}
              defaultPageSize={this.state.limit}
              showSizeChanger={false}
              showTotal={this.showTotal}
            />
          </PaginationBlock>
        </ContentBox>
      </div>
    );
  }
}
export default Customer;
