import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  Checkbox,
  InputNumber,
  TreeSelect,
  Switch,
  Row,
  Col,
} from 'antd';
import { validateSearch } from 'rc-mentions/lib/util';
import Delete from '../../assets/icons/delete.svg';
import ProfileTabHeader from './profileTabHeader';
import DetailBox from './detailBox';
import { CalculatorFilled } from '@ant-design/icons';

const MainBox = styled.div`
  font-family: 'Kanit';
`;
const Subbox = styled.div`
  // height: 6.1875rem;
  // border: 2px solid red;
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const Datepicker = styled(DatePicker)`
  width: 100%;
  // max-width:14rem;
  // margin-left:2rem;
`;
const Flexbox = styled.div`
  width: ${(props) => (props.one ? '4rem' : '100%')};
  // background: ${(props) => (props.one ? 'blue' : 'yellow')};
  flex-wrap: wrap;
  align-content: ${(props) => (props.one ? 'center' : 'none')};
  justify-content: ${(props) => (props.one ? 'none' : 'center')};
  box-sizing: border-box;
  border-top: ${(props) =>
    props.one ? 'none' : props.top ? 'none' : '1px solid #ECECEC'};
  position: relative;
  padding-top: ${(props) =>
    props.one ? 'none' : props.top ? 'none' : '0.75rem'};
  padding-bottom: ${(props) => (props.one ? 'none' : '0.75rem')};
`;
const Bluecircle = styled.div`
  position: absolute;
  height: ${(props) => (props.small ? '1.375rem' : '2rem')};
  width: ${(props) => (props.small ? '1.375rem' : '2rem')};
  border-radius: 50%;
  background: ${(props) =>
    props.small
      ? 'white'
      : 'linear-gradient(90deg, #013640 -69.09%, #2A8B9D 111.79%)'};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 99;
  top: ${(props) => (props.small ? 'none' : '1em')};
  transform: ${(props) => (props.small ? 'none' : 'translate(0, -50%)')};
`;
const Greycircle = styled.div`
  position: absolute;
  height: ${(props) => (props.small ? '1.110625rem' : '1.615625rem')};
  width: ${(props) => (props.small ? '1.110625rem' : '1.615625rem')};
  border-radius: 50%;
  background: ${(props) => (props.small ? 'white' : '#D2D2D2')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => (props.small ? 'none' : '0.1921875rem')};
  z-index: 99;
  top: ${(props) => (props.small ? 'none' : 'calc(0.75rem + 1em)')};
  transform: ${(props) => (props.small ? 'none' : 'translate(0, -50%)')};
`;
const Verticalline = styled.div`
  height: 100%;
  margin-left: 0.9375rem;
  width: 0.125rem;
  background: #e1e1e1;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
`;
const Horizontalbox = styled.div`
  display: flex;
  @media (max-width: 714px) {
    flex-direction: column;
  }
`;
const Flexformbox = styled.div`
  width: 50%;
  display: flex;
  padding-right: 2rem;

  box-sizing: border-box;
  margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: column;
  }
  @media (max-width: 714px) {
    padding-right: 0;
    width: 100%;
  }
`;
const Line = styled.div`
  // margin-top:2.0625rem;
  margin-bottom: 1.5rem;
  height: 0.0625rem;
  background: #e3e3e3;
  width: 100%;
`;
const Label = styled.div`
  // font-family: 'Kanit';
  font-size: 16px;
  font-weight: 400;
  width: 9.59875rem;
  min-width: 9.59875rem;
  color: black;
  @media (max-width: 1424px) {
    width: 100%;
    min-width: none;
  }
`;
const Formitem = styled(Form.Item)`
  width: 100%;
  margin: 0;
`;
const Inputdata = styled(Input)`
  // max-width:14rem;
  width: 100%;
  height: 2rem;
  // margin-left:2rem;
`;
const Headdetail = styled.div`
  font-family: 'Kanit';
  font-size: 26px;
  font-weight: 600;
  color: #20abc5;
`;
const Text = styled.div`
  font-size: ${(props) => (props.big ? '1.125rem' : '0.875rem')};
  color: ${(props) => (props.medium ? '#737373' : '#232323')};
  font-weight: ${(props) => (props.big ? '500' : '300')};
`;

const Buttonedit = styled(Button)`
  width: 72px;
  height: 30px;
  background: #2d95a8;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-item: center;
  color: white;
`;
const FlexBox = styled.div`
  width: 50%;
  display: flex;
  align-items: baseline;
  padding-right: 2rem;

  box-sizing: border-box;
  margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: column;
  }
  @media (max-width: 714px) {
    padding-right: 0;
    width: 100%;
  }
`;
const Textdata = styled.div`
  width: 100%;
  font-size: 14px;
`;

class Workexperience extends Component {
  state = {
    workList: this.props.data.workExperience?.workExperiences,
    workListForShow: this.props.data.workExperience?.workExperiences,
    deletedList: [],
    // isInEditMode: false,
  };
  formRef = React.createRef();

  addNewRow = () => {
    this.setState((prevState) => ({
      workList: prevState.workList
        ? [
            ...prevState.workList,
            { position: '', companyName: '', startDate: '', endDate: '' },
          ]
        : [],
    }));
  };

  clickOnDelete(record) {
    this.setState({
      workList: this.state.workList.filter((r) => r !== record),
      deletedList: [...this.state.deletedList, record.id],
    });
  }

  // changeEditMode = () => {
  //   this.setState({
  //     isInEditMode: !this.state.isInEditMode,
  //     workList: this.props.data.workExperience?.workExperiences,
  //     workListForShow: this.props.data.workExperience?.workExperiences,
  //   });
  //   // console.log(this.state);
  // };
  onFinish = (values) => {
    this.props.onSubmit(values, this.state.deletedList);
    this.props.changeEditMode();
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.isInEditMode !== prevProps.isInEditMode) {
      this.setState({
        workList: this.props.data.workExperience?.workExperiences,
        workListForShow: this.props.data.workExperience?.workExperiences,
      });
    }
  }

  renderEditView = () => {
    return (
      <MainBox>
        <Form
          id="workExperiences"
          ref={this.formRef}
          onFinish={this.onFinish}
          autoComplete="off"
        >
          {/* <Row gutter={24}>
            <Col span={12}>
              <Headdetail style={{ marginBottom: '2rem' }}>
                Work Experiences
              </Headdetail>
            </Col>
            <Col span={12} style={{ position: 'relative' }}>
              <div
                style={{ position: 'absolute', right: '0', display: 'flex' }}
              >
                <Buttonedit
                  style={{ background: '#C6C6C6' }}
                  onClick={this.changeEditMode}
                >
                  Cancel
                </Buttonedit>
                &nbsp;&nbsp;
                <Buttonedit type='primary' htmlType='submit'>
                  Save
                </Buttonedit>
              </div>
            </Col>
          </Row> */}
          <Form.List name="workList">
            {(fields, { add, remove }) => {
              fields = [];
              let array = this.state.workList;
              if (array?.length >= 0) {
                for (var i = 0; i < array?.length; i++) {
                  fields.push({
                    name: i,
                    key: i,
                    fieldKey: i,
                  });
                }
              }
              let removefunc = (a, b) => {
                this.clickOnDelete(a);
                remove(b);
              };
              return (
                <div>
                  {fields?.map((field) => (
                    <MainBox>
                      <Box>
                        <Row gutter={24} style={{ marginBottom: '1rem' }}>
                          <Col span={12}>
                            <div
                              style={{ color: 'black', fontSize: '1.125em' }}
                            >
                              {field.fieldKey + 1}.
                            </div>
                          </Col>

                          <Col span={12} style={{ position: 'relative' }}>
                            <div
                              style={{
                                position: 'absolute',
                                right: '0',
                                display: 'flex',
                              }}
                            >
                              <Flexformbox style={{ width: '100%' }}>
                                <Button
                                  style={{
                                    color: '#EB5757',
                                    fontSize: '0.8750em',
                                    border: 'none',
                                  }}
                                  onClick={() => {
                                    removefunc(
                                      array[field.fieldKey],
                                      field.name,
                                    );
                                  }}
                                >
                                  <img src={Delete}></img>&nbsp;Delete
                                </Button>
                              </Flexformbox>
                            </div>
                          </Col>
                        </Row>

                        <Horizontalbox>
                          <Formitem
                            hidden
                            {...field}
                            name={[field.name, 'workExperienceId']}
                            fieldKey={[field.fieldKey, 'id']}
                            initialValue={array[field.fieldKey].id}
                          ></Formitem>
                          <Flexformbox style={{ width: '100%' }}>
                            <Label>Position Name</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'position']}
                              fieldKey={[field.fieldKey, 'position']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Position Name',
                                },
                              ]}
                              initialValue={array[field.fieldKey].position}
                            >
                              <Inputdata />
                            </Formitem>
                          </Flexformbox>
                        </Horizontalbox>

                        <Horizontalbox>
                          <Flexformbox style={{ width: '100%' }}>
                            <Label>Company Name</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'companyName']}
                              fieldKey={[field.fieldKey, 'companyName']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Company Name',
                                },
                              ]}
                              initialValue={array[field.fieldKey].companyName}
                            >
                              <Inputdata />
                            </Formitem>
                          </Flexformbox>
                        </Horizontalbox>

                        <Horizontalbox>
                          <Flexformbox left>
                            <Label>Job Start Date</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'startDate']}
                              fieldKey={[field.fieldKey, 'startDate']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Job Start Date',
                                },
                              ]}
                              initialValue={
                                array[field.fieldKey].startDate == ''
                                  ? ''
                                  : moment(
                                      array[field.fieldKey].startDate,
                                      'YYYY-MM-DD',
                                    )
                              }
                            >
                              <Datepicker format="DD/MM/YYYY" />
                            </Formitem>
                          </Flexformbox>
                          <Flexformbox>
                            <Label>Job End Date</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'endDate']}
                              fieldKey={[field.fieldKey, 'endDate']}
                              dependencies={[
                                ['workList', field.fieldKey, 'startDate'],
                                ['workList', field.fieldKey, 'endDate'],
                              ]}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Job End Date',
                                },
                                () => ({
                                  validator: (_, value) => {
                                    if (
                                      moment(
                                        this.formRef.current.getFieldValue([
                                          'workList',
                                          field.fieldKey,
                                          'startDate',
                                        ]),
                                      ) > moment(value)
                                    ) {
                                      return Promise.reject(
                                        'Job End Date must be greater than Job Start Date.',
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                              initialValue={
                                array[field.fieldKey].endDate == ''
                                  ? ''
                                  : moment(
                                      array[field.fieldKey].endDate,
                                      'YYYY-MM-DD',
                                    )
                              }
                            >
                              <Datepicker format="DD/MM/YYYY" />
                            </Formitem>
                          </Flexformbox>
                        </Horizontalbox>
                        <Horizontalbox>
                          <Flexformbox style={{ width: '100%' }}>
                            <Label>Description</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'description']}
                              fieldKey={[field.fieldKey, 'description']}
                              initialValue={array[field.fieldKey].description}
                            >
                              <Inputdata />
                            </Formitem>
                          </Flexformbox>
                        </Horizontalbox>

                        <Line></Line>
                      </Box>
                    </MainBox>
                  ))}

                  <Form.Item>
                    <Button type="dashed" onClick={this.addNewRow}>
                      Add Experiences
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </MainBox>
    );
  };
  renderDefaultView = () => {
    return (
      <MainBox>
        {/* <Row gutter={24}>
          <Col span={12}>
            <Headdetail>Work Experiences</Headdetail>
          </Col>
          <Col span={12} style={{ position: 'relative' }}>
            <Buttonedit
              style={{ position: 'absolute', right: '0' }}
              onClick={this.changeEditMode}
            >
              Edit
            </Buttonedit>
          </Col>
        </Row> */}
        <Box>
          {/* <Subbox>
            <Flexbox one style={{ justifyContent: 'flex-end' }}>
              <Bluecircle>
                <Bluecircle small></Bluecircle>
              </Bluecircle>
              <Verticalline
                style={{
                  position: 'absolute',
                  top: '1em',
                  height: 'calc(100% - 1em)',
                  display:
                    this.props.data.workExperience?.workExperiences?.length == 0
                      ? 'none'
                      : 'block',
                }}
              ></Verticalline>
            </Flexbox>
            <Flexbox two top>
              <div style={{ display: 'flex' }}>
                <Text big style={{ color: '#004368' }}>
                  {this.props.data.personalDetail.position}
                </Text>
                <Text
                  style={{ position: 'absolute', right: '0', color: '#004368' }}
                >
                  {moment(
                    this.props.data.personalDetail.startJobDate,
                    'YYYY-MM-DD'
                  ).format('MMM YYYY')}
                  &nbsp;-&nbsp;Currently
                </Text>
              </div>
              <Text medium>Playtorium Solutions Company Limited</Text>
            </Flexbox>
          </Subbox> */}
          {this.props.data.workExperience?.workExperiences?.map((val, idx) => (
            <div style={{ display: !!val.companyName ? 'block' : 'none' }}>
              <Subbox>
                <Flexbox one>
                  <Greycircle>
                    <Greycircle small></Greycircle>
                  </Greycircle>
                  <Verticalline
                    style={{
                      height:
                        idx ==
                        this.props.data.workExperience?.workExperiences
                          ?.length -
                          1
                          ? 'calc(0.75rem + 1em)'
                          : '100%',
                    }}
                  ></Verticalline>
                </Flexbox>
                <Flexbox two>
                  <div style={{ display: 'flex' }}>
                    <Text big>{val.position}</Text>
                    <Text style={{ position: 'absolute', right: '0' }}>
                      {moment(val.startDate, 'YYYY-MM-DD').format('MMM YYYY')}
                      &nbsp;-&nbsp;
                      {moment(val.endDate, 'YYYY-MM-DD').format('MMM YYYY')}
                    </Text>
                  </div>
                  <Text medium>{val.companyName}</Text>
                  <Text>{val.description}</Text>
                </Flexbox>
              </Subbox>
            </div>
          ))}
        </Box>
        {/* doesnt count current position at playtorium?? */}
        {this.props.data.workExperience?.workExperiences?.length == 0 ? (
          <></>
        ) : (
          <Line style={{ marginTop: '1rem' }}></Line>
        )}
        <Horizontalbox>
          <FlexBox Left style={{ marginBottom: 0 }}>
            <Label>Update Date</Label>
            <Form.Item
              style={{ width: '100%', margin: '0' }}
              name="updateDatetime"
            >
              <Text medium>
                {this.props.data.workExperience?.updateUserInfo?.updateDatetime
                  ? moment(
                      this.props.data.workExperience?.updateUserInfo
                        ?.updateDatetime,
                      'YYYY-MM-DD',
                    ).format('DD MMM YYYY')
                  : '-'}
              </Text>
            </Form.Item>
          </FlexBox>
          <FlexBox style={{ marginBottom: 0 }}>
            <Label>Update By</Label>
            <Form.Item
              style={{ width: '100%', margin: '0' }}
              name="updateUserInfo"
            >
              <Text medium>
                {this.props.data.workExperience?.updateUserInfo?.updateDatetime
                  ? `${this.props.data.workExperience?.updateUserInfo?.firstnameEn} ${this.props.data.workExperience?.updateUserInfo?.lastnameEn}`
                  : '-'}
              </Text>
            </Form.Item>
          </FlexBox>
        </Horizontalbox>
      </MainBox>
    );
  };

  render() {
    return (
      <>
        {/* <ProfileTabHeader
          title='Work Experiences'
          icon={workExperiencesIcon}
          isInEditMode={this.state.isInEditMode}
          changeEditMode={this.changeEditMode}
          id='workExperiences'
        /> */}
        <DetailBox>
          {this.props.isInEditMode
            ? this.renderEditView()
            : this.renderDefaultView()}
        </DetailBox>
      </>
    );
  }
}
export default Workexperience;
