import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import Icon from '@ant-design/icons';
import playIcon from '../assets/icons/playIconTextWhite.svg';
import playIconSmall from '../assets/icons/playIconWhiteSmall.svg';
import { ReactComponent as timesheetIcon } from '../assets/icons/timesheet.svg';
import { ReactComponent as projectIcon } from '../assets/icons/project.svg';
import { ReactComponent as userIcon } from '../assets/icons/user.svg';
import { ReactComponent as leaveIcon } from '../assets/icons/leave.svg';
import { ReactComponent as holidaysIcon } from '../assets/icons/holidays.svg';
import { ReactComponent as announceIcon } from '../assets/icons/announce.svg';
import { ReactComponent as ReportIcon } from '../assets/icons/report.svg';
import { ReactComponent as settingIcon } from '../assets/icons/setting.svg';
import { ReactComponent as salaryCertificateIcon } from '../assets/icons/salaryCertificate.svg';
import { ReactComponent as medicalClaimIcon } from '../assets/icons/medicalClaim.svg';
import { ReactComponent as payslipIcon } from '../assets/icons/payslip.svg';

const { Sider } = Layout;

const Sidebar = styled(Sider)`
  &.ant-layout-sider {
    background-color: #004368;
  }
  &.ant-layout-sider > .ant-layout-sider-children > .ant-menu {
    background-color: #004368;
  }
  &.ant-layout-sider > .ant-layout-sider-children > .ant-menu > .ant-menu-item {
    background-color: #004368;
  }
  &.ant-layout-sider > .ant-layout-sider-trigger {
    background-color: #004368;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    background-color: #1890ff;
  }
`;

const NavbarIcon = styled(Icon)`
  &.anticon {
    font-size: 18px;
  }
  & > svg {
    fill: transparent;
  }
  & > svg > path {
    stroke: ${(props) => (props.selected ? 'white' : null)};
  }
`;

// TODO each item should have AllowRole for easy to check Role if there another page like /setting
const items = [
  { path: '/announce', icon: announceIcon, label: 'Announce' },
  { path: '/timesheet', icon: timesheetIcon, label: 'Timesheet' },
  { path: '/leavepage', icon: leaveIcon, label: 'Leave' },
  { path: '/payslip', icon: payslipIcon, label: 'Pay Slip' },
  {
    path: '/salary-certificate',
    icon: salaryCertificateIcon,
    label: 'Salary Certificate',
  },
  { path: '/medical-claim', icon: medicalClaimIcon, label: 'Medical Claim' },
  { path: '/report', icon: ReportIcon, label: 'Report' },
  { path: '/project', icon: projectIcon, label: 'Project' },
  { path: '/holiday', icon: holidaysIcon, label: 'Holidays' },
  { path: '/user', icon: userIcon, label: 'User' },
  { path: '/settings', icon: settingIcon, label: 'Settings' },
];

const Navbar = ({ selectedNavbar, role }) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Sidebar
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{
        top: 0,
        left: 0,
        background: '#004368',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '21.5px',
          borderBottom: '1px solid #005D90',
        }}
      >
        <img src={collapsed ? playIconSmall : playIcon} />
      </div>
      <Menu
        mode="inline"
        theme="dark"
        selectedKeys={[`navbar${selectedNavbar}`]}
      >
        {items.map(({ path, icon, label }) =>
          role !== 'ADMIN' && path === '/settings' ? (
            <></>
          ) : (
            <Menu.Item
              key={`navbar${label}`}
              icon={
                <NavbarIcon
                  component={icon}
                  selected={selectedNavbar === label}
                />
              }
            >
              <span>{label}</span>
              <NavLink to={path} />
            </Menu.Item>
          ),
        )}
      </Menu>
    </Sidebar>
  );
};

export default Navbar;
