import React, { Component } from 'react';
import {
  Form,
  Select,
  Table,
  Row,
  Col,
  Modal,
  Input,
  Button,
  message,
  Switch,
  Popover,
  Popconfirm,
  Spin,
} from 'antd';
import styled from 'styled-components';
import { MasterdataService } from '../../services/api';
import actiona from '../../assets/icons/actiona.svg';
import binIcon from '../../assets/icons/bin.svg';
import { PlusOutlined } from '@ant-design/icons';
import { AppContext } from '../../components/context/AppContext';
import statusActiveIcon from '../../assets/icons/statusActive.svg';
import statusInactiveIcon from '../../assets/icons/statusInactive.svg';
import { redirectTo } from '../../services/redirect';
import CreateOrEditSettingsModal from '../../components/settings/createOrEditSettingModal';

const TitleBox = styled.div`
  box-sizing: border-box;
  padding: 20px 3% 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  justify-content: space-between;
  align-items: center;
`;

const ActionBox = styled.div`
  box-sizing: border-box;
  padding: 20px 3%;
  background: ${(props) => props.bg || '#ffffff'};
`;

const Selectdata = styled(Select)`
  width: 12rem;
  height: 2rem;
`;

const TableText = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.isKey ? '#004368' : '#737373'};
  font-size: 1em;
  font-weight: ${(props) =>
    props.title ? '600' : props.isKey ? '500' : '300'};
  font-family: Kanit;
  line-height: 22px;
  text-align: left;
`;
const ProjectTable = styled(Table)`
  & .ant-table.ant-table-small .ant-table-thead > tr > th {
    font-size: 16px;
    font-weight: bold;
    background-color: white;
    padding-bottom: 20px;
  }
  &.table-striped-rows tr > th {
    background-color: #ffffff;
  }
`;

const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#ffffff')};
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  /* flex-direction: row; */
  /* align-items: center; */
  width: auto;
  min-height: calc(100vh - 7.5em);
`;

const RejectedButton = styled(Button)`
  min-width: 106px;
  border-radius: 5px;
  display: flex;
  background-color: white;
  border: 1px solid #f2f2f2;
  justify-content: center;
  color: #232323;
  font-family: Kanit;
  font-style: Regular;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 5px 15px;
  align-items: center;
  &:disabled {
    border: 1px solid white !important;
  }
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  border-radius: 5px;
  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  // justify-content: center;
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Kanit;
  font-style: Regular;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid white !important;
    background-color: #f5f5f5 !important;
  }
`;

const Text = styled.div`
  color: '#232323';
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1.125em' : '16px'};
  font-weight: ${(props) =>
    props.title ? 'bold' : props.subtitle ? '600' : 'normal'};
  font-family: 'Kanit';
  text-transform: ${(props) => (props.subtitle ? 'uppercase' : 'none')};
`;

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createOrEditModalVisible: false,
      modalMode: null,
      tableColumns: [],
      dataSource: null,
      isLoading: false,
      seletedSettingTypeIndex: 0,
      selectedRecord: null,
    };
  }

  // Definition:
  // Label - display in dropdown
  // Title - display in head of table
  // dataIndex - use in columns table
  // primaryKey - use for delete
  // payloadPrimaryKey - use in send primary key(id) in payload
  // payloadFields - what fields do you want to add or edit in database
  //               -- label - display title
  //               -- key   - use in payload
  settingTypeList = [
    {
      label: 'University / Institution',
      title: 'INSTITUTION NAME',
      dataIndex: 'institutionName',
      primaryKey: 'id',
      fetchApi: MasterdataService.fetchInstitutionList,
      addingApi: MasterdataService.addInstitution,
      updateApi: MasterdataService.updateInstitution,
      deleteApi: MasterdataService.deleteInstitution,
      payloadPrimaryKey: 'institutionId',
      payloadFields: [{ label: 'Institution Name', key: 'institutionName' }],
    },
    {
      label: 'Education Major',
      title: 'Education Major Name',
      dataIndex: 'educationMajorName',
      primaryKey: 'id',
      fetchApi: MasterdataService.fetchEducationMajorList,
      addingApi: MasterdataService.addEducationMajor,
      updateApi: MasterdataService.updateEducationMajor,
      deleteApi: MasterdataService.deleteEducationMajor,
      payloadPrimaryKey: 'educationMajorId',
      payloadFields: [
        { label: 'Education Major Name', key: 'educationMajorName' },
      ],
    },
    {
      label: 'Department',
      title: 'Department Name',
      dataIndex: 'name',
      primaryKey: 'id',
      fetchApi: MasterdataService.fetchDepartmentList,
      addingApi: MasterdataService.addDepartment,
      updateApi: MasterdataService.updateDepartment,
      deleteApi: MasterdataService.deleteDepartment,
      payloadPrimaryKey: 'departmentId',
      payloadFields: [{ label: 'Department Name', key: 'name' }],
    },
    {
      label: 'Section',
      title: 'Section Name',
      dataIndex: 'sectionName',
      primaryKey: 'id',
      fetchApi: MasterdataService.fetchSectionList,
      addingApi: MasterdataService.addSection,
      updateApi: MasterdataService.updateSection,
      deleteApi: MasterdataService.deleteSection,
      payloadPrimaryKey: 'sectionId',
      payloadFields: [{ label: 'Section Name', key: 'sectionName' }],
    },
    {
      label: 'Position',
      title: 'Position Name',
      dataIndex: 'positionName',
      primaryKey: 'id',
      fetchApi: MasterdataService.fetchPositionList,
      addingApi: MasterdataService.addPosition,
      updateApi: MasterdataService.updatePosition,
      deleteApi: MasterdataService.deletePosition,
      payloadPrimaryKey: 'positionId',
      payloadFields: [{ label: 'Position Name', key: 'positionName' }],
    },
    {
      label: 'Level',
      title: 'Level Name',
      dataIndex: 'levelName',
      primaryKey: 'levelNo',
      fetchApi: MasterdataService.fetchLevelList,
      addingApi: MasterdataService.addLevel,
      updateApi: MasterdataService.updateLevel,
      deleteApi: MasterdataService.deleteLevel,
      payloadPrimaryKey: 'levelNo',
      payloadFields: [
        { label: 'Level No', key: 'levelNo', uneditable: true },
        { label: 'Level Name', key: 'levelName' },
        { label: 'Annual Leave Per Year', key: 'maxHrPerYear' },
      ],
    },
    {
      label: 'Skill',
      title: 'Skill Type Name',
      dataIndex: 'skillType',
      primaryKey: 'id',
      fetchApi: MasterdataService.fetchSkillList,
      addingApi: MasterdataService.addSkill,
      updateApi: MasterdataService.updateSkill,
      deleteApi: MasterdataService.deleteSkill,
      payloadPrimaryKey: 'computerSkillId',
      payloadFields: [{ label: 'Skill Type Name', key: 'skillType' }],
    },
    {
      label: 'Task',
      title: 'Task Type Name',
      dataIndex: 'taskTypeName',
      primaryKey: 'id',
      fetchApi: MasterdataService.fetchTaskList,
      addingApi: MasterdataService.addTask,
      updateApi: MasterdataService.updateTask,
      deleteApi: MasterdataService.deleteTask,
      payloadPrimaryKey: 'taskTypeId',
      payloadFields: [{ label: 'Task Type Name', key: 'taskTypeName' }],
    },
    {
      label: 'Project Type',
      title: 'Project Type Name',
      dataIndex: 'projectTypeName',
      primaryKey: 'id',
      fetchApi: MasterdataService.fetchProjectTypeList,
      addingApi: MasterdataService.addProjectType,
      updateApi: MasterdataService.updateProjectType,
      deleteApi: MasterdataService.deleteProjectType,
      payloadPrimaryKey: 'projectTypeId',
      payloadFields: [{ label: 'Project Type Name', key: 'projectTypeName' }],
    },
  ];

  componentDidMount = () => {
    this.fetchDataSource(0);
  };

  defaultColumns = (index) => {
    const setting = this.settingTypeList[index];
    const defaultStringCompare = (property) => (a, b) =>
      a[property].localeCompare(b[property]);
    return [
      {
        title: setting.title,
        dataIndex: setting.dataIndex,
        fixed: 'left',
        align: 'center',
        sorter: defaultStringCompare(setting.dataIndex),
        render: (value) => <TableText>{value}</TableText>,
      },
      {
        title: 'STATUS',
        index: 'isActived',
        dataIndex: 'isActived',
        width: '10%',
        align: 'center',
        render: (isActived) => (
          <Popover
            content={
              <span style={{ display: 'flex' }}>
                <img
                  src={!isActived ? statusInactiveIcon : statusActiveIcon}
                  style={{ marginRight: '5px' }}
                />
                <span>{isActived ? 'Active' : 'Inactive'}</span>
              </span>
            }
            arrowPointAtCenter
          >
            <img
              src={!isActived ? statusInactiveIcon : statusActiveIcon}
              style={{ marginRight: '5px' }}
            />
          </Popover>
        ),
      },

      {
        title: 'ACTION',
        align: 'center',
        width: '10%',
        render: (key, record) => (
          <div style={{ textAlign: '' }}>
            <a
              style={{ marginRight: '16px' }}
              onClick={() => this.handleClickEditRecord(record)}
            >
              <img src={actiona} />
            </a>
            {record.isDeletable ? (
              <Popconfirm
                title="Are you sure to delete？"
                okText="OK"
                cancelText="Cancel"
                okButtonProps={{
                  style: {
                    backgroundColor: '#004368',
                    borderColor: '##004368',
                  },
                }}
                onConfirm={() => {
                  this.handleConfirmDeleteRecord(record);
                }}
                onCancel={() => {}}
              >
                <img src={binIcon} />
              </Popconfirm>
            ) : (
              <div style={{ width: '24px', display: 'inline-block' }}></div>
            )}
          </div>
        ),
      },
    ];
  };

  fetchDataSource = (index) => {
    const setting = this.settingTypeList[index];
    this.setState({
      isLoading: true,
    });
    setting.fetchApi(
      undefined,
      ({ data }) => {
        this.setState({
          isLoading: false,
          dataSource: data,
          tableColumns: [...this.defaultColumns(index)],
        });
      },
      (response) => {
        if (response) message.error(`Error ${response.data.message}`);
        this.setState({
          isLoading: false,
        });
      },
    );
  };

  handleCancelModal = () => {
    this.setState({
      createOrEditModalVisible: false,
      modalMode: null,
      selectedRecord: null,
    });
  };

  handleChangeSettingType = (value) => {
    this.fetchDataSource(value);
    this.setState({
      seletedSettingTypeIndex: value,
    });
  };

  handleClickAddRecord = () => {
    this.setState({
      createOrEditModalVisible: true,
      modalMode: 'create',
      selectedRecord: null,
    });
  };

  handleClickEditRecord = (record) => {
    this.setState({
      createOrEditModalVisible: true,
      modalMode: 'edit',
      selectedRecord: record,
    });
  };

  handleConfirmDeleteRecord = (record) => {
    const setting = this.settingTypeList[this.state.seletedSettingTypeIndex];
    const params = record[setting.primaryKey];
    setting.deleteApi(
      params,
      ({ data }) => {
        message.success('Delete success');
        this.fetchDataSource(this.state.seletedSettingTypeIndex);
      },
      (response) => {
        if (response) message.error(`Error ${response.data.message}`);
        this.setState({
          isLoading: false,
        });
      },
    );
  };

  render() {
    return (
      <Spin spinning={this.state.isLoading}>
        <TitleBox>
          <Text>Setting Types</Text>
        </TitleBox>

        <ActionBox>
          <Row align="middle">
            <Col>
              <Selectdata
                onChange={this.handleChangeSettingType}
                defaultValue={0}
              >
                {this.settingTypeList.map((item, index) => (
                  <Select.Option value={index}>{item.label}</Select.Option>
                ))}
              </Selectdata>
            </Col>
            <Col flex="auto" />
            <Col>
              <NormalButton
                onClick={() => {
                  redirectTo('/settings/decrypt');
                }}
                style={{ width: 135, marginRight: '24px' }}
              >
                Decryption
              </NormalButton>
              <NormalButton
                icon={<PlusOutlined />}
                onClick={this.handleClickAddRecord}
                style={{ width: 135 }}
              >
                Add Record
              </NormalButton>
            </Col>
          </Row>
        </ActionBox>

        <ContentBox>
          <ProjectTable
            className="table-striped-rows"
            columns={this.state.tableColumns}
            scroll={{ x: 300 }}
            dataSource={this.state.dataSource}
            rowKey="id"
            style={{ width: '100%' }}
            size="small"
            indentSize={1}
            pagination={{
              position: ['bottomRight'],
              pageSizeOptions: [10, 20, 30, 40],
              showSizeChanger: true,
            }}
          />
        </ContentBox>

        <CreateOrEditSettingsModal
          createOrEditModalVisible={this.state.createOrEditModalVisible}
          modalMode={this.state.modalMode}
          handleCancelModal={this.handleCancelModal}
          settingType={this.settingTypeList[this.state.seletedSettingTypeIndex]}
          selectedRecord={this.state.selectedRecord}
          fetchDataSource={() =>
            this.fetchDataSource(this.state.seletedSettingTypeIndex)
          }
        />
      </Spin>
    );
  }
}

export default Settings;
