import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Checkbox,
  Input,
  Table,
  Row,
  message,
  Modal,
  Form,
  DatePicker,
  TimePicker,
  Select,
  Divider,
  Col,
  Space,
} from 'antd';
import moment from 'moment';
import { redirectTo } from '../../services/redirect';
import { leaveService } from '../../services/api';
import { imageservice } from '../../services/api';
import { profileservice } from '../../services/api';
import actiona from '../../assets/icons/actiona.svg';
import eye from '../../assets/icons/eye.svg';
import profileimage from '../../assets/icons/profileimage.svg';
import { PlusOutlined } from '@ant-design/icons';

import { AppContext } from '../../components/context/AppContext';
import { holidayService } from '../../services/api';
//#region
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.subtitle ? '#20ABC5' : '#ff2132'};
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1em' : '1em'};
  font-weight: ${(props) =>
    props.title ? 'bold' : props.subtitle ? '600' : 'normal'};
  font-family: 'Kanit';
  text-transform: ${(props) => (props.subtitle ? 'uppercase' : 'none')};
`;

const RejectedButton = styled(Button)`
  min-width: 106px;
  border-radius: 5px;
  display: flex;
  background-color: white;
  border: 1px solid #f2f2f2;
  justify-content: center;
  color: #232323;
  font-family: Kanit;
  font-style: Regular;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 5px 15px;
  align-items: center;
  &:disabled {
    border: 1px solid white !important;
  }
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  border-radius: 5px;
  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Kanit;
  font-style: Regular;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid white !important;
    background-color: #f5f5f5 !important;
  }
`;
const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: end;
  align-items: center;
`;
const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#ffffff')};
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  /* flex-direction: row; */
  /* align-items: center; */
  width: auto;
  min-height: calc(100vh - 200px);
`;
const TableText = styled.div`
  color: ${(props) => (props.name ? '#004368' : '#737373')};
  font-size: ${(props) => (props.description ? '12px' : '14px')};
  font-weight: ${(props) =>
    props.name ? '500' : props.description ? '300' : '400'};
  font-family: 'Kanit';
  line-height: 18px;
  text-align: left;
`;
const ProjectTable = styled(Table)`
  & .ant-table.ant-table-small .ant-table-thead > tr > th {
    font-size: 16px;
    font-weight: bold;
    background-color: white;
    padding-bottom: 20px;
  }

  &.table-striped-rows tr > th {
    background-color: #ffffff;
  }
  &.table-striped-rows tr > td {
    height: 50px;
  }
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 6px 8px;
  }
`;
const Rows = styled(Row)`
  margin: 0px 0px;
`;

const Headdetail = styled.div`
  font-family: 'Kanit';
  font-size: 18px;
  font-weight: 500;
`;

const Label = styled.div`
  font-family: 'Kanit';
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin: 0px 1rem;
  color: ${(props) => (props.grey ? '#737373' : '#232323')};
`;

const LeaveCount = styled.div`
  color: #232323;
  width: auto;
  border-radius: 5px;
  height: 2rem;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  justify-content: space-between;
`;

const LilHeader = styled.div`
  max-width: 400px;
  //styleName: Body2 (light 12);
  font-family: Kanit;
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
`;

const LilHeader2 = styled.div`
  //styleName: Title Body2 (reg 12);
  font-family: Kanit;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #acacac;
`;

const BigHeader = styled.div`
  //styleName: Title Box (medium 16);
  font-family: Kanit;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

const BigHeader2 = styled.div`
  //styleName: Title Box (medium 16);
  font-family: Kanit;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #004368;
`;

const Selectdata = styled(Select)`
  /* width: 10rem; */
  border-radius: 5px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Circle = styled.img`
  margin: 0;
  background: ${(props) =>
    props.small ? (props.src ? 'url(' + props.src + ')' : 'grey') : 'white'};
  background-repeat: no-repeat;
  background-size: 9.313rem;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Break = styled(Divider)`
  margin: 12px 0;
`;

const FieldSection = styled(Form.Item)`
  margin-bottom: 20px;
`;

const FormLabel = styled.div`
  font-size: 14px;
  font-weight: 600;

  margin-bottom: 8px;
`;

const SelectTimeSlots = styled(Select)`
  // max-width:14rem;
  width: 10rem;
  height: 2rem;
  // margin-left:2rem;
`;

const { Search } = Input;
const { TextArea } = Input;
const { confirm } = Modal;
//#endregion

class LeavePageEmployee extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.filterFormRef = React.createRef();
    this.state = {
      userCurrentRole: null,
      userData: null,
      userInfo: null,
      remainingTime: null,
      datamain: null,
      isLoading: true,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      annual: 0,
      personal: 0,
      sick: 0,
      modalVisible: false,
      addLeaveModalVisible: false,
      modalEdit: false,
      record: null,
      image: null,
      image2: null,
      isPending: false,
      isApprove: false,
      isCancelUser: false,
      editable: false,
      remarkText: null,
      Comment: false,
      userId: null,
      type: 'All',
      searchWord: null,
      addForm: {
        isFullDay: false,
        isDifferentDay: false,
      },
      selectedStartLeaveDate: moment(),

      prevFormStartTime: moment({ hour: 9, minute: 0 }).format('HH:mm:ss'),
      prevFormEndTime: moment({ hour: 18, minute: 0 }).format('HH:mm:ss'),
    };

    const defaultStringCompare = (property) => (a, b) => {
      let result;

      if (property === 'employeeName') {
        if (a.userInfo.firstnameEn.localeCompare(b.userInfo.firstnameEn) != 0)
          result = a.userInfo.firstnameEn.localeCompare(b.userInfo.firstnameEn);
        else
          result = a.userInfo.lastnameEn.localeCompare(b.userInfo.lastnameEn);
      } else result = String(a[property]).localeCompare(String(b[property]));

      return result;
    };
    this.columns = [
      {
        title: 'LEAVE TYPE',
        dataIndex: 'leaveName',
        width: '12%',
        align: 'center',
        sorter: defaultStringCompare('leaveName'),
        render: (value) => (
          <TableText style={{ textAlign: 'center' }}>
            {value == 'ANNUAL'
              ? 'Annual Leave'
              : value == 'PERSONAL'
              ? 'Personal Leave'
              : 'Sick Leave'}
          </TableText>
        ),
      },

      {
        title: 'PURPOSE',
        dataIndex: 'purpose',
        align: 'center',
        sorter: defaultStringCompare('purpose'),
        render: (name, record) => (
          <div>
            <TableText name>{record.purpose}</TableText>
          </div>
        ),
      },
      {
        title: 'START DATE',
        dataIndex: 'startDate',
        width: '11%',
        align: 'center',
        sorter: defaultStringCompare('startDate'),
        render: (value) => (
          <TableText style={{ textAlign: 'center' }}>
            {moment(value).format('DD/MM/YYYY')}
          </TableText>
        ),
      },
      {
        title: 'END DATE',
        dataIndex: 'endDate',
        width: '11%',
        align: 'center',
        sorter: defaultStringCompare('endDate'),
        render: (value) => (
          <TableText style={{ textAlign: 'center' }}>
            {moment(value).format('DD/MM/YYYY')}
          </TableText>
        ),
      },
      {
        title: 'TOTAL',
        dataIndex: 'leaveHour',
        width: '7%',
        align: 'center',
        render: (value) => (
          <TableText style={{ textAlign: 'center' }}>
            {Math.floor(value / 8).toString()} :{' '}
            {Math.floor(value % 8).toString()}
          </TableText>
        ),
      },

      {
        title: 'STATUS',
        dataIndex: 'leaveStatus',
        width: '9%',
        align: 'center',
        sorter: defaultStringCompare('leaveStatus'),
        render: (value) => (
          <TableText style={{ textAlign: 'center' }}>
            {value == 'Pending' ? (
              <label style={{ color: '#FFAB4F' }}> Pending</label>
            ) : value == 'Approved' ? (
              <label style={{ color: '#3C8618' }}>Approved</label>
            ) : value == 'Rejected' ? (
              <label style={{ color: '#668EA4' }}>Rejected</label>
            ) : value == 'Canceled by Approver' ? (
              <label style={{ color: '#D62923' }}>Canceled by Approver</label>
            ) : (
              <label style={{ color: '#F37370' }}>Canceled by User</label>
            )}
          </TableText>
        ),
      },
      {
        title: 'ACTION',
        align: 'center',
        width: '10%',
        render: (key, record) => (
          <a onClick={() => this.modalOpen(key, record)}>
            {record.leaveStatus == 'Pending' ? (
              <img src={actiona} />
            ) : (
              <img src={eye} />
            )}
          </a>
        ),
      },
    ];
  }

  formRef = React.createRef();
  leaveFormRef = React.createRef();

  fetchLeave = () => {
    var jwt = require('jsonwebtoken');
    var token = sessionStorage.getItem('access-token');
    var decode1 = jwt.decode(token);
    const userId = decode1.userId;
    const defaultRemainingTime = { hourRemain: 0 };

    this.setState({
      isLoading: true,
      userId: userId,
    });
    const payload = {
      year: this.state.year,
    };

    leaveService.getleaveByYear(
      {
        year: this.state.year,
      },
      ({ data }) => {
        console.log(data);
        this.setState({
          isLoading: false,
          datamain: data.filter((leave) => leave.userId === userId),
          annual: data.filter((data) => data.leaveName == 'ANNUAL').length,
          personal: data.filter((data) => data.leaveName == 'PERSONAL').length,
          sick: data.filter((data) => data.leaveName == 'SICK').length,
        });
        this.filterChange(this.state.searchWord, this.state.type);
      },
      (response) => {
        this.setState({
          isLoading: false,
          data: null,
        });
        if (response && response.status === 400) {
          message.error(`Cannot access data - ${response.data.message}`);
        } else {
          //message.error(response);
        }
      },
    );

    leaveService.getRemainByUserId(
      userId,
      ({ data }) => {
        console.log(data);
        this.setState({
          remainingTime: {
            annual: data[0] ? data[0] : defaultRemainingTime,
            personal: data[1] ? data[1] : defaultRemainingTime,
            sick: data[2] ? data[2] : defaultRemainingTime,
          },
        });
        console.log(this.state.remainingTime.annual.hourRemain);
      },
      (response) => {
        this.setState({
          isLoading: false,
          remainingTime: null,
        });
        if (response && response.status === 400) {
          message.error(`Cannot access data - ${response.data.message}`);
        } else {
          //message.error('getRemainByUserId Error');
        }
      },
    );

    profileservice.personalDetail.getpersonalDetail(
      userId,
      ({ data }) => {
        console.log(data);
        this.setState({
          userInfo: data,
        });
      },
      (response) => {
        this.setState({
          isLoading: false,
          userInfo: null,
        });
        if (response && response.status === 400) {
          message.error(`Cannot access data - ${response.data.message}`);
        } else {
          message.error('Error');
        }
      },
    );
  };

  componentDidMount = () => {
    const { userState } = this.context;

    this.setState({
      userCurrentRole: userState.currentRole,
    });

    this.fetchLeave();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.addLeaveModalVisible) {
      if (this.state.addForm.isFullDay != prevState.addForm.isFullDay) {
        // toggle all day
        if (this.state.addForm.isFullDay) {
          this.leaveFormRef.current.setFieldsValue({
            startTime: moment({ hour: 9, minute: 0 }).format('HH:mm:ss'),
            endTime: moment({ hour: 18, minute: 0 }).format('HH:mm:ss'),
          });
        } else {
          this.leaveFormRef.current.setFieldsValue({
            startTime: this.state.prevFormStartTime,
            endTime: this.state.prevFormEndTime,
          });
        }
      }
    }
  };

  handleRedirect = (path) => {
    redirectTo(path);
  };

  modalOpen = (payload, record) => {
    console.log(record);
    this.getPic(record);
    this.setState({
      record: record,
      modalVisible: true,
      isPending: record.leaveStatus == 'Pending',
      isApprove: record.leaveStatus == 'Approved',
      isCancelUser: record.leaveStatus == 'Canceled by User',
      editable: record.leaveStatus == 'Approved',
    });
  };

  modalClose = () => {
    this.setState({
      image: null,
      record: null,
      modalVisible: false,
      remarkText: null,
    });
  };

  handleDownloadReport = () => {
    console.log('download');
  };

  getPic = (value) => {
    if (value.userInfo.picturePath == null) {
      this.setState({
        image: profileimage,
      });
    } else {
      imageservice.image.getImage(this.state.picturePath, (res) => {
        if (!res.status) {
          const url = URL.createObjectURL(res.data);
          this.setState({
            image: url,
          });
        } else {
          this.setState({
            image: profileimage,
          });
        }
      });
    }
    if (value.lastUpdateUserInfo.picturePath == null) {
      this.setState({
        image2: profileimage,
      });
    } else {
      imageservice.image.getImage(
        value.lastUpdateUserInfo.picturePath,
        (res) => {
          if (!res.status) {
            const url = URL.createObjectURL(res.data);
            this.setState({
              image2: url,
            });
          } else {
            this.setState({
              image2: profileimage,
            });
          }
        },
      );
    }
  };

  onChange = ({ target: { value } }) => {
    this.setState({ remarkText: value });
  };

  onTypeFilterChange = (value) => {
    this.setState({ type: value });
    this.filterChange(this.state.searchWord, value);
  };

  onSearch = (value) => {
    this.setState({
      searchWord: value,
    });
    this.filterChange(value, this.state.type);
  };

  filterChange = (search, type) => {
    this.setState({
      data: this.state.datamain
        .filter((data) => {
          if (!search) {
            return true;
          } else {
            const q1 = search.toUpperCase().split(' ')[0];
            const q2 = search.toUpperCase().split(' ')[1];

            return (
              (String(data.userInfo.firstnameEn).toUpperCase().includes(q1) &&
                (!q2 ||
                  String(data.userInfo.lastnameEn)
                    .toUpperCase()
                    .includes(q2))) ||
              String(data.userInfo.employeeId)
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              String(data.purpose).toUpperCase().includes(search.toUpperCase())
            );
          }
        })
        .filter((data) => {
          if (type != 'All') {
            return data.leaveStatus == type;
          } else {
            return true;
          }
        }),
    });
  };

  onYearFilterChange = (value) => {
    this.setState(
      {
        month: moment(value).month() + 1,
        year: moment(value).year(),
      },
      () => this.fetchLeave(),
    );
  };

  onChangeApprove = () => {
    this.setState({ editable: !this.state.editable });
  };
  onPageSizeChange = (value) => {
    this.setState({ pageSize: value });
  };

  handelCancelRequest = () => {
    leaveService.editLeave(
      {
        leaveId: this.state.record.leaveId,
        leaveStatusId: 400,
      },
      (call) => {
        this.fetchLeave();
        this.modalClose();
        message.success('success');
      },
      (response) => {
        if (response && response.status === 400) {
          message.error(`Cannot access data - ${response.data.message}`);
        } else {
          message.error('Error');
        }
      },
    );
  };

  fetchHolidayByYear = async () => {
    return holidayService.getYear(
      this.state.selectedStartLeaveDate.year(),
      ({ data }) => {
        return data;
      },
      (response) => {
        if (response && response.status === 400) {
          message.error(`Cannot access data - ${response.data.message}`);
          return null;
        } else {
          //message.error('fetchHolidayByYear Error');
          return null;
        }
      },
    );
  };

  submitLeaveForm = async (values) => {
    const holidayByYear = await this.fetchHolidayByYear();
    const timestampFormatDB = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
    const dateFormat = 'DD/MM/YYYY';
    const startLeaveDate = values.dateRange[0];
    const endLeaveDate = values.dateRange[1];
    console.log(startLeaveDate);

    for (var i = 0; i < holidayByYear.length; i++) {
      //console.log(moment(holidayByYear[i]['holidayDate'], timestampFormatDB))
      const holidayMoment = moment(
        holidayByYear[i]['holidayDate'],
        timestampFormatDB,
      );
      if (
        startLeaveDate.isSame(holidayMoment, 'day') ||
        endLeaveDate.isSame(holidayMoment, 'day')
      ) {
        message.error(
          `Can\'t leave on holiday ${moment(
            holidayByYear[i]['holidayDate'],
            timestampFormatDB,
          )}`,
        );
        return;
      }
    }

    const payload = {
      startDate: values.dateRange[0].format('YYYY-MM-DD'),
      endDate: values.dateRange[1].format('YYYY-MM-DD'),
      isSpecificTime: !(
        this.state.addForm.isFullDay || this.state.addForm.isDifferentDay
      ),
      startTime:
        this.state.addForm.isFullDay || this.state.addForm.isDifferentDay
          ? null
          : values.dateRange[0].format('YYYY-MM-DD') +
            ' ' +
            values.startTime /* .format('HH:mm:ss') */,
      endTime:
        this.state.addForm.isFullDay || this.state.addForm.isDifferentDay
          ? null
          : values.dateRange[0].format('YYYY-MM-DD') +
            ' ' +
            values.endTime /* .format('HH:mm:ss') */,
      purpose: values.description,
      leaveTypeId: parseInt(values.leaveType),
    };
    console.log(payload);

    if (moment(values.dateRange[0]).isBefore()) {
      confirm({
        title: 'Warning!',
        content:
          'Leave start date is in the past. Can only be canceled by Admin. Are you sure?',
        onOk: () => {
          this.handleAddLeaveForm(payload);
        },
        onCancel: () => {},
      });
    } else {
      this.handleAddLeaveForm(payload);
    }
  };

  openAddLeaveModal = () => {
    this.setState({
      addLeaveModalVisible: true,
    });
  };

  closeAddLeaveModal = () => {
    this.setState({ addLeaveModalVisible: false });
    this.leaveFormRef.current.setFieldsValue({
      dateRange: [moment(), moment()],
      //startTime: '',
      //endTime: '',
      isFullDay: false,
      description: '',
    });

    this.setState({
      addForm: {
        isFullDay: false,
        isDifferentDay: false,
      },
    });
  };

  getTimeOption = () => {
    let res = [];
    let start = moment({ hour: 9, minute: 0 });
    while (start.hour() != 18 + 1) {
      res.push(
        <Select.Option value={start.format('HH:mm:ss')}>
          {start.format('HH:mm')}
        </Select.Option>,
      );
      start.add(1, 'hours');
    }

    return res;
  };

  handleAddLeaveForm = (payload) => {
    const messageKey = 'creating-leave';
    message.loading({ key: messageKey, content: 'Adding...', duration: 0 });
    leaveService.addLeave(
      payload,
      ({ data }) => {
        console.log(data);
        message.success({ key: messageKey, content: 'Success' });
        setTimeout(() => {
          this.fetchLeave();
        }, 1000);
        this.closeAddLeaveModal();
      },
      (response) => {
        console.log(response.data);
        if (response && response.status === 400) {
          message.error({
            key: messageKey,
            content: 'Error: ' + response.data.message,
          });
        } else {
          message.error({
            key: messageKey,
            content: 'Error',
          });
        }
      },
    );
  };

  render() {
    return (
      <div style={{ width: 'auto' }}>
        <Space
          direction="vertical"
          size="middle"
          style={{
            width: '100%',
            padding: '20px 3%',
            backgroundColor: '#ffffff',
          }}
        >
          <Row justify="space-between" gutter={[16, 16]}>
            <Col>
              <DatePicker
                defaultValue={moment()}
                picker="year"
                format="YYYY"
                //size='large'
                onChange={(value) => {
                  this.onYearFilterChange(value);
                }}
              />
            </Col>
            <Col flex="auto" />

            <Col>
              <Selectdata
                defaultValue={'All'}
                //size='large'
                style={{ width: 208 }}
                onChange={(value) => {
                  this.onTypeFilterChange(value);
                }}
              >
                <Select.Option value="All">All Status</Select.Option>
                <Select.Option value="Approved">Approved</Select.Option>
                <Select.Option value="Pending">Pending</Select.Option>
                <Select.Option value="Rejected">Rejected</Select.Option>
                <Select.Option value="Canceled by Approver">
                  Canceled by Approver
                </Select.Option>
                <Select.Option value="Canceled by User">
                  Canceled by User
                </Select.Option>
              </Selectdata>
            </Col>
          </Row>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col flex={1}>
              <LeaveCount>
                {this.state.year < moment().year() ? (
                  <>
                    <Label>Annual Leave Requested</Label>
                    <Label grey>{this.state.annual}</Label>
                  </>
                ) : this.state.year === moment().year() ? (
                  <>
                    <Label>Annual Leave</Label>
                    <Label grey>
                      {Math.floor(
                        this.state.remainingTime?.annual.hourRemain / 8,
                      ).toString()}
                      {' Day '}
                      {Math.floor(
                        this.state.remainingTime?.annual.hourRemain % 8,
                      ).toString()}
                      {' hr.'}
                    </Label>
                  </>
                ) : this.state.year > moment().year() ? (
                  <>
                    <Label>Annual Leave</Label>
                    <Label grey>
                      {Math.floor(
                        this.state.remainingTime?.annual.maxHrPerYear / 8,
                      ).toString()}
                      {' Day '}
                      {Math.floor(
                        this.state.remainingTime?.annual.maxHrPerYear % 8,
                      ).toString()}
                      {' hr.'}
                    </Label>
                  </>
                ) : (
                  <></>
                )}
              </LeaveCount>
            </Col>
            <Col flex={1}>
              <LeaveCount>
                {this.state.year < moment().year() ? (
                  <>
                    <Label>Personal Leave Requested</Label>
                    <Label grey>{this.state.personal}</Label>
                  </>
                ) : this.state.year === moment().year() ? (
                  <>
                    <Label>Personal Leave</Label>
                    <Label grey>
                      {Math.floor(
                        this.state.remainingTime?.personal.hourRemain / 8,
                      ).toString()}
                      {' Day '}
                      {Math.floor(
                        this.state.remainingTime?.personal.hourRemain % 8,
                      ).toString()}
                      {' hr.'}
                    </Label>
                  </>
                ) : this.state.year > moment().year() ? (
                  <>
                    <Label>Personal Leave</Label>
                    <Label grey>
                      {Math.floor(
                        this.state.remainingTime?.personal.maxHrPerYear / 8,
                      ).toString()}
                      {' Day '}
                      {Math.floor(
                        this.state.remainingTime?.personal.maxHrPerYear % 8,
                      ).toString()}
                      {' hr.'}
                    </Label>
                  </>
                ) : (
                  <></>
                )}
              </LeaveCount>
            </Col>
            <Col flex={1}>
              <LeaveCount>
                {this.state.year < moment().year() ? (
                  <>
                    <Label>Sick Leave Requested</Label>
                    <Label grey>{this.state.sick}</Label>
                  </>
                ) : this.state.year === moment().year() ? (
                  <>
                    <Label>Sick Leave</Label>
                    <Label grey>
                      {Math.floor(
                        this.state.remainingTime?.sick.hourRemain / 8,
                      ).toString()}
                      {' Day '}
                      {Math.floor(
                        this.state.remainingTime?.sick.hourRemain % 8,
                      ).toString()}
                      {' hr.'}
                    </Label>
                  </>
                ) : this.state.year > moment().year() ? (
                  <>
                    <Label>Sick Leave</Label>
                    <Label grey>
                      {Math.floor(
                        this.state.remainingTime?.sick.maxHrPerYear / 8,
                      ).toString()}
                      {' Day '}
                      {Math.floor(
                        this.state.remainingTime?.sick.maxHrPerYear % 8,
                      ).toString()}
                      {' hr.'}
                    </Label>
                  </>
                ) : (
                  <></>
                )}
              </LeaveCount>
            </Col>
            <Col flex="none">
              <NormalButton
                //size='large'
                style={{ fontSize: '14px', width: '208px' }}
                onClick={() => this.openAddLeaveModal()}
              >
                <PlusOutlined style={{ fontSize: '16px' }} />
                Create Request
              </NormalButton>
            </Col>
          </Row>
        </Space>

        <ContentBox>
          <ProjectTable
            className="table-striped-rows"
            columns={this.columns}
            scroll={{ x: '100%' }}
            dataSource={this.state.data}
            rowKey="id"
            style={{ width: '100%' }}
            size="small"
            indentSize={1}
            loading={this.state.isLoading}
            pagination={{
              position: ['bottomRight'],
              pageSizeOptions: [10, 20, 30, 40],
              showSizeChanger: true,
            }}
          />
        </ContentBox>
        <Modal
          width="450px"
          bodyStyle={{
            maxHeight: '800px',
          }}
          visible={this.state.modalVisible}
          centered
          footer={null}
          onCancel={() => this.modalClose()}
          maskClosable
        >
          <Row>
            <Col span={6}>
              <Space
                direction="vertical"
                align="center"
                size={6}
                style={{ width: '100%', paddingRight: 24 }}
              >
                <Circle width={66} height={66} src={this.state.image}></Circle>
                <Label style={{ textAlign: 'center' }}>
                  {this.state.userInfo?.employeeId}
                </Label>
              </Space>
            </Col>

            <Col span={18}>
              <LilHeader>Leave Request</LilHeader>
              <BigHeader>
                {this.state.userInfo?.firstnameEn}{' '}
                {this.state.userInfo?.lastnameEn}
              </BigHeader>
              <Rows
                style={{
                  width: '100%',
                  paddingTop: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Column style={{ alignItems: 'flex-start', width: '50%' }}>
                  <LilHeader2>Department</LilHeader2>
                  <LilHeader>{this.state.userInfo?.department}</LilHeader>
                </Column>
                <Column style={{ alignItems: 'flex-start', width: '50%' }}>
                  <LilHeader2>Position</LilHeader2>
                  <LilHeader>{this.state.userInfo?.position}</LilHeader>
                </Column>
              </Rows>
            </Col>
          </Row>
          <Break />
          <Rows style={{ justifyContent: 'space-between' }}>
            <BigHeader style={{ color: '#004368' }}>
              {this.state.record?.leaveName == 'PERSONAL'
                ? 'Personal Leave'
                : this.state.record?.leaveName == 'ANNUAL'
                ? 'Annual Leave'
                : 'Sick Leave'}
            </BigHeader>
            <TableText>
              {this.state.record?.leaveStatus == 'Pending' ? (
                <label style={{ color: '#FFAB4F' }}> Pending</label>
              ) : this.state.record?.leaveStatus == 'Approved' ? (
                <label style={{ color: '#3C8618' }}>Approved</label>
              ) : this.state.record?.leaveStatus == 'Rejected' ? (
                <label style={{ color: '#668EA4' }}>Rejected</label>
              ) : this.state.record?.leaveStatus == 'Canceled by Approver' ? (
                <label style={{ color: '#D62923' }}>Canceled by Approver</label>
              ) : (
                <label style={{ color: '#F37370' }}>Canceled by User</label>
              )}
            </TableText>
          </Rows>
          <Rows>
            <BigHeader2>
              {Math.floor(this.state.record?.leaveHour / 8).toString()}
              {' Day '}
              {Math.floor(this.state.record?.leaveHour % 8).toString()}
              {' hr.'}
            </BigHeader2>
          </Rows>
          <Rows>
            {this.state.record ? (
              Math.floor(this.state.record.leaveHour / 8) == 0 ? (
                <LilHeader>
                  {moment(this.state.record.startTime).format(
                    'DD MMMM YYYY HH.mm',
                  )}
                  {' - '}
                  {moment(this.state.record.endTime).format(' HH.mm')}
                </LilHeader>
              ) : Math.floor(this.state.record.leaveHour / 8) == 1 ? (
                <LilHeader>
                  {moment(this.state.record.startDate).format('DD MMMM YYYY')}
                </LilHeader>
              ) : (
                <LilHeader>
                  {moment(this.state.record.startDate).format('DD MMMM')}
                  {' - '}
                  {moment(this.state.record.endDate).format('DD MMMM YYYY')}
                </LilHeader>
              )
            ) : (
              ''
            )}
          </Rows>

          <Rows style={{ paddingTop: '10px' }}>
            <LilHeader2>Description</LilHeader2>
          </Rows>
          <Rows>
            <LilHeader>{this.state.record?.purpose}</LilHeader>
          </Rows>
          <Break />

          <Rows style={{ justifyContent: 'space-between' }}>
            <Column>
              <BigHeader2>
                {Math.floor(
                  this.state.remainingTime?.annual.hourRemain / 8,
                ).toString()}
                {' Day '}
                {Math.floor(
                  this.state.remainingTime?.annual.hourRemain % 8,
                ).toString()}
                {' hr.'}
              </BigHeader2>
              <LilHeader2>Annual Leave</LilHeader2>
            </Column>
            <Column>
              <BigHeader2>
                {Math.floor(
                  this.state.remainingTime?.personal.hourRemain / 8,
                ).toString()}
                {' Day '}
                {Math.floor(
                  this.state.remainingTime?.personal.hourRemain % 8,
                ).toString()}
                {' hr.'}
              </BigHeader2>
              <LilHeader2>Personal Leave</LilHeader2>
            </Column>
            <Column>
              <BigHeader2>
                {Math.floor(
                  this.state.remainingTime?.sick.hourRemain / 8,
                ).toString()}
                {' Day '}
                {Math.floor(
                  this.state.remainingTime?.sick.hourRemain % 8,
                ).toString()}
                {' hr.'}
              </BigHeader2>
              <LilHeader2>Sick Leave</LilHeader2>
            </Column>
          </Rows>

          {((!this.state.isPending && !this.state.isCancelUser) ||
            (this.state.isApprove && this.state.editable)) && (
            <div>
              <Break />
              <Rows>
                <Column
                  style={{
                    justifyContent: 'center',
                  }}
                >
                  <Circle
                    style={{
                      height: '45px',
                      width: '45px',
                    }}
                    src={this.state.image2}
                  ></Circle>
                </Column>

                <Column
                  style={{
                    paddingLeft: '15px',
                    alignItems: 'flex-start',
                    width: '60%',
                  }}
                >
                  <LilHeader>
                    {this.state.record
                      ? this.state.record.leaveStatus == 'Approved'
                        ? 'Approved by'
                        : this.state.record.leaveStatus ==
                          'Canceled by Approver'
                        ? 'Canceled by'
                        : 'Rejected by'
                      : ''}
                  </LilHeader>
                  <BigHeader2>
                    {this.state.record?.lastUpdateUserInfo?.firstnameEn}{' '}
                    {this.state.record?.lastUpdateUserInfo?.lastnameEn}
                  </BigHeader2>
                  <Rows style={{ width: '100%' }}>
                    <LilHeader>
                      {moment(this.state.record?.updateDatetime).format(
                        'DD MMMM YYYY, HH:mm',
                      )}
                    </LilHeader>
                  </Rows>
                </Column>
              </Rows>
              <Rows style={{ paddingTop: '10px' }}>
                <LilHeader2>Description</LilHeader2>
              </Rows>
              <Rows>
                {this.state.record ? (
                  this.state.record.remark ? (
                    <LilHeader>{this.state.record.remark}</LilHeader>
                  ) : (
                    <LilHeader>{this.state.record.remark}</LilHeader>
                  )
                ) : (
                  ''
                )}
              </Rows>
              <Rows style={{ paddingTop: '10px' }}>
                {this.state.record ? (
                  this.state.record.approveInfo ? (
                    <div>
                      <Rows>
                        <LilHeader2>Description for cancellation</LilHeader2>
                      </Rows>
                      <LilHeader>{this.state.record.remark}</LilHeader>
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </Rows>
            </div>
          )}

          {(this.state.isPending ||
            (this.state.isApprove && !this.state.editable)) && (
            <Rows
              style={{
                paddingTop: '10px',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
            >
              {/* <ButtonBlock width='100px'> */}
              {moment(this.state.record?.startDate).isBefore() ? (
                <RejectedButton disabled>
                  Start Leave Date in the Past. Contact Admin.
                </RejectedButton>
              ) : (
                <RejectedButton
                  width="100px"
                  onClick={this.handelCancelRequest}
                >
                  Cancel Request
                </RejectedButton>
              )}
              {/* </ButtonBlock> */}
            </Rows>
          )}
        </Modal>

        <Modal
          width="450px"
          bodyStyle={{
            maxHeight: '800px',
          }}
          visible={this.state.addLeaveModalVisible}
          centered
          footer={null}
          onCancel={() => this.closeAddLeaveModal()}
          maskClosable
        >
          <Form
            ref={this.leaveFormRef}
            onFinish={this.submitLeaveForm}
            onValuesChange={(changedValues, allValues) => {
              if (
                changedValues.dateRange &&
                (changedValues.dateRange[0] != undefined ||
                  changedValues.dateRange[1] != undefined)
              ) {
                allValues.dateRange[0].format('YYYY-MM-DD') !=
                allValues.dateRange[1].format('YYYY-MM-DD')
                  ? this.setState({
                      addForm: {
                        isDifferentDay: true,
                      },
                    })
                  : this.setState({
                      addForm: {
                        isDifferentDay: false,
                      },
                    });
                console.log('date changed');
              }
            }}
            requiredMark={true}
            initialValues={{
              leaveType: '1',
              dateRange: [moment(), moment()],
            }}
          >
            <Row>
              <Headdetail>Create Request</Headdetail>
            </Row>
            <Break />
            <FieldSection
              name="leaveType"
              rules={[
                {
                  required: true,
                  message: 'Please select leave type.',
                },
              ]}
            >
              <FormLabel style={{ justifyContent: 'space-between' }}>
                Leave Type
              </FormLabel>
              <Selectdata
                id="leaveType"
                defaultValue={'Annual Leave'}
                onChange={(values) =>
                  this.leaveFormRef.current.setFieldsValue({
                    leaveType: values,
                  })
                }
                style={{ width: '100%' }}
              >
                <Select.Option value="1">Annual Leave</Select.Option>
                <Select.Option value="2">Personal Leave</Select.Option>
                <Select.Option value="3">Sick Leave</Select.Option>
              </Selectdata>
            </FieldSection>
            <div>
              <FormLabel style={{ justifyContent: 'space-between' }}>
                Pick Date
              </FormLabel>
              <Rows>
                <Form.Item
                  name="dateRange"
                  rules={[
                    {
                      required: true,
                      message: 'Please input leave date.',
                    },
                  ]}
                >
                  <DatePicker.RangePicker
                    format="DD/MM/YYYY"
                    onChange={(dates) => {
                      /* this.leaveFormRef.current.setFieldsValue({
                        dateRange: dates,
                      }); */
                      this.setState({ selectedStartLeaveDate: dates[0] });
                    }}
                  />
                </Form.Item>
              </Rows>
            </div>
            <div>
              <FormLabel style={{ justifyContent: 'space-between' }}>
                Pick Time
              </FormLabel>
              <Rows>
                <Form.Item
                  name="startTime"
                  rules={[
                    {
                      required: !(
                        this.state.addForm.isFullDay ||
                        this.state.addForm.isDifferentDay
                      ),
                      message: 'Please input leave time.',
                    },
                  ]}
                  initialValue={moment({ hour: 9, minute: 0 }).format(
                    'HH:mm:ss',
                  )}
                >
                  {/* <TimePicker
                    showNow={false}
                    minuteStep={15}
                    format='HH:mm'
                    disabledHours={ () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 12, 19, 20, 21, 22, 23] }
                    hideDisabledOptions={true}
                    disabled={
                      this.state.addForm.isFullDay ||
                      this.state.addForm.isDifferentDay
                    } /> */}

                  {!this.state.addForm.isFullDay ? (
                    <SelectTimeSlots
                      onChange={(val) =>
                        this.setState({ prevFormStartTime: val })
                      }
                      style={{ width: '160px' /* , resize: 'none' */ }}
                    >
                      {this.getTimeOption()}
                    </SelectTimeSlots>
                  ) : (
                    <SelectTimeSlots style={{ width: '160px' }} disabled>
                      <Select.Option
                        value={moment({ hour: 9, minute: 0 }).format(
                          'HH:mm:ss',
                        )}
                      >
                        {moment({ hour: 9, minute: 0 }).format('HH:mm')}
                      </Select.Option>
                    </SelectTimeSlots>
                  )}
                </Form.Item>

                <span style={{ margin: '0px 10px' }}> - </span>

                <Form.Item
                  name="endTime"
                  rules={[
                    {
                      required: !(
                        this.state.addForm.isFullDay ||
                        this.state.addForm.isDifferentDay
                      ),
                      message: 'Please input leave time.',
                    },
                    () => ({
                      validator: (_, value) => {
                        const startTimeMoment = moment(
                          this.leaveFormRef.current.getFieldValue('startTime'),
                          'HH:mm',
                        );
                        const endTimeMoment = moment(value, 'HH:mm');
                        if (endTimeMoment.isBefore(startTimeMoment)) {
                          return Promise.reject(
                            'End time must be greater than Start time.',
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  initialValue={moment({ hour: 18, minute: 0 }).format(
                    'HH:mm:ss',
                  )}
                >
                  {/* <TimePicker
                    showNow={false}
                    minuteStep={15}
                    format='HH:mm'
                    disabledHours={ () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 12, 19, 20, 21, 22, 23] }
                    hideDisabledOptions={true}
                    disabled={
                      this.state.addForm.isFullDay ||
                      this.state.addForm.isDifferentDay
                    }
                  /> */}

                  {!this.state.addForm.isFullDay ? (
                    <SelectTimeSlots
                      onChange={(val) =>
                        this.setState({ prevFormEndTime: val })
                      }
                      style={{ width: '160px' /* , resize: 'none' */ }}
                    >
                      {this.getTimeOption()}
                    </SelectTimeSlots>
                  ) : (
                    <SelectTimeSlots disabled style={{ width: '160px' }}>
                      <Select.Option
                        value={moment({ hour: 18, minute: 0 }).format(
                          'HH:mm:ss',
                        )}
                      >
                        {moment({ hour: 18, minute: 0 }).format('HH:mm')}
                      </Select.Option>
                    </SelectTimeSlots>
                  )}
                </Form.Item>
              </Rows>
            </div>

            <Form.Item style={{ marginBottom: '20px' }} name="isFullDay">
              <Checkbox
                onChange={(value) => {
                  this.leaveFormRef.current.setFieldsValue({
                    isFullDay: value.target.checked,
                  });

                  /* if(value.target.checked){
                    this.leaveFormRef.current.setFieldsValue({
                    startTime: moment({hour:9, minute:0}).format('HH:mm:ss'),
                    endTime: moment({hour:18, minute:0}).format('HH:mm:ss'),
                    })
                  } */

                  this.setState({
                    addForm: {
                      isFullDay: value.target.checked,
                    },
                  });
                }}
                checked={
                  this.state.addForm.isFullDay ||
                  this.state.addForm.isDifferentDay
                }
                disabled={this.state.addForm.isDifferentDay}
                style={{ userSelect: 'none' }}
              >
                All Day
              </Checkbox>
            </Form.Item>
            <FieldSection name="description">
              <TextArea
                value={this.state.remarkText}
                onChange={this.onChange}
                placeholder="Description"
                autoSize={{ minRows: 4, maxRows: 5 }}
              />
            </FieldSection>

            <Break />
            {this.state.remainingTime && (
              <Rows style={{ justifyContent: 'space-between' }}>
                <Column>
                  <BigHeader2>
                    {Math.floor(
                      this.state.remainingTime.annual.hourRemain / 8,
                    ).toString()}
                    {' Day '}
                    {Math.floor(
                      this.state.remainingTime.annual.hourRemain % 8,
                    ).toString()}
                    {' hr.'}
                  </BigHeader2>
                  <LilHeader2>Annual Leave</LilHeader2>
                </Column>
                <Column>
                  <BigHeader2>
                    {Math.floor(
                      this.state.remainingTime?.personal.hourRemain / 8,
                    ).toString()}
                    {' Day '}
                    {Math.floor(
                      this.state.remainingTime?.personal.hourRemain % 8,
                    ).toString()}
                    {' hr.'}
                  </BigHeader2>
                  <LilHeader2>Personal Leave</LilHeader2>
                </Column>
                <Column>
                  <BigHeader2>
                    {Math.floor(
                      this.state.remainingTime?.sick.hourRemain / 8,
                    ).toString()}
                    {' Day '}
                    {Math.floor(
                      this.state.remainingTime?.sick.hourRemain % 8,
                    ).toString()}
                    {' hr.'}
                  </BigHeader2>
                  <LilHeader2>Sick Leave</LilHeader2>
                </Column>
              </Rows>
            )}
            <Break />
            <Rows style={{ justifyContent: 'space-between' }}>
              <ButtonBlock width="100px">
                <RejectedButton width="100px" onClick={this.closeAddLeaveModal}>
                  Cancel
                </RejectedButton>
              </ButtonBlock>
              <ButtonBlock width="100px">
                <NormalButton
                  width="100px"
                  onClick={() => this.leaveFormRef.current.submit()}
                  disabled={!this.state.remarkText}
                >
                  Submit
                </NormalButton>
              </ButtonBlock>
            </Rows>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default LeavePageEmployee;
