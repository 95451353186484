import { imageservice } from '../services/api';
import profileimage from '../assets/icons/profileimage.svg';

export const getUserPic = async (record) => {
  try {
    // User Profile Image
    await imageservice.image.getImage(record.userInfo.picturePath, (res) => {
      if (!res.status) {
        const url = URL.createObjectURL(res.data);
        record.userImage = url;
      } else {
        record.userImage = profileimage;
      }
    });

    // Admin Profile Image
    await imageservice.image.getImage(
      record.updateUserInfo.picturePath,
      (res) => {
        if (!res.status) {
          const url = URL.createObjectURL(res.data);
          record.updateUserImage = url;
        } else {
          record.updateUserImage = profileimage;
        }
      },
    );
    return record;
  } catch (err) {
    console.log(err);
  }
};

export const getMedicalClaimPic = async (record) => {
  try {
    // User Medical Certificate Image
    await imageservice.image.getImage(
      record.medicalCertificatePicturePath,
      (res) => {
        if (!res.status) {
          const url = URL.createObjectURL(res.data);
          record.medicalCertificateURL = url;
        }
      },
    );

    // Admin Medical Invoice Image
    await imageservice.image.getImage(
      record.medicalInvoicePicturePath,
      (res) => {
        if (!res.status) {
          const url = URL.createObjectURL(res.data);
          record.medicalInvoiceURL = url;
        }
      },
    );
    return record;
  } catch (err) {
    console.log(err);
  }
};
