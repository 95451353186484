import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Button,
  Row,
  Col,
  Divider,
  Modal,
  Table,
  message,
  Form as AntForm,
  Input as AntInput,
} from 'antd';
import editIcon from '../../assets/icons/edit.svg';
import binIcon from '../../assets/icons/bin.svg';
import { CustomerService } from '../../services/api';
import { redirectTo } from '../../services/redirect';
const TitleBox = styled.div`
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  height: 6.875em;
  justify-content: space-between;
`;
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.subtitle ? '#20ABC5' : '#565656'};
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1.625em' : '1em'};
  font-weight: ${(props) =>
    props.title
      ? 'bold'
      : props.subtitle
      ? '600'
      : props.name
      ? '500'
      : 'normal'};
  font-family: 'Kanit';
`;
const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#2d95a8 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#2d95a8 !important'};
  justify-content: center;
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Inter;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 5px 15px;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;
const OutlineButton = styled(Button).attrs((props) => ({
  type: 'default',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#2d95a8 !important'};
  justify-content: center;
  color: #2d95a8 !important;
  font-family: Inter;
  font-style: Bold;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  align-items: center;
  padding: 5px 15px;
  text-transform: capitalize;
  transition: 0.5s;
  &:hover {
    border-color: #d77501 !important;
    color: #d77501 !important;
  }
  &:active {
    border-color: #e77d00 !important;
    color: #e77d00 !important;
  }
`;
const Icons = styled.path`
  fill: ${(props) => props.fill};
  transition: 0.5s;
  ${OutlineButton}:hover & {
    fill: #d77501 !important;
  }
  ${OutlineButton}:active {
    fill: #e77d00 !important;
  }
`;
const ContentBox = styled.div`
  background: #f6f6f6;
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: auto;
  min-height: calc(100vh - 7.5em);
  justify-content: center;
`;
const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;
const TextCard = styled.div`
  height: auto;
  width: 100%;
  background-color: white;
  margin-top: 2em;
  box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 40px 50px;
`;
const FlexContent = styled.div`
  display: flex;
  align-items: stretch;
  padding: 10px 5px;
  align-items: center;
`;
const FlexItem = styled.div`
  flex-grow: ${(props) => props.flexGrow || 1};
  padding: 5px 5px;
  justify-content: left;
  /* border: 2px solid black; */
`;
const Input = styled.input`
  height: 35px;
  width: ${(props) => (props.full ? '100%' : '100%')};
  /* max-width: 300px; */
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0px 11px;
  font-family: Inter;
  font-style: normal;
  font-size: 1em;
  color: #737373;
  outline: none;
  &:focus {
    border-color: #20abc5;
  }
`;
const { Search } = AntInput;
const Form = styled.form`
  padding: 5px 5px;
  height: auto;
`;
const Cols = styled(Col)`
  display: flex;
  align-items: center;
`;
const Rows = styled(Row)`
  margin: ${(props) => (props.start ? '0px 0px' : '20px 0px')};
  display: flex;
  align-items: ${(props) => (props.start ? 'baseline' : 'center')};
`;
const Required = styled.span`
  padding-left: 5px;
  color: #eb5757;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;
const TableText = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.no ? '#20ABC5' : '#737373'};
  font-size: 1em;
  font-weight: ${(props) =>
    props.title ? '600' : props.no ? 'bold' : 'normal'};
  font-family: 'Kanit';
  line-height: 22px;
`;
const ActionBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ActionItem = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ContactList = styled(Table)`
  &.table-striped-rows tr > td {
    background-color: #ffffff;
  }
  &.table-striped-rows tr > th {
    background-color: #ffffff;
  }
  &.table-striped-rows thead {
    background-color: #ffffff;
  }
  &.ant-table-thead > tr > th,
  &.ant-table-tbody > tr > td {
    padding: 16px 16px;
    word-break: break-word;
    -ms-word-break: break-all;
  }
  /* media query to override the default ant design style that is specifically for desktop devices */
  @media (min-width: 768px) {
    &.ant-table-thead > tr > th,
    &.ant-table-tbody > tr > td {
      padding: 16px 16px;
    }
  }
`;

const columns = [
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name',
    render: (name) => <TableText>{name}</TableText>,
  },
  {
    title: 'EMAIL',
    dataIndex: 'email',
    key: 'email',
    render: (email) => <TableText>{email}</TableText>,
  },
  {
    title: 'TEL',
    dataIndex: 'mobile_number',
    key: 'tel',
    render: (tel) => <TableText>{tel}</TableText>,
  },
  {
    title: 'POSITION',
    key: 'position',
    dataIndex: 'position',
    render: (position) => <TableText>{position}</TableText>,
  },
  {
    title: 'DEPARTMENT',
    key: 'department',
    dataIndex: 'department',
    render: (department) => <TableText>{department}</TableText>,
  },
  {
    title: 'ACTION',
    key: 'action',
    dataIndex: 'index',
    render: (index) => (
      <ActionBox>
        <ActionItem>
          <img src={editIcon} width="20" height="20" />
        </ActionItem>
        <ActionItem>
          <img src={binIcon} />
        </ActionItem>
      </ActionBox>
    ),
  },
];
const validateMessages = {
  required: '${name} is required!',
  types: {
    text: '${name} is not validate!',
    email: '${name} is not validate email!',
    number: '${name} is not a validate number!',
  },
  number: {
    range: '${name} must be between ${min} and ${max}',
  },
};
const typeTemplate = "'${name}' is not a valid ${type}";

export const defaultValidateMessages = {
  default: "Validation error on field '${name}'",
  required: "'${name}' is required",
  enum: "'${name}' must be one of [${enum}]",
  whitespace: "'${name}' cannot be empty",
  date: {
    format: "'${name}' is invalid for format date",
    parse: "'${name}' could not be parsed as date",
    invalid: "'${name}' is invalid date",
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate,
  },
  string: {
    len: "'${name}' must be exactly ${len} characters",
    min: "'${name}' must be at least ${min} characters",
    max: "'${name}' cannot be longer than ${max} characters",
    range: "'${name}' must be between ${min} and ${max} characters",
  },
  number: {
    len: "'${name}' must equal ${len}",
    min: "'${name}' cannot be less than ${min}",
    max: "'${name}' cannot be greater than ${max}",
    range: "'${name}' must be between ${min} and ${max}",
  },
  array: {
    len: "'${name}' must be exactly ${len} in length",
    min: "'${name}' cannot be less than ${min} in length",
    max: "'${name}' cannot be greater than ${max} in length",
    range: "'${name}' must be between ${min} and ${max} in length",
  },
  pattern: {
    mismatch: "'${name}' does not match pattern ${pattern}",
  },
};
class AddCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      hasData: true,
      data: [],
      incorrect: false,
      searchName: '',
      limit: 500,
      offset: 0,
      no: 0,
      newValue: {},
    };
  }
  formRef = React.createRef();
  onFinish = (values) => {
    console.log(values);
  };

  onReset = () => {
    this.formRef.current.resetFields();
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  addData = () => {
    this.setState((state) => {
      const data = [...state.data, state.newValue];
      return {
        data,
        newValue: '',
      };
    });
    this.onReset();
  };
  handleOk = (payload) => {
    console.log(payload);
    this.setState({ data: payload });
    this.handleAddCustomer();
  };
  // handleOk = async (e) => {
  //   this.setState({ newValue: e, visible: false });
  //   this.addData();
  // };
  shouldComponentUpdate() {
    return true;
  }
  handleCancel = () => {
    this.onReset();
    redirectTo('/customer');
  };
  handleAddCustomer = () => {
    const payload = this.state.data;
    CustomerService.addCustomer(
      payload,
      ({ data }) => {
        console.log(data);
        let secondsToGo = 1;
        message.success(data.message);
        const timer = setInterval(() => {
          secondsToGo -= 1;
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          this.onReset();
          // redirectTo(`/customer`);
        }, secondsToGo * 1000);
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Error: ' + response.data.message);
        }
      },
    );
  };
  render() {
    return (
      <div style={{ width: 'auto' }}>
        <TitleBox>
          <Text title>Add Customer</Text>
          <ButtonBlock width="200px">
            <NormalButton
              form="my-form"
              grey
              width="96px"
              onClick={this.handleCancel}
            >
              Cancel
            </NormalButton>
            <NormalButton width="96px" form="my-form" htmlType="submit">
              Submit
            </NormalButton>
          </ButtonBlock>
        </TitleBox>
        <ContentBox>
          <TextCard style={{ marginBottom: '2.5em', paddingBottom: '1em' }}>
            <Text subtitle>Personal Information</Text>
            <Form>
              <AntForm
                id="my-form"
                ref={this.formRef}
                name="new contact"
                onFinish={this.handleOk}
                validateMessages={defaultValidateMessages}
                autoComplete={false}
              >
                <Rows start style={{ marginTop: '2.1em' }}>
                  <Cols span={4}>
                    <Text name>
                      Company Name<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={7}>
                    <AntForm.Item
                      style={{ width: '100%' }}
                      name="name"
                      title="a"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type="text" />
                    </AntForm.Item>
                  </Cols>
                  <Cols span={1}></Cols>
                  <Cols span={4}>
                    <Text name>
                      Company Tel<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={8}>
                    <AntForm.Item
                      style={{ width: '100%' }}
                      name="phone_number"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type="text" />
                    </AntForm.Item>
                  </Cols>
                </Rows>
                <Rows start>
                  <Cols span={4}>
                    <Text name>
                      Tax No<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={7}>
                    <AntForm.Item
                      style={{ width: '100%' }}
                      name="tax_no"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type="text" />
                    </AntForm.Item>
                  </Cols>
                  <Cols span={1}></Cols>
                  <Cols span={4}>
                    <Text name>
                      Fax<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={8}>
                    <AntForm.Item
                      style={{ width: '100%' }}
                      name="fax"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type="text" />
                    </AntForm.Item>
                  </Cols>
                </Rows>
                <Divider
                  style={{
                    margin: '40px 0px',
                    borderColor: '#737373',
                  }}
                  dashed
                />
                <Rows start>
                  <Cols span={4}>
                    <Text name>
                      Address Line1<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={20}>
                    <AntForm.Item
                      style={{ width: '100%' }}
                      name="address_1"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type="text" full />
                    </AntForm.Item>
                  </Cols>
                </Rows>
                <Rows start>
                  <Cols span={4}>
                    <Text name>Address Line2</Text>
                  </Cols>
                  <Cols span={20}>
                    <AntForm.Item
                      style={{ width: '100%' }}
                      name="address_2"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input type="text" full />
                    </AntForm.Item>
                  </Cols>
                </Rows>
                <Rows start>
                  <Cols span={4}>
                    <Text name>
                      Country<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={7}>
                    <AntForm.Item
                      style={{ width: '100%' }}
                      name="country"
                      initialValue="Thailand"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type="text" />
                    </AntForm.Item>
                  </Cols>
                  <Cols span={1}></Cols>
                  <Cols span={4}>
                    <Text name>
                      Province<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={8}>
                    <AntForm.Item
                      style={{ width: '100%' }}
                      name="province"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type="text" />
                    </AntForm.Item>
                  </Cols>
                </Rows>
                <Rows start>
                  <Cols span={4}>
                    <Text name>
                      District<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={7}>
                    <AntForm.Item
                      style={{ width: '100%' }}
                      name="district"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type="text" />
                    </AntForm.Item>
                  </Cols>
                  <Cols span={1}></Cols>
                  <Cols span={4}>
                    <Text name>
                      Postcode<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={8}>
                    <AntForm.Item
                      style={{ width: '100%' }}
                      name="zip_code"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type="text" />
                    </AntForm.Item>
                  </Cols>
                </Rows>
              </AntForm>
            </Form>
          </TextCard>
        </ContentBox>
      </div>
    );
  }
}
export default AddCustomer;
