import React, { Component } from 'react';
import styled from 'styled-components';
import { Button, Col, Row, Space } from 'antd';
import Icon from '@ant-design/icons';
import editIcon from '../../assets/icons/edit.svg';
import saveIcon from '../../assets/icons/saveBlue.svg';
import addUserIcon from '../../assets/icons/addUser.svg';

const Headdetail = styled.div`
  font-family: 'Kanit';
  font-size: 26px;
  font-weight: 500;
  color: #ffffff;
`;

const Buttonedit = styled(Button)`
  // width: 81px;
  width: auto;
  height: 30px;
  background: ${(props) => (props.blue ? '#004368' : '#ffffff')};
  border: ${(props) => (props.blue ? '1px solid #FFFFFF' : '')};
  color: ${(props) => (props.blue ? '#ffffff' : '#004368')};
  &:hover {
    background: ${(props) => (props.blue ? '#ffffff' : '#B3C7D2')};
  }
  border-radius: 5px;
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  align-items: center;
  font-family: 'Kanit';
  font-weight: 400;
  font-size: 14px;
`;

const ProfileTabHeader = ({
  title,
  icon,
  isInEditMode,
  changeEditMode,
  formId,
  addUser,
  cancelAddUser,
}) => {
  return (
    <div style={{ width: '100%', position: 'relative', marginTop: '2rem' }}>
      <Row
        justify="space-around"
        align="middle"
        style={{
          backgroundColor: '#004368',
          height: '80px',
          position: 'absolute',
          top: '38px',
          left: 0,
          right: 0,
          zIndex: 10,
        }}
      >
        <Col>
          <img src={icon} style={{ margin: '0 1rem 0 2rem' }} />
        </Col>
        <Col>
          <Headdetail>{title}</Headdetail>
        </Col>
        <Col flex="auto" />
        <Col style={{ marginRight: '40px' }}>
          {isInEditMode ? (
            <>
              <Space size={12}>
                <Buttonedit
                  // style={{ background: '#C6C6C6' }}
                  blue
                  onClick={addUser ? cancelAddUser : changeEditMode}
                >
                  Cancel
                </Buttonedit>
                <Buttonedit form={formId} htmlType="submit">
                  <img
                    src={addUser ? addUserIcon : saveIcon}
                    style={{ paddingRight: '9px' }}
                  />
                  {addUser ? 'Add User' : 'Save'}
                </Buttonedit>
              </Space>
            </>
          ) : (
            <Col>
              <Buttonedit onClick={changeEditMode}>
                <img src={editIcon} style={{ paddingRight: '9px' }} />
                Edit
              </Buttonedit>
            </Col>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProfileTabHeader;
