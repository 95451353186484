import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select, Spin, Upload, message } from 'antd';
import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import { AuthorizeRoleService } from '../../services/api';

const ContentBox = styled.div`
  background: #f6f6f6;
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: auto;
  min-height: calc(100vh - 7em);
  justify-content: flex-start;
  padding-top: 0.5em;
`;

const Label = styled.div`
  font-family: 'Kanit';
  font-size: 16px;
  font-weight: 500;
  width: 11rem;
  min-width: 11rem;
  color: black;
  @media (max-width: 1424px) {
    width: 100%;
    min-width: none;
  }
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  justify-content: center;
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Inter;
  font-style: Regular;
  font-size: 14px;
  line-height: 19.42px;
  text-align: center;
  vertical-align: middle;
  padding: 5px 15px;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;

const TextCard = styled.div`
  height: auto;
  width: 100%;
  background-color: white;
  margin-top: 2em;
  box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 40px 50px;
`;

class SalaryPage extends Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
    };
  }

  componentDidMount = () => {
    this.setState({
      isLoading: true,
    });
    AuthorizeRoleService.getSalaryFilterOption(
      ({ data }) => {
        console.log(data);
        this.setState({
          data: data,
          isLoading: false,
        });
      },
      (response) => {
        this.setState({
          isLoading: false,
        });
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  onFinishDownloadSalary = (value) => {
    const index = value.index;
    const payload = this.state.data[index];
    AuthorizeRoleService.getSalaryPDFFile(
      payload,
      async ({ data }) => {
        const a = document.createElement('a');
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = `payslip-${payload.option}.pdf`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  render() {
    return (
      <div style={{ width: 'auto' }}>
        <Spin spinning={this.state.isLoading}>
          <ContentBox>
            <TextCard>
              <Form ref={this.formRef} onFinish={this.onFinishDownloadSalary}>
                <Row gutter={[64, 16]}>
                  <Col
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    style={{ width: '100%' }}
                  >
                    <Row gutter={16}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                        <Label>Month-Year</Label>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 16 }}
                      >
                        <Form.Item
                          name="index"
                          style={{ width: '100%', height: '2rem' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please Select your Month and Year',
                            },
                          ]}
                        >
                          <Select
                            style={{
                              width: '100%',
                            }}
                          >
                            {this.state.data.map((value, index) => (
                              <Select.Option value={index}>
                                {value.option}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col flex="none">
                    <NormalButton
                      white
                      style={{
                        width: '128px',
                        alignItems: 'center',
                      }}
                      onClick={() => this.formRef.current.submit()}
                    >
                      <DownloadOutlined style={{ fontSize: '16px' }} />
                      Download
                    </NormalButton>
                  </Col>
                </Row>
              </Form>
            </TextCard>
          </ContentBox>
        </Spin>
      </div>
    );
  }
}

export default SalaryPage;
