import React, { Component } from 'react';
import Topbar from '../components/topbar.js';
import styled from 'styled-components';
const Box = styled.div`
  padding-left: 10%;
  padding-right: 10%;
`;
class Leave extends Component {
  render() {
    const name = 'Leave';
    return (
      <div>
        <Topbar barname={name} />
        <Box></Box>
      </div>
    );
  }
}
export default Leave;
