import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Layout,
  Button,
  Table,
  Space,
  Row,
  Col,
  message,
  Select,
  Form,
  Modal,
  Input as AntInput,
} from 'antd';
import binIcon from '../../assets/icons/bin.svg';

import { ItemService } from '../../services/api';
import { redirectTo } from '../../services/redirect';

//#region
const { Header, Content, Footer } = Layout;
const TitleBox = styled.div`
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  height: 6.875em;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.subtitle ? '#20ABC5' : '#ff2132'};
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1.125em' : '1em'};
  font-weight: ${(props) =>
    props.title ? 'bold' : props.subtitle ? '600' : 'normal'};
  font-family: 'Kanit';
  text-transform: ${(props) => (props.subtitle ? 'uppercase' : 'none')};
`;
const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  padding: 5px 15px;
  background-color: #2d95a8 !important;
  border-color: #2d95a8 !important;
  justify-content: center;
  color: white;
  font-family: Inter;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;
const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#f6f6f6')};
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  /* flex-direction: row; */
  /* align-items: center; */
  width: auto;
  min-height: calc(100vh - 7.5em);
`;
const TableText = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.key_item ? '#20ABC5' : '#737373'};
  font-size: 1em;
  font-weight: ${(props) =>
    props.title ? '600' : props.key_item ? 'bold' : 'normal'};
  font-family: 'Kanit';
  line-height: 22px;
`;
const ItemList = styled(Table)`
  &.table-striped-rows tr > th {
    background-color: #f6f6f6;
  }
  &.table-striped-rows tr:nth-child(2n) td {
    background-color: #f2f2f2;
  }
  &.table-striped-rows tr:nth-child(2n + 1) td {
    background-color: #f6f6f6;
  }
  &.table-striped-rows thead {
    background-color: #f1f1f1;
  }
`;
const { Search } = AntInput;
const { confirm } = Modal;
//#endregion

const data1 = [
  {
    index: '1',
    item_code: 'TK-TEST-AUTO',
    item_type: 'Turnkey',
    item_name: 'Turnkey automated test service',
    item_descriptions: 'Description only...',
    price_per_unit: '100000',
    unit: 'manday',
  },
  {
    index: '2',
    item_code: 'OS-PROG-IOS',
    item_type: 'Outsource',
    item_name: 'IOS Developer',
    item_descriptions: 'Description only...',
    price_per_unit: '50000',
    unit: 'manday',
  },
  {
    index: '3',
    item_code: 'TK-TEST-FUNC',
    item_type: 'Turnkey',
    item_name: 'Turnkey functional test service',
    item_descriptions: 'Description only...',
    price_per_unit: '125000',
    unit: 'manday',
  },
];

class AllItem extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: 'ITEM CODE',
        index: 'code',
        dataIndex: 'item_code',
        width: '20%',
        sorter: {
          compare: (a, b) => a.item_code.localeCompare(b.item_code),
        },
        render: (code, record) => (
          <a
            onClick={() =>
              this.handleRedirect('/item/iteminfo/?no=' + record.index)
            }
          >
            <TableText key_item>
              {code}
              {/* {TextFormatter.normalText(code)} */}
            </TableText>
          </a>
        ),
      },
      {
        title: 'ITEM NAME',
        index: 'name',
        dataIndex: 'item_name',
        width: '25%',
        sorter: {
          compare: (a, b) => a.price_per_unit - b.price_per_unit,
        },
        render: (name) => <TableText>{name}</TableText>,
      },
      {
        title: 'ITEM DESCRIPTION',
        index: 'descriptions',
        dataIndex: 'item_descriptions',
        width: 'auto',
        render: (descriptions) => <TableText>{descriptions}</TableText>,
      },
      {
        title: 'PRICE PER MANDAY',
        index: 'price',
        dataIndex: 'price_per_unit',
        width: '20%',
        sorter: {
          compare: (a, b) => a.item_code.localeCompare(b.item_code),
        },

        render: (price) => <TableText>{price}</TableText>,
      },
      {
        title: 'ACTION',
        index: 'action',
        dataIndex: 'index',
        width: 100,
        render: (key, record) =>
          key && (
            <Row>
              <Col span={24}>
                <a>
                  {/* <img src={binIcon} onClick={(e) => this.handleDeleteItem(key, e)} /> */}
                  <img
                    src={binIcon}
                    onClick={() => this.showConfirm(key, record.item_code)}
                  />
                </a>
              </Col>
            </Row>
          ),
      },
    ];
    this.state = {
      hasData: false,
      incorrect: false,
      limit: 500,
      offset: 0,
    };
  }
  //#region

  showConfirm(index, code) {
    confirm({
      title: 'Do you want to delete this item?',
      content: `item: ${code}`,
      onOk: () => {
        this.handleDeleteItem(index);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  handleRedirect = (path) => {
    redirectTo(path);
  };
  handlefetchItemsList = (value) => {
    const payload = {
      searchString: value,
      limit: this.state.limit,
      offset: this.state.offset,
    };
    console.log(payload);
    ItemService.fetchItemsList(
      payload,
      ({ data }) => {
        console.log(data);
        this.setState({
          hasData: true,
          mData: data,
        });
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
            hasData: false,
          });
          if (!response.data.message == 'empty array')
            message.error('Cannot access data' + ' - ' + response.data.message);
        }
      },
    );
  };
  handleDeleteItem = (payload) => {
    // e.preventDefault();
    ItemService.deleteItems(
      payload,
      ({ data }) => {
        console.log(data);
        if (data.message == 'Delete Items is success') {
          message.success(
            'Delete Items is success',
            1,
            this.handlefetchItemsList(''),
          );
        }
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Error' + ' - ' + response.data.message);
        }
      },
    );
  };
  //#endregion
  componentDidMount = () => {
    this.handlefetchItemsList('');
  };
  render() {
    const name = 'Item List';
    return (
      <div style={{ width: 'auto' }}>
        <TitleBox>
          <Text title>Item List</Text>
          <NormalButton href="./item/additem">+Add Item</NormalButton>
        </TitleBox>
        <TitleBox bg="#f6f6f6">
          <Text subtitle>All Item List</Text>
          <Search
            placeholder="Search"
            onSearch={(value) => {
              this.handlefetchItemsList(value);
            }}
            style={{ width: 200 }}
          />
        </TitleBox>
        <ContentBox>
          <ItemList
            className="table-striped-rows"
            columns={this.columns}
            // dataSource={this.state.hasData ? data1 : null}
            dataSource={this.state.hasData ? this.state.mData.items : null}
            style={{ width: '100%' }}
            indentSize={1}
            size="middle"
            loading={!this.state.hasData && !this.state.incorrect}
            pagination={{ position: ['bottomRight'] }}
          />
        </ContentBox>
      </div>
    );
  }
}
export default AllItem;
