import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Layout,
  Button,
  Table,
  Space,
  Row,
  Col,
  message,
  Select,
  Form,
  Input as AntInput,
} from 'antd';
import binIcon from '../../assets/icons/bin.svg';

import { ItemService } from '../../services/api';

//#region
const { Header, Content, Footer } = Layout;
const TitleBox = styled.div`
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  height: 6.875em;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.subtitle ? '#20ABC5' : '#565656'};
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1.625em' : '1em'};
  font-weight: ${(props) =>
    props.title
      ? 'bold'
      : props.subtitle
      ? '600'
      : props.name
      ? '500'
      : 'normal'};
  font-family: 'Kanit';
  word-wrap: break-word;
  white-space: pre;
`;
const WordWrap = styled.p`
  word-wrap: break-word;
`;
const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#2d95a8 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#2d95a8 !important'};
  justify-content: center;
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Inter;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 5px 15px;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;
const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;
const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#f6f6f6')};
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  /* flex-direction: row; */
  /* align-items: center; */
  width: auto;
  min-height: calc(100vh - 7.5em);
`;
const Input = styled.input`
  height: 35px;
  width: 100%; /* max-width: 300px; */
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0px 11px;
  font-family: Inter;
  font-style: normal;
  font-size: 1em;
  color: #737373;
  outline: none;
  &:focus {
    border-color: #20abc5;
  }
`;
const MyForm = styled.form`
  padding: 5px 5px;
  height: auto;
`;
const Cols = styled(Col)`
  display: flex;
  align-items: center;
`;
const Rows = styled(Row)`
  margin: 10px 0px;
  align-items: baseline;
`;
const TextCard = styled.div`
height: auto;
width: 100%;
background - color: white;
margin - top: 2em;
box - shadow: 0px 0px 21px rgba(0, 0, 0, 0.11);
border - radius: 5px;
padding: 40px 50px;
`;
const { Option } = Select;
const Required = styled.span`
padding - left: 5px;
color: #eb5757;
font - family: Inter;
font - style: normal;
font - weight: 500;
font - size: 16px;
`;
const { TextArea } = AntInput;

//#endregion

class ItemInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasData: false,
      inEditMode: false,
    };
  }
  handleEdit = (e) => {
    console.log('edit');
    this.setState({
      inEditMode: true,
    });
  };
  handleCancel = (e) => {
    this.setState({
      inEditMode: false,
    });
  };
  handleSave = (values) => {
    console.log(values);
    const payload = {
      item_name: values.name,
      item_descriptions:
        values.description == null
          ? this.state.mData.item_descriptions
          : values.description,
      price_per_unit: values.price,
      unit: 'manday',
      sow: values.sow == null ? this.state.mData.sow : values.sow,
      deliverable:
        values.deliverable == null
          ? this.state.mData.deliverable
          : values.deliverable,
    };
    console.log(payload);
    ItemService.editItems(
      this.state.mData.index,
      payload,
      ({ data }) => {
        console.log(data);
        if (data.message == 'Edit Items is success') {
          message.success('Edit Items is success', 0.5, this.handlefetchItem());
        }
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          message.error('Cannot access data' + ' - ' + response.data.message);
        }
      },
    );
  };
  handlefetchItem = () => {
    let payload = this.state.index;
    console.log(payload);
    ItemService.fetchItem(
      payload,
      ({ data }) => {
        this.setState({
          hasData: true,
          mData: data.items[0],
          inEditMode: false,
        });
        console.log(this.state.mData);
        this.props.form.setFieldsValue({
          tetxareaSow: data.items[0].sow,
        });
        console.log(data.field);
      },
      (response) => {
        // console.log(response.data.message);
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          message.error('Cannot access data: ' + response.data.message);
        }
      },
    );
  };
  async componentDidMount() {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var no = await url.searchParams.get('no');
    this.setState({ index: no });
    this.handlefetchItem();
  }

  render() {
    if (!this.state.inEditMode) {
      return (
        <div style={{ width: 'auto' }}>
          <TitleBox>
            <Text title>
              {this.state.hasData && this.state.mData.item_code}
            </Text>
            <NormalButton width="96px" onClick={this.handleEdit}>
              Edit
            </NormalButton>
          </TitleBox>
          <ContentBox>
            <TextCard>
              <Text subtitle>Item Information</Text>
              <MyForm>
                <Form id="item-form" onFinish={this.handleOk}>
                  <Rows>
                    <Cols span={5}>
                      <Text name>Item Type</Text>
                    </Cols>
                    <Cols span={7}>
                      <Form.Item
                        name={['type']}
                        style={{ width: '100%', height: '35px' }}
                      >
                        <Text>
                          {this.state.hasData && this.state.mData.item_type}
                        </Text>
                      </Form.Item>
                    </Cols>
                  </Rows>
                  <Rows>
                    <Cols span={5}>
                      <Text name>Item Code</Text>
                    </Cols>
                    <Cols span={7}>
                      <Form.Item name={['code']} style={{ width: '100%' }}>
                        <Text>
                          {this.state.hasData && this.state.mData.item_code}
                        </Text>
                      </Form.Item>
                    </Cols>
                  </Rows>
                  <Rows>
                    <Cols span={5}>
                      <Text name>Item Name</Text>
                    </Cols>
                    <Cols span={7}>
                      <Form.Item name={['name']} style={{ width: '100%' }}>
                        <Text>
                          {this.state.hasData && this.state.mData.item_name}
                        </Text>
                      </Form.Item>
                    </Cols>
                  </Rows>
                  <Rows>
                    <Cols span={5}>
                      <Text name>Price Per Manday</Text>
                    </Cols>
                    <Cols span={7}>
                      <Form.Item name={['price']} style={{ width: '100%' }}>
                        <Text>
                          {this.state.hasData &&
                            this.state.mData.price_per_unit}
                        </Text>
                      </Form.Item>
                    </Cols>
                  </Rows>
                  <Rows>
                    <Cols span={5}>
                      <Text name>Item Description</Text>
                    </Cols>
                    <Cols span={7}>
                      <Form.Item
                        name={['description']}
                        style={{ width: '100%' }}
                      >
                        <Text>
                          {this.state.hasData &&
                            this.state.mData.item_descriptions}
                        </Text>
                      </Form.Item>
                    </Cols>
                  </Rows>
                  <Rows>
                    <Cols span={5}>
                      <Text name>Scope Of Work</Text>
                    </Cols>
                    <Cols span={7}>
                      <Form.Item name={['sow']} style={{ width: '100%' }}>
                        <Text>
                          {this.state.hasData && this.state.mData.sow}
                        </Text>
                      </Form.Item>
                    </Cols>
                  </Rows>
                  <Rows>
                    <Cols span={5}>
                      <Text name>Deliverable</Text>
                    </Cols>
                    <Cols span={7}>
                      <Form.Item
                        name={['deliverable']}
                        style={{ width: '100%' }}
                      >
                        <Text>
                          {this.state.hasData && this.state.mData.deliverable}
                        </Text>
                      </Form.Item>
                    </Cols>
                  </Rows>
                </Form>
              </MyForm>
            </TextCard>
          </ContentBox>
        </div>
      );
    } else {
      return (
        <div style={{ width: 'auto' }}>
          <TitleBox>
            <Text title>{this.state.mData.item_code}</Text>
            <ButtonBlock width="200px">
              <NormalButton grey width="96px" onClick={this.handleCancel}>
                Cancel
              </NormalButton>
              <NormalButton width="96px" form="item-form" htmlType="submit">
                Save
              </NormalButton>
            </ButtonBlock>
          </TitleBox>
          <ContentBox>
            <TextCard>
              <Text subtitle>Item Information</Text>
              <MyForm>
                <Form
                  id="item-form"
                  ref={this.formRef}
                  onFinish={this.handleSave}
                >
                  <Rows>
                    <Cols span={5}>
                      <Text name>
                        Item Type<Required>*</Required>
                      </Text>
                    </Cols>
                    <Cols span={7}>
                      <Form.Item
                        name={['type']}
                        initialValue="OS"
                        style={{ width: '100%', height: '35px' }}
                      >
                        <Select
                          defaultValue={this.state.mData.item_type}
                          disabled
                        >
                          <Option value="OS">OS</Option>
                          <Option value="TK">TK</Option>
                        </Select>
                      </Form.Item>
                    </Cols>
                  </Rows>
                  <Rows>
                    <Cols span={5}>
                      <Text name>
                        Item Code<Required>*</Required>
                      </Text>
                    </Cols>
                    <Cols span={7}>
                      <Form.Item name={['code']} style={{ width: '100%' }}>
                        <Input
                          defaultValue={this.state.mData.item_code}
                          disabled
                        ></Input>
                      </Form.Item>
                    </Cols>
                  </Rows>
                  <Rows>
                    <Cols span={5}>
                      <Text name>
                        Item Name<Required>*</Required>
                      </Text>
                    </Cols>
                    <Cols span={7}>
                      <Form.Item
                        name={['name']}
                        rules={[
                          {
                            initialValue: this.state.mData.item_name,
                          },
                        ]}
                        style={{ width: '100%' }}
                      >
                        <Input
                          defaultValue={this.state.mData.item_name}
                        ></Input>
                      </Form.Item>
                    </Cols>
                  </Rows>
                  <Rows>
                    <Cols span={5}>
                      <Text name>
                        Price Per Manday<Required>*</Required>
                      </Text>
                    </Cols>
                    <Cols span={7}>
                      <Form.Item
                        name={['price']}
                        rules={[
                          {
                            initialValue: this.state.mData.price_per_unit,
                          },
                        ]}
                        style={{ width: '100%' }}
                      >
                        <Input
                          type="number"
                          min="0"
                          defaultValue={this.state.mData.price_per_unit}
                        ></Input>
                      </Form.Item>
                    </Cols>
                  </Rows>
                  <Rows>
                    <Cols span={5}>
                      <Text name>Item Description</Text>
                    </Cols>
                    <Cols span={7}>
                      <Form.Item
                        name={['description']}
                        style={{ width: '100%' }}
                      >
                        <textarea
                          rows={2}
                          style={{ width: '100%', border: 'none' }}
                        >
                          {this.state.mData.item_descriptions}
                        </textarea>
                      </Form.Item>
                    </Cols>
                  </Rows>
                  <Rows>
                    <Cols span={5}>
                      <Text name>Scope Of Work</Text>
                    </Cols>
                    <Cols span={7}>
                      <Form.Item name={['sow']} style={{ width: '100%' }}>
                        <textarea
                          rows={4}
                          style={{ width: '100%', border: 'none' }}
                        >
                          {this.state.mData.sow}
                        </textarea>
                      </Form.Item>
                    </Cols>
                  </Rows>
                  <Rows>
                    <Cols span={5}>
                      <Text name>Deliverable</Text>
                    </Cols>
                    <Cols span={7}>
                      <Form.Item
                        name={['deliverable']}
                        style={{ width: '100%' }}
                      >
                        <textarea
                          rows={2}
                          style={{ width: '100%', border: 'none' }}
                        >
                          {this.state.mData.deliverable}
                        </textarea>
                      </Form.Item>
                    </Cols>
                  </Rows>
                </Form>
              </MyForm>
            </TextCard>
          </ContentBox>
        </div>
      );
    }
  }
}
export default ItemInfo;
