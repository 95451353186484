import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Input,
  Table,
  Row,
  message,
  Modal,
  DatePicker,
  Select,
  Divider,
  Col,
  Space,
} from 'antd';
import moment from 'moment';
import { redirectTo } from '../../services/redirect';
import { leaveService } from '../../services/api';
import { imageservice } from '../../services/api';
import actiona from '../../assets/icons/actiona.svg';
import eye from '../../assets/icons/eye.svg';
import profileimage from '../../assets/icons/profileimage.svg';
import { DownloadOutlined } from '@ant-design/icons';
//#region
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.subtitle ? '#20ABC5' : '#ff2132'};
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1em' : '1em'};
  font-weight: ${(props) =>
    props.title ? 'bold' : props.subtitle ? '600' : 'normal'};
  font-family: 'Kanit';
  text-transform: ${(props) => (props.subtitle ? 'uppercase' : 'none')};
`;

const RejectedButton = styled(Button)`
  min-width: 106px;
  border-radius: 5px;
  display: flex;
  background-color: white;
  border: 1px solid #f2f2f2;
  justify-content: center;
  color: #232323;
  font-family: Kanit;
  font-style: Regular;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 5px 15px;
  align-items: center;
  &:disabled {
    border: 1px solid white !important;
  }
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  border-radius: 5px;
  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  // justify-content: center;
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Kanit;
  font-style: Regular;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid white !important;
    background-color: #f5f5f5 !important;
  }
`;
const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: end;
  align-items: center;
`;
const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#ffffff')};
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  /* flex-direction: row; */
  /* align-items: center; */
  width: auto;
  min-height: calc(100vh - 200px);
`;
const TableText = styled.div`
  color: ${(props) => (props.name ? '#004368' : '#737373')};
  font-size: ${(props) => (props.description ? '12px' : '14px')};
  font-weight: ${(props) =>
    props.name ? '500' : props.description ? '300' : '400'};
  font-family: 'Kanit';
  line-height: 18px;
  text-align: left;
`;
const ProjectTable = styled(Table)`
  & .ant-table.ant-table-small .ant-table-thead > tr > th {
    font-size: 14px;
    font-weight: bold;
    background-color: white;
    padding-bottom: 20px;
  }
  &.table-striped-rows tr > th {
    background-color: #ffffff;
  }
  &.table-striped-rows tr > td {
    height: 50px;
  }
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 6px 8px;
  }
`;
const Rows = styled(Row)`
  margin: 0px 0px;
`;

const Label = styled.div`
  font-family: 'Kanit';
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin: 0px 1rem;
  color: ${(props) => (props.grey ? '#737373' : '#232323')};
`;

const LeaveCount = styled.div`
  color: #232323;
  width: auto;
  border-radius: 5px;
  height: 2.5rem;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  justify-content: space-between;
`;

const LilHeader = styled.div`
  max-width: 400px;
  //styleName: Body2 (light 12);
  font-family: Kanit;
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
`;

const LilHeader2 = styled.div`
  //styleName: Title Body2 (reg 12);
  font-family: Kanit;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #acacac;
`;

const BigHeader = styled.div`
  //styleName: Title Box (medium 16);
  font-family: Kanit;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

const BigHeader2 = styled.div`
  //styleName: Title Box (medium 16);
  font-family: Kanit;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #004368;
`;

const Selectdata = styled(Select)`
  // max-width:14rem;
  // width: 10rem;
  // height: 2rem;
  // margin-left:2rem;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Circle = styled.img`
  margin: 0;
  background: ${(props) =>
    props.small ? (props.src ? 'url(' + props.src + ')' : 'grey') : 'white'};
  background-repeat: no-repeat;
  background-size: 9.313rem;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Break = styled(Divider)`
  margin: 12px 0;
`;

const { Search } = Input;
const { TextArea } = Input;
//#endregion

class LeavePage extends Component {
  constructor(props) {
    super(props);
    this.filterFormRef = React.createRef();
    this.state = {
      data: null,
      datamain: null,
      isLoading: true,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      annual: 0,
      personal: 0,
      sick: 0,
      modalVisible: false,
      modalEdit: false,
      record: null,
      image: null,
      image2: null,
      isPending: false,
      isApprove: false,
      isCancelUser: false,
      editable: false,
      remarkText: null,
      Comment: false,
      userId: null,
      type: 'All',
      searchWord: null,
    };

    // const valueWithImage = await imageservice.image.getImage(
    //   data.picturePath,
    //   (res) => {
    //     const url = URL.createObjectURL(res.data);
    //     return { image: url };
    //   }
    // );

    const defaultStringCompare = (property) => (a, b) => {
      let result;

      if (property === 'employeeName') {
        if (a.userInfo.firstnameEn.localeCompare(b.userInfo.firstnameEn) != 0)
          result = a.userInfo.firstnameEn.localeCompare(b.userInfo.firstnameEn);
        else
          result = a.userInfo.lastnameEn.localeCompare(b.userInfo.lastnameEn);
      } else result = String(a[property]).localeCompare(String(b[property]));

      return result;
    };
    this.columns = [
      {
        title: 'EMP NO.',
        dataIndex: 'userId',
        width: '10%',
        align: 'center',
        sorter: (a, b) => a.userInfo.employeeId - b.userInfo.employeeId,
        render: (value, record) => (
          <TableText>
            {record.userInfo.employeeId.toString().padStart(5, '0')}
          </TableText>
        ),
      },
      {
        title: 'NAME',
        dataIndex: 'employeeName',
        align: 'center',
        sorter: defaultStringCompare('employeeName'),
        render: (name, record) => (
          <div>
            <TableText name>
              {record.userInfo.firstnameEn} {record.userInfo.lastnameEn}
            </TableText>
            <TableText description>{record.purpose}</TableText>
          </div>
        ),
      },
      {
        title: 'START DATE',
        dataIndex: 'startDate',
        width: '11%',
        align: 'center',
        sorter: defaultStringCompare('startDate'),
        render: (value) => (
          <TableText style={{ textAlign: 'center' }}>
            {moment(value).format('DD/MM/YYYY')}
          </TableText>
        ),
      },
      {
        title: 'END DATE',
        dataIndex: 'endDate',
        width: '11%',
        align: 'center',
        sorter: defaultStringCompare('endDate'),
        render: (value) => (
          <TableText style={{ textAlign: 'center' }}>
            {moment(value).format('DD/MM/YYYY')}
          </TableText>
        ),
      },
      {
        title: 'TOTAL',
        dataIndex: 'leaveHour',
        width: '7%',
        align: 'center',
        render: (value) => (
          <TableText style={{ textAlign: 'center' }}>
            {Math.floor(value / 8).toString()} :{' '}
            {Math.floor(value % 8).toString()}
          </TableText>
        ),
      },
      {
        title: 'LEAVE TYPE',
        dataIndex: 'leaveName',
        width: '12%',
        align: 'center',
        sorter: defaultStringCompare('leaveName'),
        render: (value) => (
          <TableText style={{ textAlign: 'center' }}>
            {value == 'ANNUAL'
              ? 'Annual Leave'
              : value == 'PERSONAL'
              ? 'Personal Leave'
              : 'Sick Leave'}
          </TableText>
        ),
      },
      {
        title: 'STATUS',
        dataIndex: 'leaveStatus',
        width: '13%',
        align: 'center',
        sorter: defaultStringCompare('leaveStatus'),
        render: (value) => (
          <TableText style={{ textAlign: 'center' }}>
            {value == 'Pending' ? (
              <label style={{ color: '#FFAB4F' }}> Pending</label>
            ) : value == 'Approved' ? (
              <label style={{ color: '#3C8618' }}>Approved</label>
            ) : value == 'Rejected' ? (
              <label style={{ color: '#668EA4' }}>Rejected</label>
            ) : value == 'Canceled by Approver' ? (
              <label style={{ color: '#D62923' }}>Canceled by Approver</label>
            ) : (
              <label style={{ color: '#F37370' }}>Canceled by User</label>
            )}
          </TableText>
        ),
      },
      {
        title: 'ACTION',
        align: 'center',
        width: '10%',
        render: (key, record) => (
          <a onClick={() => this.modalOpen(key, record)}>
            {(record.leaveStatus == 'Pending' &&
              record.supervisorId == this.state.userId) ||
            (record.leaveStatus == 'Approved' &&
              record.supervisorId == this.state.userId) ? (
              <img src={actiona} />
            ) : (
              <img src={eye} />
            )}
          </a>
        ),
      },
    ];
  }
  formRef = React.createRef();

  fetchLeave = () => {
    // TODO generate function for return userInfo and call one line instead of this 4 line
    var jwt = require('jsonwebtoken');
    var token = sessionStorage.getItem('access-token');
    var decode1 = jwt.decode(token);
    const userId = decode1.userId;

    this.setState({
      isLoading: true,
      userId: userId,
    });
    leaveService.getleave(
      {
        year: this.state.year,
        month: this.state.month,
      },
      ({ data }) => {
        this.setState({
          isLoading: false,
          datamain: data,
          // data: data,
          annual: data.filter((data) => data.leaveName == 'ANNUAL').length,
          personal: data.filter((data) => data.leaveName == 'PERSONAL').length,
          sick: data.filter((data) => data.leaveName == 'SICK').length,
        });
        // console.log(data);
        this.filterChange(this.state.searchWord, this.state.type);
      },
      (response) => {
        this.setState({
          isLoading: false,
          data: null,
        });
        if (response && response.status === 400) {
          message.error(`Cannot access data - ${response.data.message}`);
        } else {
          //message.error(response);
        }
      },
    );
  };

  componentDidMount = () => {
    this.fetchLeave();
  };

  handleRedirect = (path) => {
    redirectTo(path);
  };

  modalOpen = (payload, record) => {
    this.getPic(record);
    this.setState({
      record: record,
      modalVisible: true,
      isPending: record.leaveStatus == 'Pending',
      isApprove: record.leaveStatus == 'Approved',
      isCancelUser: record.leaveStatus == 'Canceled by User',
      editable:
        // record.supervisorId == this.state.userId &&
        record.leaveStatus == 'Approved',
    });
    // console.log(record);
  };

  modalClose = () => {
    this.setState({
      image: null,
      record: null,
      modalVisible: false,
      remarkText: null,
    });
    // console.log('record', this.state.record);
  };

  handleDownloadReport = () => {
    console.log('download');
  };

  getPic = (value) => {
    if (value.userInfo.picturePath == null) {
      this.setState({
        image: profileimage,
      });
    } else {
      imageservice.image.getImage(value.userInfo.picturePath, (res) => {
        if (!res.status) {
          const url = URL.createObjectURL(res.data);
          this.setState({
            image: url,
          });
        } else {
          this.setState({
            image: profileimage,
          });
        }
      });
    }
    if (value.lastUpdateUserInfo.picturePath == null) {
      this.setState({
        image2: profileimage,
      });
    } else {
      imageservice.image.getImage(
        value.lastUpdateUserInfo.picturePath,
        (res) => {
          if (!res.status) {
            const url = URL.createObjectURL(res.data);
            this.setState({
              image2: url,
            });
          } else {
            this.setState({
              image2: profileimage,
            });
          }
        },
      );
    }
  };

  handleApprove = () => {
    if (this.state.remarkText?.length > 255) {
      // console.log('too long');
    }

    leaveService.editLeave(
      {
        leaveId: this.state.record.leaveId,
        leaveStatusId: 200,
        remark: this.state.remarkText,
      },
      (call) => {
        this.fetchLeave();
        this.modalClose();
        message.success('success');
      },
      (response) => {
        // TODO there is another good way for handle error message in one place, in api service.
        if (response && response.status === 400) {
          message.error(`Cannot access data - ${response.data.message}`);
        } else {
          message.error('Error');
        }
      },
    );
  };

  handleReject = () => {
    leaveService.editLeave(
      {
        leaveId: this.state.record.leaveId,
        leaveStatusId: 300,
        remark: this.state.remarkText,
      },
      (call) => {
        this.fetchLeave();
        this.modalClose();
        message.success('success');
      },
      (response) => {
        if (response && response.status === 400) {
          message.error(`Cannot access data - ${response.data.message}`);
        } else {
          message.error('Error');
        }
      },
    );
  };

  onChange = ({ target: { value } }) => {
    this.setState({ remarkText: value });
  };

  onTypeFilterChange = (value) => {
    this.setState(
      { type: value },
      // () => this.fetchLeave()
    );
    this.filterChange(this.state.searchWord, value);
  };

  onSearch = (value) => {
    this.setState({
      searchWord: value,
    });
    this.filterChange(value, this.state.type);
  };

  filterChange = (search, type) => {
    this.setState({
      data: this.state.datamain
        .filter((data) => {
          if (!search) {
            return true;
          } else {
            const q1 = search.toUpperCase().split(' ')[0];
            const q2 = search.toUpperCase().split(' ')[1];

            return (
              (String(data.userInfo.firstnameEn).toUpperCase().includes(q1) &&
                (!q2 ||
                  String(data.userInfo.lastnameEn)
                    .toUpperCase()
                    .includes(q2))) ||
              String(data.userInfo.employeeId)
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              String(data.purpose).toUpperCase().includes(search.toUpperCase())
            );
          }
        })
        .filter((data) => {
          if (type != 'All') {
            return data.leaveStatus == type;
          } else {
            return true;
          }
        }),
    });
  };

  onMonthFilterChange = (value) => {
    this.setState(
      {
        month: moment(value).month() + 1,
        year: moment(value).year(),
      },
      () => this.fetchLeave(),
    );
  };

  onChangeApprove = () => {
    this.setState({ editable: !this.state.editable });
  };
  onPageSizeChange = (value) => {
    this.setState({ pageSize: value });
  };

  handleRejectApprove = () => {
    if (this.state.remarkText.length > 255) {
      message.error('Surpress the limit characters at 255 characters.');
    } else {
      leaveService.editLeave(
        {
          leaveId: this.state.record.leaveId,
          leaveStatusId: 401,
          remark: this.state.remarkText,
        },
        (call) => {
          this.fetchLeave();
          this.modalClose();
          message.success('success');
        },
        (response) => {
          if (response && response.status === 400) {
            message.error(`Cannot access data - ${response.data.message}`);
          } else {
            message.error('Error');
          }
        },
      );
    }
  };

  render() {
    return (
      <div style={{ width: 'auto' }}>
        <Space
          direction="vertical"
          size="middle"
          style={{
            width: '100%',
            padding: '20px 3%',
            backgroundColor: '#ffffff',
          }}
        >
          <Row justify="space-between" gutter={[16, 16]}>
            <Col>
              <DatePicker
                defaultValue={moment()}
                picker="month"
                format="MMM YYYY"
                //size='large'
                onChange={(value) => {
                  this.onMonthFilterChange(value);
                }}
              />
            </Col>
            <Col flex="auto" />
            <Col>
              <Search
                placeholder="Search"
                /* onSearch={(value) => this.onSearch(value)} */
                onChange={(e) => this.onSearch(e.target.value)}
                //size='large'
                style={{ width: 256 }}
              />
            </Col>
            <Col>
              <Select
                defaultValue={'All'}
                //size='large'
                style={{ width: 208 }}
                onChange={(value) => {
                  this.onTypeFilterChange(value);
                }}
              >
                <Select.Option value="All">All Status</Select.Option>
                <Select.Option value="Approved">Approved</Select.Option>
                <Select.Option value="Pending">Pending</Select.Option>
                <Select.Option value="Rejected">Rejected</Select.Option>
                <Select.Option value="Canceled by Approver">
                  Canceled by Approver
                </Select.Option>
                <Select.Option value="Canceled by User">
                  Canceled by User
                </Select.Option>
              </Select>
            </Col>
          </Row>
          {/* <Row justify='space-between' gutter={[16, 16]}>
            <Col flex={1}>
              <LeaveCount>
                <Label>Annual Leave</Label>
                <Label grey>{this.state.annual}</Label>
              </LeaveCount>
            </Col>
            <Col flex={1}>
              <LeaveCount>
                <Label>Personal Leave</Label>
                <Label grey>{this.state.personal}</Label>
              </LeaveCount>
            </Col>
            <Col flex={1}>
              <LeaveCount>
                <Label>Sick Leave</Label>
                <Label grey>{this.state.sick}</Label>
              </LeaveCount>
            </Col>
          </Row> */}
        </Space>

        <ContentBox>
          <ProjectTable
            className="table-striped-rows"
            columns={this.columns}
            scroll={{ x: '100%' }}
            dataSource={this.state.data}
            rowKey="id"
            style={{ width: '100%' }}
            size="small"
            indentSize={1}
            loading={this.state.isLoading}
            pagination={{
              position: ['bottomRight'],
              pageSizeOptions: [10, 20, 30, 40],
              showSizeChanger: true,
            }}
          />
        </ContentBox>
        <Modal
          width="450px"
          bodyStyle={{
            maxHeight: '800px',
            // overflowY: 'scroll',
          }}
          visible={this.state.modalVisible}
          centered
          footer={null}
          onCancel={() => this.modalClose()}
        >
          <Row>
            <Col span={6}>
              <Space
                direction="vertical"
                align="center"
                size={6}
                style={{ width: '100%', paddingRight: 24 }}
              >
                <Circle width={66} height={66} src={this.state.image}></Circle>
                <Label style={{ textAlign: 'center' }}>
                  {this.state.record?.userInfo.employeeId}
                </Label>
              </Space>
            </Col>

            <Col span={18}>
              <LilHeader>Leave Request</LilHeader>
              <BigHeader>
                {this.state.record?.userInfo.firstnameEn}{' '}
                {this.state.record?.userInfo.lastnameEn}
              </BigHeader>
              <Rows
                style={{
                  width: '100%',
                  paddingTop: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Column style={{ alignItems: 'flex-start', width: '50%' }}>
                  <LilHeader2>Department</LilHeader2>
                  <LilHeader>
                    {this.state.record?.userInfo.department}
                  </LilHeader>
                </Column>
                <Column style={{ alignItems: 'flex-start', width: '50%' }}>
                  <LilHeader2>Position</LilHeader2>
                  <LilHeader>{this.state.record?.userInfo.position}</LilHeader>
                </Column>
              </Rows>
            </Col>
          </Row>
          <Break />
          <Rows style={{ justifyContent: 'space-between' }}>
            <BigHeader style={{ color: '#004368' }}>
              {this.state.record?.leaveName == 'PERSONAL'
                ? 'Personal Leave'
                : this.state.record?.leaveName == 'ANNUAL'
                ? 'Annual Leave'
                : 'Sick Leave'}
            </BigHeader>
            <TableText>
              {this.state.record?.leaveStatus == 'Pending' ? (
                <label style={{ color: '#FFAB4F' }}> Pending</label>
              ) : this.state.record?.leaveStatus == 'Approved' ? (
                <label style={{ color: '#3C8618' }}>Approved</label>
              ) : this.state.record?.leaveStatus == 'Rejected' ? (
                <label style={{ color: '#668EA4' }}>Rejected</label>
              ) : this.state.record?.leaveStatus == 'Canceled by Approver' ? (
                <label style={{ color: '#D62923' }}>Canceled by Approver</label>
              ) : (
                <label style={{ color: '#F37370' }}>Canceled by User</label>
              )}
            </TableText>
          </Rows>
          <Rows>
            <BigHeader2>
              {Math.floor(this.state.record?.leaveHour / 8).toString()}
              {' Day '}
              {Math.floor(this.state.record?.leaveHour % 8).toString()}
              {' hr.'}
            </BigHeader2>
          </Rows>
          <Rows>
            {this.state.record ? (
              Math.floor(this.state.record.leaveHour / 8) == 0 ? (
                <LilHeader>
                  {moment(this.state.record.startTime).format(
                    'DD MMMM YYYY HH.mm',
                  )}
                  {' - '}
                  {moment(this.state.record.endTime).format(' HH.mm')}
                </LilHeader>
              ) : Math.floor(this.state.record.leaveHour / 8) == 1 ? (
                <LilHeader>
                  {moment(this.state.record.startDate).format('DD MMMM YYYY')}
                </LilHeader>
              ) : (
                <LilHeader>
                  {moment(this.state.record.startDate).format('DD MMMM')}
                  {' - '}
                  {moment(this.state.record.endDate).format('DD MMMM YYYY')}
                </LilHeader>
              )
            ) : (
              ''
            )}
          </Rows>

          <Rows style={{ paddingTop: '10px' }}>
            <LilHeader2>Description</LilHeader2>
          </Rows>
          <Rows>
            <LilHeader>{this.state.record?.purpose}</LilHeader>
          </Rows>
          <Break />
          <Rows style={{ justifyContent: 'space-between' }}>
            <Column>
              <BigHeader2>
                {Math.floor(
                  this.state.record?.leaveRemain.annual.hourRemain / 8,
                ).toString()}
                {' Day '}
                {Math.floor(
                  this.state.record?.leaveRemain.annual.hourRemain % 8,
                ).toString()}
                {' hr.'}
              </BigHeader2>
              <LilHeader2>Annual Leave</LilHeader2>
            </Column>
            <Column>
              <BigHeader2>
                {Math.floor(
                  this.state.record?.leaveRemain.personal.hourRemain / 8,
                ).toString()}
                {' Day '}
                {Math.floor(
                  this.state.record?.leaveRemain.personal.hourRemain % 8,
                ).toString()}
                {' hr.'}
              </BigHeader2>
              <LilHeader2>Personal Leave</LilHeader2>
            </Column>
            <Column>
              <BigHeader2>
                {Math.floor(
                  this.state.record?.leaveRemain.sick.hourRemain / 8,
                ).toString()}
                {' Day '}
                {Math.floor(
                  this.state.record?.leaveRemain.sick.hourRemain % 8,
                ).toString()}
                {' hr.'}
              </BigHeader2>
              <LilHeader2>Sick Leave</LilHeader2>
            </Column>
          </Rows>
          {((!this.state.isPending && !this.state.isCancelUser) ||
            (this.state.isApprove && this.state.editable)) && (
            <div>
              <Break />
              <Rows>
                <Column
                  style={{
                    // width: '30%',
                    justifyContent: 'center',
                  }}
                >
                  <Circle
                    style={{
                      height: '45px',
                      width: '45px',
                    }}
                    src={this.state.image2}
                  ></Circle>
                </Column>

                <Column
                  style={{
                    paddingLeft: '15px',
                    alignItems: 'flex-start',
                    width: '60%',
                  }}
                >
                  <LilHeader>
                    {this.state.record
                      ? this.state.record.leaveStatus == 'Approved'
                        ? 'Approved by'
                        : this.state.record.leaveStatus ==
                          'Canceled by Approver'
                        ? 'Canceled by'
                        : 'Rejected by'
                      : ''}
                  </LilHeader>
                  <BigHeader2>
                    {this.state.record?.lastUpdateUserInfo.firstnameEn}{' '}
                    {this.state.record?.lastUpdateUserInfo.lastnameEn}
                  </BigHeader2>
                  <Rows style={{ width: '100%' }}>
                    <LilHeader>
                      {moment(this.state.record?.updateDatetime).format(
                        'DD MMMM YYYY, HH:mm',
                      )}
                    </LilHeader>
                  </Rows>
                </Column>
              </Rows>
              <Rows style={{ paddingTop: '10px' }}>
                <LilHeader2>Description</LilHeader2>
              </Rows>
              <Rows>
                {this.state.record ? (
                  this.state.record.approveInfo ? (
                    <LilHeader>
                      {this.state.record.approveInfo.remark}
                    </LilHeader>
                  ) : (
                    <LilHeader>{this.state.record.remark}</LilHeader>
                  )
                ) : (
                  ''
                )}
              </Rows>
              <Rows style={{ paddingTop: '10px' }}>
                {this.state.record ? (
                  this.state.record.approveInfo ? (
                    <div>
                      <Rows>
                        <LilHeader2>Description for cancellation</LilHeader2>
                      </Rows>
                      <LilHeader>{this.state.record.remark}</LilHeader>
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </Rows>

              {this.state.isApprove &&
                this.state.editable &&
                this.state.userId == this.state.record?.supervisorId && (
                  <Rows
                    style={{
                      justifyContent: 'flex-start',
                      paddingTop: '10px',
                    }}
                  >
                    <ButtonBlock>
                      <RejectedButton onClick={this.onChangeApprove}>
                        Cancel Request
                      </RejectedButton>
                    </ButtonBlock>
                  </Rows>
                )}
            </div>
          )}

          {((this.state.isPending &&
            this.state.record?.supervisorId == this.state.userId) ||
            (this.state.isApprove && !this.state.editable)) && (
            <Rows
              style={{
                paddingTop: '10px',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
            >
              <TextArea
                value={this.state.remarkText}
                onChange={this.onChange}
                placeholder="Description"
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Rows>
          )}

          {this.state.isApprove && !this.state.editable && (
            <div style={{ margin: '24px 0' }}>
              <Rows style={{ justifyContent: 'space-between' }}>
                <ButtonBlock width="100px">
                  <RejectedButton width="100px" onClick={this.modalClose}>
                    Cancel
                  </RejectedButton>
                </ButtonBlock>
                <ButtonBlock width="100px">
                  <NormalButton
                    width="100px"
                    onClick={this.handleRejectApprove}
                    disabled={!this.state.remarkText}
                  >
                    Confirm
                  </NormalButton>
                </ButtonBlock>
              </Rows>
            </div>
          )}
          {this.state.isPending &&
            this.state.record?.supervisorId == this.state.userId && (
              // this.state.editable &&
              <div style={{ margin: '24px 0' }}>
                <Rows style={{ justifyContent: 'space-between' }}>
                  <ButtonBlock width="100px">
                    <RejectedButton
                      width="100px"
                      onClick={this.handleReject}
                      disabled={!this.state.remarkText}
                    >
                      Reject
                    </RejectedButton>
                  </ButtonBlock>
                  <ButtonBlock width="100px">
                    <NormalButton
                      width="100px"
                      onClick={this.handleApprove}
                      //disabled={!this.state.remarkText}
                    >
                      Approve
                    </NormalButton>
                  </ButtonBlock>
                </Rows>
              </div>
            )}
        </Modal>
      </div>
    );
  }
}

export default LeavePage;
