import React, { Component, createRef } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Upload,
  message,
} from 'antd';
import styled from 'styled-components';
import crypto from 'crypto';

const ContentBox = styled.div`
  background: #f6f6f6;
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: auto;
  min-height: calc(100vh - 7em);
  justify-content: flex-start;
  padding-top: 0.5em;
`;

const Label = styled.div`
  font-family: 'Kanit';
  font-size: 16px;
  font-weight: 500;
  color: black;
  @media (max-width: 1424px) {
    width: 100%;
    min-width: none;
  }
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  background-color: ${(props) =>
    props.white ? '#FFFFFF  !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.white ? '#004368  !important' : '#004368 !important'};
  justify-content: center;
  color: ${(props) => (props.white ? '#004368' : 'white')};
  font-family: Inter;
  font-style: Regular;
  font-size: 14px;
  line-height: 19.42px;
  text-align: center;
  vertical-align: middle;
  padding: 5px 15px;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;

const TextCard = styled.div`
  height: auto;
  width: 100%;
  background-color: white;
  margin-top: 2em;
  box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 40px 50px;
`;

class DecryptPage extends Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      decryptData: null,
    };
  }

  onFinishDecrypt = (value) => {
    try {
      const algorithm = 'aes-256-cbc';
      const encryptionKey = crypto
        .createHash('sha512')
        .update(value.key)
        .digest('hex')
        .substring(0, 32);
      const encryptionIV = crypto
        .createHash('sha512')
        .update(value.iv)
        .digest('hex')
        .substring(0, 16);
      const buff = Buffer.from(value.text, 'base64');
      const decipher = crypto.createDecipheriv(
        algorithm,
        encryptionKey,
        encryptionIV,
      );
      const text =
        decipher.update(buff.toString('utf8'), 'hex', 'utf8') +
        decipher.final('utf8');
      this.setState({
        decryptData: text,
      });
    } catch (err) {
      this.setState({
        decryptData: `Error: Something is wrong`,
      });
    }
  };

  render() {
    return (
      <div style={{ width: 'auto' }}>
        <ContentBox>
          <TextCard>
            <Form ref={this.formRef} onFinish={this.onFinishDecrypt}>
              <Row gutter={[64, 16]}>
                <Col
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  style={{ width: '100%' }}
                >
                  <Row gutter={16}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <Label>Text</Label>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 16 }}>
                      <Form.Item
                        name="text"
                        style={{ width: '100%', height: '2rem' }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  style={{ width: '100%' }}
                >
                  <Row gutter={16}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <Label>Key</Label>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 16 }}>
                      <Form.Item
                        style={{ width: '100%', height: '2rem' }}
                        name="key"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={[64, 16]}>
                <Col
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  style={{ width: '100%' }}
                >
                  <Row gutter={16}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <Label>IV</Label>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 16 }}>
                      <Form.Item
                        name="iv"
                        style={{ width: '100%', height: '2rem' }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  style={{ width: '100%' }}
                >
                  <Row gutter={16}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <Label>Output</Label>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 16 }}>
                      <Label>{this.state.decryptData}</Label>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row justify="end" gutter={[16, 16]}>
                <Col flex="none">
                  <NormalButton
                    style={{
                      width: '128px',
                      alignItems: 'center',
                      marginTop: '20px',
                    }}
                    onClick={() => this.formRef.current.submit()}
                  >
                    Decrypt
                  </NormalButton>
                </Col>
              </Row>
            </Form>
          </TextCard>
        </ContentBox>
      </div>
    );
  }
}

export default DecryptPage;
