import React, { useState, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { AppProvider } from './components/context/AppContext';
import { redirectTo } from './services/redirect';
import Login from './pages/loginpage';
import Resetpassword from './pages/resetpassword';
import Project from './pages/staff/project';
import Profile from './pages/staff/profile';
import Timesheet from './pages/staff/timesheet';
import TimesheetDetail from './pages/staff/timesheetDetail';
import Leave from './pages/staff/leave';
import { subDomain } from './services/redirect';
import Customer from './pages/admin/customer';
import Announce from './pages/admin/announce';
import AddCustomer from './pages/admin/addcustomer';
import ViewCustomer from './pages/admin/viewcustomer';
import Quotation from './pages/admin/quotation';
import CreateQuotation from './pages/admin/createquotation';
import Loginredirect from './pages/login.js';
import User from './pages/admin/user';
import AnnounceForm from './pages/admin/announceform';
import AllItem from './pages/admin/allitem';
import AddItem from './pages/admin/additem';
import ItemInfo from './pages/admin/iteminfo';
import ProjectForm from './pages/admin/projectform';
import Holiday from './pages/staff/holiday';
import LeavePage from './pages/staff/leavepage';
import { Layout } from 'antd';
import Navbar from './components/navbar';
import NewHeader from './components/newHeader';
import ReportForm from './pages/admin/report';
import ReportFormEmployee from './pages/employee/report';
import TimesheetDetailEmployee from './pages/employee/timesheetDetail';
import AnnounceEmployee from './pages/employee/announce';
import AnnounceFormEmployee from './pages/employee/announceform';
import LeavePageEmployee from './pages/employee/leavepage';
import Settings from './pages/admin/settings';
import PayslipPage from './pages/admin/payslip';
import PayslipPageEmployee from './pages/employee/payslip';
import MedicalClaimPage from './pages/admin/medicalClaim';
import MedicalClaimPageEmployee from './pages/employee/medicalClaim';
import MedicalClaimRequestPage from './pages/employee/medicalClaimRequest';
import SalaryUploadPage from './pages/admin/salaryUpload';
import SalarySettingPage from './pages/admin/salarySetting';
import SalaryPage from './pages/employee/salary';
import DecryptPage from './pages/admin/decrypt';

const { Content } = Layout;

const signedIn =
  sessionStorage.getItem('access-token') &&
  sessionStorage.getItem('refresh-token');
const resetToken = sessionStorage.getItem('reset-token');
const Privateroute = ({ issignedIn, ...props }) => {
  return issignedIn ? <Route {...props} /> : redirectTo(`/login`);
};
const Mainroute = ({ issignedIn }) => {
  return issignedIn ? redirectTo(`/announce`) : redirectTo(`/login`);
  // return <Redirect to={`/timesheet`} />;
};
const ProtectedRoute = ({ isAllowed, redirectPath, children }) => {
  return isAllowed ? children : redirectTo(redirectPath);
};
var jwt = require('jsonwebtoken');
var token = sessionStorage.getItem('access-token');
var decode1 = jwt.decode(token);
const userId = decode1?.userId;

const urlList = [
  { path: '/timesheet', selectedNavbar: 'Timesheet', labelHeader: 'Timesheet' },
  { path: '/project', selectedNavbar: 'Project', labelHeader: 'Project' },
  { path: '/report', selectedNavbar: 'Report', labelHeader: 'Report' },
  { path: '/user/view', selectedNavbar: 'User', labelHeader: 'User Profile' },
  { path: '/user', selectedNavbar: 'User', labelHeader: 'User' },
  { path: '/leavepage', selectedNavbar: 'Leave', labelHeader: 'Leave Request' },
  { path: '/holiday', selectedNavbar: 'Holidays', labelHeader: 'Holidays' },
  { path: '/settings', selectedNavbar: 'Settings', labelHeader: 'Settings' },
  {
    path: '/salary-certificate',
    selectedNavbar: 'Salary Certificate',
    labelHeader: 'Salary Certificate',
  },
  { path: '/customer', selectedNavbar: 'Customer', labelHeader: 'Customer' },
  { path: '/quotation', selectedNavbar: 'Quotation', labelHeader: 'Quotation' },
  {
    path: '/announce',
    selectedNavbar: 'Announce',
    labelHeader: 'Announce News',
  },
  {
    path: '/questionare',
    selectedNavbar: 'Questionare',
    labelHeader: 'Questionare',
  },
  {
    path: '/medical-claim',
    selectedNavbar: 'Medical Claim',
    labelHeader: 'Medical Claim',
  },
  { path: '/profile', selectedNavbar: null, labelHeader: 'My Profile' },
  { path: '/payslip', selectedNavbar: 'Pay Slip', labelHeader: 'Pay Slip' },
];

function App() {
  const [username, setUsername] = useState(function () {});
  const callBackFunction = (f) => {
    // console.log(f);
    setUsername(() => f);
  };
  const [urlStartsWith, setUrlStartsWith] = useState({
    path: '/',
    selectedNavbar: null,
    labelHeader: null,
  });
  const location = useLocation();

  //keep track of user role
  const currentRole = sessionStorage.getItem('current-role');
  const [userState, setUserState] = useState({
    role: decode1?.role,
    currentRole:
      currentRole !== 'undefined'
        ? decode1?.role === 'ADMIN'
          ? currentRole
          : 'USER'
        : decode1?.role,
  });

  useEffect(() => {
    console.log(userState);
    sessionStorage.setItem('current-role', userState.currentRole);
  }, [userState]);

  useEffect(() => {
    console.log(
      `urlStartsWith: ${urlStartsWith.path} ${urlStartsWith.selectedNavbar}`,
    );
  }, [urlStartsWith]);

  useEffect(() => {
    // Check token
    const urlFound = urlList.find((url) =>
      location.pathname.startsWith(url.path),
    );
    if (urlFound) {
      setUrlStartsWith(urlFound);
    } else if (!urlFound && signedIn) {
      redirectTo(`/announce`);
    } else if (window.location.pathname === `${subDomain}/login`) {
      setUrlStartsWith({
        path: '/login',
        selectedNavbar: null,
        labelHeader: null,
      });
    } else if (
      !(
        window.location.pathname === `${subDomain}/login` ||
        window.location.pathname.search('resetpassword') != -1 ||
        window.location.pathname.search('loginredirect') != -1
      ) &&
      !signedIn
    ) {
      redirectTo(`/`);
    }
    console.log(urlStartsWith);
  }, [location]);

  // console.log(sessionStorage.getItem("access-token"));
  // console.log("pathname", window.location.pathname);
  const headerHeight = '4rem';
  if (
    (window.location.pathname === `${subDomain}/login` ||
      window.location.pathname.search('resetpassword') != -1 ||
      window.location.pathname.search('loginredirect') != -1) &&
    !signedIn
  ) {
    return (
      <div className="App">
        <Switch>
          <Route exact path={`/login`} component={Login} />
          <Route exact path={`/loginredirect`} component={Loginredirect} />
          <Route
            exact
            path={
              process.env.PUBLIC_URL +
              `/loginredirect/:accessToken/:refreshToken`
            }
            component={Loginredirect}
          />
          <Route
            exact
            path={`/resetpassword/:token`}
            component={Resetpassword}
          />
        </Switch>
      </div>
    );
  } else {
    console.log(urlStartsWith);
    // console.log('public_url', process.env.PUBLIC_URL);

    return (
      <>
        <div className="App">
          <AppProvider value={{ userState, setUserState }}>
            <Layout>
              <Navbar
                selectedNavbar={urlStartsWith.selectedNavbar}
                role={userState.currentRole}
              />
              <Layout>
                <NewHeader labelHeader={urlStartsWith.labelHeader} />
                {/* <Header headerHeight={headerHeight} username={callBackFunction} /> */}
                <Content>
                  <Switch>
                    {/* <Route path={'/login'} component={Login} /> */}
                    {/* <Mainroute issignedIn={signedIn} exact path={`${subDomain}/`} /> */}
                    <Mainroute issignedIn={signedIn} exact path={`/`} />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/project`}
                      component={Project}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/leavepage`}
                      // component={LeavePage}
                      component={
                        userState.currentRole === 'ADMIN'
                          ? LeavePage
                          : LeavePageEmployee
                      }
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/holiday`}
                      component={Holiday}
                    />

                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/settings`}
                      component={() => {
                        if (userState.currentRole === 'ADMIN') {
                          return <Settings />;
                        }
                        sessionStorage.setItem('current-role', 'USER');
                        redirectTo('/announce');
                      }}
                    />

                    <Privateroute
                      issignedIn={signedIn}
                      path={`/project/projectform`}
                      component={ProjectForm}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/report`}
                      component={
                        userState.currentRole === 'ADMIN'
                          ? ReportForm
                          : ReportFormEmployee
                      }
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/leave`}
                      component={Leave}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/timesheet`}
                      component={
                        userState.currentRole === 'ADMIN'
                          ? Timesheet
                          : TimesheetDetailEmployee
                      }
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/timesheetdetail`}
                      component={TimesheetDetail}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/profile`}
                      component={(props) => {
                        const id = new URLSearchParams(
                          props.location.search,
                        ).get('id');
                        return (
                          <ProtectedRoute
                            redirectPath={`/profile/?id=${userId}`}
                            isAllowed={id === userId.toString()}
                          >
                            <Profile {...props} currentPage="profile" />
                          </ProtectedRoute>
                        );
                      }}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/customer`}
                      component={Customer}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/announce`}
                      component={
                        userState.currentRole === 'ADMIN'
                          ? Announce
                          : AnnounceEmployee
                      }
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      path={`/announce/announceform`}
                      component={
                        userState.currentRole === 'ADMIN'
                          ? AnnounceForm
                          : AnnounceFormEmployee
                      }
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      path={`/customer/addcustomer`}
                      component={AddCustomer}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      path={`$/customer/view/`}
                      component={ViewCustomer}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/quotation`}
                      component={Quotation}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/item`}
                      component={AllItem}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/quotation/createquotation`}
                      component={CreateQuotation}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      path={`/user/adduser`}
                      // component={AddUser}
                      component={(props) => (
                        <ProtectedRoute
                          redirectPath={`/user`}
                          isAllowed={userState.currentRole === 'ADMIN'}
                        >
                          <Profile {...props} currentPage="add" />
                        </ProtectedRoute>
                      )}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/item/additem`}
                      component={AddItem}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      path={`/user/view`}
                      component={(props) => (
                        <ProtectedRoute
                          redirectPath={`/user`}
                          isAllowed={userState.currentRole === 'ADMIN'}
                        >
                          <Profile {...props} currentPage="user" />
                        </ProtectedRoute>
                      )}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/user`}
                      component={User}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/item/iteminfo`}
                      component={ItemInfo}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/report`}
                      component={ReportForm}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/salary-certificate`}
                      component={
                        userState.currentRole === 'ADMIN'
                          ? PayslipPage
                          : PayslipPageEmployee
                      }
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/medical-claim`}
                      component={
                        userState.currentRole === 'ADMIN'
                          ? MedicalClaimPage
                          : MedicalClaimPageEmployee
                      }
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/medical-claim/request`}
                      component={MedicalClaimRequestPage}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/payslip/setting`}
                      component={() => (
                        <ProtectedRoute
                          redirectPath={`/announce`}
                          isAllowed={userState.currentRole === 'ADMIN'}
                        >
                          <SalarySettingPage />
                        </ProtectedRoute>
                      )}
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/payslip`}
                      component={
                        userState.currentRole === 'ADMIN'
                          ? SalaryUploadPage
                          : SalaryPage
                      }
                    />
                    <Privateroute
                      issignedIn={signedIn}
                      exact
                      path={`/settings/decrypt`}
                      component={() => (
                        <ProtectedRoute
                          redirectPath={`/announce`}
                          isAllowed={userState.currentRole === 'ADMIN'}
                        >
                          <DecryptPage />
                        </ProtectedRoute>
                      )}
                    />
                    {
                      ////////////////////////////// CV Export Section //////////////////////////////
                      // <Privateroute
                      //   issignedIn={signedIn}
                      //   path={`/user/cv`}
                      //   component={(props) => (
                      //     <ProtectedRoute
                      //       redirectPath={`/user`}
                      //       isAllowed={
                      //         userState.currentRole === 'ADMIN' ||
                      //         userState.currentRole === 'ADMIN_HR'
                      //       }
                      //     >
                      //       <CV {...props} currentPage="user" />
                      //     </ProtectedRoute>
                      //   )}
                      // />
                      ////////////////////////////// CV Export Section //////////////////////////////
                    }
                  </Switch>
                </Content>
              </Layout>
            </Layout>
          </AppProvider>
        </div>
      </>
    );
  }
}

export default App;
