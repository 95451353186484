import { message } from 'antd';
import { authentication } from './api';

const getToken = () => {
  return `Bearer ${sessionStorage.getItem('access-token')}`;
};

const getRefreshToken = () => {
  return `Bearer ${sessionStorage.getItem('refresh-token')}`;
};

const applyToken = (token) => {
  sessionStorage.setItem('access-token', token);
};

/**
 * Create Promise of access token and store it
 * If this function invoke while promise is not resolved
 * the stored promised will be returned
 *
 * when promise is resolved, the promise is clear
 *
 * @typedef {string} AccessToken
 * @returns {Promise<AccessToken>}
 */
const refreshToken = (() => {
  let refreshSession = null;
  return () => {
    if (!refreshSession) {
      const session = new Promise((resolve, reject) => {
        authentication
          .getNewToken(getRefreshToken())
          .then((data) => {
            const { accessToken } = data;
            applyToken(accessToken);
            if (refreshSession === session) {
              refreshSession = null;
            }
            resolve(accessToken);
          })
          .catch((error) => {
            const status = error.response.status;
            if (status === 401) {
              sessionStorage.clear();
              message.error('กรุณาเข้าสู่ระบบอีกครั้ง');
            } else {
              message.error('เกิดข้อผิดพลาด กรุณาลองใหม่');
            }

            reject(error);
          });
      });

      refreshSession = session;
    }

    return refreshSession;
  };
})();

export const createAuthCycle = (client) => {
  // Attach token to all request created by this client
  client.interceptors.request.use(
    (config) => {
      config.headers['Authorization'] = getToken();
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  // When request failed with Unauthorized (401)
  // Try to get new token and resend request
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      // If status is not 401, bypass error
      console.log(error);
      if (!error.response || error.response.status !== 401) {
        return Promise.reject(error);
      }

      const config = error.response.config;

      // Token maybe already changed (when 401 of another request occured) when 401 occured
      // So we check the change first
      const storedToken = getToken();
      const tokenHasBeenChanged =
        storedToken !== config.headers['Authorization'];
      // If changed, Set the changed token and resend
      if (tokenHasBeenChanged) {
        config.headers['Authorization'] = storedToken;
        return client.request(config);
      }

      // Refresh token and resend
      // Note that refresh function must handle multiple calls at a time
      return refreshToken()
        .then((token) => {
          config.headers['Authorization'] = token;
          return client.request(config);
        })
        .catch((authError) => {
          return Promise.reject(error);
        });
    },
  );
};
