import {
  Table,
  Row,
  Col,
  Space,
  Select,
  message,
  Button,
  DatePicker,
} from 'antd';
import styled from 'styled-components';
import React, { Component } from 'react';
import actionIcon from '../../assets/icons/actiona.svg';
import eyeIcon from '../../assets/icons/eye.svg';
import { PlusOutlined } from '@ant-design/icons';
import { AuthorizeRoleService } from '../../services/api';
import EditMedicalClaimModal from '../../components/medicalClaim/editMedicalClaimModal';
import { getMedicalClaimPic, getUserPic } from '../../services/image';
import moment from 'moment';

const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#ffffff')};
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  width: auto;
  min-height: calc(100vh - 200px);
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: auto;
  border-radius: 5px;
  background-color: ${(props) =>
    props.white ? '#FFFFFF  !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.white ? '#004368  !important' : '#004368 !important'};
  color: ${(props) => (props.white ? '#004368' : 'white')};
  font-family: Kanit;
  font-style: Regular;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid white !important;
    background-color: #f5f5f5 !important;
  }
`;

const ProjectTable = styled(Table)`
  & .ant-table.ant-table-small .ant-table-thead > tr > th {
    font-size: 16px;
    font-weight: bold;
    background-color: white;
    padding-bottom: 20px;
  }

  &.table-striped-rows tr > th {
    background-color: #ffffff;
  }
  &.table-striped-rows tr > td {
    height: 50px;
  }
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 6px 8px;
  }
`;

const Selectdata = styled(Select)`
  border-radius: 5px;
`;

const TableText = styled.div`
  color: ${(props) => (props.name ? '#004368' : '#737373')};
  font-size: 1em;
  font-weight: ${(props) =>
    props.title ? '600' : props.isKey ? '500' : '300'};
  font-family: Kanit;
  line-height: 22px;
  text-align: left;
`;

const Text = styled.div`
  color: #232323;
  font-size: 16px;
  font-weight: 500;
  font-family: Kanit;
`;

class MedicalClaimPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      mainData: null,
      isLoading: null,
      editModalVisible: false,
      record: null,
      year: moment().year(),
    };
  }

  componentDidMount = () => {
    this.fetchMedicalClaimHistroryByUserId();
  };

  editModalClose = () => {
    this.setState({
      editModalVisible: false,
      record: null,
    });
  };

  editModalOpen = async (record) => {
    record = await getUserPic(record);
    record = await getMedicalClaimPic(record);
    console.log(record);
    this.setState({
      editModalVisible: true,
      record: record,
    });
  };

  fetchMedicalClaimHistroryByUserId = () => {
    this.setState({
      isLoading: true,
    });
    const payload = {
      year: this.state.year,
    };
    AuthorizeRoleService.getMedicalClaimHistroryByUserId(
      payload,
      ({ data }) => {
        this.setState({
          data: data,
          mainData: data,
          isLoading: false,
        });
      },
      (response) => {
        this.setState({
          isLoading: false,
        });
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  handleRedirect = (path) => {
    const { history } = this.props;
    history.push(path);
  };

  onStatusTypeFilterChange = (value) => {
    if (value === 'All') {
      this.setState({
        data: this.state.mainData,
      });
    } else {
      this.setState({
        data: this.state.mainData.filter(
          (record) => record.requestStatusName === value,
        ),
      });
    }
  };

  onYearFilterChange = (value) => {
    this.setState(
      {
        year: moment(value).year(),
      },
      () => this.fetchMedicalClaimHistroryByUserId(),
    );
  };

  render() {
    const defaultStringCompare = (property) => (a, b) =>
      a[property].localeCompare(b[property]);

    const columns = [
      {
        title: 'TYPE',
        dataIndex: 'medicalClaimName',
        width: '300px',
        align: 'center',
        sorter: defaultStringCompare('medicalClaimName'),
        render: (value) => <TableText>{value}</TableText>,
      },
      {
        title: 'NAME',
        dataIndex: 'firstnameEn',
        align: 'center',
        sorter: defaultStringCompare('firstnameEn'),
        render: (key, record) => (
          <TableText>{`${record.userInfo.firstnameEn} ${record.userInfo.lastnameEn}`}</TableText>
        ),
      },
      {
        title: 'AMOUNT',
        dataIndex: 'amountUse',
        width: '200px',
        align: 'center',
        render: (value) => (
          <TableText style={{ textAlign: 'right' }}>
            {value.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </TableText>
        ),
      },
      {
        title: 'STATUS',
        dataIndex: 'requestStatusName',
        width: '180px',
        align: 'center',
        sorter: defaultStringCompare('requestStatusName'),
        render: (value) => (
          <TableText style={{ textAlign: 'center' }}>
            {value == 'Pending' ? (
              <label style={{ color: '#FFAB4F' }}>Pending</label>
            ) : value === 'Approved' ? (
              <label style={{ color: '#3C8618' }}>Approved</label>
            ) : value === 'Rejected' ? (
              <label style={{ color: '#668EA4' }}>Rejected</label>
            ) : value === 'Canceled by Approver' ? (
              <label style={{ color: '#D62923' }}>Canceled by Approver</label>
            ) : (
              <label style={{ color: '#F37370' }}>Canceled by User</label>
            )}
          </TableText>
        ),
      },
      {
        title: 'ACTION',
        align: 'center',
        width: '180px',
        render: (key, record) => (
          <a
            onClick={() => {
              this.editModalOpen(record);
            }}
          >
            {record.requestStatusName === 'Pending' ? (
              <img src={actionIcon} />
            ) : (
              <img src={eyeIcon} />
            )}
          </a>
        ),
      },
    ];

    return (
      <div style={{ width: 'auto' }}>
        <Space
          direction="vertical"
          size="middle"
          style={{
            width: '100%',
            padding: '20px 3%',
            backgroundColor: '#ffffff',
          }}
        >
          <Row justify="space-between" gutter={[16, 16]}>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text>All medical claim</Text>
            </Col>
            <Col>
              <Selectdata
                defaultValue={'All'}
                style={{ width: 208 }}
                onChange={this.onStatusTypeFilterChange}
              >
                <Select.Option value="All">All Status</Select.Option>
                <Select.Option value="Approved">Approved</Select.Option>
                <Select.Option value="Pending">Pending</Select.Option>
                <Select.Option value="Rejected">Rejected</Select.Option>
                <Select.Option value="Canceled by User">
                  Canceled by User
                </Select.Option>
                <Select.Option value="Canceled by Approver">
                  Canceled by Approver
                </Select.Option>
              </Selectdata>
            </Col>
          </Row>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col>
              <DatePicker
                defaultValue={moment()}
                picker="year"
                format="YYYY"
                onChange={(value) => {
                  this.onYearFilterChange(value);
                }}
              />
            </Col>
            <Col flex="none">
              <NormalButton
                style={{ fontSize: '14px', width: '208px' }}
                onClick={() => this.handleRedirect('/medical-claim/request')}
              >
                <PlusOutlined style={{ fontSize: '16px' }} />
                Create Request
              </NormalButton>
            </Col>
          </Row>
        </Space>
        <ContentBox>
          <ProjectTable
            className="table-striped-rows"
            columns={columns}
            scroll={{ x: 300 }}
            dataSource={this.state.data}
            style={{ width: '100%' }}
            size="small"
            loading={this.state.isLoading}
            pagination={{
              position: ['bottomRight'],
              pageSizeOptions: [10, 20, 30, 40],
              showSizeChanger: true,
            }}
          />
        </ContentBox>
        <EditMedicalClaimModal
          open={this.state.editModalVisible}
          onCancel={this.editModalClose}
          onFetch={this.fetchMedicalClaimHistroryByUserId}
          record={this.state.record}
        />
      </div>
    );
  }
}

export default MedicalClaimPage;
