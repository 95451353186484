import { Form, Input, Modal } from 'antd';
import React from 'react';
import { profileservice } from '../../services/api';
import { message } from 'antd';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
  labelAlign: 'left',
};

const ChangePasswordModal = ({ visible, closeModal }) => {
  const [form] = Form.useForm();

  const handleChangePasswordSubmit = (payload) => {
    const messageKey = 'changing-password';
    message.loading({ key: messageKey, content: 'Changing', duration: 0 });
    profileservice.personalDetail.changePassword(
      payload,
      ({ data }) => {
        message.success({ key: messageKey, content: data });
        handleCancel();
      },
      (response) => {
        if (response && response.status === 400) {
          message.error({
            key: messageKey,
            content: 'Error: ' + response.data,
          });
        } else {
          message.error({
            key: messageKey,
            content: 'Error',
          });
        }
      },
    );
  };

  const handleCancel = () => {
    form.resetFields();
    closeModal();
  };

  return (
    <Modal
      title="Change Password"
      visible={visible}
      onOk={form.submit}
      onCancel={handleCancel}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="changePasswordForm"
        onFinish={handleChangePasswordSubmit}
      >
        <Form.Item
          name="oldPassword"
          label="Old Password"
          rules={[
            {
              required: true,
              message: 'Please input your old password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[
            {
              required: true,
              message: 'Please input your new password!',
            },
            { min: 8, message: 'Password must be minimum 8 characters.' },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your new password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error('The two passwords that you entered do not match!'),
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
