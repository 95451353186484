import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import {
  Row,
  Form,
  Divider,
  message,
  Select,
  Button,
  Modal,
  Col,
  Space,
  Input,
} from 'antd';
import { AuthorizeRoleService } from '../../services/api';
import { generateExcelMedicalClaim } from '../../services/reportGenerator';

const Break = styled(Divider)`
  margin: 12px 0;
`;

const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: end;
  align-items: center;
`;

const HeadDetail = styled.div`
  font-family: 'Kanit';
  font-size: 18px;
  font-weight: 500;
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  border-radius: 5px;
  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Kanit;
  font-style: Regular;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid white !important;
    background-color: #f5f5f5 !important;
  }
`;

class ExportMedicalClaimModal extends Component {
  medicalClaimFromRef = createRef();
  constructor(props) {
    super(props);
    this.state = {};
  }

  onCancelMedicalClaimExport = () => {
    this.props.onCancel();
  };

  onFinishMedicalClaimExport = (value) => {
    const payload = value;
    AuthorizeRoleService.getMedicalClaimReport(
      payload,
      async ({ data }) => {
        const a = document.createElement('a');
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = `Medical Treatment Fee ${payload.year}.xlsx`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
    // old
    // AuthorizeRoleService.getMedicalClaimHistroryByYear(
    //   payload,
    //   ({ data }) => {
    //     console.log(data);
    //     generateExcelMedicalClaim(data, payload);
    //   },
    //   (response) => {
    //     if (response) message.error(`Error: ${response.data.message}`);
    //   },
    // );
  };

  render() {
    const year = new Date().getFullYear();

    return (
      <Modal
        width="450px"
        bodyStyle={{
          maxHeight: '800px',
        }}
        open={this.props.open}
        centered
        footer={null}
        onCancel={this.onCancelMedicalClaimExport}
        maskClosable
      >
        <Form
          ref={this.medicalClaimFromRef}
          onFinish={this.onFinishMedicalClaimExport}
        >
          <Row>
            <HeadDetail>Export</HeadDetail>
          </Row>
          <Break />
          <Form.Item
            name="year"
            label="Year"
            rules={[
              {
                required: true,
                message: 'Please select year',
              },
            ]}
            initialValue={year}
          >
            <Input placeholder="input year" defaultValue={year} />
          </Form.Item>
          <Break />
          <Row style={{ justifyContent: 'end' }}>
            <ButtonBlock width="100px">
              <NormalButton
                width="100px"
                onClick={() => this.medicalClaimFromRef.current.submit()}
              >
                Download
              </NormalButton>
            </ButtonBlock>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ExportMedicalClaimModal;
