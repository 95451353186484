import React, { Component } from 'react';
import styled from 'styled-components';
import { Prompt } from 'react-router-dom';
import {
  Form,
  Button,
  message,
  Spin,
  Input,
  DatePicker,
  Select,
  Row,
  Col,
  Popconfirm,
} from 'antd';
import {
  AnnounceService,
  AuthorizeRoleService,
  imageservice,
} from '../../services/api';
import { redirectTo } from '../../services/redirect';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState, RichUtils } from 'draft-js';
import { insertNewUnstyledBlock } from 'draftjs-utils';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import htmlParser from 'html-react-parser';
import moment from 'moment';
import { unstable_batchedUpdates } from 'react-dom';
import { subDomain } from '../../services/redirect';
import { EditOutlined, LeftOutlined } from '@ant-design/icons';

// #region Component Styles
const TitleBox = styled.div`
  box-sizing: border-box;
  padding-left: 6%;
  padding-right: 6%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  height: 6.875em;
  justify-content: space-between;
`;
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#004368' : props.subtitle ? '#20ABC5' : '#565656'};
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1.625em' : '1em'};
  font-weight: ${(props) =>
    props.title
      ? 'bold'
      : props.subtitle
      ? '600'
      : props.name
      ? '500'
      : 'normal'};
  font-family: 'Kanit';
`;
const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  background-color: ${(props) =>
    props.grey
      ? '#C6C6C6 !important'
      : props.white
      ? '#FFFFFF !important'
      : '#004386 !important'};
  border-color: ${(props) =>
    props.grey
      ? '#C6C6C6 !important'
      : props.white
      ? '#D9D9D9 !important'
      : '#004386 !important'};
  justify-content: center;
  color: ${(props) =>
    props.grey ? '#737373' : props.white ? '#232323 !important' : 'white'};
  font-family: Kanit;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 5px 15px;
  align-items: center;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;
const ContentBox = styled.div`
  background: #f6f6f6;
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: auto;
  min-height: calc(100vh - 7em);
  justify-content: flex-start;
  padding-top: 0.5em;
`;
const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;
const TextCard = styled.div`
  height: auto;
  width: 100%;
  background-color: white;
  margin-top: 2em;
  box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 40px 50px;
`;
const Horizontalbox = styled.div`
  display: flex;
  @media (max-width: 714px) {
    flex-direction: column;
  }
`;
const FlexBox = styled.div`
  width: 50%;
  display: flex;
  padding-right: 2rem;

  box-sizing: border-box;
  margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: row;
  }
  @media (max-width: 714px) {
    padding-right: 0;
    width: 100%;
  }
`;
const Label = styled.div`
  font-family: 'Kanit';
  font-size: 16px;
  font-weight: 500;
  width: 11rem;
  min-width: 11rem;
  color: black;
  @media (max-width: 1424px) {
    width: 100%;
    min-width: none;
  }
  &.required::after {
    content: '*';
    color: red;
  }
`;

const ShowAnnounceContent = styled.div`
  color: #232323;
  padding: 1rem;
  height: 2.5em;
  align-items: center;
  display: flex;
  font-family: 'Kanit';
  font-size: 16px;
  font-weight: 500;
  min-width: 11rem;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  margin-bottom: 5px;
  height: auto;
`;

const ViewData = styled.div`
  width: 100%;
  height: 2rem;
  font-family: 'Kanit';
  font-size: 16px;
`;
const Inputdata = styled(Input)`
  // max-width:14rem;
  width: 100%;
  height: 2rem;
  // margin-left:2rem;
`;
const Picker = styled(DatePicker)`
  // max-width:14rem;
  width: 100%;
  height: 2rem;
  // margin-left:2rem;
`;
const Selectdata = styled(Select)`
  // max-width:14rem;
  width: 100%;
  height: 2rem;
  // margin-left:2rem;
`;
const ToolbarStyle = {
  border: '1px solid #d9d9d9',
};
const EditorStyle = {
  border: '1px solid #d9d9d9',
  minHeight: '20vw',
  marginBottom: '5rem',
};

const Box = styled.div`
  box-sizing: border-box;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 4.9375rem;
  padding-bottom: 5%;
  background: #f6f6f6;
`;

const StyledSpin = styled(Spin)`
  position: fixed;
  text-align: center;
  bottom: 50%;
  z-index: 10;
`;
const SpinBox = styled(Box)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 5;
`;

export default class AnnounceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: '',
      editorState: EditorState.createEmpty(),
      formMode: Boolean(this.id) ? false : true,
      initialValues: {},
      isChanged: false,
      isLoading: true,
      lastPublishDate: null,
    };
  }
  formRef = React.createRef();
  id = new URLSearchParams(this.props.location.search).get('id');
  isEditMode = Boolean(this.id);
  isAddMode = !this.isEditMode;
  handleSubmit = (values) => {
    const data = {
      announcementId: this.id ? this.id : undefined,
      announcementName: values.announcementName,
      announcementDetail: draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent()),
      ),
      type: values.type,
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: values.endDate
        ? moment(values.endDate).format('YYYY-MM-DD')
        : moment('9999-12-31').format('YYYY-MM-DD'),
    };
    console.log(values);
    this.setState({ isChanged: false }); //to not trigger reload confirmation
    if (this.isAddMode) {
      this.handleAddAnnounce(data);
    } else {
      this.handleUpdateAnnounce(data);
    }
  };
  handleAddAnnounce = (payload) => {
    const messageKey = 'adding-announce';
    AnnounceService.addAnnouncement(
      payload,
      ({ data }) => {
        message.success('Successfully created');
        setTimeout(() => {
          redirectTo('/announce');
        }, 1000);
      },
      (response) => {
        if (response && response.status === 400) {
          message.error({
            key: messageKey,
            content: 'Error: ' + response.data.message,
          });
        } else {
          message.error({
            key: messageKey,
            content: 'Error',
          });
        }
      },
    );
  };
  handleUpdateAnnounce = (payload) => {
    const messageKey = 'updating-announce';
    AnnounceService.updateAnnouncement(
      payload,
      ({ data }) => {
        setTimeout(() => {
          message.success('Saved');
          this.setState({ formMode: false });
          unstable_batchedUpdates(() => {
            this.getAnnounceDetail();
          });
        }, 1000);
      },
      (response) => {
        if (response && response.status === 400) {
          message.error({
            key: messageKey,
            content: 'Error: ' + response.data.message,
          });
        } else {
          message.error({
            key: messageKey,
            content: 'Error',
          });
        }
      },
    );
  };
  handleCancel = () => {
    redirectTo('/announce');
  };
  getAnnounceDetail = () => {
    const announcementId = this.id;
    if (!announcementId) return false;
    AnnounceService.getAnnouncement(
      announcementId,
      ({ data }) => {
        if (data[0].endDate !== undefined) {
          //reset endDate api 9999 to undefined
          const endDate = data[0].endDate;
          if (moment(endDate).year() === 9999) {
            data[0].endDate = undefined;
          }
        }
        this.setState(
          {
            initialValues: data[0],
            isLoading: false,
            lastPublishDate: data[0].lastPublishDate,
          },
          () => {
            const contentBlock = data[0]?.announcementDetail
              ? htmlToDraft(data[0].announcementDetail)
              : undefined;
            if (contentBlock) {
              const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks,
              );
              const editorState = EditorState.createWithContent(contentState);
              this.setState({ editorState: editorState });
              this.formRef.current.setFieldsValue({
                announcementDetail: editorState,
              });
            }
            this.formRef.current.setFieldsValue({
              announcementName: data[0]?.announcementName,
              startDate: data[0]?.startDate
                ? moment(data[0].startDate).add(7, 'hours')
                : null,
              endDate: data[0]?.endDate
                ? moment(data[0]?.endDate).add(7, 'hours')
                : null,
              type: data[0]?.type,
            });
            this.setState({
              initialValues: {
                ...data[0],
                startDate: data[0]?.startDate
                  ? moment(data[0].startDate).add(7, 'hours')
                  : null,
                endDate: data[0]?.endDate
                  ? moment(data[0]?.endDate).add(7, 'hours')
                  : null,
                type: data[0]?.type,
              },
            });
          },
        );
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          if (response.data.message != 'empty array') {
            message.error('Cannot access data: ' + response.data.message);
          }
        }
      },
    );
  };
  uploadImageCallback = (file) => {
    var formData = new FormData();
    formData.append('img', file);

    return imageservice.image.uploadImage(formData, ({ data }) => {
      const url = process.env.REACT_APP_API_URL + `/image/getImage/${data}`;
      console.log(url);
      return new Promise((resolve, reject) => {
        resolve({ data: { link: url } });
      });
    });
  };

  onPublish = () => {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    const linkUrl = `${window.location.origin}${subDomain}/login?type=announcement&redirect=announce-${id}`;
    const payload = {
      announcementId: id,
      head: this.state.initialValues.announcementName,
      linkUrl,
    };
    AuthorizeRoleService.notifyAnnouncementToLineGroup(
      payload,
      ({ data }) => {
        this.setState({
          lastPublishDate: data[0].lastPublishDate,
        });
        message.success('Publish Success');
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  onUnload = (e) => {
    if (this.state.isChanged) {
      e.preventDefault();
      e.returnValue = '';
    }
  };
  componentDidMount() {
    this.getAnnounceDetail();
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  render() {
    return this.isEditMode && this.state.isLoading ? (
      //Add mode no need to wait for fetching data
      <SpinBox>
        <StyledSpin size="large" />
      </SpinBox>
    ) : (
      <div style={{ width: 'auto' }}>
        <Prompt
          when={this.state.isChanged}
          message="Are you sure you want to leave?"
        />
        <TitleBox>
          <Text
            title
            style={{
              color: '#232323',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {this.isAddMode ? 'Create Announce News' : 'Edit Announce News'}
          </Text>
          {this.state.formMode || this.isAddMode ? (
            <ButtonBlock width="200px">
              <NormalButton
                white
                form="user-form"
                width="96px"
                onClick={() => {
                  if (this.isAddMode) {
                    this.handleCancel();
                  } else {
                    this.setState((prevState) => ({
                      formMode: !prevState.formMode,
                      isChanged: false,
                    }));
                    this.formRef.current.resetFields();
                  }
                }}
                //style={{ height: '40px' }}
              >
                Cancel
              </NormalButton>
              <NormalButton
                width="96px"
                //style={{ height: '40px' }}
                onClick={() => this.formRef.current.submit()}
              >
                Submit
              </NormalButton>
            </ButtonBlock>
          ) : (
            <ButtonBlock style={{ justifyContent: 'end' }}>
              <Text>
                Last Publish:{' '}
                {this.state.lastPublishDate
                  ? moment(this.state.lastPublishDate).format(
                      'YYYY-MM-DD HH:mm',
                    )
                  : 'Never'}{' '}
              </Text>
              <Popconfirm
                title="Are you sure to publish?"
                okButtonProps={{
                  style: {
                    backgroundColor: '#004368',
                    borderColor: '#2d95a8',
                  },
                }}
                onConfirm={this.onPublish}
                onCancel={() => {}}
              >
                <NormalButton white width="96px" style={{ marginLeft: '8px' }}>
                  Publish
                </NormalButton>
              </Popconfirm>
              <NormalButton
                width="96px"
                style={{ marginLeft: '8px' }}
                onClick={() =>
                  this.setState((prevState) => ({
                    formMode: !prevState.formMode,
                  }))
                }
              >
                <EditOutlined /> Edit
              </NormalButton>
              <NormalButton
                white
                form="user-form"
                width="96px"
                onClick={() => {
                  this.handleCancel();
                }}
                style={{ marginLeft: '8px' }}
              >
                <LeftOutlined />
                Back
              </NormalButton>
            </ButtonBlock>
          )}
        </TitleBox>
        <ContentBox>
          <TextCard style={{ marginBottom: '2.5em', paddingBottom: '1em' }}>
            <Form
              ref={this.formRef}
              onFinish={this.handleSubmit}
              initialValues={{
                announcementName: this.state.initialValues?.announcementName,
              }}
              requiredMark={true}
            >
              <Row gutter={[32, 16]}>
                <Col
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  style={{ width: '100%' }}
                >
                  <Row gutter={16}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <Label
                        className={
                          this.state.formMode || this.isAddMode
                            ? 'required'
                            : ''
                        }
                      >
                        Announce Topic
                      </Label>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 16 }}>
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="announcementName"
                        rules={[
                          {
                            required: true,
                            message: 'Please input Announce Topic',
                          },
                        ]}
                      >
                        {this.state.formMode || this.isAddMode ? (
                          <Inputdata
                            id="announcementName"
                            placeholder="Name"
                            onChange={() => {
                              this.setState({ isChanged: true });
                            }}
                          />
                        ) : (
                          <ViewData
                            style={{
                              width: '100%',
                              overflowX: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {/*this.state.initialValues?.announcementName.length >
                            25
                              ? this.state.initialValues?.announcementName
                                  .substring(0, 24)
                                  .concat('...')
                        : this.state.initialValues?.announcementName*/}
                            {this.state.initialValues?.announcementName}
                          </ViewData>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  style={{ width: '100%' }}
                >
                  <Row gutter={16}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <Label
                        className={
                          this.state.formMode || this.isAddMode
                            ? 'required'
                            : ''
                        }
                      >
                        Start Date
                      </Label>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 16 }}>
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="startDate"
                        rules={[
                          {
                            required: true,
                            message: 'Please select Start Date.',
                          },
                        ]}
                        initialValue={
                          this.state.initialValues?.startDate
                            ? moment(
                                this.state.initialValues?.startDate,
                                'YYYY-MM-DD',
                              )
                            : null
                        }
                      >
                        {this.state.formMode || this.isAddMode ? (
                          <Picker
                            format="DD/MM/YYYY"
                            onChange={() => {
                              this.setState({ isChanged: true });
                            }}
                          />
                        ) : (
                          <ViewData>
                            {this.state.initialValues
                              ? moment(
                                  this.state.initialValues?.startDate,
                                ).format('YYYY-MM-DD')
                              : null}
                          </ViewData>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  style={{ width: '100%' }}
                >
                  <Row gutter={16}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <Label
                        className={
                          this.state.formMode || this.isAddMode
                            ? 'required'
                            : ''
                        }
                      >
                        Type
                      </Label>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 16 }}>
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="type"
                        rules={[
                          {
                            required: true,
                            message: 'Please select Type.',
                          },
                        ]}
                        initialValue={
                          this.state.initialValues?.type
                            ? this.state.initialValues.type
                            : null
                        }
                      >
                        {this.state.formMode || this.isAddMode ? (
                          <Selectdata
                            placeholder="Select type"
                            onChange={() => {
                              this.setState({ isChanged: true });
                            }}
                          >
                            <Select.Option value="news">News</Select.Option>
                            <Select.Option value="social security">
                              Social security
                            </Select.Option>
                            <Select.Option value="other">Other</Select.Option>
                          </Selectdata>
                        ) : (
                          <ViewData>{this.state.initialValues?.type}</ViewData>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  style={{ width: '100%' }}
                >
                  <Row gutter={16}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <Label>End Date</Label>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 16 }}>
                      <Form.Item
                        dependencies={['startDate', 'endDate']}
                        noStyle
                      >
                        {() => {
                          return (
                            <Form.Item
                              style={{ width: '100%' }}
                              name="endDate"
                              fieldKey="endDate"
                              dependencies={['startDate', 'endDate']}
                              rules={[
                                () => ({
                                  validator: (_, value) => {
                                    if (
                                      moment(
                                        this.formRef.current.getFieldValue(
                                          'startDate',
                                        ),
                                      ) > moment(value)
                                    ) {
                                      return Promise.reject(
                                        'End Date must be greater than Start Date.',
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                              initialValue={
                                this.state.initialValues?.endDate
                                  ? moment(
                                      this.state.initialValues?.endDate,
                                      'YYYY-MM-DD',
                                    )
                                  : null
                              }
                            >
                              {this.state.formMode || this.isAddMode ? (
                                <Picker
                                  format="DD/MM/YYYY"
                                  onChange={() => {
                                    this.setState({ isChanged: true });
                                  }}
                                />
                              ) : (
                                <ViewData>
                                  {this.state.initialValues?.endDate
                                    ? moment(
                                        this.state.initialValues?.endDate,
                                      ).format('YYYY-MM-DD')
                                    : null}
                                </ViewData>
                              )}
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="announcementDetail"
                    rules={[
                      () => ({
                        validator: (_, value) => {
                          if (
                            !this.state.editorState
                              .getCurrentContent()
                              .hasText()
                          ) {
                            return Promise.reject(
                              'Please input Announce Detail.',
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    {this.state.formMode || this.isAddMode ? (
                      <Editor
                        onChange={() => {
                          this.setState({ isChanged: true });
                        }}
                        editorState={this.state.editorState}
                        editorStyle={EditorStyle}
                        toolbarStyle={ToolbarStyle}
                        toolbar={{
                          options: [
                            'colorPicker',
                            'fontFamily',
                            'fontSize',
                            'inline',
                            'textAlign',
                            'list',
                            'link',
                            'image',
                            'emoji',
                            'history',
                            'remove',
                          ],
                          inline: {
                            inDropdown: false,
                            options: ['bold', 'italic', 'underline'],
                          },
                          list: { inDropdown: false },
                          textAlign: {
                            inDropdown: false,
                            options: ['left', 'center', 'right'],
                          },
                          link: { inDropdown: false },
                          history: { inDropdown: false },
                          image: {
                            previewImage: true,
                            uploadCallback: this.uploadImageCallback,
                            defaultSize: {
                              height: '100%',
                              width: '100%',
                            },
                          },
                        }}
                        handleReturn={(event) => {
                          // override behavior for enter key
                          var newEditorState = null;
                          if (event.keyCode === 13 && event.shiftKey) {
                            // with shift, make a new block
                            newEditorState = insertNewUnstyledBlock(
                              this.state.editorState,
                            );
                          } else if (event.keyCode === 13 && !event.shiftKey) {
                            // without shift, just a normal line break
                            newEditorState = RichUtils.insertSoftNewline(
                              this.state.editorState,
                            );
                          }
                          if (newEditorState) {
                            this.setState({ editorState: newEditorState });
                            return true;
                          }
                          return false;
                        }}
                        onEditorStateChange={(value) =>
                          this.setState({ editorState: value })
                        }
                      />
                    ) : (
                      <ShowAnnounceContent>
                        <span style={{ overflowWrap: 'break-word' }}>
                          {this.state.initialValues?.announcementDetail
                            ? htmlParser(
                                this.state.initialValues.announcementDetail,
                              )
                            : ''}
                        </span>
                      </ShowAnnounceContent>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TextCard>
        </ContentBox>
      </div>
    );
  }
}
