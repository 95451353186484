import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Layout,
  Button,
  Input,
  Table,
  Row,
  Col,
  message,
  Select,
  Form,
  Space,
} from 'antd';
import Icon from '@ant-design/icons';
import editIcon from '../../assets/icons/edit.svg';
import binIcon from '../../assets/icons/bin.svg';
import exportIcon from '../../assets/icons/export.svg';
import { ReactComponent as addUserIcon } from '../../assets/icons/addUser.svg';
import { redirectTo } from '../../services/redirect';
import { AppContext } from '../../components/context/AppContext';
import { UserService } from '../../services/api';
import { MasterdataService } from '../../services/api';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';

const Excel = require('exceljs');

//#region
const { Header, Content, Footer } = Layout;
const TitleBox = styled.div`
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  height: 6.875em;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.subtitle ? '#20ABC5' : '#232323'};
  font-size: ${(props) =>
    props.title ? '16px' : props.subtitle ? '1.125em' : '1em'};
  font-weight: ${(props) =>
    props.title ? '500' : props.subtitle ? '600' : 'normal'};
  font-family: 'Kanit';
  text-transform: ${(props) => (props.subtitle ? 'uppercase' : 'none')};
`;
const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  border-radius: 5px;
  background-color: ${(props) =>
    props.white ? '#FFFFFF  !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.white ? '#004368  !important' : '#004368 !important'};
  color: ${(props) => (props.white ? '#004368' : 'white')};
  font-family: Inter;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
  &:disabled {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }
`;
const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;
const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#ffffff')};
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  /* flex-direction: row; */
  /* align-items: center; */
  width: auto;
  min-height: calc(100vh - 180px);
`;
const TableText = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.key_user ? '#004368' : '#737373'};
  font-size: 1em;
  font-weight: ${(props) =>
    props.title ? '600' : props.key_user ? '500' : '300'};
  font-family: 'Kanit';
  line-height: 22px;
  text-align: left;
`;
const ItemList = styled(Table)`
  & .ant-table.ant-table-small .ant-table-thead > tr > th {
    font-size: 1em;
    background-color: white;
    padding-bottom: 20px;
  }
  & .table-striped-rows tr > th {
    background-color: #ffffff;
  }

  @media (max-width: 1366px) {
    & .ant-table.ant-table-small .ant-table-thead > tr > th {
      font-size: 0.8em;
      background-color: white;
      padding-bottom: 20px;
    }
    & ${TableText} {
      font-size: 0.8em;
    }
  }
`;
const { Search } = Input;
const { Option } = Select;
const Cols = styled(Col)`
  display: flex;
  align-items: center;
`;
const Rows = styled(Row)`
  margin: 10px 0px;
  align-items: center;
  position: right;
`;
//#endregion

class AllUser extends Component {
  previousContext;

  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      hasData: false,
      incorrect: false,
      sLevel: null,
      sDepartment: null,
      sUser: '',
      mData: [],
      mDept: [],
      currentRole: 'USER',
    };
    this.columns = [
      {
        title: 'LEVEL',
        index: 'level',
        dataIndex: 'level',
        fixed: 'left',
        width: '6%',
        align: 'center',
        sorter: {
          compare: (a, b) => a.level - b.level,
        },
        render: (value, record) =>
          this.getCellRendering(value, record, {
            style: { textAlign: 'center' },
          }),
      },
      {
        title: 'EMP NO.',
        index: 'empCode',
        dataIndex: 'empCode',
        fixed: 'left',
        width: '7%',
        defaultSortOrder: 'ascend',
        align: 'center',
        sorter: {
          compare: (a, b) => a.empCode.localeCompare(b.empCode),
        },
        render: (code, record) => {
          return {
            props: {
              style: {
                background: !record.isActived && '#e3e3e3',
              },
            },
            children:
              this.state.currentRole === 'ADMIN' ? (
                <a
                  onClick={() =>
                    this.handleRedirect('/user/view/?id=' + record.userId)
                  }
                >
                  <TableText key_user>{code}</TableText>
                </a>
              ) : (
                <TableText key_user>{code}</TableText>
              ),
          };
        },
      },
      {
        title: 'NICKNAME',
        index: 'nicknameEn',
        dataIndex: 'nicknameEn',
        width: '9%',
        align: 'center',
        sorter: {
          compare: (a, b) => a.nicknameEn.localeCompare(b.nicknameEn),
        },
        render: this.getCellRendering,
      },
      {
        title: 'FIRST NAME',
        index: 'firstnameEn',
        dataIndex: 'firstnameEn',
        width: '11%',
        align: 'center',
        sorter: {
          compare: (a, b) => a.firstnameEn.localeCompare(b.firstnameEn),
        },
        render: this.getCellRendering,
      },
      {
        title: 'LAST NAME',
        index: 'lastnameEn',
        dataIndex: 'lastnameEn',
        width: '11%',
        align: 'center',
        sorter: {
          compare: (a, b) => a.lastnameEn.localeCompare(b.lastnameEn),
        },
        render: this.getCellRendering,
      },
      {
        title: 'POSITION',
        index: 'position',
        dataIndex: 'position',
        width: '18%',
        align: 'center',
        sorter: {
          compare: (a, b) => a.position.localeCompare(b.position),
        },
        render: this.getCellRendering,
      },
      {
        title: 'DEPARTMENT',
        index: 'department',
        dataIndex: 'department',
        width: '15%',
        align: 'center',
        sorter: {
          compare: (a, b) => a.department.localeCompare(b.department),
        },
        render: this.getCellRendering,
      },
      {
        title: 'MOBILE',
        index: 'mobile',
        dataIndex: 'mobileNumber',
        width: '9%',
        align: 'center',
        render: this.getCellRendering,
      },
      {
        title: 'EMAIL',
        index: 'email',
        dataIndex: 'email',
        fixed: 'right',
        align: 'center',
        render: this.getCellRendering,
      },
      ////////////////////////////// CV Export Section //////////////////////////////
      // {
      //   title: 'CV',
      //   index: 'cv',
      //   dataIndex: 'userId',
      //   fixed: 'right',
      //   width: 50,
      //   align: 'center',
      //   render: (userId) =>
      //     !!userId && (
      //       <a>
      //         <img
      //           src={exportIcon}
      //           onClick={(e) =>
      //             this.state.currentRole === 'ADMIN'
      //               ? this.handleRedirect('/user/cv/?id=' + userId)
      //               : {}
      //           }
      //         />
      //       </a>
      //     ),
      // },
      ////////////////////////////// CV Export Section //////////////////////////////
      // {
      //   title: "ACTION",
      //   index: "action",
      //   dataIndex: "index",
      //   fixed: "right",
      //   width: 100,
      //   render: (key) =>
      //     key && (
      //       <Row>
      //         <Col>
      //           <a>
      //             <img src={editIcon} onClick={(e) => this.handleEditUser(key, e)} />
      //           </a>
      //         </Col>
      //         <Col>
      //           <a>
      //             <img src={editIcon} onClick={(e) => this.handleEditProfile(key, e)} />
      //           </a>
      //         </Col>
      //       </Row>
      //     ),
      // },
    ];
  }

  getCellRendering = (value, record, option) => {
    return {
      props: {
        style: {
          background: !record.isActived && '#e3e3e3',
        },
      },
      children: <TableText {...option}>{value}</TableText>,
    };
  };

  getAddressText = (row) => {
    if (row.current_address1 !== '' && row.current_address1 !== null)
      return `${row.current_address1 ?? ''} ${row.current_address2 ?? ''} ${
        row.current_country ?? ''
      } ${row.current_district ?? ''} ${row.current_province ?? ''} ${
        row.current_zip_code ?? ''
      }`.replace('\n\n*', '\n');
    else
      return `${row.registration_address1 ?? ''} ${
        row.registration_address2 ?? ''
      } ${row.registration_country ?? ''} ${row.registration_district ?? ''} ${
        row.registration_province ?? ''
      } ${row.registration_zip_code ?? ''}`.replace('\n\n*', '\n');
  };

  generateExcelAllUser = (data) => {
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet('My Sheet', {
      views: [{ state: 'frozen', ySplit: 3 }],
    });

    ws.columns = [
      { width: 9.71 },
      { key: 'role', width: 12.57 },
      { key: 'username', width: 41.0 },
      { key: 'employeeId', width: 13.0 },
      { key: 'contractType', width: 14.29 },
      { key: 'firstNameEn', width: 22.0 },
      { key: 'lastNameEn', width: 22.0 },
      { key: 'firstNameTh', width: 22.0 },
      { key: 'lastNameTh', width: 22.0 },
      { key: 'position', width: 37.29 },
      { key: 'department', width: 27.43 },
      { key: 'gender', width: 9.0 },
      { key: 'citizenId', width: 19.0 },
      { key: 'level', width: 6.45 },
      { key: 'birthday', width: 12.14 },
      { key: 'address', width: 35 },
      { key: 'lineId', width: 15 },
      { key: 'notebookOwner', width: 12.14 },
      { key: 'isBoi', width: 12.14 },
      { key: 'isActived', width: 12.14 },
      { key: 'startJobDate', width: 12.14 },
      { key: 'endProbationDate', width: 15 },
      { key: 'toeic', width: 12.14 },
      { key: 'jlpt', width: 12.14 },
      { key: 'carDrivingLicense', width: 12.14 },
    ];

    ws.properties.defaultRowHeight = 15;

    ws.addRow();
    ws.addRow({
      role: 'Role',
      username: 'Username',
      employeeId: 'Employee ID',
      contractType: 'Contract Type',
      firstNameEn: 'Firstname',
      lastNameEn: 'Lastname',
      firstNameTh: 'ชื่อ',
      lastNameTh: 'นามสกุล',
      position: 'Position',
      department: 'Department',
      gender: 'Gender',
      citizenId: 'Citizen ID',
      level: 'Level',
      birthday: 'Birthday',
      address: 'Address',
      lineId: 'Line Id',
      notebookOwner: 'Notebook',
      isBoi: 'Boi Status',
      isActived: 'Active Status',
      startJobDate: 'วันที่เข้าทำงาน',
      endProbationDate: 'วันที่ผ่าน Probation',
      toeic: 'Toiec Score',
      jlpt: 'JPT Score',
      carDrivingLicense: 'Park right',
    });

    const row2 = ws.getRow(2);
    row2.font = {
      color: { argb: 'FFFFFFFF' },
      name: 'Arial',
      size: 9,
      bold: true,
    };
    row2.alignment = { vertical: 'middle', horizontal: 'center' };
    for (let i = 2; i <= 25; i++) {
      row2.getCell(i).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF538DD5' },
      };
    }

    data.forEach((user) => {
      ws.addRow({
        role: user.role,
        username: user.username,
        employeeId: user.emp_code,
        contractType: user.contract_type,
        firstNameEn: user.firstname_en,
        lastNameEn: user.lastname_en,
        firstNameTh: user.firstname_th,
        lastNameTh: user.lastname_th,
        position: user.position,
        department: user.department,
        gender: user.gender,
        citizenId: user.citizend_id,
        level: user.level,
        birthday: moment(user.birthday).format('YYYY-MM-DD'),
        address: this.getAddressText(user),
        lineId: user.line_id,
        notebookOwner: user.notebook_owner,
        isBoi: user.is_boi ? 'boi' : 'non boi',
        isActived: user.is_actived ? 'Active' : 'Inactive',
        startJobDate:
          user.start_job_date !== null
            ? moment(user.start_job_date).format('YYYY-MM-DD')
            : '',
        endProbationDate:
          user.end_probation_date !== null
            ? moment(user.end_probation_date).format('YYYY-MM-DD')
            : '',
        toeic: user.toeic,
        jlpt: user.jlpt,
        carDrivingLicense: user.car_driving_license ? 'Yes' : 'No',
      });
    });

    for (let i = 3; i <= ws.rowCount; i++) {
      for (let j = 2; j <= 25; j++) {
        ws.getRow(i).getCell(j).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        if ([2, 4, 5, 12, 13, 14, 15, 18, 19, 20, 21, 22, 25].includes(j)) {
          ws.getRow(i).getCell(j).alignment = { horizontal: 'center' };
        }
      }
    }

    console.log(data);

    const filename = `Users_information.xlsx`;

    wb.xlsx
      .writeBuffer({
        base64: true,
      })
      .then(function (xls64) {
        var a = document.createElement('a');
        var data = new Blob([xls64], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        var url = URL.createObjectURL(data);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      })
      .catch(function (error) {
        console.log(error.message);
      });
  };

  handlefetchDepartmentList = () => {
    MasterdataService.fetchDepartmentList(
      undefined,
      ({ data }) => {
        this.setState({
          mDept: data,
        });
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          message.error('Cannot access data' + ' - ' + response.data.message);
        }
      },
    );
    console.log(this.state);
  };
  handlefetchUserAll = () => {
    this.setState({
      hasData: false,
    });
    const payload = {
      department: this.state.sDepartment,
      level: this.state.sLevel,
      search: this.state.sUser,
    };
    UserService.fetchUserAll(
      payload,
      ({ data }) => {
        this.setState({
          hasData: true,
          mData: data,
        });
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          message.error('Cannot access data' + ' - ' + response.data.message);
        }
      },
    );
  };

  handlefetchUserAllWithSearchValue = (sUser) => {
    this.setState({
      hasData: false,
    });
    const payload = {
      department: this.state.sDepartment,
      level: this.state.sLevel,
      search: sUser,
    };
    UserService.fetchUserAll(
      payload,
      ({ data }) => {
        this.setState({
          hasData: true,
          mData: data,
          sUser: sUser,
        });
      },
      (response) => {
        if (response && response.status === 400) {
          this.setState({
            incorrect: true,
          });
          message.error('Cannot access data' + ' - ' + response.data.message);
        }
      },
    );
  };
  handleEditUser = (payload, e) => {};
  handleEditProfile = (payload, e) => {};
  handleExport = (payload, e) => {
    message.info('export cv', 1.0);
  };

  handleRedirect = (path) => {
    const { history } = this.props;
    history.push(path);
  };

  handleExportUser = (e) => {
    const messageKey = 'export-excel-all-user';
    UserService.fetchAllUserInfo(
      async ({ data }) => {
        this.generateExcelAllUser(data);
      },
      (response) => {
        if (response && response.status === 400) {
          message.error({
            key: messageKey,
            content: 'Error: ' + response.data.message,
          });
        } else {
          message.error({
            key: messageKey,
            content: 'Error',
          });
        }
      },
    );
  };

  componentDidMount = () => {
    this.handlefetchDepartmentList();
    this.handlefetchUserAll();
    this.previousContext = this.context;
    this.setState({
      currentRole: this.context.userState.currentRole,
    });
  };

  componentDidUpdate = () => {
    if (
      this.previousContext.userState.currentRole !==
      this.context.userState.currentRole
    ) {
      this.setState({
        currentRole: this.context.userState.currentRole,
      });
    }

    this.previousContext = this.context;
  };

  render() {
    var dataSource;

    if (this.state.hasData === true) {
      // force rerender Table
      if (this.context.userState.currentRole === 'USER') {
        dataSource = this.state.mData
          .filter((item) => item.isActived)
          .map((item) => ({
            ...item,
            key: item.id + '_frerender',
          }));
      } else {
        dataSource = this.state.mData.map((item) => ({
          ...item,
          key: item.id + '_frerender',
        }));
      }
    }
    return (
      <div style={{ width: 'auto' }}>
        <Space
          direction="vertical"
          size="middle"
          style={{
            width: '100%',
            padding: '20px 3%',
            backgroundColor: '#ffffff',
          }}
        >
          <Form id="searchform">
            <Row gutter={[16, 16]}>
              <Col>
                <Text title>All Users</Text>
              </Col>
              <Col flex="auto" />
              <Col>
                <Form.Item name={['department']} noStyle>
                  <Select
                    //size='large'
                    style={{ width: 198 }}
                    placeholder="Department"
                    allowClear
                    onChange={(value) => {
                      this.setState({ sDepartment: value }, () => {
                        this.handlefetchUserAll();
                      });
                    }}
                  >
                    {this.state.mDept.map((dept) => (
                      <Option key={dept.name}>{dept.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name={['search']} noStyle>
                  <Search
                    placeholder="Search User"
                    onChange={(e) =>
                      this.handlefetchUserAllWithSearchValue(e.target.value)
                    }
                    style={{ width: 267 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {this.state.currentRole === 'ADMIN' && (
            <Row justify="end" gutter={[16, 16]}>
              <Col>
                <NormalButton
                  onClick={this.handleExportUser}
                  icon={<DownloadOutlined />}
                >
                  Export User
                </NormalButton>
              </Col>
              <Col>
                <NormalButton
                  white
                  onClick={() => this.handleRedirect('/user/adduser')}
                >
                  <Icon
                    component={addUserIcon}
                    style={{
                      fontSize: '16px',
                    }}
                  />
                  Add User
                </NormalButton>
              </Col>
            </Row>
          )}
        </Space>
        <ContentBox>
          <ItemList
            className="table-striped-rows"
            columns={this.columns}
            scroll={{ x: 1300 }}
            dataSource={dataSource}
            rowKey="id"
            style={{ width: '100%' }}
            size="small"
            indentSize={1}
            loading={!this.state.hasData && !this.state.incorrect}
            pagination={{
              position: ['bottomRight'],
              pageSizeOptions: [10, 20, 30, 40],
              showSizeChanger: true,
            }}
          />
        </ContentBox>
      </div>
    );
  }
}
export default AllUser;
