import moment from 'moment';

export const change0to24 = (hour, format) => {
  // format should be like HH:mm:ss, HH:mm, HH.mm,
  // should use only H,m,s
  if (moment(hour, format).get('hour') === 0) {
    return format
      .replaceAll('HH', '24')
      .replaceAll('m', '0')
      .replaceAll('s', '0');
  }
  return hour;
};
