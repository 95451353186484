import React, { Component } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import Topbar from '../components/topbar.js';
import styled from 'styled-components';
import Mask from '../../assets/icons/Mask.svg';
import { Menu, Button, Tabs, Row, Col, message } from 'antd';
import { UserService } from '../../services/api';
import 'antd/dist/antd.css';
import Personaldetail from '../components/personaldetail.js';
import Workexperience from '../components/workexperience.js';
import Education from '../components/education.js';
import Trainingcourses from '../components/trainingcourses.js';
import Address from '../components/address.js';
import Certificate from '../components/certificate.js';
import ComputerSkills from '../components/computerskills';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { subDomain } from '../../services/redirect';
import { redirectTo } from '../../services/redirect';
import {
  imageservice,
  profileservice as api,
  MasterdataService,
} from '../../services/api';
import Email from '../../assets/icons/mailgrey.svg';
import Phoneicon from '../../assets/icons/phone.svg';
import profileImage from '../../assets/icons/profileimage.svg';
import Calendar from '../../assets/icons/calendar.svg';
import Editicon from '../../assets/icons/edit.svg';
import profileInformationIcon from '../../assets/icons/personalInformation.svg';
import addressIcon from '../../assets/icons/address.svg';
import workExperiencesIcon from '../../assets/icons/workExperiences.svg';
import educationIcon from '../../assets/icons/education.svg';
import trainingCoursesIcon from '../../assets/icons/trainingCourses.svg';
import certificatesIcon from '../../assets/icons/certificates.svg';
import skillsIcon from '../../assets/icons/skills.svg';
import smileIcon from '../../assets/icons/smile.svg';
import { Spin } from 'antd';
import UploadModal from '../components/uploadModal.js';
import Uploader from '../components/uploader.js';
import ProfileTabHeader from '../components/profileTabHeader.js';

const { TabPane } = Tabs;

const Box = styled.div`
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  // padding-top: 4.9375rem;
  padding-bottom: 5%;
  background: #f6f6f6;
  min-height: calc(100vh - 64px);
`;
const Circle = styled.div`
  margin-top: ${(props) => (props.small ? '0' : '-2.563rem;')};
  background: ${(props) =>
    props.small ? (props.src ? 'url(' + props.src + ')' : 'grey') : 'white'};
  background-repeat: no-repeat;
  background-size: 9.313rem;
  border-radius: 50%;
  height: ${(props) => (props.small ? '9.313rem' : '10.188rem')};
  width: ${(props) => (props.small ? '9.313rem' : '10.188rem')};
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
const CircleImage = styled(Circle)`
  &:hover .edit-btn {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
  }
  .edit-btn {
    background-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0);
    visibility: hidden;
  }
`;
const Rectangle = styled.div`
  z-index: -99;
  background: white;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 12.5rem;
  border-radius: 10px;
`;
const Detail = styled.div`
  box-sizing: border-box;
  width: ${(props) => (props.one ? '20%' : props.two ? '65%' : '15%')};
  min-width: ${(props) => (props.one ? '200px' : props.tree ? '200px' : '0')};
  // width:${(props) => (props.one ? '20rem' : props.two ? '80%' : '20%')};
  // background:${(props) =>
    props.one ? 'blue' : props.two ? 'yellow' : 'red'};
  // background: white;
  display: flex;
  flex-direction: ${(props) => (props.two ? 'column' : 'row')};
  justify-content: ${(props) => (props.one ? 'center' : 'none')};
`;
const Personal = styled.div`
  filter: drop-shadow(0px 0px 21px #999999);
  margin-top: 4.9375rem;
`;
const ID = styled.div`
  position: absolute;
  bottom: 12%;
  width: 127px;
  height: 46px;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #004368;
  font-size: 1.25em;
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 500;
  color: #004368;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Wave = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  // background: linear-gradient(90deg, #013640 -69.09%, #2a8b9d 111.79%);
  background-color: #004368;
  overflow: hidden;
  position: relative;
  align-items: center;
  border-radius: 0 10px 10px 0;
`;

const Position = styled.div`
  padding-top: 1rem;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const Text = styled.div`
  color: ${(props) => (props.big ? '#004368' : ' #B7B7B7;')};
  font-size: ${(props) => (props.big ? '1.125em' : '1em')};
  font-weight: ${(props) => (props.big ? '500' : '300')};
  font-family: 'Kanit';
  word-wrap: break-word;
  padding-right: 1rem;
`;
const Flexbox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: ${(props) => (props.big ? '0' : '0.51em')};
  font-size: ${(props) => (props.big ? '6.25em' : '1.125em')};
  font-weight: 600;
  font-style: normal;
  color: white;
  font-family: 'Kanit';
  // text-shadow: 0px 0px 9px #004368;
`;
const Curve = styled.div`
  height: 150%;
  width: 10rem;
  background: white;
  border-radius: 50%;
  position: absolute;
  margin-left: -8.5rem;
`;
const Level = styled.h4`
  -webkit-text-stroke: 0.3rem white;
  color: transparent;
`;
const Line = styled.div`
  margin-top: 0.5rem;
  height: 0.055rem;
  background: #b2d4da;
`;
const Name = styled.div`
  margin-top: 1rem;
  font-size: 2em;
  color: black;
  font-family: 'Kanit';
`;
const IMG = styled.img`
  // @media(max-width: 1455px){
  //     display: none;
  // }
`;

const Menulist = styled.div`
  width: 25%;
  box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.11);
  height: auto;
  padding-top: 2rem;
`;
const MenuDetail = styled.div`
  min-height: 48.875rem;
  background: white;
  box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.11);
  width: 100%;
  margin-top: -16px;
  margin-left: 1.5625rem;
  padding: 3rem 5%;
`;
const Menubox = styled.div`
  display: flex;
`;

const Profiledetail = styled.div`
  &.card-container > .ant-tabs-card .ant-tabs-content {
    margin-top: -16px;
  }
  &.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding-top: 80px;
    background: #fff;
  }
  &.card-container > .ant-tabs-card .ant-tabs-tab,
  [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: #ffffff;
    border-radius: 10px 10px 0 0;
    border: 0;
  }
  &.card-container > .ant-tabs-card .ant-tabs-tab-active,
  [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #004368;
  }
  .ant-tabs-tab {
    display: block; // centers text inside tabs
    flex: 1;
    text-align: center;
  }
  .ant-tabs-nav-wrap {
    display: unset !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
  }
  .ant-tabs-nav-more {
    display: none;
  }
  @media (max-width: 950px) {
    .ant-tabs-nav-more {
      display: flex;
    }
  }
`;
const Tabcontent = styled.div.attrs((props) => ({
  className: 'Tabcontent',
}))`
  display: none;
`;

const Listmenu = styled.button.attrs((props) => ({
  className: 'Listmenu',
}))`
  width: 100%;
  transition: 0.3s;
  font-size: 1.25em;
  font-family: "Inter";
  padding: 22px 16px;
  border: none;
  background: none;
  padding: 1rem 1rem;
  text-align: left;
  outline: none;
  color: #b6b6b6;

  &:hover {
    background: #b2d4da;
  }
  &:hover +img{
    display:block;
  }
  &.active {
    color: black;
    background: #f3f3f3;
    font-weight: 500;
    // border-radius : 0.2rem;
    // border-collapse: separate;
    box-shadow: -0.5rem 0px 0px 0px #2d95a8;
    // &:before {
  //  border-left: 0.2rem outset #2d95a8;
    //   content: "";
    //   padding-left: 1rem;
    //   background: #2d95a8;
    //   margin-right: 1rem;
    //   margin-left: -2rem;
    }
  }
`;
const StyledSpin = styled(Spin)`
  position: fixed;
  text-align: center;
  bottom: 50%;
  z-index: 10;
`;
const SpinBox = styled(Box)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 5;
`;
const EditButton = styled(Button)`
  position: relative;
  bottom: -40%;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 40%;
  text-align: center;
  vertical-align: text-top;
  align-items: center;
  color: rgba(0, 0, 0, 0);
  focus: disable;
  outline: none;
  border: none;
  padding-top: 0;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;

  &:focus {
    outline: none;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    color: transparent;
  }
  &:hover:focus {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
  }
`;

const activeTabDict = {
  'Personal Information': {
    icon: profileInformationIcon,
    formId: 'personalInformation',
    isInEditMode: 'isPersonalInformationInEditMode',
  },
  Address: {
    icon: addressIcon,
    formId: 'address',
    isInEditMode: 'isAddressInEditMode',
  },
  'Work Experiences': {
    icon: workExperiencesIcon,
    formId: 'workExperiences',
    isInEditMode: 'isWorkExperiencesInEditMode',
  },
  Education: {
    icon: educationIcon,
    formId: 'education',
    isInEditMode: 'isEducationInEditMode',
  },
  'Training Courses': {
    icon: trainingCoursesIcon,
    formId: 'trainingCourses',
    isInEditMode: 'isTrainingCoursesInEditMode',
  },
  Certificates: {
    icon: certificatesIcon,
    formId: 'certificates',
    isInEditMode: 'isCertificatesInEditMode',
  },
  Skills: {
    icon: skillsIcon,
    formId: 'skills',
    isInEditMode: 'isSkillsInEditMode',
  },
};

//props.currentPage : 'add', 'user', profile'
class Profile extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getGeneralInfo = this.getGeneralInfo.bind(this);
  }
  id = new URLSearchParams(this.props.location.search).get('id');
  state = {
    activeTab: 'Personal Information',
    isPersonalInformationInEditMode: this.props.currentPage === 'add',
    isAddressInEditMode: false,
    isWorkExperiencesInEditMode: false,
    isEducationInEditMode: false,
    isTrainingCoursesInEditMode: false,
    isCertificatesInEditMode: false,
    isSkillsInEditMode: false,
    hasData: false,
    personalDetail: {},
    address: {
      registration_address_1: '',
      registration_address_2: '',
      registration_district: '',
      registration_province: '',
      registration_country: '',
      registration_zip_code: '',
      current_address_1: '',
      current_address_2: '',
      current_district: '',
      current_province: '',
      current_country: '',
      current_zip_code: '',
      is_same_address: false,
    },
    workExperience: [],
    education: [],
    trainingCourses: [],
    certificate: [],
    modalVisible: false,
  };

  changeTab = (activeKey) => {
    console.log(activeKey);
    this.setState({
      activeTab: activeKey,
    });
  };

  changeEditMode = () => {
    if (this.props.currentPage === 'add') {
      return;
    } else {
      this.setState({
        [activeTabDict[this.state.activeTab].isInEditMode]:
          !this.state[activeTabDict[this.state.activeTab].isInEditMode],
      });
    }
  };

  handleSubmit = (val) => {
    const personalDetail = val;
    personalDetail.birthday = personalDetail.birthday.format('YYYY-MM-DD');
    personalDetail.startJobDate =
      personalDetail.startJobDate.format('YYYY-MM-DD');
    personalDetail.endProbationDate =
      personalDetail.endProbationDate.format('YYYY-MM-DD');
    if (personalDetail.toeic === '') {
      personalDetail.toeic = null;
    }
    this.setState({ personalDetail, hasData: false });
    var jwt = require('jsonwebtoken');
    var token = sessionStorage.getItem('access-token');
    var decode1 = jwt.decode(token);
    const userId = this.id ? this.id : decode1.userId;
    personalDetail.userId = userId;
    const payload = personalDetail;
    api.personalDetail
      .editpersonalDetail(
        payload,
        ({ data }) => {
          this.setState({ hasData: true });
          message.success({ key: 'edit-user', content: 'Edit Success' });
          // this.state.handleCallBack();
        },
        (response) => {
          if (response && response.status === 400) {
            message.error(`${response.data.message}`);
          } else {
            message.error(`ERROR`);
          }
        },
      )
      .then(() => {
        this.getGeneralInfo();
      });
  };

  handleCancelAddUser = () => {
    // redirectTo('/user');
    const { history } = this.props;
    history.push('/user');
  };

  handleAddUser = (payload) => {
    //check total user
    UserService.getActiveUserCount(
      undefined,
      ({ data }) => {
        //console.log('getactiveduser', data)
        const resp = data;

        if (resp.maxAccount <= resp.userCount) {
          //-1 < 1
          message.error('max users reached');
          return;
        }
      },
      (err) => {
        message.error(`${err.data.message}`);
      },
    );

    payload.username = payload.email;
    const messageKey = 'adding-user';
    //message.loading({ key: messageKey, content: 'Adding', duration: 0 });
    UserService.addUser(
      payload,
      ({ data }) => {
        //message.success({ key: messageKey, content: data.message });
        message.success('Add user successfully');
        setTimeout(() => {
          redirectTo('/user');
        }, 1000);
      },
      (response) => {
        if (response && response.status === 400) {
          message.error({
            key: messageKey,
            content: 'Error: ' + response.data.message,
          });
        } else {
          message.error({
            key: messageKey,
            content: 'Error',
          });
        }
      },
    );
  };

  componentWillMount() {}

  getGeneralInfo = async () => {
    this.setState({ hasData: false });
    var jwt = require('jsonwebtoken');
    var token = sessionStorage.getItem('access-token');
    var decode1 = jwt.decode(token);
    const userId = this.id ? this.id : decode1.userId;
    await api.personalDetail.getpersonalDetail(
      userId,
      async ({ data }) => {
        const image = data.picturePath;
        if (data.osLicensePicturePath) {
          await imageservice.image.getImage(
            data.osLicensePicturePath,
            (res) => {
              if (res.status) return false;
              const blobUrl = URL.createObjectURL(res.data);
              data.osImage = blobUrl;
            },
          );
        }
        if (data.microsoftOfficeLicensePicturePath) {
          await imageservice.image.getImage(
            data.microsoftOfficeLicensePicturePath,
            (res) => {
              const blobUrl = URL.createObjectURL(res.data);
              data.msImage = blobUrl;
            },
          );
        }
        if (data.signaturePath) {
          await imageservice.image.getImage(data.signaturePath, (res) => {
            const blobUrl = URL.createObjectURL(res.data);
            data.signatureImage = blobUrl;
          });
        }
        const dataValue = JSON.stringify(data);
        // dataValue = (dataValue.replace('null', '-'));
        const setDataValue = JSON.parse(dataValue);
        //console.log("api user/get-user/id resp: ", setDataValue)
        this.setState({
          personalDetail: setDataValue,
          hasData: true,
        });
        if (!!image) {
          imageservice.image.getImage(image, (res) => {
            if (res.status) return false;
            const blobUrl = URL.createObjectURL(res.data);
            this.setState({
              profileImage: blobUrl,
            });
          });
        }
      },
      (response) => {
        if (response === undefined || response.status === 500) {
          this.setState({
            personalDetail: [],
            hasData: true,
          });
          const payload = { userId: userId, empCode: 123456 };
          api.personalDetail.createpersonalDetail(payload, ({ data }) => {});
        }
      },
    );

    api.workExperience.getWorkExperience(
      userId,
      ({ data }) => {
        const workEx = data;
        this.setState({
          workExperience: workEx,
        });
      },
      (response) => {
        if (response === undefined || response.status === 500) {
          this.setState({
            workExperience: [],
          });
        }
      },
    );

    api.education.getEducation(
      userId,
      ({ data }) => {
        const EduList = data;
        this.setState({
          education: EduList,
        });
      },
      (response) => {
        if (response === undefined || response.status === 500) {
          this.setState({
            education: [],
          });
        }
      },
    );

    api.trainingCourses.getTrainingCourses(
      userId,
      ({ data }) => {
        const trainCou = data;
        this.setState({
          trainingCourses: trainCou,
        });
      },
      (response) => {
        if (response === undefined || response.status === 500) {
          this.setState({
            trainingCourses: [],
          });
        }
      },
    );

    api.address.getAddress(
      userId,
      ({ data }) => {
        const dataAddress = data;
        this.setState({
          address: dataAddress,
        });
      },
      (response) => {
        if (response === undefined || response.status === 500) {
          this.setState({
            address: [],
          });
        }
      },
    );

    api.certificate.getCertificate(
      userId,
      ({ data }) => {
        const certList = data;
        this.setState({
          certificate: certList,
        });
      },
      (response) => {
        if (response === undefined || response.status === 500) {
          this.setState({
            certificate: [],
          });
        }
      },
    );

    api.computerSkills.getComputerSkills(
      userId,
      ({ data }) => {
        const skill = data;
        this.setState({
          computerSkills: skill,
        });
      },
      (response) => {
        if (response === undefined || response.status === 500) {
          this.setState({
            trainingCourses: [],
          });
        }
      },
    );
  };

  handleSubmitAddress = (val) => {
    var jwt = require('jsonwebtoken');
    var token = sessionStorage.getItem('access-token');
    var decode1 = jwt.decode(token);
    const empindex = this.id ? this.id : decode1.userId;
    const address = val;
    address.userId = empindex;
    const payload = address;
    api.address
      .editAddress(payload, ({ data }) => {})
      .then(() => {
        unstable_batchedUpdates(() => {
          api.address.getAddress(
            empindex,
            ({ data }) => {
              const dataAddress = data;
              this.setState({
                address: dataAddress,
              });
            },
            (response) => {
              if (response === undefined || response.status === 500) {
                this.setState({
                  address: [],
                });
              }
            },
          );
        });
      });
  };

  handleSubmitWorkEx = (val, deletedList) => {
    const workExperience = val.workList ? val.workList : [];
    for (var i = 0; i < workExperience.length; i++) {
      workExperience[i].startDate =
        workExperience[i].startDate.format('YYYY-MM-DD');
      workExperience[i].endDate =
        workExperience[i].endDate.format('YYYY-MM-DD');
      workExperience[i].action = 'update';
    }
    for (var i = 0; i < deletedList.length; i++) {
      if (deletedList[i]) {
        workExperience.push({
          workExperienceId: deletedList[i],
          action: 'delete',
        });
      }
    }
    var jwt = require('jsonwebtoken');
    var token = sessionStorage.getItem('access-token');
    var decode1 = jwt.decode(token);
    const empindex = this.id ? this.id : decode1.userId;
    const payload = { userId: empindex, experienceList: workExperience };
    api.workExperience
      .editWorkExperience(payload, ({ data }) => {})
      .then(() => {
        unstable_batchedUpdates(() => {
          api.workExperience.getWorkExperience(
            empindex,
            ({ data }) => {
              const workEx = data;
              this.setState({
                workExperience: workEx,
              });
            },
            (response) => {
              if (response === undefined || response.status === 500) {
                this.setState({
                  workExperience: [],
                });
              }
            },
          );
        });
      });
  };

  handleSubmitTrain = (val, deletedList) => {
    const trainingCourses = val.trainList ? val.trainList : [];
    for (var i = 0; i < trainingCourses.length; i++) {
      trainingCourses[i].startDate =
        trainingCourses[i].startDate.format('YYYY-MM-DD');
      trainingCourses[i].endDate =
        trainingCourses[i].endDate.format('YYYY-MM-DD');
      trainingCourses[i].action = 'update';
    }
    for (var i = 0; i < deletedList.length; i++) {
      if (deletedList[i]) {
        trainingCourses.push({
          trainingCourseId: deletedList[i],
          action: 'delete',
        });
      }
    }
    var jwt = require('jsonwebtoken');
    var token = sessionStorage.getItem('access-token');
    var decode1 = jwt.decode(token);
    const empindex = this.id ? this.id : decode1.userId;
    const payload = { userId: empindex, trainingCourseList: trainingCourses };
    api.trainingCourses
      .editTrainingCourses(payload, ({ data }) => {})
      .then(() => {
        unstable_batchedUpdates(() => {
          api.trainingCourses.getTrainingCourses(
            empindex,
            ({ data }) => {
              const trainCou = data;
              this.setState({
                trainingCourses: trainCou,
              });
            },
            (response) => {
              if (response === undefined || response.status === 500) {
                this.setState({
                  trainingCourses: [],
                });
              }
            },
          );
        });
      });
  };

  handleSubmitEdu = (val, deletedList) => {
    const education = val.eduList ? val.eduList : [];
    for (var i = 0; i < education.length; i++) {
      education[i].startDate = education[i].startDate.format('YYYY-MM-DD');
      if (education[i].isStudying) {
        // education[i].yearGraduated = 0;
        education[i].graduateDate = null;
      } else {
        education[i].graduateDate =
          education[i].graduateDate.format('YYYY-MM-DD');
      }
      education[i].action = 'update';
    }
    for (var i = 0; i < deletedList.length; i++) {
      if (deletedList[i]) {
        education.push({
          educationId: deletedList[i],
          action: 'delete',
        });
      }
    }
    var jwt = require('jsonwebtoken');
    var token = sessionStorage.getItem('access-token');
    var decode1 = jwt.decode(token);
    const empindex = this.id ? this.id : decode1.userId;
    const payload = { userId: empindex, educationList: education };
    api.education
      .editEducation(payload, ({ data }) => {})
      .then(() => {
        unstable_batchedUpdates(() => {
          api.education.getEducation(
            empindex,
            ({ data }) => {
              const eduList = data;
              this.setState({
                education: eduList,
              });
            },
            (response) => {
              if (response === undefined || response.status === 500) {
                this.setState({
                  education: [],
                });
              }
            },
          );
        });
      });
  };

  handleSubmitCert = async (val, fd, deletedList) => {
    const certificate = val.certificateList ? val.certificateList : [];
    for (var i = 0; i < certificate.length; i++) {
      certificate[i].certificateDate =
        certificate[i].certificateDate.format('YYYY-MM-DD');
      certificate[i].action = 'update';
      certificate[i].certificatePicturePath = fd[i].certificatePicturePath;
    }
    for (var i = 0; i < deletedList.length; i++) {
      if (deletedList[i]) {
        certificate.push({
          certificateId: deletedList[i],
          action: 'delete',
        });
      }
    }
    var jwt = require('jsonwebtoken');
    var token = sessionStorage.getItem('access-token');
    var decode1 = jwt.decode(token);
    const empindex = this.id ? this.id : decode1.userId;
    const payload = { userId: empindex, certificateList: certificate };

    await api.certificate
      .editCertificate(payload, ({ data }) => {})
      .then(() => {
        unstable_batchedUpdates(() => {
          api.certificate.getCertificate(
            empindex,
            ({ data }) => {
              const certList = data;
              this.setState({
                certificate: certList,
              });
            },
            (response) => {
              if (response === undefined || response.status === 500) {
                this.setState({
                  certificate: [],
                });
              }
            },
          );
        });
      });
  };

  handleSubmitSkills = (val, deletedList) => {
    const computerSkills = val.computerSkillsList ? val.computerSkillsList : [];
    for (var i = 0; i < computerSkills.length; i++) {
      computerSkills[i].action = 'update';
    }
    for (var i = 0; i < deletedList.length; i++) {
      if (deletedList[i]) {
        computerSkills.push({
          computerSkillId: deletedList[i],
          action: 'delete',
        });
      }
    }
    var jwt = require('jsonwebtoken');
    var token = sessionStorage.getItem('access-token');
    var decode1 = jwt.decode(token);
    const empindex = this.id ? this.id : decode1.userId;
    const payload = { userId: empindex, computerSkillsList: computerSkills };
    api.computerSkills
      .editComputerSkills(payload, ({ data }) => {})
      .then(() => {
        unstable_batchedUpdates(() => {
          api.computerSkills.getComputerSkills(
            empindex,
            ({ data }) => {
              const skill = data;
              this.setState({
                computerSkills: skill,
              });
            },
            (response) => {
              if (response === undefined || response.status === 500) {
                this.setState({
                  computerSkills: [],
                });
              }
            },
          );
        });
      });
  };

  handleUploadProfileImage = (image) => {
    if (image && this.state.personalDetail) {
      var jwt = require('jsonwebtoken');
      var token = sessionStorage.getItem('access-token');
      var decode1 = jwt.decode(token);
      const userId = this.id ? this.id : decode1.userId;
      const payload = { userId: userId, picturePath: image };
      api.personalDetail.editpersonalDetail(payload, ({ data }) => {
        imageservice.image.getImage(data.picturePath, (res) => {
          if (res.status) return false;
          const blobUrl = URL.createObjectURL(res.data);
          this.setState({
            profileImage: blobUrl,
          });
        });
      });
    }
  };

  componentDidMount() {
    if (this.props.currentPage === 'add') {
      this.setState({
        hasData: true,
      });
    } else {
      this.getGeneralInfo();
    }
    if (this.props.callBack != null) {
      this.setState({ handleCallBack: this.props.callBack });
    }
  }

  onClickModal = () => {
    let prev = this.state.modalVisible;
    this.setState({ modalVisible: !prev });
  };

  handleModalOk = (image) => {
    this.handleUploadProfileImage(image);
    this.setState({
      modalVisible: false,
    });
  };

  handleModalCancel = () => {
    this.setState({
      modalVisible: false,
    });
  };

  render() {
    return (
      <div>
        {this.state.hasData == false ? (
          <SpinBox>
            <StyledSpin size="large" />
          </SpinBox>
        ) : null}
        <Box>
          {this.props.currentPage !== 'add' && (
            <Personal>
              <Rectangle>
                <Detail one>
                  <Circle>
                    <CircleImage
                      small
                      src={
                        this.state.profileImage
                          ? this.state.profileImage
                          : profileImage
                      }
                    >
                      {/* <img
                        src={Phoneicon}
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "10o0%"
                        }}
                      /> */}
                      <UploadModal
                        button={
                          <EditButton
                            className="edit-btn"
                            onClick={this.onClickModal}
                          >
                            {/* <object type="image/svg+xml" data={Editicon} /> */}
                            Edit
                          </EditButton>
                        }
                        handleOk={this.handleModalOk}
                        handleCancel={this.handleModalCancel}
                        visible={this.state.modalVisible}
                      />
                    </CircleImage>
                  </Circle>
                  <ID>{this.state.personalDetail.empCode}</ID>
                </Detail>
                <Detail two>
                  <Name style={{ fontWeight: '500' }}>
                    {this.state.personalDetail.firstnameEn}&nbsp;
                    {this.state.personalDetail.lastnameEn}&nbsp;
                    {this.state.personalDetail?.nicknameEn
                      ? '(' + this.state.personalDetail.nicknameEn + ')'
                      : undefined}
                  </Name>
                  <div style={{ marginTop: '1em' }}>
                    <Text>{this.state.personalDetail.position}</Text>
                    <Text big>{this.state.personalDetail.department}</Text>
                  </div>
                  {/* <Line></Line> */}
                  <div style={{ display: 'flex', marginTop: '1em' }}>
                    <div style={{ width: '33%' }}>
                      <Text>
                        Mobile&nbsp;&nbsp;
                        <img src={Phoneicon} />
                      </Text>
                      <Text style={{ color: 'black' }}>
                        {this.state.personalDetail.mobileNumber}
                      </Text>
                    </div>
                    <div style={{ width: '33%' }}>
                      <Text>
                        E-mail&nbsp;&nbsp;
                        <img src={Email} />
                      </Text>
                      <Text style={{ color: 'black' }}>
                        {this.state.personalDetail.email}
                      </Text>
                    </div>
                    <div style={{ width: '33%' }}>
                      <Text>
                        Start Job Date&nbsp;&nbsp;
                        <img src={Calendar} />
                      </Text>
                      <Text style={{ color: 'black' }}>
                        {this.state.personalDetail.startJobDate == null
                          ? null
                          : moment(
                              this.state.personalDetail.startJobDate,
                              'YYYY-MM-DD',
                            ).format('DD MMM YYYY')}
                      </Text>
                    </div>
                  </div>
                </Detail>
                <Detail tree>
                  <Wave>
                    <Curve></Curve>
                    <Position>
                      <Flexbox style={{ justifycontent: 'center' }}>
                        <Flexbox>LEVEL</Flexbox>
                        <Flexbox big>
                          <Level style={{ margin: '0' }}>
                            {this.state.personalDetail.level}
                          </Level>
                        </Flexbox>
                      </Flexbox>
                    </Position>
                  </Wave>
                </Detail>
              </Rectangle>
            </Personal>
          )}
          <ProfileTabHeader
            title={this.state.activeTab}
            icon={activeTabDict[this.state.activeTab].icon}
            isInEditMode={
              this.state[activeTabDict[this.state.activeTab].isInEditMode]
            }
            changeEditMode={this.changeEditMode}
            formId={activeTabDict[this.state.activeTab].formId}
            addUser={this.props.currentPage === 'add'}
            cancelAddUser={this.handleCancelAddUser}
          />
          <Profiledetail className="card-container">
            <Tabs
              type="card"
              tabBarStyle={{ fontFamily: 'Kanit' }}
              activeKey={this.state.activeTab}
              onChange={this.changeTab}
            >
              <TabPane tab="Personal Information" key="Personal Information">
                <Personaldetail
                  currentPage={this.props.currentPage}
                  data={this.state.personalDetail}
                  isInEditMode={this.state.isPersonalInformationInEditMode}
                  changeEditMode={this.changeEditMode}
                  onSubmit={
                    this.props.currentPage === 'add'
                      ? this.handleAddUser
                      : this.handleSubmit
                  }
                />
              </TabPane>
              <TabPane
                tab="Address"
                key="Address"
                disabled={this.props.currentPage === 'add'}
              >
                <Address
                  data={this.state.address}
                  onSubmit={this.handleSubmitAddress}
                  isInEditMode={this.state.isAddressInEditMode}
                  changeEditMode={this.changeEditMode}
                />
              </TabPane>
              <TabPane
                tab="Work Experiences"
                key="Work Experiences"
                disabled={this.props.currentPage === 'add'}
              >
                <Workexperience
                  data={this.state}
                  onSubmit={this.handleSubmitWorkEx}
                  isInEditMode={this.state.isWorkExperiencesInEditMode}
                  changeEditMode={this.changeEditMode}
                />
              </TabPane>
              <TabPane
                tab="Education"
                key="Education"
                disabled={this.props.currentPage === 'add'}
              >
                <Education
                  data={this.state}
                  onSubmit={this.handleSubmitEdu}
                  isInEditMode={this.state.isEducationInEditMode}
                  changeEditMode={this.changeEditMode}
                />
              </TabPane>
              <TabPane
                tab="Training Courses"
                key="Training Courses"
                disabled={this.props.currentPage === 'add'}
              >
                <Trainingcourses
                  data={this.state}
                  onSubmit={this.handleSubmitTrain}
                  isInEditMode={this.state.isTrainingCoursesInEditMode}
                  changeEditMode={this.changeEditMode}
                />
              </TabPane>
              <TabPane
                tab="Certificates"
                key="Certificates"
                disabled={this.props.currentPage === 'add'}
              >
                <Certificate
                  data={this.state}
                  onSubmit={this.handleSubmitCert}
                  isInEditMode={this.state.isCertificatesInEditMode}
                  changeEditMode={this.changeEditMode}
                />
              </TabPane>
              <TabPane
                tab="Skills"
                key="Skills"
                disabled={this.props.currentPage === 'add'}
              >
                <ComputerSkills
                  data={this.state}
                  onSubmit={this.handleSubmitSkills}
                  isInEditMode={this.state.isSkillsInEditMode}
                  changeEditMode={this.changeEditMode}
                />
              </TabPane>
            </Tabs>
          </Profiledetail>
        </Box>
      </div>
    );
  }
}
export default Profile;
