import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Switch,
  message,
} from 'antd';
import React, { Component, createRef } from 'react';
import styled from 'styled-components';

const Break = styled(Divider)`
  margin: 12px 0;
`;

const FormLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const HeadDetail = styled.div`
  font-family: 'Kanit';
  font-size: 18px;
  font-weight: 500;
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  border-radius: 5px;
  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  // justify-content: center;
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Kanit;
  font-style: Regular;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid white !important;
    background-color: #f5f5f5 !important;
  }
`;

const RejectedButton = styled(Button)`
  min-width: 106px;
  border-radius: 5px;
  display: flex;
  background-color: white;
  border: 1px solid #f2f2f2;
  justify-content: center;
  color: #232323;
  font-family: Kanit;
  font-style: Regular;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 5px 15px;
  align-items: center;
  &:disabled {
    border: 1px solid white !important;
  }
`;

const StatusSwitch = styled(Switch)`
  height: 32px;
  width: 85px;
  &.ant-switch {
    background: #ffab4f;
  }
  &.ant-switch .ant-switch-inner {
    margin: 0 -2px 0 25px;
  }

  &.ant-switch-checked {
    background: #004368;
  }

  & .ant-switch-handle {
    width: 27px;
    height: 27px;
    overflow: hidden;
    border-radius: 999999px;
  }

  &.ant-switch-checked .ant-switch-inner {
    margin: 0 25px 0 -3px;
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 30px);
  }
`;

class CreateOrEditSettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  formRef = createRef();

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.selectedRecord) {
      this.formRef.current.setFieldsValue({
        ...this.props.selectedRecord,
      });
    }
    if (this.props.selectedRecord === null) {
    }
  };

  handleCancelModal = () => {
    this.props.handleCancelModal();
    this.formRef.current.resetFields();
  };

  handleAddRecord = (values) => {
    const payload = values;
    this.props.settingType.addingApi(
      payload,
      ({ data }) => {
        message.success('Adding success');
        this.props.fetchDataSource();
      },
      (response) => {
        if (response) message.error(`Error ${response.data.message}`);
      },
    );
  };

  handleEditRecord = (values) => {
    const key = this.props.settingType.primaryKey;
    const payloadKey = this.props.settingType.payloadPrimaryKey;
    const payload = {
      ...values,
    };
    payload[payloadKey] = this.props.selectedRecord[key];
    this.props.settingType.updateApi(
      payload,
      ({ data }) => {
        message.success('Update success');
        this.props.fetchDataSource();
      },
      (response) => {
        if (response) message.error(`Error ${response.data.message}`);
      },
    );
  };

  handleFinishForm = (values) => {
    switch (this.props.modalMode) {
      case 'create':
        this.handleAddRecord(values);
        break;
      case 'edit':
        this.handleEditRecord(values);
        break;
      default:
        break;
    }
    this.handleCancelModal();
  };

  render() {
    return (
      <Modal
        width="450px"
        bodyStyle={{
          maxHeight: '800px',
        }}
        open={this.props.createOrEditModalVisible}
        centered
        footer={null}
        onCancel={this.handleCancelModal}
      >
        <Form ref={this.formRef} onFinish={this.handleFinishForm}>
          <Row>
            <HeadDetail>
              {this.props.modalMode === 'create' ? 'Add Record' : 'Edit Record'}
            </HeadDetail>
          </Row>
          <Break />
          {this.props.settingType.payloadFields.map((field) => (
            <>
              {field.label && <FormLabel>{field.label}</FormLabel>}
              <Form.Item
                name={field.key}
                rules={[
                  {
                    required: true,
                    message: `Please input ${field.label}`,
                  },
                ]}
                hidden={!field.label}
              >
                <Input
                  disabled={this.props.modalMode === 'edit' && field.uneditable}
                />
              </Form.Item>
            </>
          ))}
          {this.props.modalMode === 'edit' && (
            <Form.Item
              name="isActived"
              valuePropName="checked"
              initialValue={this.state.isActived}
            >
              <StatusSwitch
                checked={this.state.isActived}
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={this.activeStatusSwitch}
              ></StatusSwitch>
            </Form.Item>
          )}
        </Form>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1.5rem',
          }}
        >
          <RejectedButton
            grey
            style={{ width: 106 }}
            onClick={this.handleCancelModal}
          >
            Cancel
          </RejectedButton>
          <NormalButton
            style={{ width: 106 }}
            onClick={() => this.formRef.current.submit()}
          >
            Submit
          </NormalButton>
        </div>
      </Modal>
    );
  }
}

export default CreateOrEditSettingsModal;
