import React, { Component } from 'react';
import styled, { ThemeConsumer } from 'styled-components';
import {
  Form,
  Button,
  message,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Switch,
  Badge,
  Table,
  Modal,
  AutoComplete,
  Typography,
  Tooltip,
  Popconfirm,
  Spin,
} from 'antd';
import {
  UserAddOutlined,
  EditOutlined,
  LeftOutlined,
  EyeOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import binIcon from '../../assets/icons/bin.svg';
import saveIcon from '../../assets/icons/save.svg';
import {
  AuthorizeRoleService,
  ProjectService,
  UserService,
} from '../../services/api';
import { redirectTo } from '../../services/redirect';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import moment from 'moment';
import { unstable_batchedUpdates } from 'react-dom';
import { NavLink } from 'react-router-dom';
import actiona from '../../assets/icons/actiona.svg';
import { AppContext } from '../../components/context/AppContext';
import CreateOtModal from '../../components/projectform/createOtModal';

// #region Component Styles
const TitleBox = styled.div`
  box-sizing: border-box;
  padding-left: 7%;
  padding-right: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  height: 70px;
  justify-content: space-between;
`;
const Selectdata = styled(Select)`
  width: 10rem;
  height: 2rem;
`;
const Headdetail = styled.div`
  font-family: 'Kanit';
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 2rem;
`;
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#004368' : props.subtitle ? '#20ABC5' : '#565656'};
  font-size: ${(props) =>
    props.title ? '26px' : props.subtitle ? '1.625em' : '1em'};
  font-weight: ${(props) =>
    props.title
      ? '500'
      : props.subtitle
      ? '500'
      : props.name
      ? '500'
      : 'normal'};
  font-family: 'Kanit';
`;
const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  border-radius: 5px;
  background-color: ${(props) =>
    props.white ? '#FFFFFF !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.white ? '#D9D9D9 !important' : '#004368 !important'};
  color: ${(props) => (props.white ? '#232323' : 'white')};
  font-family: Kanit;
  font-style: Regular;
  font-size: 14px;

  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }

  &:focus {
    color: ${(props) => (props.whitefocus ? 'white !important' : '#232323')};
  }
`;

const RejectedButton = styled(Button)`
  width: ${(props) => (props.width ? props.width : '106px')};
  border-radius: 5px;
  display: flex;
  background-color: white;
  border: 1px solid #d9d9d9;
  justify-content: center;
  color: #232323 !important;
  font-family: Kanit;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding: 5px 15px;
  align-items: center;

  &:disabled {
    border: 1px solid white;
  }
`;
const ContentBox = styled.div`
  background: #f6f6f6;
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: auto;
  min-height: calc(100vh - 134px);
  justify-content: flex-start;
  padding-top: 0.5em;
`;
const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;
const TextCard = styled.div`
  height: auto;
  width: 100%;
  background-color: white;
  margin-top: 2em;
  border-radius: 5px;
`;
const Verticalbox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2% 4%;
`;
const Horizontalbox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || 'start'};
  @media (max-width: 714px) {
    flex-direction: column;
  }
`;
const FlexBox = styled.div`
  width: 50%;
  display: flex;
  padding-right: 2rem;

  box-sizing: border-box;
  margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: row;
  }
  @media (max-width: 714px) {
    padding-right: 0;
    width: 100%;
  }
`;
const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
  width: 11rem;
  min-width: 11rem;
  color: black;
  @media (max-width: 1424px) {
    min-width: none;
  }
`;
const Break = styled.div`
  width: 100%;
  border: 0.5px solid #e2e2e2;
`;
const ViewData = styled.div`
  display: inline-block;
  width: 100%;
  height: 2rem;
  font-weight: 300;
`;

const StatusSwitch = styled(Switch)`
  height: 32px;
  width: 85px;
  &.ant-switch {
    background: #ffab4f;
  }
  &.ant-switch .ant-switch-inner {
    margin: 0 -2px 0 25px;
  }

  &.ant-switch-checked {
    background: #004368;
  }

  & .ant-switch-handle {
    width: 27px;
    height: 27px;
    overflow: hidden;
    border-radius: 999999px;
  }

  &.ant-switch-checked .ant-switch-inner {
    margin: 0 25px 0 -3px;
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 30px);
  }
`;

const Inputdata = styled(Input)`
  width: 100%;
  height: 2rem;
  border-radius: 5px;
`;
const TableText = styled.div`
  color: ${(props) => (props.title ? '#004368' : '#232323')};
  font-size: 1em;
  font-weight: ${(props) => (props.title ? '600' : '300')};
  font-family: 'Kanit';
  line-height: 22px;
  text-transform: ${(props) => props.capitalize && 'capitalize'};
  text-align: left;
`;

const TableContainer = styled.div`
  padding: 0px 50px;
`;

const CustomTable = styled(Table)`
  //Header
  & .ant-table.ant-table-small .ant-table-thead > tr > th {
    font-size: 16px;
    font-weight: bold;
    background-color: white;
    padding-bottom: 20px;
  }

  &.table-striped-rows thead {
    background-color: #f1f1f1;
  }

  min-height: 330px;

  margin-bottom: 20px;
`;

// let { id } = useParams();
// #endregion

const Circle = styled.div`
  height: ${(props) => (props.small ? '1.375rem' : '2rem')};
  width: ${(props) => (props.small ? '1.375rem' : '2rem')};
  border-radius: 50%;
  background: ${(props) => props.color};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 99;
`;

const DummyColorCodes = [
  '#0083DE',
  '#F05E6B',
  '#FFAA56',
  '#64B78F',
  '#AF62EB',
  '#055F9D',
];

export default class ProjectForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberModalVisible: false,
      otModalVisible: false,
      formMode: Boolean(this.id) ? false : true,
      initialValues: { projectProgressId: 2 },
      options: null,
      progress: [],
      record: [],
      delete: null,
      /* progressid: 2, */
      startDate: null,
      endDate: null,
      isActive: true,
      modalEdit: false,
      memberIsActive: true,
      clickedColor: null,
      matchedUserProjects: null,
      projectTypeList: [],
      isLoading: false,
    };
  }

  static contextType = AppContext;

  formRef = React.createRef();
  memberFormRef = React.createRef();
  id = new URLSearchParams(this.props.location.search).get('id');
  isEditMode = Boolean(this.id);
  isAddMode = !this.isEditMode;
  otFormRef = React.createRef();

  handleSubmit = (values) => {
    const data = {
      projectId: this.id ? this.id : undefined,
      projectNo: values.projectNo,
      projectName: values.projectName,
      quotationNo: values.quotationNo,
      poNo: values.poNo,
      contactPerson: values.contactPerson,
      customerName: values.customerName,
      projectDetail: values.projectDetail,
      startDate: this.state.initialValues.startDate
        ? moment(this.state.initialValues.startDate).format('yyyy-MM-DD')
        : this.state.startDate,
      endDate: this.state.initialValues.endDate
        ? moment(this.state.initialValues.endDate).format('yyyy-MM-DD')
        : this.state.endDate,
      projectProgressId: this.state.initialValues.projectProgressId,
      isActive: this.state.isActive,
      members: this.state.initialValues?.members?.map((value) => {
        return {
          userId: Number(value.userId),
          isActive: value.isActive,
          projectRole: value.projectRole,
        };
      }),
      userCurrentRole: null,
      otStatus: this.state.initialValues?.otStatus
        ? this.state.initialValues?.otStatus
        : [],
      projectTypeId: values.projectTypeId,
    };
    if (data.members === undefined) {
      data.members = [];
    }
    if (this.isAddMode) {
      this.handleAddProject(data);
    } else {
      this.handleUpdateProject(data);
    }
  };
  handleAddProject = (payload) => {
    const messageKey = 'creating-project';
    this.setState({
      isLoading: true,
    });
    ProjectService.addProject(
      payload,
      ({ data }) => {
        message.success({ key: messageKey, content: 'Add Success' });
        this.setState({
          isLoading: false,
        });
        setTimeout(() => {
          redirectTo('/project');
        }, 1000);
      },
      (response) => {
        // console.log(response.data);
        if (response) {
          message.error({
            key: messageKey,
            content: 'Error: ' + response.data.message,
          });
        }
        this.setState({
          isLoading: false,
        });
      },
    );
  };
  handleUpdateProject = (payload) => {
    const messageKey = 'updating-project';
    this.setState({
      isLoading: true,
    });
    ProjectService.editProject(
      payload,
      ({ data }) => {
        message.success({ key: messageKey, content: 'Update Success' });
        setTimeout(() => {
          this.setState({ formMode: false, isLoading: false });
          unstable_batchedUpdates(() => {
            this.getProjectDetail();
          });
        }, 1000);
      },
      (response) => {
        if (response && response.status === 400) {
          message.error({
            key: messageKey,
            content: 'Error: ' + response.data.message,
          });
        }
        this.setState({
          isLoading: false,
        });
      },
    );
  };

  handleFetchProjectType = () => {
    AuthorizeRoleService.getAllProjectType(
      ({ data }) => {
        this.setState({
          projectTypeList: data.filter((d) => d.isActived),
        });
      },
      (response) => {
        if (response) {
          message.error('Error: ' + response.data.message);
        }
      },
    );
  };

  cancelEdit = () => {
    this.getProjectDetail();
    this.setState((prevState) => ({
      formMode: !prevState.formMode,
    }));
  };

  getProjectDetail = () => {
    const projectId = this.id;
    this.setState({
      isLoading: true,
    });
    ProjectService.fetchProject(
      projectId,
      ({ data }) => {
        this.formRef.current.setFieldsValue({
          projectNo: data.projectNo,
          projectName: data.projectName,
          quotationNo: data.quotationNo,
          poNo: data.poNo,
          contactPerson: data.contactPerson,
          customerName: data.customerName,
          projectDetail: data.projectDetail,
          isActive: data.isActive,
          startDate: data.startDate,
          endDate: data.endDate,
          projectProgressId: data.projectProgressId,
          projectTypeId: data.projectTypeId,
        });
        this.setState({
          initialValues: data,
          isActive: data.isActive,
          isLoading: false,
        });
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Cannot access data: ' + response.data.message);
        }
        this.setState({
          incorrect: true,
          isLoading: false,
        });
      },
    );
  };

  getProjectProgess = () => {
    this.setState({
      isLoading: true,
    });
    ProjectService.progessProject(
      (data) => {
        this.setState({
          progress: data.data,
          isLoading: false,
        });
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Error: ' + response.data.message);
        }
        this.setState({
          isLoading: false,
        });
      },
    );
  };

  getAllUserProjectsList = () => {
    console.log('+getAllUserProjectsList');
    var jwt = require('jsonwebtoken');
    var token = sessionStorage.getItem('access-token');
    var decode1 = jwt.decode(token);
    const userId = decode1?.userId;
    this.setState({
      isLoading: true,
    });
    ProjectService.fetchProjectListByUser(
      (data) => {
        this.setState({
          projectList: data.data,
        });
        const CurrentProject = data.data.filter((arrele) => {
          return arrele.id == this.id;
        });
        this.setState({
          clickedColor:
            CurrentProject.length != 0
              ? CurrentProject[0].projectColorCode
              : null,
          matchedUserProjects: CurrentProject,
          isLoading: false,
        });
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Error: ' + response.data.message);
        }
        this.setState({
          isLoading: false,
        });
      },
    );
  };

  updateProjectColor = (projectColorCode) => {
    var jwt = require('jsonwebtoken');
    var token = sessionStorage.getItem('access-token');
    var decode1 = jwt.decode(token);
    const userId = decode1.userId;
    console.log('userid', userId);
    this.setState({
      isLoading: true,
    });
    ProjectService.updateProjectColor(
      { projectId: this.id, memberId: userId },
      { projectColorCode: projectColorCode },
      () => {
        message.success('updated project color.');
        this.setState({
          isLoading: false,
        });
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Error: ' + response.data.message);
        }
        this.setState({
          isLoading: false,
        });
      },
    );
  };

  submitAddMember = (values) => {
    // console.log(values, this.state.delete);
    const members = this.state.initialValues.members
      ? this.state.initialValues.members.filter(
          (r) => r.userId !== values.userId,
        )
      : [];

    this.setState({
      initialValues: {
        ...this.state.initialValues,
        members: [...members, values],
      },
    });
    this.closeMemberModal();
  };

  submitAddOt = (value) => {
    const otStatus = this.state.initialValues.otStatus
      ? this.state.initialValues.otStatus
      : [];

    this.setState({
      initialValues: {
        ...this.state.initialValues,
        otStatus: [...otStatus, value],
      },
    });

    this.closeOtModal();
  };

  componentDidMount() {
    const { userState } = this.context;
    this.setState({
      userCurrentRole: userState.userCurrentRole,
    });

    const previousSavedStateB4Redirect = sessionStorage.getItem(
      'add-projectform-state',
    );
    //previousSavedStateB4Redirect ? this.setState({ initialValues: { ...this.state.initialValues, members: JSON.stringify(previousSavedStateB4Redirect) } }, () => { console.log('imported saved form state.initialValues', this.state) }) : console.log();
    previousSavedStateB4Redirect
      ? this.setState({
          initialValues: JSON.parse(previousSavedStateB4Redirect),
        })
      : console.log('previous project email not found');

    this.getProjectProgess();
    this.getAllUserProjectsList();
    this.handleFetchProjectType();
    if (!this.isAddMode) {
      // console.log('edit');
      this.getProjectDetail();
    }
  }

  componentDidUpdate(prevProgess) {
    const { userState } = this.context;
    if (userState.currentRole !== this.state.userCurrentRole) {
      this.setState({
        userCurrentRole: userState.currentRole,
      });
    }
    if (
      this.state.matchedUserProjects?.length == 0 &&
      this.state.userCurrentRole == 'USER'
    ) {
      redirectTo('/project');
    }
  }

  clickOnDelete = (id) => {
    // console.log(id);
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        members: this.state.initialValues.members.filter((r) => r !== id),
      },
    });
    this.closeMemberModal();
  };

  projectSwitchOnChange = (checked) => {
    this.state.isActive = checked;
    this.state.isActive
      ? this.setState({
          initialValues: {
            ...this.state.initialValues,
            projectProgressId: 2,
            isActive: true,
          },
        })
      : this.setState({
          initialValues: {
            ...this.state.initialValues,
            projectProgressId: 3,
            isActive: false,
          },
        });
    console.log(this.state.progress);
  };

  memberSwitchOnChange = (checked) => {
    this.setState({
      memberIsActive: checked,
    });
  };

  showMemberModal = (record) => {
    // console.log(record, record.isDeletable);
    this.setState({
      memberModalVisible: true,
      record: record,
      delete: record.isDeletable ? record.isDeletable : true,
      existedMemberEmailStatus: false,
    });
    if (record.userId) {
      this.setState({
        modalEdit: true,
        memberIsActive: record.isActive,
      });
      this.memberFormRef.current.setFieldsValue({
        userId: record.userId,
        email: record.email,
        firstnameEn: record.firstnameEn,
        lastnameEn: record.lastnameEn,
        position: record.position,
        department: record.department,
        projectRole: record.projectRole,
        isActive: record.isActive,
      });
    } else {
      this.memberFormRef.current.setFieldsValue({
        isActive: true,
      });
    }
  };

  showOtModal = () => {
    this.setState({
      otModalVisible: true,
    });
  };

  closeMemberModal = () => {
    this.setState({
      memberModalVisible: false,
      options: null,
      modalEdit: false,
    });

    this.memberFormRef.current.setFieldsValue({
      userId: '',
      email: '',
      firstnameEn: '',
      lastnameEn: '',
      position: '',
      department: '',
      projectRole: '',
      isActive: this.state.record.isActive,
    });
  };

  closeOtModal = () => {
    this.setState({
      otModalVisible: false,
    });

    this.otFormRef.current.setFieldsValue({
      startDate: null,
      endDate: null,
    });
  };

  mockVal = (str, repeat) => ({
    value: str.repeat(repeat),
  });

  getOptions = (search) => {
    UserService.getUserOptions(
      {
        search: search,
      },
      async (data) => {
        const emailList = await data?.data.map((res) => {
          const getHighlightedText = (text) => {
            let isSearchThai = search && /[\u0E00-\u0E7F]/g.test(search);

            let searchCriteria = text;
            let index = searchCriteria
              .toLowerCase()
              .indexOf(search.toLowerCase());

            if (search.length != 0 && index !== -1) {
              let prefix = searchCriteria.substr(0, index);
              let subfix = searchCriteria.substr(index + search.length);
              let matched = searchCriteria.substr(index, search.length);
              return (
                <>
                  {prefix}
                  <span style={{ fontWeight: 'bold', fontColor: '#004368' }}>
                    {matched}
                  </span>
                  {subfix}
                </>
              );
            } else if (
              !isSearchThai &&
              (res.firstnameEn
                .toLowerCase()
                .indexOf(search.toLowerCase().trim()) !== -1 ||
                res.lastnameEn
                  .toLowerCase()
                  .indexOf(search.toLowerCase().trim()) !== -1 ||
                res.email.toLowerCase().indexOf(search.toLowerCase().trim()) !==
                  -1 ||
                res.nicknameEn
                  .toLowerCase()
                  .indexOf(search.toLowerCase().trim()) !== -1)
            ) {
              return (
                [
                  res.firstnameEn,
                  res.lastnameEn,
                  res.nicknameEn,
                  res.email,
                ].includes(text) && text
              );
            } else {
              return (
                [
                  res.firstnameTh,
                  res.lastnameTh,
                  res.nicknameTh,
                  res.email,
                ].includes(text) && text
              );
            }
          };

          return {
            value: res.email,
            label: (
              <Row>
                <Col span={15}>
                  <Typography.Title
                    style={{
                      fontWeight: 'normal',
                      fontSize: '15px',
                    }}
                    level={5}
                    ellipsis={{
                      rows: 1,
                    }}
                  >
                    {getHighlightedText(res.firstnameEn)}
                    {getHighlightedText(res.firstnameTh)}{' '}
                    {getHighlightedText(res.lastnameEn)}
                    {getHighlightedText(res.lastnameTh)}
                  </Typography.Title>

                  <Typography.Text
                    style={{
                      fontWeight: 'normal',
                      fontSize: '12px',
                    }}
                    ellipsis={{
                      rows: 1,
                    }}
                  >
                    {getHighlightedText(res.email)}
                  </Typography.Text>
                </Col>
                <Col span={5} offset={3}>
                  <Typography.Title
                    style={{
                      fontWeight: 'normal',
                      fontSize: '15px',
                      textAlign: 'left',
                    }}
                    level={5}
                    ellipsis={{
                      rows: 1,
                    }}
                  >
                    {getHighlightedText(res.nicknameEn)}
                    {getHighlightedText(res.nicknameTh)}
                  </Typography.Title>
                </Col>
              </Row>
            ),
          };
        });
        this.setState({
          options: emailList,
        });
        console.log(data);

        if (this.state.initialValues.members !== undefined) {
          this.state.initialValues.members.map((member) => {
            if (search === member.email) {
              return this.setState({ existedMemberEmailStatus: true }, () => {
                console.log('set autocomstatus');
              });
            }
          });
        }
      },
    );
  };

  selectOptions = (search) => {
    UserService.getUserOptions(
      {
        search: search,
      },
      async (data) => {
        const selectedUser = data.data[0];
        // console.log(selectedUser);
        this.memberFormRef.current.setFieldsValue({
          userId: selectedUser.userId,
          email: selectedUser.email,
          firstnameEn: selectedUser.firstnameEn,
          lastnameEn: selectedUser.lastnameEn,
          empCode: selectedUser.empCode,
          position: selectedUser.position,
          department: selectedUser.department,
          projectRole: '',
        });
      },
    );
  };

  onConfirmDeleteOt = (record) => {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        otStatus: this.state.initialValues.otStatus.filter(
          (rec) => rec !== record,
        ),
      },
    });
  };

  render() {
    const renderProgress = () => {
      const progressName = this.state.progress
        .map((item) => {
          if (item.id === this.state.initialValues.projectProgressId)
            return item.progressName;
        })
        .join('');

      return (
        <Badge
          dot
          color={
            this.state.initialValues.projectProgressId == 2
              ? '#20ABC5'
              : '#E89A3C'
          }
          text={progressName}
        />
      );
    };
    const OtColumns = [
      {
        title: 'START DATE',
        dataIndex: 'startDate',
        index: 'startDate',
        width: '20%',
        align: 'center',
        render: (value) => (
          <TableText style={{ textAlign: 'center' }}>
            {moment(value).format('DD/MM/YYYY')}
          </TableText>
        ),
      },
      {
        title: 'END DATE',
        index: 'endDate',
        dataIndex: 'endDate',
        width: '20%',
        align: 'center',
        render: (value) => (
          <TableText style={{ textAlign: 'center' }}>
            {moment(value).format('DD/MM/YYYY')}
          </TableText>
        ),
      },
      {
        title: '',
        width: '7%',
        align: 'center',
        render: (key, record) =>
          (this.state.formMode || this.isAddMode) && (
            <Popconfirm
              title="Are you sure to delete？"
              okText="OK"
              cancelText="Cancel"
              okButtonProps={{
                style: {
                  backgroundColor: '#004368',
                  borderColor: '##004368',
                },
              }}
              onConfirm={() => this.onConfirmDeleteOt(record)}
              onCancel={() => {}}
            >
              <img src={binIcon} />
            </Popconfirm>
          ),
      },
    ];

    const columns = [
      {
        title: 'EMP NO.',
        dataIndex: 'empCode',
        index: 'empCode',
        width: '10%',
        align: 'center',
        defaultSortOrder: 'ascend',
        sorter: {
          compare: (a, b) => a.empCode.localeCompare(b.empCode),
        },
        render: (value) => <TableText title>{value}</TableText>,
      },
      {
        title: 'NAME',
        dataIndex: 'firstnameEn',
        index: 'firstnameEn',
        width: '20%',
        align: 'center',
        render: (name, record) => (
          <TableText>{`${record.firstnameEn} ${record.lastnameEn}`}</TableText>
        ),
      },
      {
        title: 'EMAIL',
        index: 'email',
        dataIndex: 'email',
        width: '20%',
        align: 'center',
        render: (value) => <TableText>{value}</TableText>,
      },
      {
        title: 'ROLE',
        index: 'role',
        dataIndex: 'projectRole',
        width: '10%',
        align: 'center',
        render: (value) => <TableText>{value}</TableText>,
      },
      {
        title: 'POSITION',
        index: 'position',
        dataIndex: 'position',
        width: '15%',
        align: 'center',
        render: (value) => <TableText>{value}</TableText>,
      },
      {
        title: 'STATUS',
        dataIndex: 'isActive',
        width: '7%',
        align: 'center',
        render: (value) => <Badge dot color={value ? '#20ABC5' : '#E89A3C'} />,
      },
      {
        title: '',
        width: '7%',
        align: 'center',
        // dataIndex: "index",
        render: (key, record) =>
          key && (
            <Row justify="center">
              {(this.state.userCurrentRole === 'ADMIN' &&
                this.state.formMode) ||
              this.isAddMode ? (
                <>
                  <Col span={10}>
                    <Tooltip mouseEnterDelay={0.4} title="View Timesheet">
                      <NavLink
                        onClick={() => {
                          sessionStorage.setItem(
                            'add-projectform-state',
                            JSON.stringify(this.state.initialValues),
                          );
                        }}
                        to={'/timesheetDetail/?id=' + String(record.userId)}
                      >
                        <EyeOutlined
                          style={{
                            fontSize: '20px',
                            color: '#004368',
                            cursor: 'pointer',
                            marginBottom: '8px',
                          }}
                        />
                      </NavLink>
                    </Tooltip>
                  </Col>
                  <Col span={10} offset={4}>
                    <Tooltip mouseEnterDelay={0.4} title="Edit">
                      <a onClick={() => this.showMemberModal(record)}>
                        <img src={actiona} />
                      </a>
                    </Tooltip>
                  </Col>
                </>
              ) : (
                <Col span={24}>
                  {this.state.userCurrentRole === 'ADMIN' && (
                    <Tooltip
                      mouseEnterDelay={0.4}
                      mouseLeaveDelay={0}
                      title="View Timesheet"
                    >
                      <NavLink
                        to={'/timesheetDetail/?id=' + String(record.userId)}
                      >
                        <EyeOutlined
                          style={{
                            fontSize: '20px',
                            color: '#004368',
                            cursor: 'pointer',
                          }}
                        />
                      </NavLink>
                    </Tooltip>
                  )}
                </Col>
              )}
            </Row>
          ),
      },
    ];
    return (
      <div style={{ width: 'auto' }}>
        <Spin spinning={this.state.isLoading}>
          <TitleBox>
            <Text title>
              {this.isAddMode
                ? 'Create Project'
                : this.state.formMode || this.state.userCurrentRole === 'ADMIN'
                ? 'Edit Project'
                : 'View Project'}
            </Text>
            {this.state.formMode || this.isAddMode ? (
              <ButtonBlock width="170px">
                {this.isAddMode ? (
                  <NavLink to={'/project'}>
                    <RejectedButton form="user-form" white width="76px">
                      Cancel
                    </RejectedButton>
                  </NavLink>
                ) : (
                  <RejectedButton
                    form="user-form"
                    white
                    width="76px"
                    onClick={() => this.cancelEdit()}
                  >
                    Cancel
                  </RejectedButton>
                )}
                <NormalButton
                  width="76px"
                  onClick={() => this.formRef.current.submit()}
                >
                  Submit
                </NormalButton>
              </ButtonBlock>
            ) : (
              <ButtonBlock width="200px">
                {this.state.userCurrentRole === 'ADMIN' && (
                  <NormalButton
                    width="96px"
                    onClick={() =>
                      this.setState((prevState) => ({
                        formMode: !prevState.formMode,
                      }))
                    }
                  >
                    <EditOutlined /> Edit
                  </NormalButton>
                )}
                <NavLink to={'/project'} width="96px">
                  <NormalButton white>
                    <LeftOutlined /> Back
                  </NormalButton>
                </NavLink>
              </ButtonBlock>
            )}
          </TitleBox>
          <ContentBox>
            <TextCard style={{ marginBottom: '2.5em', paddingBottom: '1em' }}>
              <Form
                ref={this.formRef}
                onFinish={this.handleSubmit}
                initialValues={{
                  projectName: this.state.initialValues?.projectName,
                }}
                requiredMark={true}
              >
                <Verticalbox>
                  <Horizontalbox justify="space-between">
                    <Headdetail>Project Detail</Headdetail>

                    {this.state.initialValues.projectProgressId == 2 ? (
                      <Typography.Text
                        style={{
                          size: '16px',
                          fontWeight: 'bold',
                          color: '#004368',
                        }}
                      >
                        Active
                      </Typography.Text>
                    ) : (
                      <Typography.Text
                        style={{
                          size: '16px',
                          fontWeight: 'normal',
                          color: '#565656',
                        }}
                      >
                        Inactive
                      </Typography.Text>
                    )}
                  </Horizontalbox>
                  <Horizontalbox>
                    <FlexBox Left>
                      <Label>
                        Project No. &nbsp;
                        <label style={{ color: 'red' }}>*</label>
                      </Label>
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="projectNo"
                        placeholder="No."
                        rules={[
                          {
                            required: true,
                            message: 'Please input project no.',
                          },
                        ]}
                      >
                        {this.isAddMode ? (
                          <Inputdata id="projectNo" placeholder="Project No." />
                        ) : this.state.formMode ? (
                          <Inputdata
                            id="projectNo"
                            placeholder="Project No."
                            disabled={true}
                          />
                        ) : (
                          <ViewData>
                            {this.state.initialValues?.projectNo}
                          </ViewData>
                        )}
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Label>
                        Project Name &nbsp;
                        <label style={{ color: 'red' }}>*</label>
                      </Label>
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="projectName"
                        placeholder="Name"
                        rules={[
                          {
                            required: true,
                            message: 'Please input project name.',
                          },
                        ]}
                      >
                        {this.state.formMode || this.isAddMode ? (
                          <Inputdata
                            id="projectName"
                            placeholder="Project Name"
                          />
                        ) : (
                          <ViewData>
                            {this.state.initialValues?.projectName}
                          </ViewData>
                        )}
                      </Form.Item>
                    </FlexBox>
                  </Horizontalbox>
                  <Horizontalbox>
                    <FlexBox Left>
                      <Label>Ref Quotation No.</Label>
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="quotationNo"
                      >
                        {this.state.formMode || this.isAddMode ? (
                          <Inputdata
                            id="quotationNo"
                            placeholder="Quotation No."
                          />
                        ) : (
                          <ViewData>
                            {this.state.initialValues?.quotationNo}
                          </ViewData>
                        )}
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Label>Ref PO No.</Label>
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="poNo"
                      >
                        {this.state.formMode || this.isAddMode ? (
                          <Inputdata id="poNo" placeholder="PO No." />
                        ) : (
                          <ViewData>{this.state.initialValues?.poNo}</ViewData>
                        )}
                      </Form.Item>
                    </FlexBox>
                  </Horizontalbox>
                  <Horizontalbox>
                    <FlexBox>
                      <Label>
                        Project Type &nbsp;
                        <label style={{ color: 'red' }}>*</label>
                      </Label>
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="projectTypeId"
                        rules={[
                          {
                            required: true,
                            message: 'Please select project type.',
                          },
                        ]}
                      >
                        {this.state.formMode || this.isAddMode ? (
                          <Select placeholder="Select project type">
                            {this.state.projectTypeList?.map((record) => (
                              <Select.Option value={record.id}>
                                {record.projectTypeName}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : (
                          <ViewData>
                            {this.state.initialValues?.projectTypeName}
                          </ViewData>
                        )}
                      </Form.Item>
                    </FlexBox>
                  </Horizontalbox>
                </Verticalbox>
                <Break />
                <Verticalbox>
                  <Horizontalbox>
                    <FlexBox Left>
                      <Label>Contact Person</Label>
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="contactPerson"
                      >
                        {this.state.formMode || this.isAddMode ? (
                          <Inputdata id="contactPerson" placeholder="Name" />
                        ) : (
                          <ViewData>
                            {this.state.initialValues?.contactPerson}
                          </ViewData>
                        )}
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Label>
                        Customer Name &nbsp;
                        <label style={{ color: 'red' }}>*</label>
                      </Label>
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="customerName"
                        rules={[
                          {
                            required: true,
                            message: 'Please input customer name.',
                          },
                        ]}
                      >
                        {this.state.formMode || this.isAddMode ? (
                          <Inputdata id="customerName" placeholder="Name" />
                        ) : (
                          <ViewData>
                            {this.state.initialValues?.customerName}
                          </ViewData>
                        )}
                      </Form.Item>
                    </FlexBox>
                  </Horizontalbox>

                  <Horizontalbox style={{ marginBottom: '1.5rem' }}>
                    <Label style={{ width: '10%' }}>
                      Project Detail &nbsp;
                      <label style={{ color: 'red' }}>*</label>
                    </Label>
                    <Form.Item
                      style={{
                        width: '100%',
                        margin: '0',
                        paddingRight: '2rem',
                      }}
                      name="projectDetail"
                      rules={[
                        {
                          required: true,
                          message: 'Please input project detail.',
                        },
                      ]}
                    >
                      {this.state.formMode || this.isAddMode ? (
                        <Inputdata id="projectDetail" placeholder="Details" />
                      ) : (
                        <ViewData>
                          <Typography.Paragraph
                            ellipsis={{
                              rows: 2,
                              expandable: true,
                              symbol: 'more',
                            }}
                          >
                            {this.state.initialValues?.projectDetail}
                          </Typography.Paragraph>
                        </ViewData>
                      )}
                    </Form.Item>
                  </Horizontalbox>
                  <Horizontalbox>
                    <FlexBox Left>
                      <Label>Start Date</Label>

                      {this.state.formMode || this.isAddMode ? (
                        <DatePicker
                          id="startDate"
                          placeholder="Select Time"
                          format="DD/MM/YYYY"
                          defaultValue={
                            this.state.initialValues.startDate
                              ? moment(this.state.initialValues.startDate)
                              : ''
                          }
                          onChange={(value) =>
                            this.setState({
                              initialValues: {
                                ...this.state.initialValues,
                                startDate: value,
                              },
                              startDate: value,
                            })
                          }
                          style={{
                            width: '100%',
                            height: '2rem',
                          }}
                        />
                      ) : (
                        <ViewData>
                          {this.state.initialValues.startDate
                            ? moment(
                                this.state.initialValues?.startDate,
                              ).format('DD/MM/YYYY')
                            : ''}
                        </ViewData>
                      )}
                    </FlexBox>
                    <FlexBox>
                      <Label>End Date</Label>
                      {this.state.formMode || this.isAddMode ? (
                        <DatePicker
                          id="endDate"
                          placeholder="Select Time"
                          format="DD/MM/YYYY"
                          defaultValue={
                            this.state.initialValues.endDate
                              ? moment(this.state.initialValues.endDate)
                              : ''
                          }
                          onChange={(value) =>
                            this.setState({
                              initialValues: {
                                ...this.state.initialValues,
                                endDate: value,
                              },
                              endDate: value,
                            })
                          }
                          style={{
                            width: '100%',
                            height: '2rem',
                          }}
                          disabledDate={(prohibitDate) => {
                            return (
                              prohibitDate &&
                              prohibitDate <
                                moment(this.state.initialValues?.startDate)
                            );
                          }}
                        />
                      ) : (
                        <ViewData>
                          {this.state.initialValues.endDate
                            ? moment(this.state.initialValues?.endDate).format(
                                'DD/MM/YYYY',
                              )
                            : ''}
                        </ViewData>
                      )}
                    </FlexBox>
                  </Horizontalbox>

                  <Horizontalbox>
                    <FlexBox Left>
                      <Label>Status</Label>
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="isActive"
                        valuePropName="checked"
                      >
                        {this.state.formMode || this.isAddMode ? (
                          <StatusSwitch
                            defaultChecked
                            checkedChildren="Active"
                            unCheckedChildren="Inactive"
                            onChange={this.projectSwitchOnChange}
                          ></StatusSwitch>
                        ) : (
                          <StatusSwitch
                            checkedChildren="Active"
                            unCheckedChildren="Inactive"
                            disabled
                          ></StatusSwitch>
                        )}
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Label>Progress</Label>
                      {this.state.formMode || this.isAddMode ? (
                        <Input
                          prefix={
                            <Badge
                              dot
                              color={
                                this.state.initialValues.projectProgressId == 2
                                  ? '#20ABC5'
                                  : '#E89A3C'
                              }
                            />
                          }
                          value={this.state.progress
                            .map((item) => {
                              if (
                                item.id ===
                                this.state.initialValues.projectProgressId
                              ) {
                                return item.progressName;
                              }
                            })
                            .join('')}
                          disabled
                          defaultValue={
                            this.state.initialValues.projectProgressId
                          }
                          style={{
                            width: '100%',
                            height: '2rem',
                          }}
                        ></Input>
                      ) : (
                        <ViewData>{renderProgress()}</ViewData>
                      )}
                    </FlexBox>
                  </Horizontalbox>

                  {this.state.userCurrentRole == 'USER' && (
                    <Horizontalbox>
                      <FlexBox Left>
                        <Label>Project Color Code</Label>
                        <Row gutter={[8, 8]} style={{ width: '100%' }}>
                          {DummyColorCodes.map((color) => (
                            <Col>
                              <a
                                onClick={() => {
                                  this.setState({ clickedColor: color });
                                  this.updateProjectColor(color);
                                }}
                              >
                                <Circle
                                  color={color}
                                  style={
                                    this.state.clickedColor == color
                                      ? {
                                          border: '2px solid #FFFFFF',
                                          boxShadow: '0px 0px 8px #00000066',
                                        }
                                      : undefined
                                  }
                                >
                                  {this.state.clickedColor == color ? (
                                    <CheckOutlined
                                      /* style={{ display: "unset" }} */ style={{
                                        color: '#FFFFFF',
                                      }}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </Circle>
                              </a>
                            </Col>
                          ))}
                        </Row>
                      </FlexBox>
                    </Horizontalbox>
                  )}
                </Verticalbox>
              </Form>
            </TextCard>
            <TextCard style={{ marginBottom: '2rem', marginTop: '0rem' }}>
              <TableContainer>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Headdetail style={{ margin: '20px 0px' }}>
                    OT Status
                  </Headdetail>
                  {(this.state.formMode || this.isAddMode) && (
                    <NormalButton
                      whitefocus
                      width="149px"
                      onClick={this.showOtModal}
                      style={{
                        margin: '20px 30px',
                      }}
                    >
                      Add OT Date
                    </NormalButton>
                  )}
                </div>
                <CustomTable
                  className="table-striped-rows"
                  columns={OtColumns}
                  dataSource={
                    this.state.initialValues
                      ? this.state.initialValues.otStatus
                      : null
                  }
                  style={{ width: '100%' }}
                  size="small"
                  // loading={!this.state.hasData && !this.state.incorrect}
                  // pagination={{ position: ["bottomRight"] }}
                  pagination={false}
                />
              </TableContainer>
            </TextCard>
            <TextCard style={{ marginBottom: '2rem', marginTop: '0rem' }}>
              <TableContainer>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Headdetail style={{ margin: '20px 0px' }}>Member</Headdetail>
                  {(this.state.formMode || this.isAddMode) && (
                    <NormalButton
                      whitefocus
                      width="149px"
                      onClick={this.showMemberModal}
                      style={{
                        margin: '20px 30px',
                      }}
                    >
                      <UserAddOutlined /> Add Member
                    </NormalButton>
                  )}
                </div>
                <CustomTable
                  className="table-striped-rows"
                  columns={columns}
                  dataSource={
                    this.state.initialValues
                      ? this.state.initialValues.members
                      : null
                  }
                  style={{ width: '100%' }}
                  size="small"
                  // loading={!this.state.hasData && !this.state.incorrect}
                  // pagination={{ position: ["bottomRight"] }}
                  pagination={false}
                />
              </TableContainer>
            </TextCard>
          </ContentBox>
          <CreateOtModal
            otFormRef={this.otFormRef}
            submitAddOt={this.submitAddOt}
            otModalVisible={this.state.otModalVisible}
            closeOtModal={this.closeOtModal}
          />
          <Form
            ref={this.memberFormRef}
            onFinish={this.submitAddMember}
            requiredMark={true}
          >
            <Modal
              width={600}
              open={this.state.memberModalVisible}
              onCancel={this.closeMemberModal}
              footer={null}
            >
              <Headdetail>
                {this.state.modalEdit ? 'Edit Member' : 'Add Member'}
              </Headdetail>

              <Form.Item
                style={{ width: '100%', margin: '0' }}
                name="userId"
                rules={[
                  {
                    required: true,
                    message: 'Please input User Id.',
                  },
                ]}
                hidden
              ></Form.Item>
              <Horizontalbox>
                <Label>Email</Label>
                <Form.Item
                  id="email"
                  style={{ width: '100%', marginBottom: '1rem' }}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Email',
                    },
                  ]}
                >
                  <AutoComplete
                    options={this.state.options}
                    onSelect={this.selectOptions}
                    onChange={this.getOptions}
                    placeholder="Search by Email, Name, Nick Name"
                    disabled={this.state.record.isDeletable}
                    status={
                      this.state.existedMemberEmailStatus === true
                        ? 'warning'
                        : undefined
                    }
                    filterOption={(inputValue, option) => {
                      if (this.state.initialValues.members !== undefined) {
                        return !this.state.initialValues.members.some(
                          (member) => option.value === member.email,
                        );
                      } else {
                        return true;
                      }
                    }}
                  ></AutoComplete>
                </Form.Item>
              </Horizontalbox>
              <Horizontalbox>
                <Label>First Name</Label>
                <Form.Item
                  style={{ width: '100%', marginBottom: '1rem' }}
                  name="firstnameEn"
                  rules={[
                    {
                      required: true,
                      message: 'Please input first name',
                    },
                  ]}
                >
                  <Inputdata
                    id="firstnameEn"
                    disabled={this.state.record.isDeletable}
                  ></Inputdata>
                </Form.Item>
              </Horizontalbox>
              <Horizontalbox>
                <Label>Last Name</Label>
                <Form.Item
                  style={{ width: '100%', marginBottom: '1rem' }}
                  name="lastnameEn"
                  rules={[
                    {
                      required: true,
                      message: 'Please input last name',
                    },
                  ]}
                >
                  <Inputdata
                    id="lastnameEn"
                    disabled={this.state.record.isDeletable}
                  ></Inputdata>
                </Form.Item>
              </Horizontalbox>
              <Horizontalbox>
                <Label>EMP NO.</Label>
                <Form.Item
                  style={{ width: '100%', marginBottom: '1rem' }}
                  name="empCode"
                  rules={[
                    {
                      required: true,
                      message: 'Please input employee code',
                    },
                  ]}
                >
                  <Inputdata
                    id="empCode"
                    disabled={this.state.record.isDeletable}
                  ></Inputdata>
                </Form.Item>
              </Horizontalbox>
              <Horizontalbox>
                <Label>Position</Label>
                <Form.Item
                  style={{ width: '100%', marginBottom: '1rem' }}
                  name="position"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Position',
                    },
                  ]}
                >
                  <Inputdata
                    id="position"
                    placeholder="Position"
                    disabled={this.state.record.isDeletable}
                  ></Inputdata>
                </Form.Item>
              </Horizontalbox>
              <Horizontalbox>
                <Label>Department</Label>
                <Form.Item
                  style={{ width: '100%', marginBottom: '1rem' }}
                  name="department"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Department',
                    },
                  ]}
                >
                  <Inputdata
                    id="department"
                    placeholder="Department"
                    disabled={this.state.record.isDeletable}
                  ></Inputdata>
                </Form.Item>
              </Horizontalbox>
              <Horizontalbox>
                <Label>Role</Label>
                <Form.Item
                  style={{ width: '100%', marginBottom: '1rem' }}
                  name="projectRole"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Role',
                    },
                  ]}
                >
                  <Inputdata id="projectRole" placeholder="Role"></Inputdata>
                </Form.Item>
              </Horizontalbox>
              <Horizontalbox>
                <Label>Status</Label>
                <Form.Item
                  style={{ width: '100%', marginBottom: '1rem' }}
                  name="isActive"
                  valuePropName="checked"
                >
                  <StatusSwitch
                    defaultChecked
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    onChange={this.memberSwitchOnChange}
                  ></StatusSwitch>
                </Form.Item>
              </Horizontalbox>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '1rem',
                }}
              >
                {this.state.delete === true && this.state.modalEdit /* &&
                  this.state.record.isDeletable */ ? (
                  <RejectedButton
                    form="user-form"
                    grey
                    width="96px"
                    onClick={() => this.clickOnDelete(this.state.record)}
                    justifyContent="flex-start"
                  >
                    <img src={binIcon} />
                    Delete
                  </RejectedButton>
                ) : (
                  <div />
                )}

                <div>
                  <Row>
                    <RejectedButton
                      form="user-form"
                      grey
                      width="96px"
                      onClick={this.closeMemberModal}
                    >
                      Cancel
                    </RejectedButton>
                    <NormalButton
                      style={{ marginLeft: '1rem', minWidth: '106px' }}
                      width="96px"
                      onClick={() => {
                        this.memberFormRef.current.setFieldsValue({
                          isActive: this.state.memberIsActive,
                        });
                        if (!this.state.options) {
                          // console.log(this.memberFormRef.current);
                          this.memberFormRef.current.submit();
                        } else if (
                          document.getElementById('email').value !==
                          this.state.options[0]?.value
                        ) {
                          // console.log(
                          //   document.getElementById('email').value,
                          //   this.state.options[0]?.value
                          // );
                          message.error('Email is not match');
                        } else {
                          // console.log(this.memberFormRef.current);
                          this.memberFormRef.current.submit();
                        }
                      }}
                    >
                      {this.state.modalEdit ? (
                        <div>
                          <img src={saveIcon} /> <span>Save</span>
                        </div>
                      ) : (
                        'Add'
                      )}
                    </NormalButton>
                  </Row>
                </div>
              </Row>
            </Modal>
          </Form>
        </Spin>
      </div>
    );
  }
}
