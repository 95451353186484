import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Button,
  Col,
  Row,
  DatePicker,
  Input as AntInput,
  Form as AntForm,
} from 'antd';
const TitleBox = styled.div`
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.bg || '#ffffff'};
  height: 6.875em;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.div`
  color: ${(props) =>
    props.title ? '#232323' : props.subtitle ? '#20ABC5' : '#565656'};
  font-size: ${(props) =>
    props.title ? '2.25em' : props.subtitle ? '1.625em' : '1em'};
  font-weight: ${(props) =>
    props.title
      ? 'bold'
      : props.subtitle
      ? '600'
      : props.name
      ? '500'
      : 'normal'};
  font-family: 'Kanit';
  margin-bottom: ${(props) => (props.subtitle ? '1.25em' : '0px')};
`;
const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#2d95a8 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#2d95a8 !important'};
  justify-content: center;
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Inter;
  font-style: Regular;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 5px 15px;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }
`;
const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;
const ContentBox = styled.div`
  background: #f6f6f6;
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: auto;
  min-height: calc(100vh - 7.5em);
  justify-content: flex-start;
`;
const TextCard = styled.div`
  height: auto;
  width: 100%;
  background-color: white;
  margin-top: 2em;
  box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 40px 50px;
`;
const Form = styled.form`
  padding: 5px 5px;
  height: auto;
`;
const Cols = styled(Col)`
  display: flex;
  align-items: center;
`;
const Rows = styled(Row)`
  margin: ${(props) => (props.start ? '0px 0px' : '20px 0px')};
  display: flex;
  align-items: ${(props) => (props.start ? 'flex-start' : 'center')};
`;
const Required = styled.span`
  padding-left: 5px;
  color: #eb5757;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;
const Input = styled.input`
  height: 35px;
  width: ${(props) => (props.full ? '100%' : '100%')};
  /* max-width: 300px; */
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0px 11px;
  font-family: Inter;
  font-style: normal;
  font-size: 1em;
  color: #737373;
  outline: none;
  &:focus {
    border-color: #20abc5;
  }
`;
const typeTemplate = "'${name}' is not a valid ${type}";
export const defaultValidateMessages = {
  default: "Validation error on field '${name}'",
  required: "'${name}' is required",
  enum: "'${name}' must be one of [${enum}]",
  whitespace: "'${name}' cannot be empty",
  date: {
    format: "'${name}' is invalid for format date",
    parse: "'${name}' could not be parsed as date",
    invalid: "'${name}' is invalid date",
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate,
  },
  string: {
    len: "'${name}' must be exactly ${len} characters",
    min: "'${name}' must be at least ${min} characters",
    max: "'${name}' cannot be longer than ${max} characters",
    range: "'${name}' must be between ${min} and ${max} characters",
  },
  number: {
    len: "'${name}' must equal ${len}",
    min: "'${name}' cannot be less than ${min}",
    max: "'${name}' cannot be greater than ${max}",
    range: "'${name}' must be between ${min} and ${max}",
  },
  array: {
    len: "'${name}' must be exactly ${len} in length",
    min: "'${name}' cannot be less than ${min} in length",
    max: "'${name}' cannot be greater than ${max} in length",
    range: "'${name}' must be between ${min} and ${max} in length",
  },
  pattern: {
    mismatch: "'${name}' does not match pattern ${pattern}",
  },
};
const { TextArea } = AntInput;
export default class CreateQuotation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectType: 'OS',
    };
  }

  formRef = React.createRef();
  handleOk = (payload) => {
    console.log(payload);
  };
  handleCancel = () => {
    this.onReset();
  };
  onReset = () => {
    this.formRef.current.resetFields();
  };
  render() {
    return (
      <div style={{ width: 'auto' }}>
        <TitleBox>
          <Text title>Create Quotation</Text>
        </TitleBox>
        <ContentBox>
          <TextCard>
            <AntForm
              style={{ width: '100%' }}
              id="quotation-form"
              ref={this.formRef}
              name="new quotation"
              onFinish={this.handleOk}
              validateMessages={defaultValidateMessages}
              autoComplete={false}
            >
              <Text subtitle>Quotation</Text>
              <Form>
                <Rows start>
                  <Cols span={4}>
                    <Text name>
                      Project Name<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={20}>
                    <AntForm.Item
                      style={{ width: '100%' }}
                      name="project_name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </AntForm.Item>
                  </Cols>
                </Rows>
                <Rows start>
                  <Cols span={4}>
                    <Text name>
                      Approved Person<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={7}>
                    <AntForm.Item
                      style={{ width: '100%' }}
                      name="approved_person"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </AntForm.Item>
                  </Cols>
                  <Cols span={2}></Cols>
                  <Cols span={4}>
                    <Text name>
                      Project Type<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={7}>
                    <AntForm.Item
                      style={{ width: '100%' }}
                      name="project_type"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </AntForm.Item>
                  </Cols>
                </Rows>
                {this.state.projectType == 'OS' && (
                  <Rows start>
                    <Cols span={4}>
                      <Text name>
                        Scope of Work<Required>*</Required>
                      </Text>
                    </Cols>
                    <Cols span={20}>
                      <AntForm.Item
                        style={{ width: '100%' }}
                        name="scope_of_work"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <TextArea
                          rows={8}
                          style={{
                            width: '100%',
                            borderRadius: '5px',
                          }}
                        />
                      </AntForm.Item>
                    </Cols>
                  </Rows>
                )}
                <Rows start>
                  <Cols span={4}>
                    <Text name>
                      Date<Required>*</Required>
                    </Text>
                  </Cols>
                  <Cols span={7}>
                    <AntForm.Item
                      style={{ width: '100%' }}
                      name="date"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <DatePicker
                        style={{
                          height: '35px',
                          width: '100%',
                          borderRadius: '5px',
                        }}
                      />
                    </AntForm.Item>
                  </Cols>
                  <Cols span={13}></Cols>
                </Rows>
              </Form>
              <Text subtitle>Customer Information</Text>
              <Rows start>
                <Cols span={4}>
                  <Text name>
                    Company Name<Required>*</Required>
                  </Text>
                </Cols>
                <Cols span={20}>
                  <AntForm.Item style={{ width: '100%' }} name="company_name">
                    <Input />
                  </AntForm.Item>
                </Cols>
              </Rows>
              <Rows start>
                <Cols span={4}>
                  <Text name>
                    Tel<Required>*</Required>
                  </Text>
                </Cols>
                <Cols span={7}>
                  <AntForm.Item
                    style={{ width: '100%' }}
                    name="tel"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </AntForm.Item>
                </Cols>
                <Cols span={2}></Cols>
                <Cols span={4}>
                  <Text name>
                    Fax<Required>*</Required>
                  </Text>
                </Cols>
                <Cols span={7}>
                  <AntForm.Item
                    style={{ width: '100%' }}
                    name="fax"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </AntForm.Item>
                </Cols>
              </Rows>
              <Rows start>
                <Cols span={4}>
                  <Text name>
                    Address<Required>*</Required>
                  </Text>
                </Cols>
                <Cols span={20}>
                  <AntForm.Item
                    style={{ width: '100%' }}
                    name="address"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextArea
                      rows={8}
                      style={{
                        width: '100%',
                        borderRadius: '5px',
                      }}
                    />
                  </AntForm.Item>
                </Cols>
              </Rows>
              <Rows start>
                <Cols span={4}>
                  <Text name>
                    Contact Person<Required>*</Required>
                  </Text>
                </Cols>
                <Cols span={7}>
                  <AntForm.Item
                    style={{ width: '100%' }}
                    name="contact_person"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </AntForm.Item>
                </Cols>
                <Cols span={9}></Cols>
              </Rows>
              <Text subtitle>Terms & Condition</Text>
              <Rows start>
                <Cols span={4}>
                  <Text name>1. Payment Term</Text>
                </Cols>
                <Cols span={20}>
                  <AntForm.Item
                    style={{ width: '100%' }}
                    name="payment_term"
                    initialValue=""
                  >
                    <TextArea rows={4}></TextArea>
                  </AntForm.Item>
                </Cols>
              </Rows>
              <Rows start>
                <Cols span={4}>
                  <Text name>2. Delivery</Text>
                </Cols>
                <Cols span={20}>
                  <AntForm.Item
                    style={{ width: '100%' }}
                    name="delivery"
                    initialValue="N/A"
                  >
                    <Input defaultValue="N/A"></Input>
                  </AntForm.Item>
                </Cols>
              </Rows>
              <Rows start>
                <Cols span={4}>
                  <Text name>3. Validity</Text>
                </Cols>
                <Cols span={20}>
                  <AntForm.Item
                    style={{ width: '100%' }}
                    name="validity"
                    initialValue="30 days upon quotation date"
                  >
                    <Input defaultValue="30 days upon quotation date"></Input>
                  </AntForm.Item>
                </Cols>
              </Rows>
              <Rows start>
                <Cols span={4}>
                  <Text name>4. Credit Term</Text>
                </Cols>
                <Cols span={20}>
                  <AntForm.Item
                    style={{ width: '100%' }}
                    name="credit_term"
                    initialValue="30 days"
                  >
                    <Input defaultValue="30 days"></Input>
                  </AntForm.Item>
                </Cols>
              </Rows>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonBlock width="200px">
                  <NormalButton
                    form="quotation-form"
                    grey
                    width="96px"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </NormalButton>
                  <NormalButton width="96px" form="my-form" htmlType="submit">
                    Submit
                  </NormalButton>
                </ButtonBlock>
              </div>
            </AntForm>
            <Text subtitle>Quotation Detail</Text>
            {this.state.projectType == 'OS' && <Form></Form>}
            {this.state.projectType == 'TK' && <Form></Form>}
          </TextCard>
        </ContentBox>
      </div>
    );
  }
}
